import {
  createColumnHelper,
  flexRender,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import XIcon from "../../../../../assets/icon/closeIcon.png";
import "@components/table.css";
import { useEditContentPayments } from "@hooks/admin";
import { ModifyDay } from "@components/core/calendar";
import moment from "moment";
import Empty from "@components/common/Empty";

interface Accounting {
  id?: number;
  paid_at?: string;
  released_at?: string;
  price?: string;
  payments?: { paid_at: string; price: number };
}

export default function AccountingDetailTable({ data: tableData }) {
  const [data, setData] = useState([]);
  const [columnOrder, setColumnOrder] = useState([]);
  const [date, setDate] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Accounting>({
    id: 0,
    paid_at: "",
    released_at: "",
    price: "",
  });
  const [price, setPrice] = useState<number>(0);

  useEffect(() => {
    if (tableData && tableData?.length > 0) {
      setData(tableData[0]._influencer?.contents || []);
    } else {
      setData([]);
    }
  }, [tableData]);

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("number", {
        cell: (info) => info.row.index + 1,
        header: "순번",
        maxSize: 10,
      }),
      columnHelper.accessor("author", {
        cell: ({ row }) => {
          return (
            <p title={row.original["title"]}>
              {tableData?.length > 0 ? tableData[0]._influencer.nickname : "-"}
            </p>
          );
        },
        header: "작성자",
        maxSize: 15,
      }),
      columnHelper.accessor("released_at", {
        cell: ({ row }) => {
          return convertToKoreanDate(row.original["released_at"]);
        },
        header: "발행날짜",
        maxSize: 15,
      }),
      columnHelper.accessor("settlementDate", {
        cell: ({ row }) => {
          return convertToKoreanDate(row.original["payments"][0]?.paid_at);
        },
        header: "정산날짜",
        maxSize: 15,
      }),
      columnHelper.accessor("settlementAmount", {
        cell: ({ row }) => {
          const price = row.original["payments"][0]?.price;
          return price ? price.toLocaleString() + "원" : "0원";
        },
        header: "정산금액",
        maxSize: 15,
      }),
      columnHelper.accessor("edit", {
        cell: ({ row }) => {
          const onClick = () => {
            setEditMode(true);
            setSelectedRow({
              id: row.original["payments"][0]?._id,
              released_at: row.original["released_at"],
              paid_at: row.original["payments"][0]?.paid_at,
              price: row.original["payments"][0]?.price,
            });
            setPrice(row.original["payments"][0]?.price);
          };
          return <StyledBtn onClick={onClick}>수정</StyledBtn>;
        },
        header: "",
        maxSize: 15,
      }),
      columnHelper.accessor("empty", {
        header: "",
        maxSize: 15,
      }),
    ];
  }, [tableData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      // columnVisibility,
      columnOrder,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const intParse = parseFloat(e.target.value.replace(/,/g, ""));
    if (isNaN(intParse)) {
      setPrice(0);
    } else {
      setPrice(intParse);
    }
  };

  const { mutate } = useEditContentPayments();

  const onSubmit = () => {
    mutate(
      {
        payments: [
          {
            id: selectedRow.id,
            paid_at: date
              ? moment(date).format("YYYY-MM-DD")
              : selectedRow.paid_at,
            price,
          },
        ],
      },
      {
        onSuccess: () => {
          setEditMode(false);
          alert("성공적으로 수정되었습니다.");
        },
      }
    );
  };

  return (
    <>
      <Container>
        <table className="contentsTable">
          <thead className="contentsThead">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="contentsHeader"
                    style={{ width: `${header.getSize()}` }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="contentsData">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <Empty data={data || []} title="해당기간 내 정산 내역이 없어요" />
      </Container>
      {editMode && (
        <>
          <BackDrop>
            <EditSlide>
              <img
                src={XIcon}
                style={{ width: 40, marginBottom: 80 }}
                onClick={() => setEditMode(false)}
                alt="닫기 버튼"
              />
              <h4 className="head">발행날짜</h4>
              <h2 className="body">
                {convertToKoreanDate(selectedRow.released_at)}
              </h2>
              <h4 className="head">정산날짜</h4>
              <ModifyDay
                setStartDate={setDate}
                date={date ? date : new Date(selectedRow.paid_at)}
              />
              <h4 className="head">정산금액</h4>
              <InputContainer>
                <input
                  type="text"
                  value={price ? Number(price).toLocaleString() : "0"}
                  onChange={handleChange}
                />
                <p className="unit">원</p>
              </InputContainer>

              <EditButton onClick={onSubmit}>
                <p>수정</p>
              </EditButton>
            </EditSlide>
          </BackDrop>
        </>
      )}
    </>
  );
}

const Container = styled.div`
  padding-top: 24px;
`;

const StyledBtn = styled.p`
  color: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;
`;

const BackDrop = styled.div`
  z-index: 10000;
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  background-color: #00000099;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const EditSlide = styled.div`
  position: fixed;
  right: 0;
  padding: 50px 40px;
  width: 30%;
  height: 100vh;
  background-color: white;

  .head {
    margin-bottom: 10px;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
    color: ${({ theme }) => theme.colors.blueGray600};
  }

  .body {
    margin-bottom: 40px;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px; /* 131.25% */
    letter-spacing: -0.96px;
    color: ${({ theme }) => theme.colors.blueGray800};
  }
`;

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 40px;

  input {
    width: 150px;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    font-family: Pretendard-SemiBold;
    line-height: 42px; /* 131.25% */
    letter-spacing: -0.96px;
    color: ${({ theme }) => theme.colors.blueGray800};
    border: none;

    &:focus {
      outline: none;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.blueGray400};
    font-size: 32px;
    font-weight: 600;
    line-height: 42px; /* 131.25% */
    letter-spacing: -0.96px;
  }
`;

const EditButton = styled.button`
  border-radius: 10px;
  padding: 14px 0;
  width: 60%;
  background-color: ${({ theme }) => theme.colors.blue100};
  cursor: pointer;

  p {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
    letter-spacing: -0.36px;
    color: ${({ theme }) => theme.colors.blue1000};
  }
`;
