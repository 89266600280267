// export const debounce = (callback: (text?: string) => any, delay: number) => {
//   let timeoutId: NodeJS.Timeout | null = null;

//   return (text?: string): Promise<boolean> => {
//     return new Promise((resolve) => {
//       if (timeoutId) {
//         clearTimeout(timeoutId); // 기존 타이머가 있으면 취소
//       }

//       timeoutId = setTimeout(() => {
//         const result = callback(text); // delay 후 콜백 실행
//         resolve(result); // 결과를 resolve
//       }, delay);
//     });
//   };
// };
export function debounce(callback, delay = 300) {
  let timeoutId;

  return (...args) => {
    // 기존 타이머를 클리어하여 이전 호출을 취소합니다.
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // 새로운 타이머를 설정하여 delay 이후에 callback이 실행되도록 합니다.
    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
}
