import { useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import moment from "moment";

import { Arrows18, Arrows22 } from "@components/core/icon/arrow";
import { ArrowDirectionEnum } from "@components/core/icon/arrow/Arrow.interfaces";
import CalendarInfoList from "@pages/admin/imjang/application/components/CalendarInfoList";
import kakaoPng from "@assets/logo/kakao.png";
import { useGetEventList } from "@hooks/imjang";

import "./Calendar.css";

export default function Calendar() {
  const [startDate, setStartDate] = useState(new Date());
  const formatDate = (date: Date) => moment(date).format("YYYY-MM-DD");

  const previousMonthFirstDay = useMemo(() => {
    const date = new Date(startDate);
    date.setMonth(date.getMonth() - 1);
    date.setDate(1);
    return formatDate(date);
  }, [startDate]);

  const nextMonthLastDay = useMemo(() => {
    const date = new Date(startDate);
    date.setMonth(date.getMonth() + 2);
    date.setDate(0); // Setting date to 0 will give the last day of the previous month
    return formatDate(date);
  }, [startDate]);

  const { data } = useGetEventList({
    start: previousMonthFirstDay,
    end: nextMonthLastDay,
  });

  const highlightDates = useMemo(() => {
    if (!data) return [];
    return data?.map((item) => new Date(item.date));
  }, [data]);

  const infoList = useMemo(() => {
    if (!data) return [];
    const findData = data.find((item) => formatDate(startDate) === item.date);
    if (findData) {
      return findData.eventList;
    } else {
      return [];
    }
  }, [data, startDate]);

  const renderDayContents = (day, date) => {
    const isHighlighted = highlightDates.some(
      (highlightDate) => date.toDateString() === highlightDate.toDateString()
    );

    return isHighlighted ? (
      <div style={{ position: "relative", width: "100%" }}>
        <Day>{day}</Day>
        <Dot />
      </div>
    ) : (
      <Day> {day}</Day>
    );
  };

  return (
    <Container>
      <KakaoChannel />
      <div style={{ width: 438 }} className="custom-calendar">
        <DatePicker
          inline
          renderCustomHeader={(props) => <CalendarHeader {...props} />}
          selected={startDate}
          onChange={(date: Date) => {
            setStartDate(date);
          }}
          onMonthChange={(date: Date) => {
            setStartDate(date);
          }}
          highlightDates={highlightDates}
          renderDayContents={renderDayContents}
          fixedHeight
        />
      </div>
      <Info data={infoList} />
    </Container>
  );
}

const CalendarHeader = ({ date, decreaseMonth, increaseMonth }) => {
  const month = date.getMonth() + 1;
  return (
    <DateContainer>
      <span className="month">{month >= 10 ? month : "0" + month}월</span>
      <div>
        <button onClick={decreaseMonth}>
          <Arrows18 direction={ArrowDirectionEnum.LEFT} />
        </button>
        <span>다음</span>
        <button onClick={increaseMonth}>
          <Arrows18 direction={ArrowDirectionEnum.RIGHT} />
        </button>
      </div>
    </DateContainer>
  );
};

const ITEMS_PER_PAGE = 5;

const Info = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentData = data.slice(startIndex, endIndex);

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => (prevPage === totalPages ? 1 : prevPage + 1));
  };

  return (
    <SelectedList>
      {currentData.length === 0 ? (
        <Empty>등록된 일정이 없습니다</Empty>
      ) : (
        <>
          <ul>
            {currentData.map((item, index) => (
              <CalendarInfoList key={index} {...item} />
            ))}
          </ul>
          <ButtonSection>
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              <Arrows22 direction={ArrowDirectionEnum.LEFT} />
            </button>
            <div>
              <span>{currentPage}</span>
              <span className="page"> / {totalPages}</span>
            </div>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <Arrows22 direction={ArrowDirectionEnum.RIGHT} />
            </button>
          </ButtonSection>
        </>
      )}
    </SelectedList>
  );
};

const KakaoChannel = () => {
  return (
    <KakaoButton
      href="https://center-pf.kakao.com/_wtlmK/chats"
      target="_blank"
      rel="noreferrer"
    >
      <img src={kakaoPng} alt="카카오톡 채널 바로가기" />
      <span>카카오채널 바로가기</span>
    </KakaoButton>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 290px;
  max-width: 812px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.white};
`;

const Day = styled.span`
  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: center;
`;

const Dot = styled.div`
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #f04452;
`;

const DateContainer = styled.div`
  position: absolute;
  left: -90px;
  top: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;

  .month {
    margin-bottom: 6px;
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.64px;
  }

  div {
    display: flex;
    align-items: center;
  }
`;

const SelectedList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin: 22px 0 22px 16px;
  padding-left: 12px;
  /* width: 374px; */
  border-left: 1px solid ${({ theme }) => theme.colors.blueGray100};

  ul {
    height: 100%;
    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({ theme }) => theme.colors.blueGray600};
  font-size: 15px;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.3px;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;

    .page {
      color: ${({ theme }) => theme.colors.blueGray400};
    }
  }
`;

const KakaoButton = styled.a`
  position: absolute;
  top: -52px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px 4px 10px;
  border-radius: 20px;
  background: #ffe500;

  img {
    width: 32px;
    height: 32px;
  }

  span {
    margin-left: 2px;
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;
