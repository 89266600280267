import styled from "styled-components";

import { Arrows18 } from "@components/core/icon/arrow";
import { Dispatch, SetStateAction, useMemo, useRef, useState } from "react";
import useClickOutside from "@hooks/useClickOutside";
import DropDownList from "@components/dropdown/DropDownList";

interface Props {
  placeholder: string;
  value: string;
  onChange:
    | ((v: string | number) => void)
    | ((v: string | number) => Dispatch<SetStateAction<string | number>>);
  optionList: { title: string; value: string }[];
  style?: React.CSSProperties;
}

export default function DropDownInput({
  placeholder,
  value,
  onChange,
  optionList = [
    {
      title: "",
      value: "",
    },
  ],
  style,
}: Props) {
  const inputRef = useRef(null);
  const dropDownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useClickOutside([inputRef, dropDownRef], () => setIsOpen(false));

  const handleSelect = (v) => {
    onChange(v);
    setIsOpen(false);
  };

  const displayedValue = useMemo(() => {
    const selectedValue = optionList.find((el) => el.value === value);
    return selectedValue ? selectedValue.title : null;
  }, [value, optionList]);

  return (
    <Container style={{ ...style }}>
      <Input onClick={() => setIsOpen(true)} ref={inputRef}>
        {displayedValue ? (
          <span>{displayedValue}</span>
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}
        <div style={{ position: "absolute", top: 11, right: 10 }}>
          <Arrows18 />
        </div>
      </Input>
      <div ref={dropDownRef}>
        {isOpen && (
          <DropDownList
            optionList={optionList}
            handleSelect={handleSelect}
            selectedValue={value}
            scrollHeight={200}
          />
        )}
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  flex: 1;
`;

const Input = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  width: 100%;
  padding: 8px 10px 8px 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  span {
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;

    &.placeholder {
      color: ${({ theme }) => theme.colors.blueGray500};
    }
  }
`;
