import PushGraph from "@components/page/admin/manage/push/PushGraph";
import { Title } from "@components/styles";
import styled from "styled-components";

export default function PushChartPage() {
  return (
    <Container>
      <Title>푸시 통계 차트</Title>
      <PushGraph />
    </Container>
  );
}

const Container = styled.div``;
