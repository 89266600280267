import styled from "styled-components";
import DaumPostcode from "react-daum-postcode";
import { useState } from "react";
import { convertAddressToCoord } from "@utils/address";
import { Arrows32 } from "@components/core/icon/arrow";
import {
  ArrowColorEnum,
  ArrowDirectionEnum,
} from "@components/core/icon/arrow/Arrow.interfaces";
import InputRow from "./InputRow";

export default function AddressInput({ value, onChange }) {
  const [isOpenPost, setIsOpenPost] = useState<boolean>(false);

  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
  };

  const onCompletePost = async (data: any) => {
    const response = await convertAddressToCoord(data.address);

    if (response) {
      onChange({
        name: "office",
        value: {
          address: response.address_name,
          latitude: Number(response.y),
          longitude: Number(response.x),
          bjdCode: data.bcode,
        },
      });
    }

    setIsOpenPost(false);
  };

  return (
    <>
      <InputRow title="직장위치">
        <Address onClick={onChangeOpenPost}>
          <SearchIcon />
          <input
            type="text"
            value={value?.address || ""}
            readOnly
            placeholder="직장 주소"
          />
        </Address>
      </InputRow>
      {isOpenPost ? (
        <PostCode>
          <Header>
            <span onClick={() => setIsOpenPost(false)}>
              <Arrows32
                direction={ArrowDirectionEnum.LEFT}
                color={ArrowColorEnum.B50}
              />
            </span>
          </Header>
          <DaumPostcode
            hideMapBtn
            autoClose
            onComplete={onCompletePost}
            style={{ width: "100%", height: "100%", zIndex: 1000 }}
          />
        </PostCode>
      ) : null}
    </>
  );
}
const PostCode = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

const Header = styled.div`
  display: flex;
  padding: 10px 20px;
  align-items: center;
  height: 68px;
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrayA200};
  cursor: pointer;
`;

const Address = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.white};

  input {
    border: none;
    outline: none;
    width: 100%;
    padding: 0;
    margin: 0;
    background: transparent;

    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
    cursor: pointer;

    &::placeholder {
      color: ${({ theme }) => theme.colors.blueGray500};
    }
  }

  span {
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;

const SearchIcon = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 18,
        width: 18,
        marginRight: 2,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.70657 2.36737C6.9951 1.6496 6.04437 1.25924 5.03697 1.25924C4.02958 1.25924 3.07885 1.6559 2.36738 2.36737C1.64961 3.07884 1.25924 4.02956 1.25924 5.03695C1.25924 6.04434 1.65591 6.99506 2.36738 7.70653C3.07885 8.4243 4.02958 8.81466 5.03697 8.81466C6.04437 8.81466 6.9951 8.418 7.70657 7.70653C8.42434 6.99506 8.8147 6.04434 8.8147 5.03695C8.8147 4.02956 8.41804 3.07884 7.70657 2.36737ZM1.47331 1.47331C2.46182 0.491103 3.74625 0 5.03697 0V0.00629619C6.3277 0.00629619 7.61842 0.497399 8.60063 1.4796C10.4053 3.28426 10.5521 6.1251 9.04092 8.09829L11.8048 10.8621C12.0651 11.1224 12.0651 11.5445 11.8048 11.8048C11.5445 12.0651 11.1224 12.0651 10.8621 11.8048L8.09729 9.03994C7.19512 9.72924 6.11826 10.0739 5.03697 10.0739C3.74625 10.0739 2.45552 9.5828 1.47331 8.60059C-0.491105 6.62988 -0.491105 3.44401 1.47331 1.47331Z"
          fill="#808690"
        />
      </svg>
    </div>
  );
};
