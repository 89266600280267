import { Props } from "./CheckBox.interfaces";

const bgColor = {
  Activate: "#1C84FF",
  Default: "#CFE4FD",
  Disabled: "#E5E7E9",
};
const lineColor = {
  Activate: "white",
  Default: "white",
  Disabled: "#B2B7BC",
};

export default function CheckBox22({ status }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <circle cx="11" cy="11" r="11" fill={bgColor[status]} />
      <path
        d="M16 8L9.925 14L7 11.7647"
        stroke={lineColor[status]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
