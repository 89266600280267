import { useDispatch, useSelector } from "react-redux";
import { setMenu, setSubMenu, setThemeMode } from "./routerSlice";

export const useMenu = () => {
  const currentMenu = useSelector((state) => state["router"].menu);
  const currentSubMenu = useSelector((state) => state["router"].subMenu);
  const globalThemeMode = useSelector((state) => state["router"].themeMode);

  const dispatch = useDispatch();
  const setCurrentMenu = (el) => {
    dispatch(setMenu(el));
  };
  const setCurrentSubMenu = (el) => {
    dispatch(setSubMenu(el));
  };
  const setGlobalThemeMode = (el) => {
    dispatch(setThemeMode(el));
  };

  return {
    currentMenu,
    currentSubMenu,
    globalThemeMode,
    setCurrentMenu,
    setCurrentSubMenu,
    setGlobalThemeMode,
  };
};
