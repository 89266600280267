import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import BTypeInputBoxTyping from "@components/core/BTypeInput/BTypeInputBoxTyping";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import StatusChip from "./StatusChip";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../../../../firebase";

export default function AddVersionPopUp({ setIsVisible, data }) {
  const [versionInfo, setVersionInfo] = useState({
    version: null,
    status: "develop",
    createdAt: null,
  });

  const 검증_통과 = () => {
    if (data.some((el) => el.id === versionInfo.version)) {
      alert("이미 존재하는 버전입니다.");
      return false;
    }

    if (!/^\d+(\.\d+){3,}$/.test(versionInfo.version)) {
      alert("올바른 버전명이 아닙니다. 다시 확인해주세요.");
      return false;
    }

    return true;
  };

  const onAdd = async () => {
    if (!검증_통과()) return;

    const versionRef = doc(db, "versions", versionInfo.version);
    const breakTimeRef = doc(db, "breakTimes", versionInfo.version);
    await setDoc(versionRef, {
      android: versionInfo.status,
      ios: versionInfo.status,
      createdAt: serverTimestamp(),
    });

    await setDoc(breakTimeRef, {});

    setIsVisible(false);
  };

  const isDone = useMemo(() => {
    return versionInfo.version && versionInfo.status;
  }, [versionInfo]);

  return (
    <Container>
      <Title>버전 추가</Title>
      <BTypeInputBoxTyping
        inputType={InputTypeEnum.OnlyText}
        placeholder={"버전명"}
        value={versionInfo.version}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setVersionInfo((prev) => ({ ...prev, version: v }))
        }
        style={{ padding: 0 }}
        inputStyle={{ paddingTop: 11, paddingBottom: 11 }}
      />
      <div className="versionInput">
        {versionInfo.status ? (
          <StatusChip state={versionInfo.status} onClick={onAdd} />
        ) : (
          <p>AOS 상태</p>
        )}
      </div>
      <p className="notice">
        * 새로 생성되는 버전의 상태는 <span>develop</span>으로 고정됩니다.
      </p>
      <Btn
        onClick={onAdd}
        $isDone={Boolean(isDone)}
        disabled={!Boolean(isDone)}
      >
        <p>저장</p>
      </Btn>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  width: 376px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};

  .versionInput {
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 11px 16px;
    margin-top: 12px;
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
    border: 1px solid ${({ theme }) => theme.colors.blueGray100};
    height: 48px;
    border-radius: 10px;
  }

  .notice {
    margin: 8px 0 12px;
    color: ${({ theme }) => theme.colors.blueGray500};

    font-size: 14px;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;

    span {
      padding: 2px 4px;
      border-radius: 4px;
      color: #eb5757;
      background-color: rgba(135, 131, 120, 0.15);
    }
  }
`;

const Title = styled.h1`
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.blueGray800};
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;

const Btn = styled.button<{ $isDone: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  padding: 9px 0;
  border-radius: 8px;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;

  p {
    color: ${({ theme, $isDone }) =>
      $isDone ? theme.colors.white : theme.colors.blueGray400};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const StateBox = styled.div`
  display: flex;
  margin-top: 20px;

  .list {
    margin-right: 8px;
  }
`;
