export default function EmptyProfile28() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="29"
        height="29"
        rx="14.5"
        fill="#F3F5F6"
        stroke="white"
      />
      <g clipPath="url(#clip0_22234_26864)">
        <circle cx="15.0008" cy="10.7986" r="4.2" fill="#B2BEC9" />
        <path
          d="M22.7008 21.2992C22.7008 23.0389 21.3008 23.3992 15.0008 23.3992C8.70078 23.3992 7.30078 23.0389 7.30078 21.2992C7.30078 18.8492 10.7482 15.6992 15.0008 15.6992C19.2534 15.6992 22.7008 18.4992 22.7008 21.2992Z"
          fill="#B2BEC9"
        />
      </g>
      <defs>
        <clipPath id="clip0_22234_26864">
          <rect
            width="22.4"
            height="22.4"
            fill="white"
            transform="translate(3.80078 3.79883)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
