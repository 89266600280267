import styled from "styled-components";
import { Fragment } from "react";

import AddressInput from "./AddressInput";
import CompanyInput from "./CompanyInput";
import BirthAndGender from "./BirthAndGender";
import RelationInput from "./RelationInput";
import { Close24 } from "@components/core/icon/close";
import CashInput from "./CashInput";
import { useUserInfo } from "@features/useUserInfo";
import { initialUserInfo } from "@features/userInfoSlice";

export default function FamilyInfo() {
  const { globalUserInfo, setGlobalHouseholdMemberList } = useUserInfo();
  if (!globalUserInfo) return null;
  const info = globalUserInfo.householdMemberList;

  const addFamily = () => {
    setGlobalHouseholdMemberList([
      ...info,
      {
        ...initialUserInfo.data.householdMemberList[0],
        id: `${new Date().getTime()}`,
      },
    ]);
  };

  const removeFamily = (id: string) => {
    setGlobalHouseholdMemberList(info.filter((el) => el.id !== id));
  };

  return (
    <Container>
      {globalUserInfo.householdMemberList?.map((item, index) => {
        const isApplicant = index === 0;
        const handleInfo = ({ name, value }) => {
          setGlobalHouseholdMemberList(
            info.map((el) => {
              if (el.id === item.id) {
                return {
                  ...el,
                  [name]: value,
                };
              }
              return el;
            })
          );
        };

        return (
          <Fragment key={item.id}>
            <InfoSection>
              <TitleRow>
                <span className="title">
                  {isApplicant ? "신청자" : "가구원"}
                </span>
                {!isApplicant && (
                  <button onClick={() => removeFamily(item.id)}>
                    <Close24 />
                  </button>
                )}
              </TitleRow>
              <div style={{ width: "100%" }}>
                {!isApplicant && (
                  <RelationInput
                    value={item.relationCode}
                    onChange={handleInfo}
                  />
                )}
                <CompanyInput value={item.jobCode} onChange={handleInfo} />
                <BirthAndGender
                  onChange={handleInfo}
                  value={{
                    birthDate: item.birthDate,
                    genderCode: item.genderCode,
                  }}
                />
                <CashInput value={item?.annualSalary} onChange={handleInfo} />
                <AddressInput value={item.office} onChange={handleInfo} />
              </div>
            </InfoSection>
            {index !== info.length - 1 && <Divider />}
          </Fragment>
        );
      })}

      <AddFamily onClick={addFamily}>
        <span className="plus">+</span>
        <span>가구원 추가</span>
      </AddFamily>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 24px 40px;
  width: 100%;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 12px;
  width: 100%;
  span {
    color: ${({ theme }) => theme.colors.gray900};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;

const AddFamily = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 9px 0;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.blue100};

  .plus {
    margin-right: 6px;
  }

  span {
    color: ${({ theme }) => theme.colors.blue1000};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
  }
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const Divider = styled.div`
  margin: 24px 0 20px 0;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blueGrayA200};
`;
