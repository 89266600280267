import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import MainNews from "./MainNews";

export default function Main() {
  return (
    <StyledContainer>
      <MainNews />
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  padding: 48px 0 60px;
  display: flex;
  justify-content: center;
`;
