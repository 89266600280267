import { Props } from "./CheckedCircle.interfaces";

export default function CheckedCircle24({
  color,
  status,
  activeColor = "#1C84FF",
}: Props) {
  const colorMap = {
    Default: { fill: "#E5E7E9", stroke: "#B2B7BC" },
    Activate: { fill: activeColor, stroke: "#fff" },
  };
  const fillColor = color || colorMap[status].fill;
  const strokeColor = color || colorMap[status].stroke;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="12" fill={fillColor} />
      <path
        d="M17 9L10.25 16L7 13.3922"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
