import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editPermission: false,
  searchFilter: {
    applyUserName: "",
    madminId: "",
  },
};

export const imjangSlice = createSlice({
  name: "imjang",
  initialState,
  reducers: {
    setEditPermission: (state, action) => {
      state.editPermission = action.payload;
    },
    setSearchFilter: (state, action) => {
      state.searchFilter = { ...state.searchFilter, ...action.payload };
    },
  },
});

export const { setEditPermission, setSearchFilter } = imjangSlice.actions;

export default imjangSlice.reducer;
