export default function Question20() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="9.5" fill="white" stroke="#E5E7E9" />
      <path
        d="M8.67683 12.3777C8.67683 10.5961 9.11789 10.0417 9.92886 9.50941C10.5549 9.08804 11.0315 8.67406 11.0386 8.03091C11.0315 7.38777 10.5407 6.95901 9.92886 6.9664C9.32419 6.95901 8.77642 7.40255 8.7622 8.14919H6.5C6.52846 5.9832 8.07215 5 9.94309 5C12.0061 5 13.4929 6.03495 13.5 7.91263C13.4929 9.16196 12.8598 9.92339 11.9065 10.5148C11.1382 10.9805 10.7754 11.4388 10.7683 12.3777V12.5847H8.67683V12.3777ZM8.47764 14.6546C8.46341 13.9301 9.05386 13.3387 9.75813 13.3387C10.4411 13.3387 11.0457 13.9301 11.0528 14.6546C11.0457 15.4012 10.4411 16 9.75813 16C9.05386 16 8.46341 15.4012 8.47764 14.6546Z"
        fill="#4D5664"
      />
    </svg>
  );
}
