import { createSlice } from "@reduxjs/toolkit";

export const initialConsultantInfo = {
  influencer: undefined,
  title: "",
  profileImgUrl: null,
  keywords: [],
  methods: [],
  providedItem: "",
  exposures: [
    {
      title: "spotlight",
      state: false,
      imgUrl: null,
    },
    {
      title: "expertAdvice",
      state: false,
      imgUrl: null,
    },
  ],
  mainImgUrl: null,
  introImgUrl: null,
  description: "",
  count: 0,
  reportDetails: { isShow: undefined, influencerName: undefined },
  frequentVisitImgUrl: null,
  detailImgUrl: null,
  youtubeDetails: { url: null, description: null },
  paymentUrl: null,
  reviews: [],
};

export const consultantSlice = createSlice({
  name: "consultant",
  initialState: {
    data: {
      influencer: undefined,
      title: "",
      profileImgUrl: null,
      keywords: [],
      methods: [],
      providedItem: "",
      exposures: [
        {
          title: "spotlight",
          state: false,
          imgUrl: null,
        },
        {
          title: "expertAdvice",
          state: false,
          imgUrl: null,
        },
      ],
      mainImgUrl: null,
      introImgUrl: null,
      description: "",
      count: 0,
      reportDetails: { isShow: undefined, influencerName: undefined },
      frequentVisitImgUrl: null,
      detailImgUrl: null,
      youtubeDetails: { url: null, description: null },
      paymentUrl: null,
      reviews: [],
    },
  },
  reducers: {
    setConsultantInfo: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { setConsultantInfo } = consultantSlice.actions;

export default consultantSlice.reducer;
