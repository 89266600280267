import { Instance } from "@api/index";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

export const useGetIRApplicant = () =>
  useQuery({
    queryKey: ["IR"],
    queryFn: async () => {
      const response = await Instance({
        url: `/admin/IR/applicants`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 1000 * 60,
  });

export const useGetIRApplicantDetail = ({ id }) =>
  useQuery({
    queryKey: ["IR", id],
    queryFn: async () => {
      const response = await Instance({
        url: `/admin/IR/applicant/${id}`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 1000 * 60,
  });
