import styled from "styled-components";
import BTypeInputBoxTyping from "@components/core/BTypeInput/BTypeInputBoxTyping";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import { useEffect, useMemo, useState } from "react";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import Clock from "@components/core/calendar/Clock";
import { db } from "../../../../../firebase";
import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";

const Section = ({ title, bodyComponent }) => {
  return (
    <Row>
      <div className="head">{title}</div>
      <div className="body">{bodyComponent}</div>
    </Row>
  );
};

const init = {
  title: "",
  content: null,
  startTime: null,
  endTime: null,
  memo: "",
};

export default function AddBreakTimePopUp({ setIsVisible, data }) {
  const params = useParams();
  const id = params.id;
  const [breakInfo, setBreakInfo] = useState({
    ...init,
  });

  useEffect(() => {
    if (data) {
      setBreakInfo({ ...data });
    } else setBreakInfo({ ...init });
  }, [data]);

  const handleSubmit = async () => {
    const breakTimeRef = doc(db, "breakTimes", id);

    if (!data) {
      // 생성할 때
      await updateDoc(breakTimeRef, {
        data: arrayUnion(breakInfo),
      });
    } else {
      // // 수정할 때
      await updateDoc(breakTimeRef, {
        data: arrayRemove(data),
      });
      await updateDoc(breakTimeRef, {
        data: arrayUnion(breakInfo),
      });
    }
  };

  const isDone = useMemo(() => {
    return Boolean(
      breakInfo.title &&
        breakInfo.content &&
        breakInfo.startTime &&
        breakInfo.endTime
    );
  }, [breakInfo]);

  return (
    <Container>
      <Title>break time</Title>
      <Section
        title="제목"
        bodyComponent={
          <BTypeInputBoxTyping
            inputType={InputTypeEnum.OnlyText}
            placeholder={"제목"}
            value={breakInfo.title}
            onChangeValue={(v: InputTypeEnum.Number) =>
              setBreakInfo((prev) => ({ ...prev, title: v }))
            }
            style={{ padding: 0 }}
            inputStyle={{ paddingTop: 11, paddingBottom: 11 }}
          />
        }
      ></Section>
      <Section
        title="내용"
        bodyComponent={
          <BTypeTextArea
            placeholder="내용"
            textareaStyle={{
              maxWidth: 500,
              minHeight: 100,
              height: 100,
            }}
            value={breakInfo.content}
            onChangeValue={(v: InputTypeEnum.OnlyText) =>
              setBreakInfo((prev) => ({ ...prev, content: v }))
            }
          />
        }
      ></Section>
      <Section
        title="시작시간"
        bodyComponent={
          <Clock
            value={breakInfo.startTime}
            onChangeValue={(v: any) =>
              setBreakInfo((prev) => ({ ...prev, startTime: v }))
            }
          />
        }
      ></Section>
      <Section
        title="종료시간"
        bodyComponent={
          <Clock
            value={breakInfo.endTime}
            onChangeValue={(v: any) =>
              setBreakInfo((prev) => ({ ...prev, endTime: v }))
            }
          />
        }
      ></Section>
      <Section
        title="메모"
        bodyComponent={
          <BTypeTextArea
            placeholder="메모"
            textareaStyle={{
              maxWidth: 500,
              minHeight: 100,
              height: 100,
            }}
            value={breakInfo.memo}
            onChangeValue={(v: InputTypeEnum.OnlyText) =>
              setBreakInfo((prev) => ({ ...prev, memo: v }))
            }
          />
        }
      ></Section>

      <Btn
        onClick={() => {
          setIsVisible(false);
          handleSubmit();
        }}
        $isDone={isDone}
      >
        <p>저장</p>
      </Btn>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  width: 462px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  .head {
    min-width: 80px;
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }

  .body {
    flex: 1;
  }
`;

const Trow = styled.div`
  display: flex;

  p {
    margin: 0 24px 0 12px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blueGray800};
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;

const Btn = styled.div<{ $isDone: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  padding: 9px 0;
  border-radius: 8px;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;
