import styled from "styled-components";
import { Close32 } from "../icon/close";
import { PopUpContainer } from "../popUp/PopupContainer";

interface Props {
  title?: string;
  onCloseButton?: boolean;
  children: React.ReactNode;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  bottomButton?: React.ReactNode;
  ContainerHeight?: number;
}

export default function ModalContainer({
  title,
  onCloseButton,
  children,
  isVisible,
  setIsVisible,
  bottomButton,
}: Props) {
  return (
    <PopUpContainer isVisible={isVisible} setIsVisible={setIsVisible}>
      <Container>
        <div className="title">
          <span>{title}</span>
        </div>
        {onCloseButton && (
          <button className="close" onClick={() => setIsVisible(false)}>
            <Close32 />
          </button>
        )}
        <div className="body">{children}</div>
        <div style={{ padding: "0 8px" }}>{bottomButton && bottomButton}</div>
      </Container>
    </PopUpContainer>
  );
}

const Container = styled.div`
  position: relative;
  padding: 24px 8px 0px;
  width: fit-content;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    span {
      color: ${({ theme }) => theme.colors.blueGray1000};
      font-size: 20px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.4px;
    }
  }
  .close {
    position: absolute;
    top: 20px;
    right: 16px;
    display: flex;
    align-items: center;
  }

  .body {
    max-height: 594px;
    overflow-y: auto;
  }
`;
