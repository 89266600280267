import React, { useState, useRef, useEffect } from "react";

import styled from "styled-components";
import DownArrow from "@assets/arrow/DownArrow";

import ApartmentSelectModal from "./ApartmentSelectModal";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { useParams } from "react-router-dom";
import { useGetImjangApplicantDetail } from "@hooks/imjang";

// 대표 아파트 중 아파트 선택 인풋
export default function ApartmentSelectInput({
  value,
  style,
  inputStyle,
  placeholder,
  disabled,
  onChangeValue,
}: {
  value: any;
  placeholder: string;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  disabled?: boolean;
  onChangeValue?: (v: any) => void;
}) {
  const { id } = useParams();
  const { data } = useGetImjangApplicantDetail({ id });
  const [isVisible, setIsVisible] = useState(false);

  const openPopUp = () => {
    setIsVisible(true);
  };

  return (
    <Container
      style={{ ...style }}
      value={value.danjiName}
      $disabled={disabled}
      onClick={openPopUp}
    >
      <InputContainer $disabled={disabled} style={{ ...inputStyle }}>
        <p className="value">
          {value.danjiName ? value?.danjiName : placeholder}
        </p>
        <DownArrow />
      </InputContainer>
      <PopUpContainer isVisible={isVisible} setIsVisible={setIsVisible}>
        <ApartmentSelectModal
          value={value}
          setIsVisible={setIsVisible}
          onChangeValue={onChangeValue}
          data={data?.danjiInfo || []}
        />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div<{ value: string | number; $disabled: boolean }>`
  position: relative;

  .value {
    color: ${({ theme, value }) =>
      value ? theme.colors.blueGray800 : theme.colors.blueGray500};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const InputContainer = styled.div<{ $disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  height: 48px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background-color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.blueGray100 : theme.colors.blueGrayA100};
`;
