import { Arrows22 } from "@components/core/icon/arrow";
import { ArrowDirectionEnum } from "@components/core/icon/arrow/Arrow.interfaces";
import { useEffect, useState } from "react";
import styled from "styled-components";
import MaemulTable from "./MaemulTable";

export default function SearchDanji({ setSelectedValue }) {
  const [pushInfo, setPushInfo] = useState({ keyword: "", type: "아파트" });
  const [data, setData] = useState([]);

  const fetching = async ({ keyword, type }) => {
    try {
      const response = await fetch(
        `https://m.boodong.kr/search/esSearch/api?keyword=${keyword}&category=${type}&limit=100`
      );
      const data = await response.json();

      setData(data.elastic.hits.hits);
      return data;
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    fetching({ keyword: pushInfo.keyword, type: pushInfo.type });
  }, [pushInfo]);

  return (
    <Container>
      <Row>
        <InputContainer>
          <select
            onChange={(e) =>
              setPushInfo((prev) => ({
                ...prev,
                type: e.target.value,
              }))
            }
            style={{
              color: pushInfo.type ? "#333e4d" : "#808690",
            }}
            value={pushInfo.type || "default"}
            required
          >
            <option value={"default"} disabled>
              건물 유형을 선택해주세요.
            </option>
            <option value={"아파트"}>아파트</option>
            <option value={"연립다세대"}>빌라</option>
          </select>
          <div style={{ position: "absolute", right: 16, top: 10 }}>
            <Arrows22 direction={ArrowDirectionEnum.DOWN} />
          </div>
        </InputContainer>
        <Input
          placeholder="검색하기"
          style={{ width: "100%" }}
          value={pushInfo.keyword}
          onChange={(e) =>
            setPushInfo((prev) => ({ ...prev, keyword: e.target.value }))
          }
        />
      </Row>
      <MaemulTable data={data || []} setSelectedValue={setSelectedValue} />
    </Container>
  );
}

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
`;

const Input = styled.input`
  padding: 10px 20px;
  flex: 1;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  &:focus {
    outline: none;
  }
`;

const InputContainer = styled.div`
  position: relative;
  padding: 10px 20px;
  flex: 0.3;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  select {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
    width: 100%;
    border: none;

    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
    appearance: none;

    &:focus {
      outline: none;
    }
  }
`;
