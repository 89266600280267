import PermissionLayout from "@components/router/PermissionLayout";
import { ApplicantDetail, ClosingRatePage } from "@pages/admin/imjang";
import RealEstateAgent from "@pages/admin/imjang/application/RealEstateAgent";
import { Route, Routes } from "react-router-dom";

export default function VisitRouter({ pageValue }) {
  return (
    <Routes>
      <Route
        element={
          <PermissionLayout pageValue={pageValue} style={{ padding: 0 }} />
        }
      >
        <Route index element={<RealEstateAgent />} />
        <Route path=":id" element={<ApplicantDetail />} />
        <Route path=":id/closing-rate" element={<ClosingRatePage />} />
      </Route>
    </Routes>
  );
}
