import { Close22 } from "@components/core/icon/close";
import { CSSProperties } from "react";
import styled from "styled-components";
// import { StyledComponentProps } from "styled-components";

export default function StatusChangePopUp({
  setIsVisible,
  currentState,
  handleState,
}) {
  const onClick = (value) => {
    if (value === "PDF&코인지급") {
      handleState({ selectedState: encodeURIComponent("PDF&코인지급") });
    } else {
      handleState({ selectedState: value });
    }

    setIsVisible(false);
  };

  const statusArr = [
    "상담신청",
    "입금요청",
    "사전질문작성",
    "추가질문검토",
    "추가질문작성",
    "일정조율",
    "보고서 작성",
    "보고서 승인",
    "컨설팅 완료",
    "후기작성완료",
    "PDF&코인지급",
  ];

  return (
    <Container>
      <Title>진행상태</Title>
      <IconBox
        onClick={() => {
          setIsVisible(false);
        }}
      >
        <Close22 />
      </IconBox>

      <BoxWrapper>
        {statusArr.map((el, idx) => (
          <Box
            key={idx}
            $active={currentState === el}
            onClick={() => {
              onClick(el);
            }}
          >
            <BoxText>{el}</BoxText>
          </Box>
        ))}

        <Box
          $active={currentState === "상담취소"}
          onClick={() => {
            onClick("상담취소");
          }}
        >
          <BoxText style={{ color: "#F04452" }}>상담취소</BoxText>
        </Box>
      </BoxWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px 18px;
  width: 332px;
  height: fit-content;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const IconBox = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blueGray800};
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;

const BoxWrapper = styled.article`
  margin-top: 22px;
`;

const Box = styled.div<{ $active: boolean }>`
  padding: 12px 0;
  border-radius: 8px;
  background-color: ${({ theme, $active }) =>
    $active ? theme.colors.blue200 : "white"};
  cursor: pointer;
`;

const BoxText = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.colors.blueGray700};
`;
