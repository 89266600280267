import moment from "moment";
import styled from "styled-components";
import Indicator from "./Indicator";
import { useState } from "react";
import { useGetNews } from "@api/management/news";

export default function MainNews() {
  const { data } = useGetNews();
  const [current, setCurrent] = useState(1);

  if (!data) return;

  return (
    <Container>
      <h1 className="title">부동부동의 소식을 알려드려요</h1>
      <NewsList>
        {data.news.slice(current * 5 - 5, current * 5).map((el, idx) => (
          <li
            className="newsItem"
            key={idx}
            style={{ borderTopWidth: idx === 0 ? 1 : 0 }}
            onClick={() => window.open(el.url)}
          >
            <p className="releasedAt">
              {moment(el.releasedAt).format("YYYY.MM.DD")}
            </p>
            <h3 className="newsTitle"> {el.title}</h3>
          </li>
        ))}
      </NewsList>
      <Indicator
        total={data.news.length || 0}
        current={current}
        setCurrent={setCurrent}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 970px;
  min-width: 970px;

  .title {
    margin-bottom: 32px;
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 32px;
    font-weight: 600;
    line-height: 42px; /* 131.25% */
    letter-spacing: -0.64px;
  }
`;

const NewsList = styled.ul`
  width: 100%;

  .newsItem {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 36px 0;
    gap: 8px;
    border-top: 1px solid ${({ theme }) => theme.colors.blueGray200};
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray200};
    cursor: pointer;

    .releasedAt {
      color: ${({ theme }) => theme.colors.blueGray400};
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .newsTitle {
      overflow: hidden;
      color: ${({ theme }) => theme.colors.blueGray900};
      text-overflow: ellipsis;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
    }
  }
`;
