import BTypeBtn48 from "@components/core/BTypeInput/BTypeBtn48/BTypeBtn48";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import { TextInputEnum } from "@components/input/BTypeText48/BTypeText.interfaces";
import BTypeText48 from "@components/input/BTypeText48/BTypeText48";
import styled from "styled-components";
import { unixTime } from "./VersionDetailPage";

const statusList = [
  { label: "상태 없음", value: undefined },
  { label: "develop", value: "develop" },
  { label: "live", value: "live" },
  { label: "review", value: "review" },
  { label: "deprecated", value: "deprecated" },
];

export default function VersionInput({ versionData, setVersionData }) {
  return (
    <Container>
      <div className="table">
        <TRow>
          <TData>
            <div className="head">
              <p>생성 일시</p>
            </div>
            <div className="body" style={{ width: "100%" }}>
              <BTypeText48
                placeholder="버전 생성 일시"
                textType={TextInputEnum.DEFAULT}
                value={
                  versionData.createdAt ? unixTime(versionData.createdAt) : ""
                }
                disabled
                onChangeValue={() => {}}
              />
            </div>
          </TData>
        </TRow>
        {versionData.status && (
          <TRow>
            <TData>
              <div className="head">
                <p>(구)상태</p>
              </div>
              <div className="body">
                <BTypeBtn48
                  inputType={InputTypeEnum.OnlyText}
                  value={versionData.status}
                  placeholder="(구)상태"
                  onChangeValue={(value) =>
                    setVersionData((prev) => ({ ...prev, status: value }))
                  }
                  optionList={statusList}
                />
              </div>
            </TData>
          </TRow>
        )}

        <TRow>
          <TData>
            <div className="head">
              <p>AOS 상태</p>
            </div>
            <div className="body">
              <BTypeBtn48
                inputType={InputTypeEnum.OnlyText}
                value={versionData.android}
                placeholder="AOS 상태"
                onChangeValue={(value) =>
                  setVersionData((prev) => ({ ...prev, android: value }))
                }
                optionList={statusList}
              />
            </div>
          </TData>
          <TData>
            <div className="head">
              <p>IOS 상태</p>
            </div>
            <div className="body" style={{ height: 74 }}>
              <BTypeBtn48
                inputType={InputTypeEnum.OnlyText}
                value={versionData.ios}
                placeholder="IOS 상태"
                onChangeValue={(value) =>
                  setVersionData((prev) => ({ ...prev, ios: value }))
                }
                optionList={statusList}
              />
            </div>
          </TData>
        </TRow>

        <TRow>
          <TData>
            <div className="head" style={{ alignItems: "flex-end" }}>
              <p>메모</p>
            </div>
            <div className="body">
              <BTypeTextArea
                placeholder="버전 정보에 대한 메모를 작성해주세요"
                value={versionData.memo}
                maxLength={120}
                onChangeValue={(v) =>
                  setVersionData((prev) => ({
                    ...prev,
                    memo: v,
                  }))
                }
                textareaStyle={{ height: 100, fontSize: 15 }}
              />
            </div>
          </TData>
        </TRow>
      </div>
    </Container>
  );
}

const Container = styled.div``;

const TRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 52px;
  margin-bottom: 8px;
`;

const TData = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  .head {
    display: flex;
    align-items: center;
    position: relative;
    top: 13px;
    min-width: 140px;
    height: fit-content;

    p {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 15px;
      font-weight: 600;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.3px;
    }

    span {
      margin-left: 4px;
      cursor: pointer;
    }
  }

  .body {
    flex: 1;
  }
`;
