import Customer from "@pages/admin/imjang/application/components/Customer";
import styled from "styled-components";

export default function NewApplication() {
  return (
    <Container>
      <h2>임장 비서 신청 관리</h2>
      <Customer />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 16px;

  h2 {
    position: absolute;
    top: -47px;
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.4px;
  }
`;
