import SourceInput from "@components/page/admin/consultation/source/SourceInput";
import { Title } from "@components/styles";
import { usePostSource } from "@hooks/consultation";
import { uploadImageToS3 } from "@utils/file";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
export default function AddSource() {
  const navigate = useNavigate();
  const { mutate } = usePostSource();
  const [sourceInfo, setSourceInfo] = useState({
    title: "",
    data: { fileName: "", file: null },
    presignedUrl: null,
  });

  const onSubmit = async () => {
    try {
      await uploadImageToS3(sourceInfo.presignedUrl, sourceInfo.data.file);
      const { presignedUrl, ...body } = { ...sourceInfo };
      body.data.file = "";
      await mutate(body, {
        onSuccess: (res) => {
          alert("등록 완료");
          navigate(-1);
        },
      });
    } catch (error) {
      console.error("Error uploading image and mutating:", error);
      // 에러 처리 로직 추가
    }
  };

  return (
    <Container>
      <Title>자료 추가</Title>
      <SourceInput
        onSubmit={onSubmit}
        sourceInfo={sourceInfo}
        setSourceInfo={setSourceInfo}
      />
    </Container>
  );
}

const Container = styled.div``;
