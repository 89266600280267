import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

interface Props {
  text?: string;
  applyId?: string;
}

export default function CalendarInfoList({
  text = "~님의 임장일 입니다.",
  applyId,
}: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  const goToDetail = () => {
    // 어드민과 인플루언서 페이지 분리
    if (location.pathname.includes("/visit")) {
      navigate(`/visit/${applyId}`);
    } else navigate(`/imjang/applicant/${applyId}`);
  };
  return (
    <Row onClick={goToDetail}>
      <div>
        <svg width="6" height="6" viewBox="0 0 6 6" fill="none">
          <circle cx="3" cy="3" r="3" fill="#CCCFD3" />
        </svg>
      </div>
      <span>{text}</span>
    </Row>
  );
}

const Row = styled.li`
  display: flex;
  align-items: center;
  padding: 4px 6px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }

  span {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 300px;
  }
`;
