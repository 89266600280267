import styled from "styled-components";
import AddButton from "../../AddButton";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { useState } from "react";
import KeywordsInputPopUp from "./KeywordsInputPopUp";
import { useConsultantInfo } from "@features/useConsultantInfo";
import { Close16 } from "@components/core/icon/close";
import PencilIcon from "@assets/icon/PencilIcon";

export default function Keywords() {
  const [isVisible, setIsVisible] = useState(false);
  const { globalConsultantInfo, setGlobalConsultantInfo } = useConsultantInfo();

  const openPopUp = () => {
    setIsVisible(true);
  };

  const handleDelete = (value) => {
    let keywordArr = [...globalConsultantInfo.keywords];
    const filterArr = keywordArr.filter((el) => el && el !== value);
    setGlobalConsultantInfo({ keywords: filterArr });
  };

  return (
    <>
      <Container>
        {globalConsultantInfo.keywords.length > 0 ? (
          globalConsultantInfo.keywords.map((el, index) => {
            if (!el) return null;
            return (
              <KeywordBox key={index}>
                <p>{el}</p>
                <div className="iconBox" onClick={() => handleDelete(el)}>
                  <Close16 />
                </div>
              </KeywordBox>
            );
          })
        ) : (
          <AddButton onClick={openPopUp} />
        )}
        {globalConsultantInfo.keywords.length > 0 && (
          <div className="iconBox" onClick={openPopUp}>
            <PencilIcon />
          </div>
        )}
      </Container>
      <PopUpContainer isVisible={isVisible} setIsVisible={setIsVisible}>
        <KeywordsInputPopUp setIsVisible={setIsVisible} />
      </PopUpContainer>
    </>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;

  .iconBox {
    margin-left: auto;
    cursor: pointer;
  }
`;

const KeywordBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 8px;
  padding: 9px 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlue100};
  background: var(--White, #fff);

  p {
    margin-right: 6px;
    color: ${({ theme }) => theme.colors.primaryBlue100};
    font-size: 15px;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;
