import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";

export default function LoginReq() {
  const navigate = useNavigate();

  return (
    <Container>
      <H1>회원가입에 문제가 발생했나요?</H1>
      <H3>우측 하단 챗봇으로 문의주시면 빠르게 해결해드릴게요!</H3>
      <ButtonRow>
        <button onClick={() => navigate(-1)}>
          <p>뒤로가기</p>
        </button>
      </ButtonRow>
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const H1 = styled.h1`
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 87.5% */
  letter-spacing: -0.64px;
  color: ${({ theme }) => theme.colors.blueGray800};
`;

const H3 = styled.h3`
  margin-top: 24px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
  color: ${({ theme }) => theme.colors.blueGray600};
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;

  button {
    width: 102px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.blue1000};
    color: ${({ theme }) => theme.colors.blue1000};
    cursor: pointer;

    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.36px;
    }
  }

  .fill {
    margin-left: 16px;
    background-color: ${({ theme }) => theme.colors.blue1000};
    color: #fff;
  }
`;
