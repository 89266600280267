export default function MasterBadge() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_21919_34737)" />
      <path
        d="M14.6699 12.3452C13.9369 11.6871 13.1534 11.0568 12.3213 10.4602C11.9932 10.224 11.4873 10.2215 11.157 10.4545C10.3547 11.0178 9.59672 11.6125 8.88476 12.2332C8.65576 12.4324 8.31878 12.5115 8.01001 12.4391C6.83673 12.1628 5.62058 11.9583 4.36873 11.8341C3.75096 11.7724 3.20495 12.2796 3.35107 12.7617C3.60128 13.6181 3.85152 14.4745 4.10171 15.331C4.26397 15.8864 4.42624 16.4418 4.58851 16.9972C4.69806 17.3649 5.09113 17.5734 5.46906 17.4762C9.48154 16.4421 13.6933 16.4421 17.7058 17.4762C18.0837 17.5734 18.4768 17.3648 18.5863 16.9972C18.7486 16.4418 18.9109 15.8864 19.0731 15.331C19.3481 14.3899 19.623 13.4489 19.8979 12.5079C20.0589 11.9798 19.3883 11.4439 18.7405 11.5989C17.6736 11.8532 16.6452 12.1649 15.6598 12.5273C15.3259 12.6496 14.9287 12.5766 14.6699 12.3452Z"
        fill="white"
      />
      <ellipse
        cx="2.9957"
        cy="9.95301"
        rx="0.995699"
        ry="0.984365"
        fill="white"
      />
      <ellipse
        cx="20.2545"
        cy="9.95301"
        rx="0.995699"
        ry="0.984365"
        fill="white"
      />
      <ellipse
        cx="11.6256"
        cy="7.98436"
        rx="0.995699"
        ry="0.984365"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_21919_34737"
          x1="-26.25"
          y1="39.6667"
          x2="25.0989"
          y2="-34.5473"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.125575" stopColor="white" />
          <stop offset="0.489798" stopColor="#FF50F8" />
          <stop offset="0.80678" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}
