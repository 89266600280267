import styled from "styled-components";

import { useMemo } from "react";
import { ConsultationStatusEnum } from "@hooks/consultation/Consulting.interfaces";

export default function StatusChip({ status }) {
  const bgColor = useMemo(() => {
    switch (status) {
      case ConsultationStatusEnum.상담신청:
        return "#E8F3FF";
      case ConsultationStatusEnum.상담취소:
        return "#FDECEE";
      default:
        return "#F4F6F8";
    }
  }, [status]);

  const textColor = useMemo(() => {
    switch (status) {
      case ConsultationStatusEnum.상담신청:
        return "#1C84FF";
      case ConsultationStatusEnum.상담취소:
        return "#F04452";
      default:
        return "#4D5664";
    }
  }, [status]);

  return (
    <Container style={{ backgroundColor: bgColor }}>
      <Text style={{ color: textColor }}>{status}</Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 32px;
  border-radius: 8px;
`;

const Text = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
`;
