import {
  forwardRef,
  HTMLProps,
  useEffect,
  useMemo,
  useImperativeHandle,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getCoreRowModel } from "@tanstack/react-table";
import styled from "styled-components";
import cn from "classnames";
import Pagination from "@components/core/paging/Pagination";
import "@components/table.css";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import Empty from "@components/common/Empty";
import { CheckBoxEnum, SquareCheckBox20 } from "@components/core/icon/checkBox";
import { StoryObj } from "@api/management/story/story.interfaces";
import TableSkeleton from "@components/skeleton/TableSkeleton";

const StoryTable = forwardRef<any, { data: StoryObj[]; isLoading: boolean }>(
  ({ data, isLoading }, ref) => {
    const navigate = useNavigate();

    const columnHelper = createColumnHelper();
    const columns = useMemo(() => {
      return [
        columnHelper.accessor("checkBox", {
          header: () => (
            <Center onClick={table.getToggleAllRowsSelectedHandler()}>
              <SquareCheckBox20
                status={
                  table.getIsAllRowsSelected()
                    ? CheckBoxEnum.Activate
                    : CheckBoxEnum.Default
                }
              />
            </Center>
          ),
          cell: ({ row }) => {
            return (
              <Center onClick={row.getToggleSelectedHandler()}>
                <SquareCheckBox20
                  status={
                    row.getIsSelected()
                      ? CheckBoxEnum.Activate
                      : CheckBoxEnum.Default
                  }
                />
              </Center>
            );
          },
          maxSize: 5,
        }),
        columnHelper.accessor("createdAt", {
          cell: ({ getValue }) => {
            return convertToKoreanDate(getValue());
          },
          header: "등록 날짜",
          maxSize: 10,
        }),
        columnHelper.accessor("notionUrl", {
          cell: ({ getValue }) => {
            return (
              <StyledLink href={`${getValue()}`} target="_blank">
                이동
              </StyledLink>
            );
          },
          header: "노션 링크",
          maxSize: 10,
        }),
        columnHelper.accessor("title", {
          cell: ({ row }) => {
            return (
              <Title title={row.original["title"]}>
                {row.original["title"]}
              </Title>
            );
          },
          header: "컨텐츠 제목",
          maxSize: 30,
        }),
        columnHelper.accessor("area", {
          header: "지역",
          maxSize: 15,
        }),
        columnHelper.accessor("householdNum", {
          cell: ({ getValue }) => {
            return getValue() ? getValue().toLocaleString() : "0";
          },
          header: "세대수",
          maxSize: 15,
        }),

        columnHelper.accessor("id", {
          cell: ({ getValue }) => {
            const onClick = () => navigate(getValue());
            return <EditContents onClick={onClick}>정보수정</EditContents>;
          },
          header: "-",
          maxSize: 15,
        }),
      ];
    }, [data]);

    const table = useReactTable({
      data,
      columns,
      enableMultiRowSelection: true,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
    });

    // 부모 컴포넌트가 접근할 수 있게 메서드 제공
    useImperativeHandle(ref, () => ({
      getSelectedRowModel: () => table.getSelectedRowModel(),
    }));

    useEffect(() => {
      table.setPageSize(10);
    }, []);

    useEffect(() => {
      table.reset();
    }, [data]);

    return (
      <Container>
        <table className="contentsTable">
          <thead className={cn("contentsThead")}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={cn("contentsHeader")}
                    style={{ width: `${header.getSize()}%` }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={cn("contentsData", "tdStyle")}>
                    <div className="divStyle">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <Empty data={data} title="컨텐츠가 없어요" />
        )}
        <Pagination table={table} />
      </Container>
    );
  }
);

export default StoryTable;

const Container = styled.div``;

const Center = styled.div`
  display: flex;
  flex: 1;
  height: fit-content;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primaryBlue100};
`;

const EditContents = styled.p`
  color: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;
`;

const Title = styled.h2`
  margin-left: 20px;
  max-width: 600px;
  min-width: 600px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
