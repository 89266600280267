import { Instance } from "@api/index";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  ApplicantInfo,
  ConsultingInterface,
  GetConsultingInterface,
  PaymentInfo,
  SourceInfo,
} from "./Consulting.interfaces";
import { setTime } from "react-datepicker/dist/date_utils";

interface GetSource {
  title: string;
  data: { fileName: string; file: string };
  createdAt?: string;
}

interface PostSource {
  title: string;
  data: { fileName: string; file: File | null };
}

// 상담 상태 변경
export const usePatchApplicantState = () => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError<any>, { id: string; state: string }>({
    mutationFn: async ({ id, state }) => {
      const response = await Instance({
        url: `/consultation/applicants?applicantId=${id}&state=${state}`,
        method: "PATCH",
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["applicantsList"],
      });
      queryClient.invalidateQueries({
        queryKey: ["applicant"],
      });
    },
  });
};

// 신청자 목록 조회
export const useGetApplicantsList = () =>
  useQuery({
    queryKey: ["applicantsList"],
    queryFn: async () => {
      const response = await Instance({
        url: `/consultation/applicants?consultantId=`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 0,
  });

// 컨설턴트 목록 조회
export const useGetConsultingList = ({ dbVersion }) =>
  useQuery({
    queryKey: ["consultingList", dbVersion],
    queryFn: async () => {
      const response = await Instance({
        url: `/consultation/consultings?exposureType=&dbVersion=${dbVersion}`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 0,
  });

// 컨설턴트 삭제
export const useDeleteConsulting = ({ dbVersion }) => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, { consultingId: string }>({
    mutationFn: async ({ consultingId }) => {
      const response = await Instance({
        url: `/consultation/consultings/${consultingId}`,
        method: "DELETE",
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["consultingList"],
      });
    },
  });
};

// 컨설팅 상세 조회
export const useGetConsultingDetailInfo = ({ consultingId, dbVersion }) =>
  useQuery<any, AxiosError, GetConsultingInterface>({
    queryKey: ["consulting/detail"],
    queryFn: async () => {
      const response = await Instance({
        url: `/consultation/consultings/${consultingId}?dbVersion=${dbVersion}`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 0,
  });

// 컨설턴트 정보, 컨설팅 정보 생성
export const usePostConsultingInfo = ({ dbVersion }) => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, ConsultingInterface>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/consultation/consultings?dbVersion=${dbVersion}`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["consultingList"],
      });
    },
  });
};

// 컨설턴트 정보 수정
export const useEditConsultingInfo = ({ dbVersion }) => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, { body: ConsultingInterface; id: string }>(
    {
      mutationFn: async ({ body, id }) => {
        const response = await Instance({
          url: `/consultation/consultings/${id}?dbVersion=${dbVersion}`,
          method: "PUT",
          data: body,
        });
        return response.data;
      },
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["consulting/detail"],
        });
      },
    }
  );
};

// 라이브 DB로 업데이트
export const usePostLiveDB = () =>
  useMutation<{}, AxiosError, { toDB: string; consultingIds: string[] }>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/consultation/consultings/transferDB`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
  });

///////////////////////////////처방전 프로 ///////////////////////////////

// 신청자 한명에 대한 정보 조회
export const useGetApplicantInfo = ({ applicantId }) =>
  useQuery<any, AxiosError, ApplicantInfo>({
    queryKey: ["applicant", applicantId],
    queryFn: async () => {
      const response = await Instance({
        url: `/consultation/applicants/${applicantId}`,
        method: "GET",
      });
      console.log("call", applicantId);
      return response.data;
    },
    retry: false,
  });

// 결제상태 조회
export const useGetApplicantPaymentInfo = ({ applicantId }) =>
  useQuery<any, AxiosError, PaymentInfo>({
    queryKey: ["applicant", "payment", applicantId],
    queryFn: async () => {
      const response = await Instance({
        url: `/consultation/payments/status/${applicantId}/admin`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 0,
  });

// 결제상태 업데이트
export const usePostPaymentUpdate = () =>
  useMutation<
    any,
    AxiosError,
    {
      applicantId: string;
      paymentStatus: "결제 완료" | "결제 미완료" | "환불 완료";
      refundText: string;
    }
  >({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/consultation/payments/update/admin`,
        method: "POST",
        data,
      });

      return response.data;
    },
    retry: false,
  });

export const usePostCardRefund = () =>
  useMutation<
    any,
    AxiosError,
    {
      applicantId: string;
      refundText: string;
    }
  >({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/consultation/payments/refund`,
        method: "POST",
        data,
      });

      return response.data;
    },
    retry: false,
  });

// 사전 질문 보고서 등록
export const usePostPreQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation<
    {},
    AxiosError,
    {
      title: string;
      applicantId: string;
      notionUrl: string;
    }
  >({
    mutationFn: async (body) => {
      const response = await Instance({
        url: `/consultation/applicants/preQuestion`,
        method: "POST",
        data: body,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["applicantsList"],
      });
    },
  });
};

// 컨설팅 자료 제출 여부에 따른 대기자 명단
export const useGetApplicantsForReport = ({
  isSubmitted,
  start = "",
  end = "",
}) =>
  useQuery<any, AxiosError, { applicants: ApplicantInfo[] }>({
    queryKey: ["applicant", "influencer", start, end],
    queryFn: async () => {
      const response = await Instance({
        url: `/consultation/applicants/reports?isSubmitted=${isSubmitted}&start_date=${start}&end_date=${
          end ? end : start
        }`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 0,
  });

// 컨설팅 레포트 등록
export const usePostConsultingReport = () => {
  const queryClient = useQueryClient();
  return useMutation<
    {},
    AxiosError,
    {
      applicantId: string;
      notionUrl: string;
    }
  >({
    mutationFn: async (body) => {
      const response = await Instance({
        url: `/consultation/applicants/consultingReport`,
        method: "POST",
        data: body,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["applicant"],
      });
    },
  });
};

export const useGenConsultingNotion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ applicantId }: { applicantId: string }) => {
      const response = await Instance({
        url: `/consultation/applicants/notion`,
        method: "POST",
        data: { applicantId },
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["applicant"],
      });
    },
  });
};

export const usePostApproval = () => {
  const queryClient = useQueryClient();
  return useMutation<
    {},
    AxiosError,
    {
      applicantId: string;
      data: object;
    }
  >({
    mutationFn: async ({ applicantId, data }) => {
      console.log(data);
      const response = await Instance({
        url: `/consultation/applicants/reports/${applicantId}`,
        method: "PATCH",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      // 보고서 파일 업로드 하자마자 캐시 날려서 목록 조회하면서 s3에서 파일 못 찾아오는 에러 뜸
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ["applicant"],
        });
      }, 3000);
    },
  });
};

// 데이터 자료 목록 조회
export const useGetSourceList = () =>
  useQuery<any, AxiosError, { contents: SourceInfo[] }>({
    queryKey: ["source"],
    queryFn: async () => {
      const response = await Instance({
        url: `/consultation/contents`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 0,
  });

// 데이터 자료 상세 조회
export const useGetDetailedSource = ({ contentsId }) =>
  useQuery<string, AxiosError, GetSource>({
    queryKey: ["source", contentsId],
    queryFn: async () => {
      const response = await Instance({
        url: `/consultation/contents/${contentsId}`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 0,
  });

// 데이터 자료 목록 추가
export const usePostSource = () => {
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, PostSource>({
    mutationFn: async (data) => {
      console.log(data);
      const response = await Instance({
        url: `/consultation/contents`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["source"],
      });
    },
  });
};

// 데이터 자료 수정
export const useEditSource = ({ contentsId }) => {
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, PostSource>({
    mutationFn: async (data) => {
      console.log(data);
      const response = await Instance({
        url: `/consultation/contents/${contentsId}`,
        method: "PUT",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["source"],
      });
    },
  });
};

// 데이터 자료 삭제
export const useDeleteSource = () => {
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, { contentsIdList: Array<string> }>({
    mutationFn: async (data) => {
      console.log(data);
      const response = await Instance({
        url: `/consultation/contents`,
        method: "DELETE",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["source"],
      });
    },
  });
};

// 데이터 자료 목록 조회
export const useGetSearchSource = ({ text }) =>
  useQuery<any, AxiosError, { contents: object }>({
    queryKey: ["source", text],
    queryFn: async () => {
      const response = await Instance({
        url: `/consultation/contents/search?text=${text}`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 0,
  });

// presignedURL 요청
export const usePostPresignedUrl = () =>
  useMutation<string, AxiosError, { folderName: string; fileName: string }>({
    mutationFn: async ({ folderName, fileName }) => {
      const response = await Instance({
        url: `/consultation/files`,
        method: "POST",
        data: { folder_name: folderName, file_names: [fileName] },
      });
      return response.data;
    },
    retry: false,
  });
