import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import { Question20 } from "@components/core/icon/tooltip";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { TextInputEnum } from "@components/input/BTypeText48/BTypeText.interfaces";
import BTypeText48 from "@components/input/BTypeText48/BTypeText48";
import { useMemo, useState } from "react";
import styled from "styled-components";
import TooltipPopup from "./TooltipPopup";
import { ButtonBox } from "@components/styles";
import { useNavigate } from "react-router-dom";
import BTypeUnit48 from "@components/input/BTypeUnit48/BTypeUnit48";

export default function StoryInput({
  storyInfo,
  setStoryInfo,
  handleConfirm,
  edit,
}) {
  const navigate = useNavigate();
  const [isVisible툴팁팝업, setIsVisible툴팁팝업] = useState(false);
  const [type, setType] = useState(null);

  const handle툴팁팝업 = (type) => {
    setIsVisible툴팁팝업(true);
    setType(type);
  };

  const isDone = useMemo(() => {
    return (
      storyInfo.title &&
      storyInfo.notionUrl &&
      storyInfo.area &&
      (storyInfo.householdNum === 0 || storyInfo.householdNum) &&
      storyInfo.metaTitle &&
      storyInfo.metaDescription &&
      storyInfo.slug &&
      storyInfo.preview
    );
  }, [storyInfo]);

  const slug조건_체크 = (slug) => {
    if (!slug) return true;

    const pattern = /^(?!-)(?!.*--)[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/;
    return pattern.test(slug);
  };

  return (
    <Container>
      <div className="table">
        <TRow>
          <TData>
            <div className="head">
              <p>컨텐츠 제목</p>
            </div>
            <div className="body" style={{ width: "100%" }}>
              <BTypeText48
                placeholder="제목 입력"
                textType={TextInputEnum.DEFAULT}
                value={storyInfo.title}
                onChangeValue={(value) =>
                  setStoryInfo((prev) => ({ ...prev, title: value }))
                }
              />
            </div>
          </TData>
        </TRow>
        <TRow>
          <TData>
            <div className="head">
              <p>노션 링크</p>
            </div>
            <div className="body" style={{ width: "100%" }}>
              <BTypeText48
                placeholder="임장 후기글 노션 링크 입력"
                textType={TextInputEnum.DEFAULT}
                value={storyInfo.notionUrl}
                onChangeValue={(value) =>
                  setStoryInfo((prev) => ({
                    ...prev,
                    notionUrl: value,
                  }))
                }
              />
            </div>
          </TData>
        </TRow>
        <TRow>
          <TData>
            <div className="head">
              <p>지역</p>
            </div>
            <div className="body">
              <BTypeText48
                placeholder="지역, 구 입력"
                textType={TextInputEnum.DEFAULT}
                value={storyInfo.area}
                onChangeValue={(value) =>
                  setStoryInfo((prev) => ({ ...prev, area: value }))
                }
              />
            </div>
          </TData>
          <TData>
            <div className="head">
              <p>세대수</p>
            </div>
            <div className="body">
              <BTypeUnit48
                placeholder="세대 수 입력"
                unit="세대"
                value={storyInfo.householdNum}
                onChangeValue={(value) =>
                  setStoryInfo((prev) => ({
                    ...prev,
                    householdNum: value,
                  }))
                }
              />
            </div>
          </TData>
        </TRow>
        <TRow>
          <TData>
            <div className="head">
              <p>단지 코드</p>
            </div>
            <div className="body" style={{ width: "100%" }}>
              <BTypeText48
                placeholder="단지 코드"
                textType={TextInputEnum.NUMBER}
                value={storyInfo.danjiCode}
                onChangeValue={(value) => {
                  console.log(value);
                  setStoryInfo((prev) => ({
                    ...prev,
                    danjiCode: value,
                  }));
                }}
              />
            </div>
          </TData>
        </TRow>
        <TRow>
          <TData>
            <div className="head" style={{ alignItems: "flex-end" }}>
              <p>본문 미리보기</p>
            </div>
            <div className="body">
              <BTypeTextArea
                placeholder="본문 미리보기에 사용할 문장을 작성해 주세요(최대 120글자)"
                value={storyInfo.preview}
                maxLength={120}
                onChangeValue={(v) =>
                  setStoryInfo((prev) => ({
                    ...prev,
                    preview: v,
                  }))
                }
                textareaStyle={{ height: 100, fontSize: 15 }}
              />
            </div>
          </TData>
        </TRow>
      </div>
      <div className="row">
        <h3>SEO</h3>
        <p>* 작성 방법은 툴팁을 꼭 확인하세요.</p>
      </div>
      <div className="table" style={{ borderBottom: "1px solid #E5E7E9" }}>
        <TRow>
          <TData>
            <div className="head">
              <p>meta-title</p>
              <span onClick={() => handle툴팁팝업("meta-title")}>
                <Question20 />
              </span>
            </div>
            <div className="body">
              <BTypeText48
                placeholder="명확한 단어로만 작성해 주세요"
                textType={TextInputEnum.DEFAULT}
                value={storyInfo.metaTitle}
                onChangeValue={(value) =>
                  setStoryInfo((prev) => ({
                    ...prev,
                    metaTitle: value,
                  }))
                }
              />
            </div>
          </TData>
          <TData>
            <div className="head">
              <p>Slug</p>
              <span onClick={() => handle툴팁팝업("slug")}>
                <Question20 />
              </span>
            </div>
            <div className="body">
              <BTypeText48
                placeholder="영문, 숫자로만 작성해주세요"
                textType={TextInputEnum.CUSTOM}
                value={storyInfo.slug}
                disabled={edit}
                errorText={
                  "영문과 숫자로 조합하며, 띄어쓰기는 -을 사용하여 작성해주세요."
                }
                checkCondition={slug조건_체크}
                onChangeValue={(value) =>
                  setStoryInfo((prev) => ({
                    ...prev,
                    slug: value,
                  }))
                }
              />
            </div>
          </TData>
        </TRow>
        <TRow>
          <TData>
            <div className="head" style={{ alignItems: "flex-end" }}>
              <p style={{ width: 77 }}>meta-description</p>
              <span onClick={() => handle툴팁팝업("meta-description")}>
                <Question20 />
              </span>
            </div>
            <div className="body">
              <BTypeTextArea
                placeholder="임장 이야기를 잘 설명할 수 있는 문장으로 작성해 주세요"
                // textType={TextInputEnum.DEFAULT}
                value={storyInfo.metaDescription}
                onChangeValue={(v) =>
                  setStoryInfo((prev) => ({
                    ...prev,
                    metaDescription: v,
                  }))
                }
                textareaStyle={{ height: 160, fontSize: 15 }}
              />
            </div>
          </TData>
        </TRow>
      </div>
      <ButtonBox style={{ marginTop: 20 }}>
        <button onClick={() => navigate(-1)}>취소</button>
        <button
          className={!isDone && "disabled"}
          disabled={!isDone}
          onClick={handleConfirm}
        >
          적용
        </button>
      </ButtonBox>
      <PopUpContainer
        isVisible={isVisible툴팁팝업}
        setIsVisible={setIsVisible툴팁팝업}
      >
        <TooltipPopup type={type} setIsVisible={setIsVisible툴팁팝업} />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div`
  .table {
    padding: 14px 0 28px;
    border-top: 2px solid ${({ theme }) => theme.colors.blueGray800};
  }

  .row {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    gap: 12px;

    h3 {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 18px;
      font-weight: 600;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.36px;
    }

    p {
      color: ${({ theme }) => theme.colors.red1000};
      font-size: 15px;
      font-weight: 400;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.3px;
    }
  }
`;

const TRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 52px;
  margin-bottom: 8px;
`;

const TData = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  .head {
    display: flex;
    align-items: center;
    position: relative;
    top: 13px;
    min-width: 140px;
    height: fit-content;

    p {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 15px;
      font-weight: 600;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.3px;
    }

    span {
      margin-left: 4px;
      cursor: pointer;
    }
  }

  .body {
    flex: 1;
  }
`;
