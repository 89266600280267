import { flexRender } from "@tanstack/react-table";
import styled from "styled-components";
import { useState } from "react";
import { Whitespace } from "@components/core/icon/whiteSpace";
import {
  WhitespaceEnum,
  WhitespaceSize,
} from "@components/core/icon/whiteSpace/WhiteSpace.interfaces";
import NoFixedPagination from "@components/core/paging/NoFixedPagination";

export default function ScheduleTable({ table }) {
  const [hoverColumn, setHoverColumn] = useState(null);

  return (
    <Container>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup, idx) => (
            <tr key={`${headerGroup.id}_${idx}`}>
              {headerGroup.headers.map((header, idx) => (
                <th
                  key={`${header.id}_${idx}`}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, idx) => {
            return (
              <TRow
                key={`${row.id}_${idx}`}
                className="entireTr"
                $hover={
                  !(
                    hoverColumn === "" ||
                    hoverColumn === "checkBox" ||
                    hoverColumn === "checkingCall"
                  )
                }
              >
                {row.getAllCells().map((cell, idx) => {
                  return (
                    <td
                      key={`${cell.id}_${idx}`}
                      className="contentsData"
                      onMouseEnter={() => setHoverColumn(cell.column.id)}
                      onMouseLeave={() => setHoverColumn("")}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </TRow>
            );
          })}
        </tbody>
      </Table>
      {table.getRowModel().rows.length === 0 && (
        <Empty>
          <Whitespace
            status={WhitespaceEnum.Default}
            size={WhitespaceSize.Small}
          />
          <span>스케줄을 추가해주세요</span>
        </Empty>
      )}
      <NoFixedPagination table={table} />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const Table = styled.table`
  width: 100%;
`;

const TRow = styled.tr<{ $hover: boolean }>`
  cursor: pointer;

  td {
    vertical-align: middle;
  }

  .info {
    display: none;
  }

  &:hover {
    background-color: ${({ $hover }) => ($hover ? "#f2f4f6" : "fff")};
    .info {
      // 로우가 특정 컬럼이 아닌 곳에 호버되었을 때만 노출시키기
      display: ${({ $hover }) => ($hover ? "block" : "none")};
    }
  }
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;

  span {
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.blueGray400};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
  }
`;
