import styled from "styled-components";
import JoinForm from "./JoinForm";
import { useSignUp } from "@api/login";
import { useEffect, useState } from "react";
import AlreadySignUp from "./AlreadySignUp";
import { useAlert } from "@features/useAlert";
import { useSearchParams } from "react-router-dom";
import { atobUnicode } from "@utils/text";
import { Cookies } from "react-cookie";

export default function JoinPage() {
  const cookies = new Cookies();
  const [searchParams] = useSearchParams();
  const { mutate } = useSignUp();
  const [userInfo, setUserInfo] = useState({
    name: "",
    nickname: null,
    phone_number: "",
    email: "",
    invite_code: "",
  });

  useEffect(() => {
    const name = atobUnicode(searchParams.get("nm"));
    const phone_number = searchParams.get("ph")
      ? atob(searchParams.get("ph"))
      : "";
    const email = searchParams.get("em") ? atob(searchParams.get("em")) : "";
    const invite_code = cookies.get("inv_code") || "";

    setUserInfo((prev) => ({
      ...prev,
      name,
      phone_number,
      email,
      invite_code,
    }));
  }, []);

  const { showGlobalAlert } = useAlert();

  const 회원가입 = (e) => {
    e.preventDefault();
    mutate(userInfo, {
      onSuccess: () => {
        showGlobalAlert({
          desc: "회원가입 신청이 완료되었습니다. \n관리자가 승인 후 이용할 수 있습니다",
          blue: true,
          path: "/login",
        });
      },
      onError: (res) => {
        console.log(userInfo);
        if (res.status === 422) {
          showGlobalAlert({
            desc: "입력값이 올바르지 않습니다. \n다시 확인해주세요.",
            blue: false,
          });
        } else if (res.status === 409) {
          showGlobalAlert({
            desc: "이미 가입한 회원이 있어요.",
            blue: true,
          });
        } else if (res.response.data?.["message"].includes("초대")) {
          showGlobalAlert({
            desc: "형식이 올바르지 않거나 \n유효한 코드가 아닙니다.",
            blue: false,
          });
        } else if (res.response.data?.["message"].includes("세션")) {
          showGlobalAlert({
            desc: "세션이 만료되었습니다. \n다시 시도해 주세요.",
            blue: false,
            path: "/login",
          });
        } else {
          showGlobalAlert({
            desc: res.response.data["message"],
            blue: false,
          });
        }
      },
    });
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#f4f6f8";

    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, []);

  return (
    <Container>
      <JoinForm
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        onSubmit={회원가입}
      />
      {/* 이미 회원이 있나요? */}
      <AlreadySignUp />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
