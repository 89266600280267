import { CheckStatusEnum } from "@components/core/icon/checked/Checked.interfaces";
import Checked20 from "@components/core/icon/checked/Checked20";
import { Close32 } from "@components/core/icon/close";
import { useGetUserId } from "@hooks/management";
import { useEffect, useState } from "react";
import { FadeLoader } from "react-spinners";
import styled from "styled-components";

export default function GetUserIdModal({ onConfirm, setIsVisible }) {
  const { mutate, isLoading } = useGetUserId();
  const [nickname, setNickname] = useState("");
  const [userId, setUserId] = useState("");
  const [status, setStatus] = useState("PENDING");

  const 유저아이디_조회 = (e) => {
    e.preventDefault();
    mutate(
      { nickname: nickname },

      {
        onSuccess: (res) => {
          setStatus("SUCCESS");
          setUserId(res.userId);
        },
        onError: () => {
          setStatus("FAILED");
        },
        onSettled: (res) => console.log("유저 아이디 🆔 :", res),
      }
    );
  };

  useEffect(() => {
    setStatus("PENDING");
  }, [nickname]);

  return (
    <Container>
      <div className="closeButton" onClick={() => setIsVisible(false)}>
        <Close32 />
      </div>
      <h2 className="title">유저 아이디 찾기</h2>
      <Row style={{ marginTop: 20, gap: 8 }}>
        <Input
          placeholder="닉네임"
          value={nickname}
          onChange={(e) => {
            setNickname(e.target.value);
          }}
        />
        <Button onClick={유저아이디_조회}>
          {isLoading ? (
            <FadeLoader
              cssOverride={{
                width: 10,
                height: 10,
                transform: "scale(0.3)",
                top: -2,
                left: 8,
              }}
              color="#1c84ff"
            />
          ) : (
            <p>확인</p>
          )}
        </Button>
      </Row>
      {status === "PENDING" && (
        <Row style={{ marginTop: 4 }}>
          <Checked20 status={CheckStatusEnum.Disabled} />
          <Text14 style={{ marginLeft: 4 }}>유저 확인이 필요합니다</Text14>
        </Row>
      )}
      {status === "SUCCESS" && (
        <Row style={{ marginTop: 4 }}>
          <Checked20 status={CheckStatusEnum.Activate} />
          <Text14 style={{ marginLeft: 4, color: "#1c84ff" }}>{userId}</Text14>
        </Row>
      )}
      {status === "FAILED" && (
        <Row style={{ marginTop: 4 }}>
          <Checked20 status={CheckStatusEnum.Destructive} />
          <Text14 style={{ marginLeft: 4, color: "#F04452" }}>
            존재하지 않는 유저입니다
          </Text14>
        </Row>
      )}
      <BlueButton
        onClick={() => onConfirm(userId)}
        $success={status === "SUCCESS"}
      >
        <span>이 유저 아이디 사용하기</span>
      </BlueButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding: 24px 16px 20px 16px;
  flex-direction: column;
  width: 400px;

  border-radius: 20px;
  background-color: white;

  .title {
    color: ${({ theme }) => theme.colors.blueGray800};
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px; /* 150% */
    letter-spacing: -0.4px;
  }

  .closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  padding: 10px 20px;
  flex: 1;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  padding: 0 32px;
  width: 90px;
  height: 48px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.blue100};

  color: ${({ theme }) => theme.colors.primaryBlue100};
  font-size: 15px;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.3px;

  cursor: pointer;
`;

const BlueButton = styled.button<{ $success: boolean }>`
  margin-top: 32px;
  padding: 12px 20px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme, $success }) =>
    $success ? theme.colors.blue1000 : theme.colors.blue400};

  span {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Text14 = styled.p`
  color: ${({ theme }) => theme.colors.blueGray600};
  font-size: 14px;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.28px;
`;
