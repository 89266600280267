export default function CirclePlus22() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_21919_34887)">
        <path
          d="M10.75 20.75C16.2728 20.75 20.75 16.2728 20.75 10.75C20.75 5.22715 16.2728 0.75 10.75 0.75C5.22715 0.75 0.75 5.22715 0.75 10.75C0.75 16.2728 5.22715 20.75 10.75 20.75Z"
          stroke="#F4F6F8"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          d="M14.75 11.75C15.3023 11.75 15.75 11.3023 15.75 10.75C15.75 10.1977 15.3023 9.75 14.75 9.75H6.75C6.19771 9.75 5.75 10.1977 5.75 10.75C5.75 11.3023 6.19771 11.75 6.75 11.75H14.75Z"
          fill="#F4F6F8"
        />
        <path
          d="M11.75 6.75C11.75 6.19772 11.3023 5.75 10.75 5.75C10.1977 5.75 9.75 6.19772 9.75 6.75V14.75C9.75 15.3023 10.1977 15.75 10.75 15.75C11.3023 15.75 11.75 15.3023 11.75 14.75V6.75Z"
          fill="#F4F6F8"
        />
      </g>
      <defs>
        <clipPath id="clip0_21919_34887">
          <rect width="21.5" height="21.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
