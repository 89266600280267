import { useEffect, useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getCoreRowModel } from "@tanstack/react-table";
import styled from "styled-components";
import cn from "classnames";
import { Link } from "react-router-dom";
import Pagination from "@components/core/paging/Pagination";
import "@components/table.css";
import Empty from "@components/common/Empty";
import StatusChip from "./StatusChip";

export default function VersionTable({ data }) {
  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("number", {
        cell: (info) => data.length - info.row.index,
        header: "순번",
        maxSize: 8,
      }),
      columnHelper.accessor("id", {
        cell: ({ getValue }) => (
          <StyledLink to={`/dev/version/${getValue()}`}>
            {getValue() as string}
          </StyledLink>
        ),
        header: "버전 정보",
        maxSize: 60,
      }),
      columnHelper.accessor("status", {
        cell: ({ row }) => {
          return (
            <Center>
              <StatusChip state={row.original["status"]} onClick={() => {}} />
            </Center>
          );
        },
        header: "(구)status",
        maxSize: 10,
      }),
      columnHelper.accessor("android", {
        cell: ({ row }) => {
          return (
            <Center>
              <StatusChip state={row.original["android"]} onClick={() => {}} />
            </Center>
          );
        },
        header: "AOS 상태",
        maxSize: 10,
      }),

      columnHelper.accessor("ios", {
        cell: ({ row }) => {
          return (
            <Center>
              <StatusChip state={row.original["ios"]} onClick={() => {}} />
            </Center>
          );
        },
        header: "IOS 상태",
        maxSize: 10,
      }),

      columnHelper.accessor("empty", {
        header: "",
        maxSize: 2,
      }),
    ];
  }, [data]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, [data]);

  return (
    <Container>
      <table className="contentsTable">
        <thead className={cn("contentsThead", {})}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={cn("contentsHeader", {})}
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={cn("contentsData", {})}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Empty data={data} title="컨텐츠가 없어요" />
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div``;

const StyledLink = styled(Link)`
  margin-right: auto;
  margin-left: 20px;
  color: ${({ theme }) => theme.colors.blueGray700};
  text-align: left;
  display: flex;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;
