import styled from "styled-components";

export default function TableSkeleton({
  style,
}: {
  style?: React.CSSProperties;
}) {
  return (
    <Container style={{ ...style }}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((el) => (
        <div className="contentsData" key={el}>
          <Skeleton style={{ flex: 1 }} />
        </div>
      ))}
    </Container>
  );
}

const Container = styled.div`
  .header {
    display: flex;
    height: 50px;
    gap: 200px;
    border-top: 2px solid #333e4d;
    border-bottom: 1px solid #cccfd3;
    background-color: #fafafb;
    padding: 12px 60px;
    color: #333e4d;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }

  .contentsData {
    display: flex;
    padding: 16px 24px;
    gap: 70px;
    color: #4d5664;
    border-bottom: 1px solid #e5e7e9;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
    text-align: center;
  }
`;

// const Skeleton = styled.div`
//   display: flex;
//   flex: 1;
//   background-color: #f4f6f8;
//   width: 50px;
//   height: 24px;
//   /* border-radius: 16px; */
//   /* border-radius: 8px; */
//   display: inline-flex;
//   line-height: 1;
//   position: relative;
//   user-select: none;
//   overflow: hidden;

//   &::after {
//     content: " ";
//     display: block;
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     height: 100%;
//     background-repeat: no-repeat;
//     background-image: linear-gradient(
//       90deg,
//       #ebebeb 0%,
//       #f5f5f5 50%,
//       #ebebeb 100%
//     );
//     transform: translateX(-100%);
//     animation: react-loading-skeleton 1.2s ease-in-out infinite;
//   }

//   @keyframes react-loading-skeleton {
//     0% {
//       transform: translateX(-100%);
//     }
//     50% {
//       transform: translateX(50%);
//     }
//     100% {
//       transform: translateX(100%);
//     }
//   }

//   @media (prefers-reduced-motion) {
//     &::after {
//       display: none;
//     }
//   }
// `;

const Skeleton = styled.div`
  background-color: #f4f6f8;
  display: flex;
  flex: 1;
  /* background-color: #f4f6f8; */
  width: 50px;
  height: 24px;
  border-radius: 4px;
  line-height: 1;
  position: relative;
  user-select: none;
  overflow: hidden;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      90deg,
      #f4f6f8 0%,
      #e7ebf0f7 50%,
      #f4f6f8 100%
    );
    transform: translateX(-100%);
    animation: react-loading-skeleton 1.5s ease-in-out infinite;
  }

  @keyframes react-loading-skeleton {
    100% {
      transform: translateX(100%);
    }
  }

  @media (prefers-reduced-motion) {
    &::after {
      display: none;
    }
  }
`;
