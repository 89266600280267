import styled from "styled-components";

import { useMemo } from "react";

export default function PushStatusChip({ status }) {
  const bgColor = useMemo(() => {
    switch (status) {
      case "발송대기":
        return "#E9F9E8";
      case "발송완료":
        return "#E5E7E9";
      case "발송실패":
        return "#FDECEE";
      default:
        return "#F4F6F8";
    }
  }, [status]);

  const textColor = useMemo(() => {
    switch (status) {
      case "발송대기":
        return "#22C014";
      case "발송완료":
        return "#808690";
      case "발송실패":
        return "#F04452";
      default:
        return "#4D5664";
    }
  }, [status]);

  return (
    <Container style={{ backgroundColor: bgColor }}>
      <Text style={{ color: textColor }}>{status}</Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  width: fit-content;
  height: 32px;
  border-radius: 8px;
`;

const Text = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
`;
