export enum CheckedCircleStatusEnum {
  Default = "Default",
  Activate = "Activate",
}

export interface Props {
  status: CheckedCircleStatusEnum;
  color?: string;
  activeColor?: string;
}
