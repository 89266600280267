import { useGetMoveInDate } from "@api/imjang";
import BTypeBtn48 from "@components/core/BTypeInput/BTypeBtn48/BTypeBtn48";
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

export default function MovingDateInput({ value, onChangeValue }) {
  const { id } = useParams();
  const { mutate } = useGetMoveInDate();
  const [isGot, setIsGot] = useState(false);

  const 날짜_불러오기 = () => {
    if (isGot) return;

    mutate(
      { apply_id: id },
      {
        onSuccess: (res) => {
          alert(
            "이사 날짜를 불러왔습니다. \n고객이 이사 날짜를 선택하지 않은 경우 값을 불러오지 않습니다."
          );
          onChangeValue(res.moveInDate);
          setIsGot(true);
        },
        onError: (res) => {
          if (res.response.data["message"].includes("userId")) {
            alert(
              "웹으로 신청한 고객은 이사 날짜를 불러올 수 없습니다. \n이사 날짜가 있다면 직접 선택해 주세요."
            );
          } else alert(res.response.data["message"]);
        },
      }
    );
  };

  return (
    <Container>
      <div style={{ flex: 1 }}>
        <BTypeBtn48
          value={value}
          placeholder="이사 날짜 선택"
          onChangeValue={onChangeValue}
          optionList={[
            { label: "이사 날짜 선택", value: undefined },
            { label: "1개월 이내", value: "WITHIN_1_MONTH" },
            { label: "3개월 이내", value: "WITHIN_3_MONTHS" },
            { label: "6개월 이내", value: "WITHIN_6_MONTHS" },
            { label: "1년 이내", value: "WITHIN_1_YEAR" },
            { label: "미정", value: "UNDETERMINED" },
          ]}
        />
      </div>
      <Button onClick={날짜_불러오기} $disabled={isGot}>
        불러오기
      </Button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.div<{ $disabled: boolean }>`
  display: inline-flex;
  height: 48px;
  padding: 2px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};

  border-radius: 10px;
  background: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.blueGray100 : theme.colors.blue100};

  color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.blueGray500 : theme.colors.primaryBlue100};
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
`;
