export const relationshipDict = {
  F02: "배우자",
  F13: "예비배우자",
  F03: "아버지",
  F04: "어머니",
  F09: "아들",
  F10: "딸",
  F11: "사위",
  F12: "며느리",
  F05: "장인",
  F06: "장모",
  F07: "시부",
  F08: "시모",
};

export const jobDict = {
  J18: "유치원생",
  J17: "초등학생",
  J16: "중학생",
  J15: "고등학생",
  J01: "일반사무직",
  J02: "사업가",
  J03: "전업투자자",
  J04: "금융직",
  J05: "IT개발자",
  J06: "관리직",
  J07: "전문직",
  J23: "생산직",
  J08: "공인중개인",
  J09: "서비스업",
  J10: "자영업",
  J11: "영업직",
  J12: "전업주부",
  J20: "공무원",
  J21: "교육직종사자",
  J22: "농업,어업,임업",
  J13: "대학원생",
  J14: "대학생",
  J19: "미취학",
  J24: "무직",
};

export const genderDict = {
  M: "남자",
  F: "여자",
};

export const relationshipArray = Object.entries(relationshipDict).map(
  ([key, value]) => ({
    title: value,
    value: key,
  })
);

export const jobArray = Object.entries(jobDict).map(([key, value]) => ({
  title: value,
  value: key,
}));

export const genderArray = Object.entries(genderDict).map(([key, value]) => ({
  title: value,
  value: key,
}));
