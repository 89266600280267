import { Props } from "./Checked.interfaces";

const lineColor = {
  Disabled: "#CCCFD3",
  Default: "#CCE3FF",
  Activate: "#1C84FF",
  Destructive: "#F04452",
};

export default function Checked20({ status }: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15 7L8.25 14L5 11.3922"
        stroke={lineColor[status]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
