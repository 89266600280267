import { Instance } from "@api/index";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { CoinHistoryRes } from "./coin.interfaces";

// 코인 충전 - 어드민
export const usePostCoin = () => {
  const queryClient = useQueryClient();
  return useMutation<
    {},
    AxiosError,
    { id: string; data: { chargeAmount: number; chargeItem: string } }
  >({
    mutationFn: async ({ id, data }) => {
      const response = await Instance({
        url: `/payments/free_coins/${id}`,
        method: "POST",
        data,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["coin"],
      });
    },
  });
};

// 코인 거래 내역 - 어드민
export const useGetCoinHistory = () =>
  useQuery<CoinHistoryRes, AxiosError>({
    queryKey: ["coin", "history"],
    queryFn: async () => {
      const response = await Instance({
        url: "/payments/transactions/admin",
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 1000 * 60 * 5,
  });
