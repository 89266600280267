import React from "react";

import { ActionEnum, Props } from "./Action.interfaces";

export default function Action24({ status }: Props) {
  if (status === ActionEnum.COMPLETE) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <circle cx="12" cy="12" r="12" fill="#BDECB9" />
        <path
          d="M17 9L10.25 16L7 13.3922"
          stroke="#22C014"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (status === ActionEnum.WARNING) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#FFC960"
        />
        <rect x="11" y="5" width="2" height="10" rx="1" fill="black" />
        <rect x="11" y="16" width="2" height="2" rx="1" fill="black" />
      </svg>
    );
  } else if (status === ActionEnum.DANGER) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#F04452"
        />
        <rect x="11" y="5" width="2" height="10" rx="1" fill="white" />
        <rect x="11" y="16" width="2" height="2" rx="1" fill="white" />
      </svg>
    );
  }
}
