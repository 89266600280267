import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Instance } from "..";
import { UserInfoReq, UserInfoRes, UserListRes } from "./user.interfaces";

// 내 기본정보 조회
export const useGetMe = () =>
  useQuery<UserInfoRes, AxiosError>({
    queryKey: ["me"],
    queryFn: async () => {
      const response = await Instance({
        url: "/auth/common/user-info",
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 0,
  });

// 사용자 목록 조회
// 비활성화 유저 조회시 사용, true 인 경우 user_group 무시
export const useGetUserList = ({ user_group, offset, limit, inActive }) =>
  useQuery<UserListRes, AxiosError>({
    queryKey: ["user", user_group, offset, limit, inActive],
    queryFn: async () => {
      const group =
        user_group === "전체 회원" ? "" : `user_group=${user_group}`; // 전체는 유저그룹 없이 보내기
      const response = await Instance({
        url: `/auth/user/list?${group}&offset=${offset}&limit=${limit}&inActive=${inActive}`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 1000,
  });

// 유저 기본 정보 조회
export const useGetUserInfo = ({ user_id }) =>
  useQuery<UserInfoRes, AxiosError>({
    queryKey: ["user", user_id],
    queryFn: async () => {
      const response = await Instance({
        url: `/auth/user/${user_id}`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 1000,
  });

// 유저 정보 수정
export const useUpdateUserInfo = ({ user_id }) => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, UserInfoReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/auth/user/${user_id}`,
        method: "PATCH",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });
};

// 유저 개인 권한 조회
export const useGetAuthorities = () =>
  useQuery<string[], AxiosError>({
    queryKey: ["authorities"],
    queryFn: async () => {
      const response = await Instance({
        url: `/auth/common/authorities`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 1000,
  });

// 유저 개인 권한 조회
export const useMutateAuthorities = () =>
  useMutation<string[], AxiosError>({
    mutationFn: async () => {
      const response = await Instance({
        url: `/auth/common/authorities`,
        method: "GET",
      });
      return response.data;
    },
  });

// 초대 코드 발급
export const useGetInviteCode = () =>
  useMutation<{ invite_code: string }, AxiosError>({
    mutationFn: async () => {
      const response = await Instance({
        url: `/auth/user/invite_code`,
        method: "GET",
      });
      return response.data;
    },
  });
