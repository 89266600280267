import { Button, Title } from "@components/styles";
import { db } from "../../../../../firebase";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import VersionTable from "./VersionTable";
import AddVersionPopUp from "./AddVersionPopUp";
import { Plus16 } from "@components/core/icon/plus";

export default function AppVersionPage() {
  const [data, setData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // 실시간으로 데이터를 가져오는 코드
    const unsubscribe = onSnapshot(collection(db, "versions"), (snapshot) => {
      const fetchedData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(fetchedData.reverse());
    });

    // 컴포넌트가 언마운트되면 실시간 업데이트를 해제
    return () => unsubscribe();
  }, []);

  const handleAddVersion = async (e) => {
    setIsVisible(true);
  };

  return (
    <Container>
      <Title>앱 버전 관리</Title>
      <Row>
        <Button onClick={handleAddVersion}>
          <Plus16 />
          <p>버전 추가</p>
        </Button>
      </Row>
      <VersionTable data={data || []} />
      <PopUpContainer isVisible={isVisible} setIsVisible={setIsVisible}>
        <AddVersionPopUp setIsVisible={setIsVisible} data={data} />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 8px 0 10px;
`;
