import styled from "styled-components";

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blueGray900};
  font-size: 32px;
  font-weight: 600;
  line-height: 42px; /* 131.25% */
  letter-spacing: -0.64px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 8px 0 10px;

  .gap {
    display: flex;
    gap: 10px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.blueGrayA100};
  }

  img {
    flex: 1;
    margin-right: 12px;
    width: 32px;
    height: 32px;
  }

  p {
    margin-left: 6px;
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 14px;
    font-weight: 600;
    line-height: 22px; /* 155.556% */
    letter-spacing: -0.36px;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;

  .disabled {
    background-color: ${({ theme }) => theme.colors.blueGray100};
    color: ${({ theme }) => theme.colors.blueGray500};
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.primaryBlue100};
    border-radius: 8px;

    color: var(--White, #fff);
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-align: center;

    &:first-child {
      background: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.blueGray100};
      color: ${({ theme }) => theme.colors.blueGray800};
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`;
