import PermissionLayout from "@components/router/PermissionLayout";
import { Route, Routes } from "react-router-dom";
import {
  AddSource,
  ApplicantList,
  CheckSource,
  ConsultationApplicant,
  ConsultationApproval,
  ConsultationReportList,
  ConsultationSourceList,
  ConsultingAdd,
  ConsultingEdit,
  ConsultingList,
  EditSource,
  PostPreQuestion,
} from "@pages/admin/consultation";

export default function ConsultationRouter({ pageValue }) {
  return (
    <Routes>
      <Route element={<PermissionLayout pageValue={pageValue} />}>
        <Route path="applications" element={<ApplicantList />} />
        <Route path="application/:id" element={<ConsultationApplicant />} />
        <Route path="application/:id/send" element={<PostPreQuestion />} />
        <Route path="consultings" element={<ConsultingList />} />
        <Route path="consulting/add" element={<ConsultingAdd />} />
        <Route path="consulting/:id" element={<ConsultingEdit />} />
        <Route path="reports" element={<ConsultationReportList />} />
        <Route path="report/:id" element={<ConsultationApproval />} />
        <Route path="reference" element={<ConsultationSourceList />} />
        <Route path="reference/add" element={<AddSource />} />
        <Route path="reference/edit/:id" element={<EditSource />} />
        <Route path="reference/:id" element={<CheckSource />} />
      </Route>
    </Routes>
  );
}
