import { useGetScaleCodeList } from "@hooks/imjang";
import { useMemo } from "react";
import styled from "styled-components";

const tradeType = [
  { title: "매매", value: "sell" },
  { title: "전/월세", value: "rent" },
];

export default function MaemulFilter({
  data,
  selectedFilter,
  setSelectedFilter,
}) {
  const { data: scaleData } = useGetScaleCodeList({
    danjiCode: data._source.단지코드,
  });

  // '전체' 칩 액티브 상태
  const 전체_상태 = useMemo(() => {
    if (scaleData) {
      const allLength = scaleData?.scaleInfoList.length;
      const selectedLength = selectedFilter.scaleCodeList.length;
      if (allLength === selectedLength) return true;
      else return false;
    }
  }, [scaleData, selectedFilter]);

  // 평 개별 선택
  const 평형_선택 = (value: number) => {
    console.log(selectedFilter);
    if (selectedFilter.scaleCodeList.some((item) => item === value)) {
      const updatedScaleCodes = selectedFilter.scaleCodeList.filter(
        (scale) => scale !== value
      );
      setSelectedFilter((prev) => ({
        ...prev,
        scaleCodeList: updatedScaleCodes,
      }));
    } else {
      // const updatedScaleCodes = scaleCodeList.scaleCodeGroupList.find((item) => item.pyeong === value);
      // if (!updatedScaleCodes) return;
      console.log("여기", value);
      setSelectedFilter((prev) => ({
        ...prev,
        scaleCodeList: [...prev.scaleCodeList, value],
      }));
    }
  };

  // 평형 전체 선택
  const 전체_선택 = () => {
    if (전체_상태) {
      setSelectedFilter((prev) => ({ ...prev, scaleCodeList: [] }));
    } else {
      const scaleCodeList = scaleData.scaleInfoList.map((el) => el.scaleCode);
      setSelectedFilter((prev) => ({ ...prev, scaleCodeList }));
    }
  };

  if (!scaleData) return;

  return (
    <Container>
      <BasicInfo>
        <h1 className="danjiName">{data._source.단지명}</h1>
        <h3 className="danjiAddress">
          {data._source.도로명주소 || data._source.법정동주소}
        </h3>
      </BasicInfo>
      <ChipArea>
        <p className="head">거래 유형</p>
        <div className="body">
          {tradeType.map((el) => {
            const active = selectedFilter.tradeType === el.value;
            const onClick = () =>
              setSelectedFilter((prev) => ({ ...prev, tradeType: el.value }));
            return (
              <SelectChip active={active} onClick={onClick}>
                <p>{el.title}</p>
              </SelectChip>
            );
          })}
        </div>
      </ChipArea>
      <ChipArea>
        <p className="head">평형</p>
        <div className="body">
          <SelectChip active={전체_상태} onClick={전체_선택}>
            <p>전체</p>
          </SelectChip>
          {scaleData.scaleInfoList.map((el) => {
            const active = selectedFilter.scaleCodeList.includes(el.scaleCode);
            return (
              <SelectChip
                active={active}
                onClick={() => 평형_선택(el.scaleCode)}
              >
                <p>{el.pyeongType}평</p>
              </SelectChip>
            );
          })}
        </div>
      </ChipArea>
    </Container>
  );
}

const Container = styled.div`
  max-width: 860px;
`;

const BasicInfo = styled.div`
  padding: 12px 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray100};

  .danjiName {
    margin-bottom: 6px;
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 20px;
    font-weight: 600;
    line-height: 26px; /* 130% */
    letter-spacing: -0.4px;
  }

  .danjiAddress {
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
  }
`;

const ChipArea = styled.div`
  margin: 20px 0;

  .head {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 14px;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
  }

  .body {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
`;

const SelectChip = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  min-width: 130px;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, active }) =>
      active ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;

  p {
    color: ${({ theme, active }) =>
      active ? theme.colors.primaryBlue100 : theme.colors.blueGray600};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.32px;
  }
`;
