import styled from "styled-components";

export default function SelectDataList({ data, setIsOpen, onChangeValue }) {
  return (
    <Container>
      {data.map((el, idx) => (
        <li
          className="item"
          key={idx}
          style={{ border: idx + 1 === data.length && "none" }}
          onClick={() => {
            setIsOpen(false);
            onChangeValue(el);
          }}
        >
          <p>{el.label}</p>
        </li>
      ))}
    </Container>
  );
}

const Container = styled.div`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  border-radius: 10px;
  max-height: 300px;
  overflow-y: scroll;

  .item {
    padding: 16px;
    list-style-type: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray100};

    p {
      color: ${({ theme }) => theme.colors.blueGray700};
      font-size: 18px;
      font-weight: 500;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.36px;
    }
  }
`;
