import { useDispatch, useSelector } from "react-redux";
import { setConsultantInfo } from "./consultantSlice";
import { RootState } from "app/store";

export const useConsultantInfo = () => {
  const globalConsultantInfo = useSelector(
    (state: RootState) => state.consultantSlice.data
  );

  const dispatch = useDispatch();
  const setGlobalConsultantInfo = (el) => {
    dispatch(setConsultantInfo(el));
  };

  return {
    globalConsultantInfo,
    setGlobalConsultantInfo,
  };
};
