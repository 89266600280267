import { Arrows18 } from "@components/core/icon/arrow";
import { ArrowDirectionEnum } from "@components/core/icon/arrow/Arrow.interfaces";
import useClickOutside from "@hooks/useClickOutside";
import { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import DropDownList from "./DropDownList";

export enum dropDownTypeEnum {
  A = "A",
  B = "B",
}

interface Props {
  optionList: { title: string; value: string }[];
  type?: dropDownTypeEnum;
  selectedValue: string;
  onChangeValue: (v: string) => void;
  style?: React.CSSProperties;
  scrollHeight?: number;
}

export default function DropDown({
  optionList,
  selectedValue,
  onChangeValue,
  type = dropDownTypeEnum.A,
  style,
  scrollHeight,
}: Props) {
  const dropDownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelect = (item: string) => {
    onChangeValue(item);
    setIsOpen(false);
  };

  const displayTitle = useMemo(() => {
    const find = optionList.find((item) => item.value === selectedValue);
    if (!find) return "";
    return find.title;
  }, [selectedValue, optionList]);

  useClickOutside([dropDownRef], () => setIsOpen(false));

  return (
    <div style={{ position: "relative", ...style }} ref={dropDownRef}>
      <Container
        onClick={handleClick}
        style={{ borderRadius: type === dropDownTypeEnum.A ? 24 : 8 }}
      >
        <span>{displayTitle}</span>
        <Arrows18
          direction={isOpen ? ArrowDirectionEnum.UP : ArrowDirectionEnum.DOWN}
        />
      </Container>
      {isOpen && (
        <DropDownList
          optionList={optionList}
          handleSelect={handleSelect}
          selectedValue={selectedValue}
          scrollHeight={scrollHeight}
        />
      )}
    </div>
  );
}

const Container = styled.button`
  display: flex;
  padding: 7px 10px 7px 12px;
  width: fit-content;
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.white};
  align-items: center;

  span {
    margin-right: 2px;
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.3px;
  }
`;
