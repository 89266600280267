import CompletedReportTable from "@components/page/influencer/consultation/report/CompletedReportTable";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useGetApplicantsForReport } from "@hooks/consultation";
import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import styled from "styled-components";

export default function CompletedReportList() {
  const { setCurrentMenu } = useMenu();
  const { data } = useGetApplicantsForReport({ isSubmitted: true });

  useEffect(() => {
    setCurrentMenu("report");
  }, []);

  return (
    <Container>
      <Title>보고서 관리</Title>
      <div style={{ height: 40 }} />
      <CompletedReportTable data={data?.applicants || []} />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;
