export default function Tooltip16() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <rect x="1" y="1" width="16" height="16" rx="8" fill="white" />
      <rect
        x="0.75"
        y="0.75"
        width="16.5"
        height="16.5"
        rx="8.25"
        stroke="#000021"
        strokeOpacity="0.32"
        strokeWidth="0.5"
      />
      <path
        d="M7.91543 10.8102V10.9583H9.58985V10.8102C9.59619 9.8681 9.91332 9.43069 10.63 8.97981C11.4609 8.44818 12 7.74832 12 6.63795C12 4.97577 10.7505 4 8.98097 4C7.35729 4 6.03171 4.90848 6 6.79946H7.80127C7.82664 6.01211 8.37844 5.59489 8.96829 5.58816C9.58351 5.59489 10.0782 6.0323 10.0719 6.70525C10.0782 7.32436 9.65328 7.74159 9.10782 8.11844C8.34672 8.62315 7.92178 9.13459 7.91543 10.8102ZM7.72516 12.8694C7.71247 13.4953 8.1945 14 8.7907 14C9.35518 14 9.84355 13.4953 9.84355 12.8694C9.84355 12.2503 9.35518 11.7456 8.7907 11.7524C8.1945 11.7456 7.71247 12.2503 7.72516 12.8694Z"
        fill="#000021"
        fillOpacity="0.4"
      />
    </svg>
  );
}
