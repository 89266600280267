import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Instance } from "..";
import {
  PermissionMappingRes,
  UserGroupReq,
  UserGroupSettingInfoRes,
} from "./permission.interfaces";

// 전체 화면 권한 매핑 정보
export const useGetPermissionMapping = () =>
  useQuery<PermissionMappingRes, AxiosError>({
    queryKey: ["permission", "mapping"],
    queryFn: async () => {
      const response = await Instance({
        url: "/auth/login/window-permission-mapping",
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 1000 * 60 * 5,
  });

// 유저 그룹 목록 조회
export const useGetUserGroupList = () =>
  useQuery<string[], AxiosError>({
    queryKey: ["permission", "group", "list"],
    queryFn: async () => {
      const response = await Instance({
        url: "/auth/group/list",
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 0,
  });

// 유저 그룹 상세 조회
export const useGetUserGroupSettingInfo = ({ selectedGroup }) =>
  useQuery<UserGroupSettingInfoRes, AxiosError>({
    queryKey: ["permission", "group", selectedGroup],
    queryFn: async () => {
      const response = await Instance({
        url: `/auth/group/get/${selectedGroup}`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 0,
  });

// 유저 그룹 추가
export const useCreateUserGroup = () => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, UserGroupReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/auth/group/create`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["permission", "group"],
      });
    },
  });
};

// 유저 그룹 수정
export const useUpdateUserGroup = ({ selectedGroup }) => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, UserGroupReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/auth/group/update/${selectedGroup}`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["permission", "group"],
      });
      queryClient.invalidateQueries({
        queryKey: ["authorities"],
      });
    },
  });
};

// 유저 그룹 삭제
export const useDeleteUserGroup = () => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError>({
    mutationFn: async (groupName) => {
      const response = await Instance({
        url: `/auth/group/delete/${groupName}`,
        method: "DELETE",
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["permission", "group", "list"],
      });
    },
  });
};
