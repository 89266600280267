import { relationshipArray } from "@utils/code";
import DropDownInput from "../DropDownInput";
import InputRow from "./InputRow";

export default function RelationInput({ value, onChange }) {
  return (
    <InputRow title="가족관계" require>
      <DropDownInput
        placeholder="가족관계"
        onChange={(v) => onChange({ name: "relationCode", value: v })}
        value={value || ""}
        optionList={relationshipArray}
      />
    </InputRow>
  );
}
