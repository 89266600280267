import { ArrowColorEnum, ArrowDirectionEnum, Props } from "./Arrow.interfaces";
import styled from "styled-components";

const lineColor = {
  B20: "#CCCFD3",
  B30: "#B2B7BC",
  B40: "#999FA6",
  B50: "#808690",
};

//BDS에 없는 임시 아이콘
export default function Arrows32({
  color = ArrowColorEnum.B30,
  direction = ArrowDirectionEnum.RIGHT,
}: Props) {
  return (
    <Container direction={direction}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http:www.w3.org/2000/svg"
      >
        <g id="Arrows24">
          <path
            id="Vector 882 (Stroke)"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.8875 24.349C20.4819 23.8086 20.5257 22.8887 19.9854 22.2943L13.6021 15.2727L19.9854 8.25117C20.5257 7.65676 20.4819 6.73684 19.8875 6.19646C19.2931 5.65609 18.3732 5.6999 17.8328 6.29431L10.5601 14.2943C10.0557 14.8491 10.0557 15.6964 10.5601 16.2512L17.8328 24.2512C18.3732 24.8456 19.2931 24.8894 19.8875 24.349Z"
            fill={lineColor[color]}
          />
        </g>
      </svg>
    </Container>
  );
}

const Container = styled.div<{ direction: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  ${({ direction }) => {
    switch (direction) {
      case ArrowDirectionEnum.DOWN:
        return "transform: rotate(270deg);";
      case ArrowDirectionEnum.LEFT:
        return "transform: rotate(0deg); position: relative; bottom: 1px";
      case ArrowDirectionEnum.UP:
        return "transform: rotate(90deg);";
      default:
        return;
    }
  }}
`;
