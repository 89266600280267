import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function DevMain() {
  const navigate = useNavigate();

  return (
    <Container>
      <Title>404 {"\n"}page not found.</Title>
      {/*   <Row style={{ marginTop: 20 }}>
        <UploadButton onClick={() => {}} style={{ backgroundColor: "#f0f2f5" }}>
          <p style={{ color: "#666e7a" }}>아니요</p>
        </UploadButton>
        <div style={{ width: 20 }} />
        <UploadButton onClick={() => {}}>
          <p>네</p>
        </UploadButton>
      </Row> */}
      {/* <Empty title="에러가 없어요" iconType={WhitespaceEnum.Default} /> */}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 50vh;
  align-items: center;
  justify-content: center;
  /* align-items: center; */
  /* height: 100vh; */
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blueGray800};
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  white-space: pre-wrap;
`;

const Row = styled.div`
  display: flex;
`;

const UploadButton = styled.div`
  padding: 9px 16px;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;

  p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: white;
  }
`;
