import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import ApprovalInput from "@components/page/admin/consultation/report/ApprovalInput";
import { Title } from "@components/styles";
import { useGetApplicantInfo, usePostApproval } from "@hooks/consultation";
import { uploadImageToS3 } from "@utils/file";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

export default function ConsultationApproval() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { data } = useGetApplicantInfo({
    applicantId: id,
  });
  const { mutate, isLoading } = usePostApproval();
  const [approvalInfo, setApprovalInfo] = useState({
    title: "",
    content: "",
    pdf: [],
  });
  const approvalStatus = searchParams.get("approval");
  const status = { approve: "승인", reject: "반려" };

  const onSubmit = async () => {
    if (window.confirm(`보고서를 ${status[approvalStatus]}할까요?`)) {
      // 첨부한 파일이 있을 때만 s3 업데이트 작업 실시
      if (approvalInfo.pdf.length > 0) {
        await Promise.all(
          approvalInfo.pdf.map(async (pdf, index) => {
            if (pdf.presignedUrl) {
              await uploadImageToS3(pdf.presignedUrl, pdf.file);
            }
          })
        );
      }

      const pdfNameArr = approvalInfo.pdf.map((el) => {
        return { fileName: el.fileName, file: null };
      });

      await mutate(
        // mutate 호출을 await로 감싸기
        {
          applicantId: id,
          data: {
            approvalState: approvalStatus,
            comment: approvalInfo.content,
            pdf: pdfNameArr || [],
          },
        },
        {
          onSuccess: (res) => {
            alert("전송 성공");
            navigate(-1);
          },
          onError: (res) => alert("전송 실패"),
        }
      );
    } else {
      return;
    }
  };

  useEffect(() => {
    if (data) {
      if (data.report.title !== approvalInfo.title) {
        setApprovalInfo({
          title: data.report.title,
          content: data.report.comment,
          pdf: data.report.pdf || [],
        });
      }
    }
  }, [data]);

  return (
    <Container>
      <Title>보고서 {status[approvalStatus]}</Title>
      <ApprovalInput
        approvalInfo={approvalInfo}
        setApprovalInfo={setApprovalInfo}
        approvalStatus={approvalStatus}
        onSubmit={onSubmit}
      />
      {isLoading ? <AbsoluteFillLoading /> : null}
    </Container>
  );
}

const Container = styled.div``;
