import InputContainer from "@components/page/admin/report/contents/InputContainer";
import { Title } from "@components/styles";
import styled from "styled-components";

export default function EditContents() {
  return (
    <Container>
      <Title>컨텐츠 정보수정</Title>
      <InputContainer />
    </Container>
  );
}

const Container = styled.div``;
