export function btoaUnicode(str) {
  if (!str) return "";
  try {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16)); // 16진수로 변환
      })
    );
  } catch (err) {
    console.error(err);
    return "";
  }
}

export function atobUnicode(str) {
  if (!str) return "";
  try {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return "%" + c.charCodeAt(0).toString(16).padStart(2, "0");
        })
        .join("")
    );
  } catch (err) {
    console.error(err);
    return "";
  }
}
