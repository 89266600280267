import { Title } from "@components/styles";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import { useGetDetailedSource } from "@hooks/consultation";
import { useParams } from "react-router-dom";
import styled from "styled-components";

export default function ViewSource() {
  const { id } = useParams();
  const { data } = useGetDetailedSource({ contentsId: id });

  return (
    <Container>
      <Title>{data?.title}</Title>
      <Content>
        <a href={data?.data.file} className="fileName">
          {data?.data.fileName}
        </a>
        <div style={{ height: 32 }} />
        <p className="date">{convertToKoreanDate(data?.createdAt)}</p>
      </Content>
      {data?.data.fileName.includes("pdf") ? null : (
        <Preview onClick={() => window.open(data?.data.file)}>
          <img
            src={data?.data.file}
            style={{ width: "50%", height: "50%" }}
            alt={"자료 미리보기"}
          />
        </Preview>
      )}
    </Container>
  );
}

const Container = styled.div``;

const Content = styled.div`
  margin-top: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray100};

  .fileName {
    color: ${({ theme }) => theme.colors.primaryBlue100};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .date {
    color: ${({ theme }) => theme.colors.blueGray400};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
  }
`;

const Preview = styled.div`
  margin-top: 32px;
`;
