import { ThemeProvider as StyledProvider } from "styled-components";
import { lightTheme } from "./styles/theme";
import { useMenu } from "@features/useMenu";
import { useEffect } from "react";
// import { useMenu } from "@features/useMenu";

export const ThemeProvider = ({ children }) => {
  const { setGlobalThemeMode } = useMenu();
  // const theme = globalThemeMode === "light" ? lightTheme : darkTheme; // 테마 환경에 맞는 테마 컬러 가져오기.
  useEffect(() => {
    setGlobalThemeMode("light");
  }, []);

  return <StyledProvider theme={lightTheme}>{children}</StyledProvider>;
};
