import { Title } from "@components/styles";

import styled from "styled-components";
import IRTable from "./IRTable";
import { useGetIRApplicant } from "@api/management/ir";

export default function IRListPage() {
  const { data, isLoading } = useGetIRApplicant();
  console.log("🐙 ~ IRListPage ~ data:", data);

  return (
    <Container>
      <Title>투자 요청 관리</Title>
      <div style={{ height: 40 }} />
      <IRTable data={data?.applicants || []} isLoading={isLoading} />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;
