import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import PreQuestionInput from "@components/page/admin/consultation/consultationStatus/PreQuestionInput";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import {
  useGetApplicantInfo,
  usePatchApplicantState,
  usePostPreQuestion,
} from "@hooks/consultation";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

export default function PostPreQuestion() {
  const { id } = useParams();
  console.log("🐙 ~ PostPreQuestion ~ id:", id);
  const navigate = useNavigate();
  const { setCurrentMenu } = useMenu();
  const { mutate, isLoading } = usePostPreQuestion();
  const { data } = useGetApplicantInfo({ applicantId: id });
  const [preQuestionInfo, setPreQuestionInfo] = useState({
    applicantId: null,
    title: null,
    notionUrl: null,
  });

  useEffect(() => {
    setCurrentMenu("consultationStatus");
  }, []);

  useEffect(() => {
    if (data) {
      setPreQuestionInfo({
        applicantId: data?.applicantId,
        title: data?.report.title,
        notionUrl: data?.report.notionUrl,
      });
    }
  }, [data]);

  const onSubmit = () => {
    mutate(preQuestionInfo, {
      onSuccess: (res) => {
        alert("전송 완료");
        navigate(-1);
      },
    });
  };

  return (
    <Container>
      <Title>{data?.applicantName || "000"}님의 인적사항 보내기</Title>
      <PreQuestionInput
        onSubmit={onSubmit}
        preQuestionInfo={preQuestionInfo}
        setPreQuestionInfo={setPreQuestionInfo}
      />
      {isLoading ? <AbsoluteFillLoading /> : null}
    </Container>
  );
}

const Container = styled.div``;
