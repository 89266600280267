import { Outlet, Route, Routes } from "react-router-dom";
import DevMain from "@pages/dev/DevMain";

import WhiteList from "@pages/dev/WhiteList";
import Url from "@pages/dev/Url";
import AppVersionPage from "@pages/dev/appManaging/appVersion/appVersion/AppVersionPage";
import VersionDetailPage from "@pages/dev/appManaging/appVersion/detail/VersionDetailPage";

export default function DevRouter() {
  return (
    <Routes>
      <Route
        element={
          <div style={{ padding: "48px 0 60px" }}>
            <Outlet />
          </div>
        }
      >
        <Route index element={<DevMain />} />
        <Route path="version" element={<AppVersionPage />} />
        <Route path="version/:id" element={<VersionDetailPage />} />
        <Route path="white-list" element={<WhiteList />} />
        <Route path="url" element={<Url />} />
      </Route>
    </Routes>
  );
}
