// /admin/story/list

import { Instance } from "@api/index";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { StoryObj, StoryReq } from "./story.interfaces";

// 임장 이야기 리스트 조회
export const useGetStoryList = () =>
  useQuery<StoryObj[], AxiosError>({
    queryKey: ["story"],
    queryFn: async () => {
      const response = await Instance({
        url: "/brokerage/admin/story/list",
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 1000 * 60 * 5,
  });

// 임장 이야기 개별 조회
export const useGetStoryListById = ({ id }) =>
  useQuery<StoryObj, AxiosError>({
    queryKey: ["story", id],
    queryFn: async () => {
      const response = await Instance({
        url: `/brokerage/admin/story/${id}`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 1000 * 60 * 5,
  });

// 임장 이야기 추가
export const usePostImjangStory = () => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, StoryReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/story`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["story"],
      });
    },
  });
};

// 임장 이야기 수정
export const useUpdateStory = ({ id }) => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, StoryReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/story/${id}`,
        method: "PATCH",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["story"],
      });
    },
  });
};

// 임장 이야기 삭제
export const useDeleteStory = () => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError>({
    mutationFn: async () => {
      const response = await Instance({
        url: `/brokerage/admin/story`,
        method: "DELETE",
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["story"],
      });
    },
  });
};

// 임장 이야기 여러개 삭제
export const useDeleteStoryList = () => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, { storyIdList: string[] }>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/story/list`,
        method: "DELETE",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["story"],
      });
    },
  });
};
