import { CheckBox22, CheckBoxEnum } from "@components/core/icon/checkBox";
import { useAlert } from "@features/useAlert";
import { useMemo } from "react";
import styled from "styled-components";

export default function PermissionBox({
  data,
  permissionSetting,
  setSettingInfo,
}) {
  const { showGlobalAlert } = useAlert();
  const active = useMemo(() => {
    return permissionSetting.includes(data.value);
  }, [data, permissionSetting]);

  const 상태 = useMemo(() => {
    if (active) {
      return CheckBoxEnum.Activate;
    } else if (data.value === "B01" || data.value === "B02") {
      return CheckBoxEnum.Disabled;
    } else return CheckBoxEnum.Default;
  }, [active, data]);

  const onClick = () => {
    // 인플루언서 권한은 disabled
    if (data.value === "B01" || data.value === "B02") return;

    // 빼기
    if (permissionSetting.includes(data.value)) {
      const newArr = permissionSetting.filter((el) => el !== data.value);
      setSettingInfo((prev) => ({ ...prev, authorities: newArr }));
    } else {
      // 더하기
      if (permissionSetting.length >= 9) {
        showGlobalAlert({
          desc: "권한 설정은 최대 9개까지 선택가능합니다.",
          blue: true,
        });
        return;
      }

      setSettingInfo((prev) => ({
        ...prev,
        authorities: [...permissionSetting, data.value],
      }));
    }
  };

  return (
    <Container onClick={onClick}>
      <CheckBox22 status={상태} />
      <p>{data.title}</p>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 240px;
  height: fit-content;
  padding: 9px 8px;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.3px;
  }
`;
