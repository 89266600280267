import { useMutation, useQuery } from "@tanstack/react-query";
import { AuthInstance } from "../../api";

import { AxiosError } from "axios";
import { ContentUploadReq } from "./contributor.interfaces";

// index

//컨텐츠 업로드
export const usePostContent = () =>
  useMutation<string, Error, ContentUploadReq>(async (data) => {
    const response = await AuthInstance({
      url: "/contents",
      method: "POST",
      data,
    });
    return response.data;
  });

//통계
export const useGetContentsRecords = () =>
  useQuery({
    queryKey: ["contents", "records"],
    queryFn: async () => {
      const response = await AuthInstance({
        url: `/contents/records?limit=2`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 60 * 60000,
    retry: false,
  });

export const useGetContentsStat = ({ startDate, endDate }) =>
  useQuery({
    queryKey: ["contents", "statistics", startDate, endDate],
    queryFn: async () => {
      const response = await AuthInstance({
        url: `/contents/statistics?start_date=${startDate}-01-01&end_date=${endDate}-01-31`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 60 * 60000,
    retry: false,
    enabled: !!startDate,
  });

// export const useContentsStat = () =>
//   useMutation<ContentsStateRes, Error, ContentsStatReq>(async (data) => {
//     const response = await AuthInstance({
//       url: `/contents/statistics?start_date=${data.start}-01-01&end_date=${data.end}-01-31`,
//       method: "GET",
//     });

//     return response.data;
//   });

// 정산 여부로 구분하는 컨텐츠 조회 => is_paid 없으면 전체 목록
export const useGetContentPaymentsList = ({ start_date, end_date }) =>
  useQuery<
    { start_date?: string; end_date?: string; is_paid?: boolean },
    AxiosError,
    { message: string; contents: object[] }
  >({
    queryKey: ["content", start_date, end_date],
    queryFn: async () => {
      const response = await AuthInstance({
        url: `/contents/payments?start_date=${start_date}&end_date=${end_date}&sort=submitted`,
        method: "GET",
      });

      return response.data;
    },
  });
