import styled from "styled-components";
import { GoogleLogo } from "@assets/logo/Google";
import { useSearchParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import { useEffect } from "react";

const GoogleLoginButton = () => {
  const cookies = new Cookies();
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get("inv_code");

  useEffect(() => {
    if (inviteCode) {
      const expiration = new Date(Date.now() + 360 * 1000);
      cookies.set("inv_code", inviteCode, {
        path: "/",
        expires: expiration,
      });
    }
  }, []);

  const googleLogin = () => {
    const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const SCOPE =
      "https://www.googleapis.com/auth/userinfo.profile  https://www.googleapis.com/auth/userinfo.email";
    const REDIRECT_URI = `${process.env.REACT_APP_HOST}/auth?social=google`;
    const STATE = "boodong"; // 앱에서 만튼 토큰을 넣는 자리 / 임시로 string 부여
    const GOOGLE_AUTH_URL =
      `https://accounts.google.com/o/oauth2/v2/auth` +
      `?client_id=${CLIENT_ID}` +
      // `&redirect_uri=http://localhost:3000/auth?social=google` +
      `&redirect_uri=${REDIRECT_URI}` +
      `&scope=${SCOPE}` +
      `&response_type=code` +
      `&state=${STATE}`;

    window.location.href = GOOGLE_AUTH_URL;
  };

  return (
    <Container onClick={googleLogin}>
      <div className="logo">
        <GoogleLogo />
      </div>
      <p>Google 계정으로 로그인</p>
    </Container>
  );
};

export default GoogleLoginButton;

const Container = styled.button`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 365px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  border-radius: 4px;
  background-color: white;

  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 14px;
    font-weight: 600;
    line-height: 14px; /* 100% */
    letter-spacing: -0.28px;
  }

  .logo {
    position: absolute;
    left: 8px;
    top: 10px;
  }
`;
