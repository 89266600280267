import PermissionLayout from "@components/router/PermissionLayout";
import { Route, Routes } from "react-router-dom";
import {
  CohortListPage,
  CoinHistoryPage,
  CoinAddPage,
  IRDetailPage,
  IRListPage,
  PushChartPage,
  PushLogPage,
  AddPushSchedule,
  EditPushSchedule,
  PushScheduleList,
  StoryAddPage,
  StoryEditPage,
  StoryListPage,
  NewsListPage,
  NewsAddPage,
  NewsEditPage,
} from "@pages/manage";

export default function ManageRouter({ pageValue }) {
  return (
    <Routes>
      <Route element={<PermissionLayout pageValue={pageValue} />}>
        <Route path="notification/log" element={<PushLogPage />} />
        <Route path="notification/chart" element={<PushChartPage />} />
        <Route path="push/group" element={<CohortListPage />} />
        <Route path="push" element={<PushScheduleList />} />
        <Route path="push/add" element={<AddPushSchedule />} />
        <Route path="push/:id" element={<EditPushSchedule />} />
        <Route path="ir" element={<IRListPage />} />
        <Route path="ir/:id" element={<IRDetailPage />} />
        <Route path="news" element={<NewsListPage />} />
        <Route path="news/add" element={<NewsAddPage />} />
        <Route path="news/:id" element={<NewsEditPage />} />
        <Route path="coin" element={<CoinHistoryPage />} />
        <Route path="coin/add" element={<CoinAddPage />} />
        <Route path="story" element={<StoryListPage />} />
        <Route path="story/add" element={<StoryAddPage />} />
        <Route path="story/:id" element={<StoryEditPage />} />
      </Route>
    </Routes>
  );
}
