import { useMemo } from "react";
import styled from "styled-components";

export default function StatusChip({ state, onClick }) {
  const color = useMemo(() => {
    switch (state) {
      case "review":
        return ["#a8e6cf", "#007a33"];
      case "deprecated":
        return ["#f0f0f0", "#7d7d7d"];
      case "live":
        return ["#d0e7ff", "#007acc"];
      case "develop":
        return ["#f8d7da", "#c82333"];
      default:
        return ["#e0e0e0", "#9e9e9e"];
    }
  }, [state]);

  return (
    <ChipBox color={color[0]} onClick={onClick}>
      <Circle color={color[1]} />
      <ChipText color={color[1]}> {state || "none"}</ChipText>
    </ChipBox>
  );
}

const ChipBox = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 20px;
  background: ${({ color }) => color};
  cursor: pointer;
`;

const Circle = styled.div`
  margin-right: 4px;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  background-color: ${({ color }) => color};
`;

const ChipText = styled.p`
  color: ${({ color }) => color};
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
`;
