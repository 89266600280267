import styled from "styled-components";
import { Link } from "react-router-dom";

export default function SubMenu({ data, onClick }) {
  return (
    <ColumnContainer>
      {data.content.map((el, idx) => {
        return (
          <Link to={el.linkUrl} key={idx}>
            <MenuItem onClick={onClick}>
              <p className="menu">{el.menu}</p>
            </MenuItem>
          </Link>
        );
      })}
    </ColumnContainer>
  );
}

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 105px;
  padding: 9px 0;
  cursor: pointer;

  .menu {
    color: ${({ theme }) => theme.colors.blueGray600};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
  }

  &:hover {
    p {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-weight: 600;
    }
  }
`;
