import PermissionLayout from "@components/router/PermissionLayout";
import { MyContentsPage, ReportUploadPage, StatPage } from "@pages/contributor";
import { Route, Routes } from "react-router-dom";

export default function ReportRouter({ pageValue }) {
  return (
    <Routes>
      <Route element={<PermissionLayout pageValue={pageValue} />}>
        <Route path="upload" element={<ReportUploadPage />} />
        <Route path="stat" element={<StatPage />} />
        <Route path="my" element={<MyContentsPage />} />
      </Route>
    </Routes>
  );
}
