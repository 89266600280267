import styled from "styled-components";
import MaemulContent from "../../manage/maemul/MaemulContent";
import { Close32 } from "@components/core/icon/close";

export default function MaemulSearchPopup({ setIsVisible }) {
  return (
    <Container>
      <Title>단지 추가하기</Title>
      <IconBox
        onClick={() => {
          console.log("click");
          setIsVisible(false);
        }}
      >
        <Close32 />
      </IconBox>
      <MaemulContent setIsVisible={setIsVisible} />
    </Container>
  );
}

const Container = styled.div`
  padding: 40px 32px;
  background-color: white;
  min-width: 920px;
  min-height: 580px;
`;

const Title = styled.h3`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.blueGray1000};
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
`;

const IconBox = styled.div`
  position: absolute;
  right: 32px;
  top: 40px;
  cursor: pointer;
`;
