import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Title } from "@components/styles";
import { initialConsultantInfo } from "@features/consultantSlice";
import { useConsultantInfo } from "@features/useConsultantInfo";
import { useMenu } from "@features/useMenu";
import { usePostConsultingInfo } from "@hooks/consultation";
import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import ConsultingInput from "@components/page/admin/consultation/consulting/ConsultingInput";
import { uploadImageToS3ReturnResult } from "@utils/file";
import { useGetInfluencersList } from "@hooks/admin";
import useCustomSearchParams from "@hooks/useCustomSearchParams";

// const { globalConsultantInfo, setGlobalConsultantInfo } = useConsultantInfo();
// 글로벌은 그냥 이 페이지 내에서 정보 담아놓는 용도로 쓰자~
// 이 페이지에 진입할 때마다 초기정보로 리셋 (원래 새로고침하면 날아감)

export default function ConsultingAdd() {
  const navigate = useNavigate();
  const { searchParams } = useCustomSearchParams();
  const DB_VERSION = searchParams.db;

  const { globalConsultantInfo, setGlobalConsultantInfo } = useConsultantInfo();
  const [image, setImage] = useState({
    profileImgUrl: { presignedUrl: null, data: null },
    mainImgUrl: { presignedUrl: null, data: null },
    introImgUrl: { presignedUrl: null, data: null },
    frequentVisitImgUrl: { presignedUrl: null, data: null },
    detailImgUrl: { presignedUrl: null, data: null },
    spotlightImgUrl: { presignedUrl: null, data: null },
    consultingImgUrl: { presignedUrl: null, data: null },
  });

  const { mutate, isLoading } = usePostConsultingInfo({
    dbVersion: DB_VERSION,
  });

  useEffect(() => {
    setGlobalConsultantInfo(initialConsultantInfo);
  }, []);

  const handleSave = async () => {
    const transformedArray = Object.entries(image).map(([key, value]) => ({
      name: key,
      ...value,
    }));
    const imgObj = { spotlightImgUrl: null, consultingImgUrl: null };
    const uploadPromises = transformedArray.map(async (el) => {
      const result = await uploadImageToS3ReturnResult({
        name: `${globalConsultantInfo.influencer}-${el.name}.png`,
        presignedUrl: el.presignedUrl,
        file: el.data,
      });
      imgObj[el.name] = result;
    });
    await Promise.all(uploadPromises);
    console.log("🐙 ~ handleSave ~ imgObj: 이미지 오브젝트", imgObj);
    const obj = {
      ...globalConsultantInfo,
      ...imgObj,
      exposures: [
        {
          title: "spotlight",
          state: globalConsultantInfo.exposures.find(
            (item) => item.title === "spotlight"
          ).state,
          imgUrl: imgObj.spotlightImgUrl,
        },
        {
          title: "expertAdvice",
          state: globalConsultantInfo.exposures.find(
            (item) => item.title === "expertAdvice"
          ).state,
          imgUrl: imgObj.consultingImgUrl,
        },
      ],
    };

    console.log("🐙 ~ handleSave ~ obj: 통신 바디", obj);
    mutate(obj, {
      onSuccess: (res) => {
        if (window.confirm("등록 완료. 컨설팅 목록 화면으로 돌아갑니다.")) {
          navigate("/consultation/consultings");
        }
      },
      onError: (res: any) => {
        if (res) alert(res.response.data.message);
      },
    });
  };

  return (
    <Container>
      <Title>상담소 컨설팅 등록</Title>
      <ConsultingInput
        handleSave={handleSave}
        image={image}
        setImage={setImage}
      />
      {isLoading ? <AbsoluteFillLoading /> : null}
    </Container>
  );
}

const Container = styled.div``;
