import * as React from "react";
import { Props, RadioEnum } from "./Radio.interfaces";

const edgeColor = {
  Activate: "#1C84FF",
  Default: "#CFE4FD",
  Disabled: "#E5E7E9",
};
const centerColor = {
  Activate: "white",
  Default: "white",
  Disabled: "#B2B7BC",
};

export default function Radio24({ status = RadioEnum.Default }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="12" fill={edgeColor[status]} />
      <circle cx="12" cy="12" r="5" fill={centerColor[status]} />
    </svg>
  );
}
