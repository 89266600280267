export function addNextGroup(groups) {
  // Group으로 시작하고 숫자로 끝나는 구조를 가진 그룹을 찾기 위한 정규식
  const groupPattern = /^Group(\d+)$/;
  let maxGroupNumber = 0;

  // 그룹 배열을 순회하면서 가장 큰 그룹 숫자를 찾음
  groups.forEach((group) => {
    const match = group.match(groupPattern);
    if (match) {
      const groupNumber = parseInt(match[1], 10); // 그룹의 숫자 부분을 추출
      if (groupNumber > maxGroupNumber) {
        maxGroupNumber = groupNumber;
      }
    }
  });

  // 새로운 그룹 번호를 만듦, 0이면 첫 그룹인 Group01, 아니면 maxGroupNumber + 1
  const newGroupNumber = maxGroupNumber + 1;
  const newGroup = `Group${String(newGroupNumber).padStart(2, "0")}`;

  // 그룹 이름 반환
  return newGroup;
}
