export default function Minus16() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect
        x="13"
        y="7"
        width="2"
        height="10"
        transform="rotate(90 13 7)"
        fill="#808690"
      />
    </svg>
  );
}
