import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Title } from "@components/styles";
import NoticeInput from "../NoticeAdd/NoticeInput";
import { useEditNotice, useGetNotice } from "@api/notice";
import { toast } from "react-toastify";

export default function NoticeEditPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { mutate: getNotice } = useGetNotice();
  const { mutate: editNotice } = useEditNotice();
  const [noticeInfo, setNoticeInfo] = useState({
    title: "",
    content: "",
  });

  useEffect(() => {
    getNotice(
      { id },
      {
        onSuccess: (res) => {
          const { title, content } = res.announcement;
          setNoticeInfo({ title, content });
        },
      }
    );
  }, []);

  const onSubmit = () =>
    editNotice(
      { id, data: noticeInfo },
      {
        onSuccess: () => {
          toast("성공적으로 수정되었습니다.");
          navigate("/notice/config");
        },
      }
    );

  return (
    <Container>
      <Title>공지사항 수정</Title>
      <NoticeInput
        noticeInfo={noticeInfo}
        setNoticeInfo={setNoticeInfo}
        onSubmit={onSubmit}
      />
    </Container>
  );
}

const Container = styled.div``;
