import styled from "styled-components";

export default function DeleteVersionPopup({ onClick }) {
  const onSubmit = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <Container onSubmit={onSubmit}>
      <h1 className="title">해당 버전을 정말로 삭제할까요?</h1>
      <List>
        <li>
          버전 삭제 시 입력한 값은 복구가 불가능합니다. {"\n"}
          절대 라이브 중인 버전을 삭제하지마세요.
        </li>
      </List>
      <button type="submit" onClick={onSubmit}>
        삭제하기
      </button>
    </Container>
  );
}

const Container = styled.form`
  padding: 24px 16px 20px;
  width: 360px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};

  .title {
    margin-bottom: 24px;
    color: ${({ theme }) => theme.colors.blueGray1000};
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.4px;
  }

  p {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
  }

  button {
    width: 100%;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.red1000};
    border-radius: 10px;

    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  gap: 4px;

  li {
    display: flex;
    padding: 4px 0;

    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.3px;
    list-style: none;
    white-space: pre-wrap;

    &::before {
      content: "";
      margin: 9px 10px 9px 2px;
      width: 6px;
      height: 6px;
      border-radius: 25px;
      background-color: ${({ theme }) => theme.colors.blueGray200};
    }
  }
`;
