import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Minus16, Plus16 } from "@components/core/icon/plus";

import { Button, ButtonWrapper, Title } from "@components/styles";
import { toast } from "react-toastify";
import { useDeleteNews, useGetNews } from "@api/management/news";
import NewsTable from "./NewsTable";

export default function NewsListPage() {
  const navigate = useNavigate();
  const { data, isLoading } = useGetNews();
  const { mutate } = useDeleteNews();

  const [selectedRow, setSelectedRow] = useState();

  const 뉴스_삭제 = () => {
    mutate({ id: selectedRow }, { onSuccess: () => toast("뉴스 삭제 성공") });
  };

  return (
    <Container>
      <Title>뉴스 업로드 관리</Title>
      <ButtonWrapper>
        <div className="gap">
          <Button onClick={() => navigate("/manage/news/add")}>
            <Plus16 />
            <p>기사 추가</p>
          </Button>
          <Button onClick={뉴스_삭제}>
            <Minus16 />
            <p>기사 삭제</p>
          </Button>
        </div>
      </ButtonWrapper>
      <NewsTable
        data={data?.news || []}
        setSelectedRow={setSelectedRow}
        isLoading={isLoading}
      />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;
