import {
  // useQuery,
  // useMutation,
  // useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import Router from "./Router";
import GlobalStyle from "./styles/global-styles";
import { compose } from "redux";
import { Provider } from "react-redux";
import store from "./app/store";
import useApiError from "@hooks/useApiError";
import { CookiesProvider } from "react-cookie";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider } from "./ThemeProvider";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
  }
}

// const devTools =
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

function App() {
  const { handleError } = useApiError();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        onError: (error: any) => {
          console.log(error, "에러 발생");
          handleError(error);
        },
      },
      mutations: {
        onError: (error: any) => {
          console.log(error, "에러 발생");
          handleError(error);
        },
      },
    },
  });

  if (process.env.REACT_APP_BACKEND_HOST.includes("live")) {
    console.log = () => {};
    console.error = () => {};
    console.warn = () => {};
    console.info = () => {};
  }

  return (
    <Provider store={store}>
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ThemeProvider>
            <GlobalStyle />
            <Router />
            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
              theme="dark"
              transition={Bounce}
            />
          </ThemeProvider>
        </QueryClientProvider>
      </CookiesProvider>
    </Provider>
  );
}

export default App;
