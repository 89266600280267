import { Search15 } from "@components/core/icon/search";
import SourceDownTable from "@components/page/influencer/consultation/source/SourceDownTable";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useGetSourceList } from "@hooks/consultation";
import { useEffect, useState } from "react";
import styled from "styled-components";

export default function SourceList() {
  const { setCurrentMenu } = useMenu();
  const { data } = useGetSourceList();
  const [inputValue, setInputValue] = useState("");
  const [spreadData, setSpreadData] = useState(data?.contents);

  useEffect(() => {
    setCurrentMenu("source");
  }, []);

  useEffect(() => {
    let delayedFiltering;
    if (inputValue.length > 1) {
      delayedFiltering = setTimeout(() => {
        const inputWithoutSpaces = inputValue.replace(/\s/g, "").toLowerCase(); // 입력값에서 공백을 제거하고 소문자로 변환합니다.
        const filterArr = data.contents.filter((el) => {
          const titleWithoutSpaces = el.title.replace(/\s/g, "").toLowerCase(); // 항목 제목에서 공백을 제거하고 소문자로 변환합니다.
          return titleWithoutSpaces.includes(inputWithoutSpaces); // 띄어쓰기를 무시하고 대소문자를 구분하지 않고 비교합니다.
        });

        setSpreadData(filterArr);
      }, 1000); // 1초 지연
    } else {
      setSpreadData(data?.contents);
    }

    return () => clearTimeout(delayedFiltering); // cleanup 함수를 사용하여 컴포넌트가 unmount되거나 useEffect가 다시 실행되기 전에 setTimeout을 클리어합니다.
  }, [inputValue, data]);

  return (
    <Container>
      <Title>참고 자료</Title>
      <Search>
        <Search15 />
        <span style={{ width: 16 }} />
        <input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="검색어를 입력하세요"
        ></input>
      </Search>
      <div style={{ height: 40 }} />
      <SourceDownTable data={spreadData || []} />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: fit-content;
`;

const Search = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  display: flex;
  align-items: center;
  padding: 11px 16px;
  width: 20vw;
  min-width: 365px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  input {
    background: ${({ theme }) => theme.colors.blueGrayA100};
    border: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;

    &:focus {
      outline: none;
    }
  }
`;
