import React, { useState, useRef, useEffect } from "react";

import styled from "styled-components";
import DownArrow from "@assets/arrow/DownArrow";
import { PopUpContainer } from "../popUp/PopupContainer";
import AreaPopUp from "@components/common/AreaPopUp";
import { RegionInput } from "../BTypeInput/BTypeInputBoxBtn.interfaces";
import { InputTypeEnum } from "../BTypeInput/BTypeInput.interfaces";

export default function RegionBoxBtnInput({
  label,
  value,
  style,
  inputStyle,
  placeholder,
  onChangeValue,
}: RegionInput) {
  const [isFocus, setIsFocus] = useState(false);
  const [areaPopUpShow, setAreaPopUpShow] = useState(false);

  const onFocus = () => {
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  const openPopUp = () => {
    setAreaPopUpShow(true);
  };

  const closePopUp = () => {
    setAreaPopUpShow(false);
  };

  return (
    <Container
      style={{ ...style }}
      value={value.sido}
      $isFocus={isFocus}
      onClick={openPopUp}
    >
      {label ? <label className="label">{label}</label> : null}
      <InputContainer $isFocus={isFocus} style={{ ...inputStyle }}>
        <p className="value">
          {value?.sido
            ? `${value?.sido} ${value?.sigugunList
                .map((el) => el.sigugun)
                .join(", ")}`
            : "지역"}
        </p>
        <DownArrow />
      </InputContainer>
      <PopUpContainer isVisible={areaPopUpShow} setIsVisible={setAreaPopUpShow}>
        <AreaPopUp
          value={value}
          onClose={closePopUp}
          onChangeValue={onChangeValue}
        />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div<{
  value: string | number;
  $isFocus: boolean;
}>`
  position: relative;
  padding: 22px 0 28px;

  .label {
    position: absolute;
    top: 0;
    color: ${({ theme, $isFocus }) =>
      $isFocus ? theme.colors.blue1000 : theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  .value {
    width: 95%;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: ${({ theme }) => theme.colors.blueGrayA100};

    color: ${({ theme, value }) =>
      value ? theme.colors.blueGray800 : theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const InputContainer = styled.div<{ $isFocus: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding: 14px 16px;
  border: 1px solid
    ${({ theme, $isFocus }) =>
      $isFocus ? theme.colors.blue600 : theme.colors.blueGray100};
  background-color: ${({ theme }) => theme.colors.blueGrayA100};
  border-radius: 10px;
  box-sizing: content-box;

  &.disabledBG {
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }

  &.defaultBG {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
  }
`;
