import styled from "styled-components";
import Empty from "@components/common/Empty";
import { WhitespaceEnum } from "@components/core/icon/whiteSpace/WhiteSpace.interfaces";
import { useGenConsultingNotion } from "@hooks/consultation";

export default function EmptyPreQuestion({
  applicantId,
  hasPreQuestion,
}: {
  applicantId: string;
  hasPreQuestion: boolean;
}) {
  const { mutate } = useGenConsultingNotion();

  const 노션_레포트_생성 = () => {
    mutate(
      { applicantId },
      {
        onSuccess: () => {
          alert("레포트 생성 성공!");
        },
        onError: () => {
          alert(
            `레포트 생성 실패\n개발팀에게 문의하시기 바랍니다.\napplicationId: ${applicantId}`
          );
        },
      }
    );
  };

  if (hasPreQuestion) {
    return (
      <Container>
        <Empty
          title={`제출한 사전질문지가 없어요.\n제출된 질문지가 보이지 않는 경우 아래 버튼을 눌러주세요.`}
          data={[]}
          iconType={WhitespaceEnum.Default}
          style={{ height: "auto" }}
        />
        <Separator />
        <GenNotionBtn onClick={노션_레포트_생성}>질문지 생성하기</GenNotionBtn>
      </Container>
    );
  } else {
    return (
      <Empty
        title="제출한 사전질문지가 없어요."
        data={[]}
        style={{ height: "20vh" }}
        iconType={WhitespaceEnum.Default}
      />
    );
  }
}

const Container = styled.div`
  padding: 10vh 0;
`;

const Separator = styled.div`
  height: 16px;
`;

const GenNotionBtn = styled.button`
  display: block;
  margin: 0 auto;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blue100};

  color: #1c84ff;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.3px;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }
`;
