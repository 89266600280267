export default function CirclePlus20() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10Z"
        stroke="#F4F6F8"
        strokeMiterlimit="10"
      />
      <path d="M10 7L10 13" stroke="#F4F6F8" strokeLinecap="round" />
      <path d="M13 10H7" stroke="#F4F6F8" strokeLinecap="round" />
    </svg>
  );
}
