export const convertLocaleStringToNumber = (s: string) => {
  if (!s) {
    return 0;
  }

  return Number(s.replace(/,/g, ""));
};

export const isNumeric = (n: number) =>
  !isNaN(parseFloat(String(n))) && isFinite(n);
