export const deeplinkList = [
  {
    group: "BNB",
    contents: [
      {
        title: "[BNB] 처방전 스크린",
        value: "bnb/prescription",
        requiresId: false,
      },
      {
        title: "[BNB] 검색 스크린",
        value: "bnb/search",
        requiresId: false,
      },
      {
        title: "[BNB] 홈 스크린",
        value: "bnb/main",
        requiresId: false,
      },
      {
        title: "[BNB] 상담소",
        value: "bnb/report",
        requiresId: false,
      },
      {
        title: "[BNB] 마이 스크린",
        value: "bnb/my-page",
        requiresId: false,
      },
    ],
  },
  {
    group: "미니테스트",
    contents: [
      {
        title: "[미니테스트] 금리체력",
        value: "minitest/interest-health",
        requiresId: false,
      },
      {
        title: "[미니테스트] 적정가격",
        value: "minitest/proper-price",
        requiresId: false,
      },
      {
        title: "[미니테스트] 교통",
        value: "minitest/commute",
        requiresId: false,
      },
    ],
  },
  {
    group: "서비스",
    contents: [
      {
        title: "임장 온보딩",
        value: "imjang",
        requiresId: false,
      },
      {
        title: "컨설팅 상세",
        value: "consulting/:id",
        requiresId: true,
      },
      {
        title: "레포트",
        value: "report/:id",
        requiresId: true,
      },
      {
        title: "데일리 뉴스",
        value: "dailynews",
        requiresId: false,
      },
      {
        title: "공인중개사",
        value: "expert",
        requiresId: false,
      },
    ],
  },
  {
    group: "단지",
    contents: [
      {
        title: "단지 상세",
        value: "danji/:id/:scaleCode",
        requiresId: true,
      },
      {
        title: "단지 매물",
        value: "danji/:id/:scaleCode?maemul=true",
        requiresId: true,
      },
    ],
  },
  {
    group: "마이",
    contents: [
      {
        title: "문의",
        value: "inquiry/1",
        requiresId: false,
      },
      {
        title: "코인 내역",
        value: "coin/history",
        requiresId: false,
      },
    ],
  },
];
