import { Title } from "@components/styles";
import styled from "styled-components";
import { useMenu } from "@features/useMenu";
import { useEffect } from "react";
import AccountingListContent from "@components/page/admin/report/accounting/AccountingListContent";

export default function AccountingList() {
  const { setCurrentMenu, setCurrentSubMenu } = useMenu();
  useEffect(() => {
    setCurrentMenu("settlement");
    setCurrentSubMenu("accountingList");

    return () => {
      setCurrentSubMenu("");
    };
  }, []);

  return (
    <Container>
      <Title>정산내역</Title>
      <AccountingListContent />
    </Container>
  );
}

const Container = styled.div``;
