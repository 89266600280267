import { usePostCoin } from "@api/management/coin";

import { Title } from "@components/styles";
import { usePostPush } from "@hooks/management";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { toast } from "react-toastify";
import styled from "styled-components";
import CoinContent from "./CoinContent";

export default function CoinAddPage() {
  const navigate = useNavigate();
  const { mutate, isLoading } = usePostCoin();
  const { mutate: pushMutate, isLoading: pushLoading } = usePostPush();

  const [coinInfo, setCoinInfo] = useState({
    target: "",
    chargeAmount: 0,
    chargeItem: "",
    sendPush: false,
    push_title: "",
    push_body: "",
  });

  const isDone = useMemo(() => {
    return Boolean(
      coinInfo.target &&
        coinInfo.chargeAmount &&
        coinInfo.chargeItem &&
        (coinInfo.sendPush ? coinInfo.push_title && coinInfo.push_body : true)
    );
  }, [coinInfo]);

  const 코인지급_푸시발송 = () => {
    pushMutate(
      {
        target: [coinInfo.target],
        push_title: coinInfo.push_title,
        push_body: coinInfo.push_body,
        deeplink: "coin/history",
      },
      {
        onSuccess: () => {
          toast("푸시 발송 완료");
          navigate(-1);
        },
        onError: () => toast("푸시 발송 실패"),
      }
    );
  };

  const handleBottomBtn = () => {
    mutate(
      {
        id: coinInfo.target,
        data: {
          chargeAmount: coinInfo.chargeAmount,
          chargeItem: coinInfo.chargeItem,
        },
      },
      {
        onSuccess: (res) => {
          toast("코인 지급 완료");
          if (coinInfo.sendPush) 코인지급_푸시발송();
          else navigate(-1);
        },
        onError: (res) =>
          toast(`코인 지급 실패 \n${res.response.data["message"]}`),
      }
    );
  };

  return (
    <Container>
      <Title>코인 충전</Title>
      <CoinContent coinInfo={coinInfo} setCoinInfo={setCoinInfo} />
      <CTAButton $isDone={isDone} disabled={!isDone} onClick={handleBottomBtn}>
        {isLoading || pushLoading ? (
          <FadeLoader
            cssOverride={{
              width: 10,
              height: 10,
              transform: "scale(0.5)",
              top: -2,
              left: 0,
            }}
            color="white"
          />
        ) : (
          <p>등록하기</p>
        )}
      </CTAButton>
    </Container>
  );
}

const Container = styled.div`
  width: 50%;
  max-width: 700px;
`;

const CTAButton = styled.button<{ $isDone: boolean }>`
  padding: 10px 0;
  width: 100%;
  border-radius: 8px;
  border: none;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;
  color: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.white : theme.colors.blueGray500};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;

  &:disabled {
    cursor: not-allowed;
  }
`;
