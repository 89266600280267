import { useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Title } from "@components/styles";
import { FadeLoader } from "react-spinners";
import { usePostNews } from "@api/management/news";
import NewsContent from "./NewsContent";

export default function NewsAddPage() {
  const navigate = useNavigate();
  const { mutate, isLoading } = usePostNews();
  const [contentInfo, setContentInfo] = useState({
    releasedAt: null,
    title: null,
    url: null,
  });

  const 뉴스_추가 = () => {
    mutate(contentInfo, {
      onSuccess: () => {
        toast("업로드 성공");
        navigate(-1);
      },
    });
  };

  return (
    <Container>
      <Title>뉴스 추가</Title>
      <div style={{ height: 16 }} />
      <NewsContent contentInfo={contentInfo} setContentInfo={setContentInfo} />
      <div style={{ marginTop: 20, maxWidth: "30vw", minWidth: 670 }}>
        <Button $isDone={true} disabled={!true} onClick={뉴스_추가}>
          {isLoading ? (
            <FadeLoader
              cssOverride={{
                width: 10,
                height: 10,
                transform: "scale(0.5)",
                top: -2,
                left: 0,
              }}
              color="white"
            />
          ) : (
            <p>등록하기</p>
          )}
        </Button>
      </div>
    </Container>
  );
}

const Container = styled.div``;

const Button = styled.button<{ $isDone: boolean }>`
  padding: 10px 0;
  width: 100%;
  border-radius: 8px;
  border: none;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;
  color: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.white : theme.colors.blueGray500};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;

  &:disabled {
    cursor: not-allowed;
  }
`;
