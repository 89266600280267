import styled from "styled-components";
import { ko } from "date-fns/esm/locale";
import React, { LegacyRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "@assets/icon/CalendarIcon";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

export default function Calendar({
  setRangeDate,
  text,
}: {
  setRangeDate: React.Dispatch<
    React.SetStateAction<{ start: string; end: string }>
  >;
  text?: string;
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (!dates[0] && !dates[1]) {
      setRangeDate({ start: "", end: "" });
    }

    if (end) {
      // 하루만 선택한 경우
      const rangeStart = moment(start).format("YYYY-MM-DD");
      const rangeEnd = moment(end).format("YYYY-MM-DD");
      if (rangeStart === rangeEnd) {
        setRangeDate({
          start: rangeStart,
          end: "",
        });
        setSearchParams({ page: "1" });
      } else {
        setRangeDate({
          start: moment(start).format("YYYY-MM-DD"),
          end: moment(end).format("YYYY-MM-DD"),
        });
        setSearchParams({ page: "1" });
      }
    }
  };

  const ExampleCustomInput = (
    {
      onClick,
      value,
    }: {
      value: string;
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    },
    ref: LegacyRef<HTMLButtonElement>
  ) => {
    return (
      <button
        className="example-custom-input"
        onClick={onClick}
        ref={ref}
        style={{ display: "flex", alignItems: "center" }}
      >
        {startDate ? (
          <>
            <CalendarIcon />
            <DateValue style={{ marginRight: 32, marginLeft: 5, fontSize: 15 }}>
              {value}
            </DateValue>
          </>
        ) : (
          <CalendarIcon />
        )}
      </button>
    );
  };

  return (
    <Container>
      {text && <Text>{text}</Text>}
      <Box>
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          dateFormat="yyyy.MM.dd"
          maxDate={new Date()}
          locale={ko}
          isClearable={endDate ? true : false}
          customInput={React.createElement(
            React.forwardRef(ExampleCustomInput)
          )}
        />
      </Box>
    </Container>
  );
}

const Container = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
`;

const Box = styled.label`
  display: flex;
  align-items: center;
  padding: 4px 10px 3px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  cursor: pointer;

  .react-datepicker {
    font-family: Pretendard-Medium;
  }

  .react-datepicker__close-icon::after {
    color: ${({ theme }) => theme.colors.primaryBlue100};
    background-color: #e3f0ff !important;
    font-size: 16px;
  }
`;

const DateValue = styled.p`
  color: ${({ theme }) => theme.colors.BlueGray900};
  font-family: Pretendard-Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;
`;

const Text = styled.p`
  margin: 6px 0 6px auto;
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blueGray500};
`;
