import styled from "styled-components";

import bell from "../assets/bell.png";
import { Whitespace } from "@components/core/icon/whiteSpace";
import { WhitespaceEnum } from "@components/core/icon/whiteSpace/WhiteSpace.interfaces";
import MatchButton from "./MatchButton";
import { useGetImjangApplyList } from "@hooks/imjang";

export default function Customer() {
  const { data } = useGetImjangApplyList({
    filter: {
      statusList: ["APPLIED"],
    },
  });

  if (!data || data?.applyList.length === 0) {
    return (
      <EmptyContainer>
        <span className="title">신규 상담 신청</span>
        <EmptyContent>
          <Whitespace status={WhitespaceEnum.Default} />
          <span>임장 비서 신청자가 없습니다</span>
        </EmptyContent>
      </EmptyContainer>
    );
  }

  return (
    <Container>
      <Header>
        <h3>신규 상담 신청</h3>
        <div className="count">
          <span>+{data?.applyList.length}</span>
        </div>
      </Header>
      <Bell src={bell} alt="bell" />
      <Content>
        {data?.applyList[0].name.length > 5
          ? `${data?.applyList[0].name.slice(0, 5)}...`
          : data?.applyList[0].name}
        님께서 임장 비서 신청했습니다.
      </Content>
      <MatchButton />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 20px 16px;
  width: 348px;
  height: 288px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.blue1000};
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  h3 {
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.white};
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.36px;
  }

  .count {
    display: inline-flex;
    padding: 2px 8px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.white};
    justify-content: center;
    align-items: center;

    span {
      color: ${({ theme }) => theme.colors.blueGray700};
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.24px;
      text-align: center;
      font-feature-settings: "liga" off, "clig" off;
    }
  }
`;

const Bell = styled.img`
  margin: 15px 0 10px;
  width: 132px;
  height: 132px;
`;

const Content = styled.span`
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.36px;
  text-align: center;
`;

const EmptyContainer = styled(Container)`
  position: relative;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};

  .title {
    position: absolute;
    top: 12px;
    left: 12px;
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.36px;
  }
`;

const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin-top: 12px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
    text-align: center;
  }
`;
