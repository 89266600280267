import PersonPlus32 from "@assets/icon/PersonPlus32";
import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import CohortTable from "@components/page/admin/manage/cohort/CohortTable";
import DeleteFilePopup from "@components/page/admin/manage/cohort/DeleteFilePopup";
import FileUploadPopup from "@components/page/admin/manage/cohort/FileUploadPopup";
import Skeleton from "@components/skeleton/Skeleton";
import TableSkeleton from "@components/skeleton/TableSkeleton";
import { Button, Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useGetPushGroup } from "@hooks/management";
import { useEffect, useState } from "react";
import styled from "styled-components";

export default function CohortListPage() {
  const { setCurrentMenu } = useMenu();
  const { data, isLoading } = useGetPushGroup();
  const [selectedRow, setSelectedRow] = useState({ target_name: "" });
  const [isVisible업로드팝업, setIsVisible업로드팝업] = useState(false);
  const [isVisible삭제팝업, setIsVisible삭제팝업] = useState(false);

  useEffect(() => {
    setCurrentMenu("cohort");
  }, []);

  const 삭제할_그룹_클릭 = (v) => {
    setSelectedRow(v);
    setIsVisible삭제팝업(true);
  };

  return (
    <Container>
      <Title>푸시 그룹 관리</Title>
      <ControlWrapper>
        <Button onClick={() => setIsVisible업로드팝업(true)}>
          <PersonPlus32 />
          <p>그룹 추가</p>
        </Button>
      </ControlWrapper>
      <CohortTable
        data={data?.targets || []}
        onClickRow={삭제할_그룹_클릭}
        isLoading={isLoading}
      />
      <PopUpContainer
        isVisible={isVisible업로드팝업}
        setIsVisible={setIsVisible업로드팝업}
      >
        <FileUploadPopup setIsVisible={setIsVisible업로드팝업} />
      </PopUpContainer>
      <PopUpContainer
        isVisible={isVisible삭제팝업}
        setIsVisible={setIsVisible삭제팝업}
      >
        <DeleteFilePopup
          setIsVisible={setIsVisible삭제팝업}
          selectedRow={selectedRow}
        />
      </PopUpContainer>
      {/* {isLoading ? <AbsoluteFillLoading /> : null} */}
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;

const ControlWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 8px 0 24px;
`;
