import styled from "styled-components";

import NewApplication from "./components/NewApplication";
import Calendar from "./components/Calendar";
import ApplicantSearch from "./components/ApplicantSearch";
import { useEffect, useState } from "react";
import { debounce } from "@utils/debounce";
import KanbanBoard from "./components/KanbanBoard";
import FixedScrollTooltip from "./components/FixedScrollTooltip";

export default function ImjangApplicant() {
  const [padding, setPadding] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = document.documentElement.clientWidth;
      setPadding((newWidth - 1440) / 2);
    };

    const debouncedResize = debounce(handleResize, 100); // 100ms 딜레이

    window.addEventListener("resize", debouncedResize);
    handleResize(); // 초기 패딩 설정

    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);

  return (
    // 민창님 요청으로 1440px 이하는 양옆 여백 60px 띄움 24.10.30
    <Container>
      <div className="center">
        {/* // 1440px의 박스 */}
        <div className="top">
          <NewApplication />
          <Calendar />
        </div>
      </div>
      <div className="center">
        {/* // 1440px의 박스 */}
        <div className="middle">
          <ApplicantSearch />
        </div>
      </div>
      <div className="bottom">
        <KanbanBoard padding={padding} />
        <FixedScrollTooltip padding={padding} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  padding-top: calc(110px + 84px);
  padding-bottom: 116px;
  /* width: 1440px; */

  .center {
    display: flex;
    justify-content: center;
    min-width: 1440px;
  }

  .top,
  .middle {
    display: flex;
    min-width: 1440px;
    max-width: 1440px;
    width: 1440px;
  }

  @media only screen and (max-width: 1440px) {
    .top,
    .middle {
      min-width: auto;
      width: auto;
    }

    .center {
      padding-left: 60px;
      display: block;
      justify-content: flex-start;
    }
  }

  .bottom {
    min-width: 1440px;
  }
`;
