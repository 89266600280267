import DownArrow from "@assets/arrow/DownArrow";
import useCustomSearchParams from "@hooks/useCustomSearchParams";
import { Table } from "@tanstack/react-table";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

export default function Pagination({
  table,
}: {
  table: Table<unknown>;
  page?: number;
}) {
  const { searchParams, setSearchParams } = useCustomSearchParams();
  const page = Number(searchParams.page) || 1;

  const totalPage = table.getPageCount();

  const firstNum = useMemo(() => {
    if (page % 5 === 0) {
      return page - 4; // page 5, 10, 15 등 5의 배수일 경우
    } else {
      return Math.floor(page / 5) * 5 + 1; // page 1,2,3,4 => 1, page 6,7,8,9 => 6
    }
  }, [page]);

  const pageArray = useMemo(() => {
    const pageArray = [
      firstNum,
      firstNum + 1,
      firstNum + 2,
      firstNum + 3,
      firstNum + 4,
    ];
    return pageArray;
  }, [firstNum]);

  useEffect(() => {
    table.setPageIndex(page - 1);
  }, [page, table]);

  const handlePage = (pageNum) => {
    setSearchParams({ page: pageNum }, true);
  };

  const handleLeftArrow = () => {
    if (page > 5) {
      handlePage(firstNum - 5);
    } else return;
  };

  const handleRightArrow = () => {
    if (totalPage > firstNum + 4) {
      // 내 페이지가 11일 때 total이 15여도 다음 페이지로 넘어갈 수 없어야함.
      handlePage(firstNum + 5);
    } else return;
  };

  return (
    <Container style={{ marginTop: totalPage ? 30 : 0 }}>
      <div className="numberBox">
        {totalPage ? (
          <Arrow onClick={handleLeftArrow}>
            <DownArrow />
          </Arrow>
        ) : null}
        {pageArray.map((el, idx) =>
          totalPage < el ? null : (
            <NumberBtn
              key={idx}
              onClick={(e) => handlePage((e.target as HTMLButtonElement).value)}
              value={el}
              $active={el === page}
            >
              {el}
            </NumberBtn>
          )
        )}
        {totalPage ? (
          <RightArrow onClick={handleRightArrow}>
            <DownArrow />
          </RightArrow>
        ) : null}
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .numberBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Arrow = styled.span`
  display: inline-block;
  transform: rotate(90deg);
  cursor: pointer;
`;

const RightArrow = styled(Arrow)`
  transform: rotate(270deg);
`;

const NumberBtn = styled.button<{ $active: boolean }>`
  margin: 0 10px;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  border: none;
  background-color: ${({ theme, $active }) =>
    $active ? theme.colors.blueGrayA700 : "white"};

  color: ${({ theme, $active }) =>
    $active ? "white" : theme.colors.blueGrayA700};
  font-size: 15px;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.3px;
  cursor: pointer;
`;
