import {
  createColumnHelper,
  flexRender,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { HTMLProps, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { getCoreRowModel } from "@tanstack/react-table";

import "@components/table.css";
import Pagination from "@components/core/paging/Pagination";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import { User } from "@hooks/admin/Admin.interfaces";
import Empty from "@components/common/Empty";

export default function ImjangExpertTable({ setSelectedRow, data }) {
  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("checkBox", {
        cell: ({ row }) => {
          return (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
                setSelectedRow: setSelectedRow,
                agentId: row.original["agentId"],
              }}
            />
          );
        },
        header: "",
        maxSize: 5,
      }),
      columnHelper.accessor("region", {
        cell: ({ getValue }) => {
          const { sido, sigugunList } = getValue() as {
            sido: string;
            sigugunList: { sigugun: string; pnu: number }[];
          };
          return (
            <p className="ellipsis" style={{ textAlign: "left" }}>
              {sido} {` > `}
              {sigugunList.map((el) => el.sigugun).join(", ")}
            </p>
          );
        },
        header: "지역",
        maxSize: 20,
      }),
      columnHelper.accessor("name", { header: "이름", maxSize: 10 }),

      columnHelper.accessor("officeName", { header: "중개소 명", maxSize: 20 }),

      columnHelper.accessor("firstPhoneNumber", {
        cell: ({ getValue }) => {
          const tableValue = getValue() as string;
          if (tableValue) {
            return tableValue
              .replace(/[^\d]/g, "")
              .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
              .replace(/\-{1,2}$/g, "");
          } else {
            return "-";
          }
        },
        header: "연락처",
        maxSize: 13,
      }),
      columnHelper.accessor("secondPhoneNumber", {
        cell: ({ getValue }) => {
          const tableValue = getValue() as string;
          if (tableValue) {
            return tableValue
              .replace(/[^\d]/g, "")
              .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
              .replace(/\-{1,2}$/g, "");
          } else {
            return "-";
          }
        },
        header: "대체 연락처",
        maxSize: 13,
      }),
      columnHelper.accessor("visitDate", {
        cell: ({ getValue }) => {
          return convertToKoreanDate(getValue());
        },
        header: "방문날짜",
        maxSize: 9,
      }),
      columnHelper.accessor("agentId", {
        cell: ({ row }) => {
          return (
            <StyledLink to={`/imjang/expert/${row.original["agentId"]}`}>
              상세정보
            </StyledLink>
          );
        },
        header: "-",
        maxSize: 9,
      }),
    ];
  }, []);

  // useReactTable로 테이블 구조 정의
  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const isActive = (row.original as User).is_deleted;
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="contentsData"
                    style={{ color: isActive ? "#B2B7BC" : "#4D5664" }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination table={table} />
      <Empty data={data} title="등록한 중개사가 없어요" />
    </Container>
  );
}

function IndeterminateCheckbox({
  className = "",
  onChange,
  setSelectedRow,
  agentId,
  ...rest
}: {
  setSelectedRow?: React.Dispatch<React.SetStateAction<string[]>>;
  agentId?: string;
} & HTMLProps<HTMLInputElement>) {
  const onClick = (e) => {
    onChange(e);

    setSelectedRow((prev) => {
      if (prev.includes(agentId)) {
        const arr = [...prev];
        const index = arr.indexOf(agentId);
        arr.splice(index, 1);
        return arr;
      } else {
        return [...prev, agentId];
      }
    });
  };

  return (
    <input
      type="checkbox"
      className={className + " cursor-pointer"}
      onChange={onClick}
      {...rest}
    />
  );
}

const Container = styled.div`
  .ellipsis {
    max-width: 20vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryBlue100};
`;
