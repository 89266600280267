export const convertTimeInKorean = (arr) => {
  // 시간순으로 정렬
  const sorted = [...arr].sort((a, b) => a.startTime - b.startTime);

  // 붙어 있는 시간 끼리 합치기
  const merged = [];
  for (let i = 0; i < sorted.length; i++) {
    const slot = sorted[i];

    if (merged.length === 0) {
      // 배열 reference 제거
      merged.push({ ...slot });
      continue;
    }

    const lastSlot = merged[merged.length - 1];
    if (slot.startTime === lastSlot.endTime) {
      merged[merged.length - 1].endTime = slot.endTime;
    } else {
      // 배열 reference 제거
      merged.push({ ...slot });
    }
  }

  return merged
    .map(
      ({ startTime, endTime }) =>
        `${startTime < 12 ? "오전" : "오후"} ${
          startTime <= 12 ? startTime : startTime - 12
        }시 ~ ${endTime < 12 ? "오전" : "오후"} ${
          endTime <= 12 ? endTime : endTime - 12
        }시`
    )
    .join(" / ");
};

export function formatTime(dateStr) {
  const date = new Date(dateStr);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}
