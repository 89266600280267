import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import styled from "styled-components";
import moment from "moment";

import ClockIcon from "@assets/icon/ClockIcon";
import { Arrows22 } from "@components/core/icon/arrow";
import { ArrowDirectionEnum } from "@components/core/icon/arrow/Arrow.interfaces";
import "react-datepicker/dist/react-datepicker.css";
import Calendar28 from "@assets/icon/Calendar28";
import { formatTime } from "@utils/date";
import { ScheduleList } from "@hooks/imjang/Imjang.interfaces";

interface Props {
  info: ScheduleList;
  setInfo: React.Dispatch<React.SetStateAction<ScheduleList>>;
}

const defaultTime = new Date();
defaultTime.setHours(9, 0, 0, 0); // 오전 9시로 설정

export default function VisitDate({ info, setInfo }: Props) {
  const [date, setDate] = useState<Date | null>(null);
  const [time, setTime] = useState<Date | null>(null);

  const 시간_선택 = () => {
    const 시간 = moment(time).format("HH:mm");

    setInfo((prev) => ({ ...prev, meetingTime: 시간 }));
  };

  const 날짜_선택 = () => {
    const 날짜 = moment(date).format("YYYY-MM-DD");
    setInfo((prev) => ({ ...prev, meetingDate: 날짜 }));
  };

  useEffect(() => {
    if (info.meetingDate) {
      const newDate = new Date(info.meetingDate);
      setDate(newDate);
    }

    if (info.meetingTime) {
      const [hours, minutes] = info.meetingTime.split(":").map(Number);
      const newTime = new Date();
      newTime.setHours(hours, minutes, 0, 0);
      setTime(newTime);
    }
  }, [info]);

  return (
    <Container>
      <DateInputWrapper style={{ marginTop: 8 }}>
        <DatePicker
          selected={date ? new Date(date) : null}
          onChange={(v: Date) => setDate(v)}
          dateFormat="yyyy.MM.dd"
          minDate={new Date()}
          locale={ko}
          shouldCloseOnSelect={false}
          onCalendarClose={날짜_선택}
          customInput={
            <InputContainer style={{ marginRight: 10 }}>
              <Calendar28 />
              {date ? (
                <div
                  className="value"
                  style={{
                    color: "#4d5664",
                  }}
                >
                  {moment(date).format("YYYY-MM-DD")}
                </div>
              ) : (
                <div className="placeholder">날짜 선택</div>
              )}

              <div style={{ position: "absolute", right: 16, top: 10 }}>
                <Arrows22 direction={ArrowDirectionEnum.DOWN} />
              </div>
            </InputContainer>
          }
        />
        <DatePicker
          selected={time ? new Date(time) : null}
          onChange={(v: Date) => setTime(v)}
          showTimeInput
          locale={ko}
          // isClearable={startDate ? true : false}
          shouldCloseOnSelect={false}
          onCalendarClose={시간_선택}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          timeCaption="Time"
          dateFormat="h:mm aa"
          customInput={
            <InputContainer>
              <ClockIcon />
              {time ? (
                <div
                  className="value"
                  style={{
                    color: "#4d5664",
                  }}
                >
                  {formatTime(time)}
                </div>
              ) : (
                <div className="placeholder">시간 선택</div>
              )}

              <div style={{ position: "absolute", right: 16, top: 10 }}>
                <Arrows22 direction={ArrowDirectionEnum.DOWN} />
              </div>
            </InputContainer>
          }
        />
      </DateInputWrapper>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 12px;
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;

const DateInputWrapper = styled(Row)`
  .react-datepicker-wrapper {
    display: flex;
    flex: 1;
    cursor: pointer;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 12px;
  flex: 1;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  .placeholder {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  .value {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.blueGray700};
  }
`;
