import { CheckStatusEnum } from "@components/core/icon/checked/Checked.interfaces";
import Checked20 from "@components/core/icon/checked/Checked20";
import { useGetUserId } from "@hooks/management";
import { useEffect, useState } from "react";
import { FadeLoader } from "react-spinners";
import styled from "styled-components";

export default function CheckNickname({ pushInfo, setPushInfo }) {
  const { mutate, isLoading } = useGetUserId();
  const [nickname, setNickname] = useState(pushInfo.nickname || "");
  const [status, setStatus] = useState<"PENDING" | "SUCCESS" | "FAILED">(
    "PENDING"
  );

  const 닉네임_조회 = (e) => {
    e.preventDefault();
    mutate(
      { nickname },
      {
        onSuccess: (res) => {
          setStatus("SUCCESS");
          setPushInfo((prev) => ({ ...prev, target: [res.userId], nickname }));
        },
        onError: () => {
          setStatus("FAILED");
          setPushInfo((prev) => ({ ...prev, target: [], nickname: null }));
        },
        onSettled: (res) => console.log("유저 아이디 🆔 :", res),
      }
    );
  };

  useEffect(() => {
    if (pushInfo && pushInfo.nickname) {
      setNickname(pushInfo.nickname);
    }
  }, [pushInfo]);

  useEffect(() => {
    if (status === "PENDING") return;

    setStatus("PENDING");
    setPushInfo((prev) => ({ ...prev, target: [], nickname: null }));
  }, [nickname]);

  return (
    <Container>
      <div style={{ display: "flex", gap: 8 }}>
        <Input
          placeholder="닉네임"
          value={nickname}
          onChange={(e) => {
            setNickname(e.target.value);
          }}
        />
        <Button onClick={닉네임_조회}>
          {isLoading ? (
            <FadeLoader
              cssOverride={{
                width: 10,
                height: 10,
                transform: "scale(0.3)",
                top: -2,
                left: 8,
              }}
              color="#1c84ff"
            />
          ) : (
            <p>확인</p>
          )}
        </Button>
      </div>
      {status === "PENDING" && (
        <Row style={{ marginTop: 4 }}>
          <Checked20 status={CheckStatusEnum.Disabled} />
          <Text14 style={{ marginLeft: 4 }}>유저 확인이 필요합니다</Text14>
        </Row>
      )}
      {status === "SUCCESS" && (
        <Row style={{ marginTop: 4 }}>
          <Checked20 status={CheckStatusEnum.Activate} />
          <Text14 style={{ marginLeft: 4, color: "#1c84ff" }}>
            존재하는 유저입니다
          </Text14>
        </Row>
      )}
      {status === "FAILED" && (
        <Row style={{ marginTop: 4 }}>
          <Checked20 status={CheckStatusEnum.Destructive} />
          <Text14 style={{ marginLeft: 4, color: "#F04452" }}>
            존재하지 않는 유저입니다
          </Text14>
        </Row>
      )}
    </Container>
  );
}

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  padding: 10px 20px;
  flex: 1;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  padding: 0 32px;
  width: 90px;
  height: 48px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.blue100};

  color: ${({ theme }) => theme.colors.primaryBlue100};
  font-size: 15px;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.3px;

  cursor: pointer;
`;

const Text14 = styled.p`
  color: ${({ theme }) => theme.colors.blueGray600};
  font-size: 14px;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.28px;
`;
