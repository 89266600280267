import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import BTypeInputBoxTyping from "@components/core/BTypeInput/BTypeInputBoxTyping";
import { useConsultantInfo } from "@features/useConsultantInfo";
import styled from "styled-components";
import ImageUploadInput from "../ImageUploadInput";
import Section from "../Section";
import Exposures from "./main/Exposures";
import Keywords from "./main/Keywords";
import BTypeInputBoxBtn from "@components/core/BTypeInput/BtypeInputBoxBtn";
import { useGetInfluencersList } from "@hooks/admin";
import { useEffect, useMemo, useState } from "react";

export default function MainConsultant({ isShow, image, setImage }) {
  const { globalConsultantInfo, setGlobalConsultantInfo } = useConsultantInfo();

  const [influencerList, setInfluencerList] = useState([
    { label: "", value: "" },
  ]);
  const { data } = useGetInfluencersList();

  useEffect(() => {
    if (data) {
      const arr = data?.influencers.map((el) => {
        return { label: el.nickname, value: el._id };
      });
      setInfluencerList(arr);
    }
  }, [data]);

  if (!isShow) return;

  return (
    <Container>
      <Section
        title="활동명 및 상담 제목"
        bodyComponent={
          <Row>
            {/* <BTypeInputBoxTyping
              inputType={InputTypeEnum.OnlyText}
              placeholder={"활동명"}
              value={globalConsultantInfo.name}
              onChangeValue={(v: InputTypeEnum.OnlyText) =>
                setGlobalConsultantInfo({ name: v })
              }
              style={{ padding: 0 }}
            /> */}
            <BTypeInputBoxBtn
              inputType={InputTypeEnum.OnlyText}
              value={globalConsultantInfo.influencer}
              onChangeValue={(e) => {
                const id = e.target.value;
                setGlobalConsultantInfo({
                  influencer: id,
                });
              }}
              style={{ padding: 0, width: 237 }}
              inputStyle={{ marginTop: 0 }}
              listData={[
                { label: "활동명", value: undefined },
                ...influencerList,
              ]}
            />
            <BTypeInputBoxTyping
              inputType={InputTypeEnum.OnlyText}
              placeholder={"상담 제목"}
              value={globalConsultantInfo.title}
              onChangeValue={(v: InputTypeEnum.OnlyText) =>
                setGlobalConsultantInfo({ title: v })
              }
              style={{ padding: 0, paddingLeft: 16, flex: 1 }}
            />
          </Row>
        }
      />
      <Section
        title="개인 프로필"
        desc="32*32"
        bodyComponent={
          <ImageUploadInput
            width={128}
            height={128}
            image={image.profileImgUrl.data}
            folderName={"consultant"}
            fileName={`${globalConsultantInfo.influencer}-profileImgUrl.png`}
            profile
            onChange={(img) => {
              setImage((prev) => ({ ...prev, profileImgUrl: img }));
            }}
          />
        }
      />

      <Section title="상담소 키워드" bodyComponent={<Keywords />} />
      <Section title="노출설정" bodyComponent={<Exposures />} />

      <Section
        title="주목받는 전문가 이미지"
        desc="327*160"
        bodyComponent={
          <ImageUploadInput
            width={327}
            height={160}
            image={image.spotlightImgUrl.data}
            folderName={"consultant"}
            fileName={`${globalConsultantInfo.influencer}-spotlightImgUrl.png`}
            onChange={(data) => {
              setImage((prev) => ({ ...prev, spotlightImgUrl: data }));
            }}
          />
        }
      />
      <Section
        title="부동산 상담 이미지"
        desc="158*174"
        bodyComponent={
          <ImageUploadInput
            width={158}
            height={174}
            image={image.consultingImgUrl.data}
            folderName={"consultant"}
            fileName={`${globalConsultantInfo.influencer}-consultingImgUrl.png`}
            onChange={(img) => {
              setImage((prev) => ({ ...prev, consultingImgUrl: img }));
            }}
          />
        }
        style={{ border: "none" }}
      />
    </Container>
  );
}

const Container = styled.div``;

const Row = styled.div`
  display: flex;
`;
