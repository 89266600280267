import { BTypeTyping48 } from "@components/core/BTypeInput";
import styled from "styled-components";
import { useMemo, useState } from "react";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import { convertLocaleStringToNumber } from "@utils/cash";

interface Props {
  inputType?: InputTypeEnum;
  // label: string;
  value: any;
  placeholder: string;
  onChangeValue: (v: any) => void;
  handleFocus?: () => void;
  handleBlur?: () => void;
  disabled?: boolean;
  maxLength?: number;
  errorText?: string;
  checkCondition?: (v: string) => boolean;
  containerStyle?: React.CSSProperties;
}

export default function BTypeCash48({
  inputType,
  value,
  placeholder,
  onChangeValue,
  handleBlur,
  handleFocus,
  disabled,
  maxLength = 10,
  containerStyle,
}: Props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleValidation = async (e) => {
    let text = e.target.value;

    // 숫자와 콤마 외 다른 문자가 오면
    if (!/^[0-9,]*$/.test(text)) {
      onChangeValue(0);
      return;
    }

    if (text === "0") {
      text = 0;
    }

    if (text === "") {
      text = 0;
    }

    if (text === null) {
      text = 0;
    }

    const num = convertLocaleStringToNumber(text);
    onChangeValue(String(num * 10000));
  };

  const onBlur = () => {
    setIsFocused(false);
    if (handleBlur) handleBlur();
  };

  const displayedValue = useMemo(() => {
    if (value === null) return value;
    return (Number(value) / 10000).toLocaleString();
  }, [value]);

  return (
    <Container style={{ ...containerStyle }}>
      <div className="inputWrapper">
        <BTypeTyping48
          inputType={inputType}
          value={displayedValue}
          placeholder={placeholder}
          onChangeValue={handleValidation}
          handleBlur={onBlur}
          disabled={disabled}
          maxLength={maxLength}
          errorMessage={errorMessage}
        />
        <Unit>만원</Unit>
      </div>
      {errorMessage && (
        <Message>
          <span className="message">{errorMessage}</span>
        </Message>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 74px;
  width: 100%;

  .inputWrapper {
    position: relative;
  }
`;

const Message = styled.div`
  margin-top: 6px;
  height: 18px;

  .message {
    color: ${({ theme }) => theme.colors.red1000};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: -0.24px;
  }
`;

const Unit = styled.p`
  position: absolute;
  top: 11px;
  right: 16px;

  color: ${({ theme }) => theme.colors.blueGray400};
  text-align: right;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
`;
