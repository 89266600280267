import PermissionLayout from "@components/router/PermissionLayout";
import { NoticeAddPage, NoticeEditPage, NoticeListPage } from "@pages/notice";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";

export default function NoticeRouter({ pageValue }) {
  return (
    <Routes>
      <Route element={<PermissionLayout pageValue={pageValue} />}>
        <Route index element={<NoticeListPage />} />
        <Route path="add" element={<NoticeAddPage />} />
        <Route path=":id" element={<NoticeEditPage />} />
      </Route>
    </Routes>
  );
}
