import DownArrow from "@assets/arrow/DownArrow";
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

// 쿼리를 사용하지 않고 state로 페이지네이션
export default function NoFixedPagination({ table }) {
  const totalPage = table.getPageCount();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const arrowRef = useRef("");

  const pageArray = useMemo(() => {
    const firstNum = pageCount * 5 - 4;
    const pageArray = [
      firstNum,
      firstNum + 1,
      firstNum + 2,
      firstNum + 3,
      firstNum + 4,
    ];
    return pageArray;
  }, [pageCount]);

  useEffect(() => {
    if (arrowRef.current === "right") {
      setCurrentPage(pageCount * 5 - 4);
      table.setPageIndex(pageCount * 5 - 5);
    } else if (arrowRef.current === "left") {
      setCurrentPage(pageCount * 5);
      table.setPageIndex(pageCount * 5 - 1);
    }
  }, [pageCount]);

  const handlePage = (e) => {
    setCurrentPage(Number(e.target.value));
    table.setPageIndex(e.target.value - 1);
  };

  const handleLeftArrow = () => {
    if (currentPage > 5) {
      arrowRef.current = "left";
      setPageCount((prev) => prev - 1);
    } else return;
  };

  const handleRightArrow = () => {
    if (totalPage / 5 > pageCount) {
      arrowRef.current = "right";
      setPageCount((prev) => prev + 1);
    } else return;
  };

  return (
    <Container>
      <div className="numberBox">
        {totalPage ? (
          <Arrow onClick={handleLeftArrow}>
            <DownArrow />
          </Arrow>
        ) : null}
        {pageArray.map((el, idx) =>
          totalPage < el ? null : (
            <NumberBtn
              key={idx}
              onClick={(e) => handlePage(e)}
              value={el}
              $active={el === currentPage}
            >
              {el}
            </NumberBtn>
          )
        )}
        {totalPage ? (
          <RightArrow onClick={handleRightArrow}>
            <DownArrow />
          </RightArrow>
        ) : null}
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .numberBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Arrow = styled.span`
  display: inline-block;
  transform: rotate(90deg);
  cursor: pointer;
`;

const RightArrow = styled(Arrow)`
  transform: rotate(270deg);
`;

const NumberBtn = styled.button<{ $active: boolean }>`
  margin: 0 10px;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  border: none;
  background-color: ${({ theme, $active }) =>
    $active ? theme.colors.blueGrayA700 : "white"};

  color: ${({ theme, $active }) =>
    $active ? "white" : theme.colors.blueGrayA700};
  font-size: 15px;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.3px;
  cursor: pointer;
`;
