export default function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M14.5716 6.00014V3.42871H3.42871V20.5716H14.5716V18.0001"
        stroke="#666E7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1427 8.57129L20.5713 11.9999L17.1427 15.4284"
        stroke="#666E7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5713 12L8.57129 12"
        stroke="#666E7A"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
