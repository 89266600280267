import PermissionLayout from "@components/router/PermissionLayout";
import {
  AccountingConfig,
  AccountingDetail,
  AccountingList,
} from "@pages/admin/report";
import {
  AddContent,
  Contents,
  EditContents,
} from "@pages/admin/report/contents";
import { Route, Routes } from "react-router-dom";

export default function ContentRouter({ pageValue }) {
  return (
    <Routes>
      <Route element={<PermissionLayout pageValue={pageValue} />}>
        <Route index element={<Contents />} />
        <Route path="direct-add" element={<AddContent />} />
        <Route path=":id" element={<EditContents />} />
        <Route path="payment-form" element={<AccountingConfig />} />
        <Route path="payments" element={<AccountingList />} />
        <Route path="payment/:id" element={<AccountingDetail />} />
      </Route>
    </Routes>
  );
}
