export interface ConsultingInterface {
  influencer: { id: number | undefined; name: string | undefined };
  title: string;
  profileImgUrl?: string | null;
  keywords: Array<string>;
  exposures: {
    title: "spotlight" | "expertAdvice" | string;
    state: boolean;
    imgUrl: string | null;
  }[];
  mainImgUrl?: string | null;
  introImgUrl?: string | null;
  count: number;
  methods?: {
    type: string;
    time: number;
    price: number;
    detail: string;
  }[];
  providedItem: string;
  reportDetails: { isShow: boolean; influencerName?: string };
  paymentUrl: string | null;
  frequentVisitImgUrl?: string | null;
  detailImgUrl?: string | null;
  youtubeDetails?: { url: string; description: string };
  reviews?: { name: string; rating: number; bodyText: string }[];
}

export interface GetConsultingInterface {
  influencer: { id: number | undefined; name: string | undefined };
  title: string;
  profileImgUrl?: string;
  keywords: Array<string>;
  exposures: {
    title: "spotlight" | "expertAdvice" | string;
    state: boolean;
    imgUrl: string;
  }[];
  mainImgUrl?: string;
  introImgUrl?: string;
  count: number;
  methods?: {
    type: string;
    time: number;
    price: number;
    detail: string;
  }[];
  providedItem: string;
  reportDetails: { isShow: boolean; influencerName?: string };
  paymentUrl: string | null;
  frequentVisitImgUrl?: string;
  detailImgUrl?: string;
  youtubeDetails?: { url: string; description: string };
  reviews?: { reviews: { name: string; rating: number; bodyText: string }[] };
}

export enum ConsultationStatusEnum {
  "상담신청" = "상담신청",
  "상담진행" = "상담진행",
  "상담취소" = "상담취소",
  "상담완료" = "상담완료",
}

export interface ConsultationInfo {
  state: string;
  applyDate: string;
  name: string;
  phoneNumber: string;
  email: string;
  consultant: string;
  type: string;
  price: number;
}

export interface PreQuestion {
  marriagePlan: boolean | null;
  marriedIn7Years: boolean | null;
  movingWish: boolean | null;
  investmentExperience: boolean | null;
  monthlySavingAmount: number | null;
  movingWishDate: string | null;
  preferArea: string | null;
  movingWishHouse: string | null;
  considerSize: number | null;
  cheongyakScore: number | null;
  otherLoans: string | null;
  houseOwnerName: string | null;
}

export interface ApplicantInfo {
  applicantId: string;
  applicantName: string;
  applyDate: string;
  influencer: {
    id: number | undefined;
    name: string | undefined;
  };
  email: string;
  phoneNumber: string;
  price: number;
  state: string;
  type: string;
  report: {
    title: string | null;
    notionUrl: string | null;
    isSubmitted: boolean | null;
    approvalState: string | null; //"승인", "반려", "승인대기"
    pdf: null;
    comment: string | null;
    updatedAt: string | null;
    approvedAt: string | null;
  };
  preQuestion: PreQuestion;
}

export interface PaymentInfo {
  flowId: string;
  applicantId: string;
  method: string;
  status: string;
  consultingPrice: number;
  discountPrice: number;
  paymentPrice: number;
  approvedDate: string;
  requestedCardNumber: string;
  cardApprovalNumber: string;
  cardCompany: string | null;
  requestedBank: string | null;
  bankAccountOwner: string | null;
  refundText: string;
}

export interface SourceInfo {
  contentsId: string;
  title: string;
  dataUrl: string;
  createdAt: string;
}

export const 상담신청 = ConsultationStatusEnum.상담신청;
export const 상담진행 = ConsultationStatusEnum.상담진행;
export const 상담취소 = ConsultationStatusEnum.상담취소;
export const 상담완료 = ConsultationStatusEnum.상담완료;
