import { useGetInviteCode } from "@api/user";
import { Close32 } from "@components/core/icon/close";
import { copyText } from "@utils/copy";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export default function InviteCodePopup({ handleClose }) {
  const [alreadyUse, setAlreadyUse] = useState(false);
  const [inviteCode, setInviteCode] = useState("발급중");
  const { mutate } = useGetInviteCode();

  const 초대코드_복사 = () => {
    if (alreadyUse) return;

    copyText(`${process.env.REACT_APP_HOST}/login?inv_code=${inviteCode}`);
    setAlreadyUse(true);
  };

  useEffect(() => {
    mutate(undefined, { onSuccess: (res) => setInviteCode(res.invite_code) });
  }, []);

  return (
    <Container>
      <div
        style={{ marginLeft: "auto", width: "fit-content" }}
        onClick={handleClose}
      >
        <Close32 />
      </div>

      <p className="description">초대코드 생성 완료</p>
      <h5 className="inviteCode">{inviteCode || ""}</h5>
      <Button onClick={초대코드_복사} $is_used={alreadyUse}>
        <span>{alreadyUse ? "복사 완료" : "초대코드 링크 복사하기"}</span>
      </Button>
    </Container>
  );
}

const Container = styled.div`
  padding: 20px 16px;
  width: 360px;
  background-color: white;
  border-radius: 20px;

  .description {
    color: ${({ theme }) => theme.colors.blueGray600};
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.26px;
  }

  .inviteCode {
    color: ${({ theme }) => theme.colors.blueGray1000};
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px; /* 150% */
    letter-spacing: -0.64px;
  }
`;

const Button = styled.button<{ $is_used: boolean }>`
  margin-top: 32px;
  padding: 12px 20px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme, $is_used }) =>
    $is_used ? theme.colors.blue400 : theme.colors.blue1000};

  span {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
    color: ${({ theme }) => theme.colors.white};
  }
`;
