import MinusIcon from "@assets/icon/MinusIcon";
import PlusIcon from "@assets/icon/PlusIcon";
import { Minus16, Plus16 } from "@components/core/icon/plus";
import SourceListTable from "@components/page/admin/consultation/source/SourceListTable";
import { Button, ButtonWrapper, Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useDeleteSource, useGetSourceList } from "@hooks/consultation";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function ConsultationSourceList() {
  const navigate = useNavigate();
  const { setCurrentMenu } = useMenu();
  const [selectedRowArr, setSelectedRowArr] = useState([]);
  const { data } = useGetSourceList();
  const { mutate } = useDeleteSource();

  useEffect(() => {
    setCurrentMenu("source");
  }, []);

  const deleteSource = () => {
    if (selectedRowArr.length > 0) {
      if (window.confirm("선택한 자료를 삭제할까요?")) {
        mutate(
          { contentsIdList: selectedRowArr },
          {
            onSuccess: (res) => {
              alert("삭제 완료");
              setSelectedRowArr([]);
            },
          }
        );
      }
    } else {
      alert("삭제한 자료를 선택해주세요.");
    }
  };

  return (
    <Container>
      <Title>참고 자료 관리</Title>
      <ButtonWrapper>
        <div className="gap">
          <Button onClick={() => navigate("add")}>
            <Plus16 />
            <p>자료 추가</p>
          </Button>
          <Button onClick={deleteSource}>
            <Minus16 />
            <p>자료 삭제</p>
          </Button>
        </div>
      </ButtonWrapper>
      <SourceListTable
        data={data?.contents || []}
        setSelectedRow={setSelectedRowArr}
      />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;
