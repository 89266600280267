export default function LockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect width="24" height="24" fill="white" />
      <rect
        x="4.75"
        y="8.75"
        width="14.5"
        height="10.5"
        rx="1.25"
        stroke="#4D5664"
        strokeWidth="1.5"
      />
      <path
        d="M16 8.5V8C16 5.79086 14.2091 4 12 4V4C9.79086 4 8 5.79086 8 8V8.5"
        stroke="#4D5664"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 13L12 15"
        stroke="#4D5664"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
