import AccountingContent from "@components/page/admin/report/accounting/AccountingContent";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useEffect } from "react";
import styled from "styled-components";

export default function AccountingConfig() {
  const { setCurrentMenu, setCurrentSubMenu } = useMenu();
  useEffect(() => {
    setCurrentMenu("settlement");
    setCurrentSubMenu("accounting");

    return () => {
      setCurrentSubMenu("");
    };
  }, []);

  return (
    <Container>
      <Title>정산 관리</Title>
      <AccountingContent />
    </Container>
  );
}

const Container = styled.div``;
