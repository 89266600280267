export const hyphenPhoneNum = (value) => {
  if (value) {
    return value
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/\-{1,2}$/g, "");
  } else {
    return "-";
  }
};

const autoHyphen2 = (v) => {
  const result = v
    .slice(0, 13)
    .replace(/[^0-9]/g, "")
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
    .replace(/(\-{1,2})$/g, "");

  return result;
};
