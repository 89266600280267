export enum CheckStatusEnum {
  Disabled = "Disabled",
  Default = "Default",
  Activate = "Activate",
  Destructive = "Destructive",
}

export interface Props {
  status: CheckStatusEnum;
}
