import styled from "styled-components";
import BTypeInputBoxTyping from "@components/core/BTypeInput/BTypeInputBoxTyping";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import { useEffect, useMemo, useState } from "react";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import { Radio24 } from "@components/core/icon/radio";
import { RadioEnum } from "@components/core/icon/radio/Radio.interfaces";
import { useConsultantInfo } from "@features/useConsultantInfo";

const Section = ({ title, bodyComponent }) => {
  return (
    <Row>
      <div className="head">{title}</div>
      <div className="body">{bodyComponent}</div>
    </Row>
  );
};

export default function ConsultationMethodPopUp({ setIsVisible, selectedIdx }) {
  const { globalConsultantInfo, setGlobalConsultantInfo } = useConsultantInfo();
  const [methodInfo, setMethodInfo] = useState({
    type: "",
    time: null,
    price: null,
    discount: null,
    detail: "",
  });

  useEffect(() => {
    if (selectedIdx !== null) {
      setMethodInfo(globalConsultantInfo.methods[selectedIdx]);
    }
  }, [selectedIdx]);

  const handleSubmit = () => {
    // null일 때 0으로 입력
    methodInfo.discount =
      methodInfo.discount === null ? 0 : methodInfo.discount;

    if (methodInfo.discount > methodInfo.price) {
      alert("할인금액이 상품금액보다 클 수 없습니다.");
      setIsVisible(true);
      return;
    }
    // 수정할 때
    if (selectedIdx !== null) {
      const methodArr = [...globalConsultantInfo.methods];
      methodArr[selectedIdx] = methodInfo;
      setGlobalConsultantInfo({ methods: methodArr });
    } else {
      // 생성할 때
      const methodArr = [...globalConsultantInfo.methods];
      methodArr.push(methodInfo);
      setGlobalConsultantInfo({ methods: methodArr });
    }
  };

  const isDone = useMemo(() => {
    return Boolean(
      methodInfo.type &&
        methodInfo.time &&
        methodInfo.price &&
        methodInfo.detail
    );
  }, [methodInfo]);

  return (
    <Container>
      <Title>상담방식</Title>
      <Section
        title="상담방식"
        bodyComponent={
          <div style={{ display: "flex" }}>
            {["대면", "비대면"].map((el, idx) => {
              return (
                <Trow
                  key={idx}
                  onClick={() => {
                    setMethodInfo((prev) => ({ ...prev, type: el }));
                  }}
                >
                  <Radio24
                    status={
                      el === methodInfo.type
                        ? RadioEnum.Activate
                        : RadioEnum.Default
                    }
                  />
                  <p>{el}</p>
                </Trow>
              );
            })}
          </div>
        }
      ></Section>
      <Section
        title="상담시간"
        bodyComponent={
          <BTypeInputBoxTyping
            inputType={InputTypeEnum.Number}
            placeholder={"상담시간(분)"}
            value={methodInfo.time}
            onChangeValue={(v: InputTypeEnum.Number) =>
              setMethodInfo((prev) => ({ ...prev, time: v }))
            }
            style={{ padding: 0 }}
            inputStyle={{ paddingTop: 11, paddingBottom: 11 }}
          />
        }
      ></Section>
      <Section
        title="상담금액"
        bodyComponent={
          <BTypeInputBoxTyping
            inputType={InputTypeEnum.Number}
            placeholder={"상담금액(원)"}
            value={methodInfo.price}
            onChangeValue={(v: InputTypeEnum.OnlyText) =>
              setMethodInfo((prev) => ({ ...prev, price: v }))
            }
            style={{ padding: 0 }}
            inputStyle={{ paddingTop: 11, paddingBottom: 11 }}
          />
        }
      ></Section>
      <Section
        title="할인금액"
        bodyComponent={
          <BTypeInputBoxTyping
            inputType={InputTypeEnum.Number}
            placeholder={"할인금액(원)"}
            value={methodInfo.discount}
            onChangeValue={(v: InputTypeEnum.OnlyText) =>
              setMethodInfo((prev) => ({ ...prev, discount: v }))
            }
            style={{ padding: 0 }}
            inputStyle={{ paddingTop: 11, paddingBottom: 11 }}
          />
        }
      ></Section>
      <Section
        title="상담설명"
        bodyComponent={
          <BTypeTextArea
            placeholder="상담설명"
            textareaStyle={{
              maxWidth: 500,
              minHeight: 200,
              height: 200,
            }}
            value={methodInfo.detail}
            onChangeValue={(v: InputTypeEnum.OnlyText) =>
              setMethodInfo((prev) => ({ ...prev, detail: v }))
            }
          />
        }
      ></Section>
      <Btn
        onClick={() => {
          setIsVisible(false);
          handleSubmit();
        }}
        $isDone={isDone}
      >
        <p>저장</p>
      </Btn>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  width: 462px;
  border-radius: 20px;
  background-color: white;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  .head {
    min-width: 80px;
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }

  .body {
    flex: 1;
  }
`;

const Trow = styled.div`
  display: flex;

  p {
    margin: 0 24px 0 12px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blueGray800};
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;

const Btn = styled.div<{ $isDone: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  padding: 9px 0;
  border-radius: 8px;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;
