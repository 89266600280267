import { Title } from "@components/styles";
import { useEffect, useState } from "react";
import styled from "styled-components";
import EditUserContainer from "./EditUserContainer";
import { useGetUserInfo, useUpdateUserInfo } from "@api/user";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "@features/useAlert";

export default function UserEditPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useGetUserInfo({ user_id: id });
  const { mutate } = useUpdateUserInfo({ user_id: id });
  const { showGlobalAlert } = useAlert();

  const [userInfo, setUserInfo] = useState({
    nickname: "",
    phone_number: "",
    name: "",
    email: "",
    memo: "",
    user_group: "",
    inActive: false,
  });

  const 적용 = () => {
    mutate(userInfo, {
      onSuccess: () =>
        showGlobalAlert({
          desc: "변경하신 내용이 저장되었습니다.",
          blue: true,
          path: "/users",
        }),
    });
  };
  const 취소 = () => navigate(-1);

  useEffect(() => {
    if (!data) return;

    setUserInfo(data);
  }, [data]);

  return (
    <Container>
      <Title>회원 정보 수정</Title>
      <EditUserContainer userInfo={userInfo} setUserInfo={setUserInfo} />
      <ButtonBox>
        <button onClick={취소}>취소</button>
        <button onClick={적용}>적용</button>
      </ButtonBox>
    </Container>
  );
}

const Container = styled.div``;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 8px;

  .disabled {
    background-color: ${({ theme }) => theme.colors.blueGray100};
    color: ${({ theme }) => theme.colors.blueGray500};
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.primaryBlue100};
    border-radius: 8px;

    color: var(--White, #fff);
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-align: center;

    &:first-child {
      background: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.blueGray100};
      color: ${({ theme }) => theme.colors.blueGray800};
    }
  }
`;
