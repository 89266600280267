import styled from "styled-components";
import Section from "../Section";
import ImageUploadInput from "../ImageUploadInput";
import BTypeInputBoxTyping from "@components/core/BTypeInput/BTypeInputBoxTyping";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import { useConsultantInfo } from "@features/useConsultantInfo";
import ConsultationMethod from "./detail/ConsultationMethod";
import YoutubeDetails from "./detail/YoutubeDetails";
import Review from "./detail/Review";
import ReportInfluencer from "./detail/ReportInfluencer";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";

export default function DetailConsultant({ isShow, image, setImage }) {
  const { globalConsultantInfo, setGlobalConsultantInfo } = useConsultantInfo();

  if (!isShow) return;

  return (
    <Container>
      <Container>
        <Section
          title="상세 메인 이미지"
          desc="327*250"
          bodyComponent={
            <ImageUploadInput
              width={327}
              height={160}
              image={image.mainImgUrl.data}
              folderName={"consultant"}
              fileName={`${globalConsultantInfo.influencer}-mainImgUrl.png`}
              onChange={(img) => {
                setImage((prev) => ({ ...prev, mainImgUrl: img }));
              }}
            />
          }
        />
        <Section
          title="상담받는 분들께"
          bodyComponent={
            <BTypeTextArea
              placeholder="설명"
              textareaStyle={{
                maxWidth: 500,
                maxHeight: 110,
                height: 300,
              }}
              value={globalConsultantInfo.description}
              onChangeValue={(v: InputTypeEnum.OnlyText) =>
                setGlobalConsultantInfo({
                  description: v,
                })
              }
            />
          }
        />
        <Section
          title="프로필 및 인트로"
          desc={`상담받는 분들께~ ${`\n`}상담소개`}
          bodyComponent={
            <ImageUploadInput
              width={327}
              height={160}
              image={image.introImgUrl.data}
              folderName="consultant"
              fileName={`${globalConsultantInfo.influencer}-introImgUrl.png`}
              type="text"
              onChange={(img) => {
                setImage((prev) => ({ ...prev, introImgUrl: img }));
              }}
            />
          }
        />
        <Section title="상담방식" bodyComponent={<ConsultationMethod />} />
        <Section
          title="제공항목"
          bodyComponent={
            <BTypeInputBoxTyping
              inputType={InputTypeEnum.OnlyText}
              placeholder={"제공항목"}
              value={globalConsultantInfo.providedItem}
              onChangeValue={(v: InputTypeEnum.OnlyText) =>
                setGlobalConsultantInfo({ providedItem: v })
              }
              style={{ padding: 0, maxWidth: 500 }}
            />
          }
        />
        <Section
          title="상담 진행 횟수"
          bodyComponent={
            <BTypeInputBoxTyping
              inputType={InputTypeEnum.Number}
              placeholder={"진행횟수"}
              value={globalConsultantInfo.count}
              onChangeValue={(v: InputTypeEnum.Number) =>
                setGlobalConsultantInfo({ count: v })
              }
              style={{ padding: 0, maxWidth: 500 }}
            />
          }
        />
        <Section title="레포트 유무" bodyComponent={<ReportInfluencer />} />
        <Section
          title="자주 찾은 분야"
          desc="320*240(PNG)"
          bodyComponent={
            <ImageUploadInput
              width={327}
              height={160}
              image={image.frequentVisitImgUrl.data}
              folderName={"consultant"}
              fileName={`${globalConsultantInfo.influencer}-frequentVisitImgUrl.png`}
              onChange={(img) => {
                setImage((prev) => ({ ...prev, frequentVisitImgUrl: img }));
              }}
            />
          }
        />
        <Section
          title="상세페이지"
          desc="상세페이지 시작부터 리뷰까지"
          bodyComponent={
            <ImageUploadInput
              width={327}
              height={160}
              image={image.detailImgUrl.data}
              folderName="consultant"
              fileName={`${globalConsultantInfo.influencer}-detailImgUrl.png`}
              type="text"
              onChange={(img) => {
                setImage((prev) => ({ ...prev, detailImgUrl: img }));
              }}
            />
          }
        />
        <Section title="유튜브 대표영상" bodyComponent={<YoutubeDetails />} />
        <Section
          title="연결 링크"
          bodyComponent={
            <BTypeInputBoxTyping
              inputType={InputTypeEnum.OnlyText}
              placeholder={"연결 URL"}
              value={globalConsultantInfo.paymentUrl}
              onChangeValue={(v: InputTypeEnum.OnlyText) =>
                setGlobalConsultantInfo({
                  paymentUrl: v,
                })
              }
              style={{ padding: 0, paddingLeft: 0, maxWidth: 500 }}
            />
          }
        />
        <Section
          title="리뷰관리"
          style={{ border: "none" }}
          bodyComponent={<Review />}
        />
      </Container>
    </Container>
  );
}

const Container = styled.div``;
