import { useMemo } from "react";
import styled from "styled-components";
import moment from "moment";
import { useImjang } from "@features/useImjang";

export default function ScheduleTooltip({ row, handle스케줄수정 }) {
  const { globalImjangStatus } = useImjang();
  const arr = useMemo(
    () => [
      { label: "날짜", value: moment(row.meetingDate).format("YYYY.MM.DD") },
      { label: "시간", value: row.meetingTime },
      {
        label: "매물",
        value: (
          <div className="maemul">
            <span className="danjiName">{row.danjiName}</span>
            <div className="pyeongType">
              <span>{row.tradeType}</span>
              <div className="divid" />
              <span>{row.pyeongType}평</span>
            </div>
          </div>
        ),
      },
      { label: "상세 정보", value: row.description },
      { label: "약속 장소", value: row.meetingPlace },
      { label: "방문 중개소", value: row.agentOfficeName },
      { label: "중개소 연락처", value: row.agentOfficeContact },
    ],
    [row]
  );

  return (
    <InfoBox>
      {arr.map((item, index) => {
        return (
          <div
            key={index}
            className="row"
            style={{
              borderBottom:
                index === arr.length - 1
                  ? "none"
                  : "1px solid var(--BlueGrayA200, #e1e4e8)",
            }}
          >
            <div className="left">{item.label}</div>
            <div className="right">{item.value}</div>
          </div>
        );
      })}
      {globalImjangStatus.editPermission && (
        <button className="editButton" onClick={handle스케줄수정}>
          스케줄 수정
        </button>
      )}
      <Triangle />
    </InfoBox>
  );
}

const InfoBox = styled.div`
  cursor: default;
  position: absolute;
  top: -90px;
  left: 240px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  width: 400px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
  z-index: 10;

  .row {
    display: flex;
    width: 100%;

    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0.3;
      background: var(--BlueGrayA100, #f4f6f8);
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.3px;
    }

    .right {
      display: flex;
      flex: 0.7;
      padding: 10px 12px;
      color: ${({ theme }) => theme.colors.blueGray700};
      font-size: 15px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: -0.3px;
    }
  }

  .maemul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .danjiName {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 137.5% */
      letter-spacing: -0.32px;
    }

    .pyeongType {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.blueGray600};
      font-size: 14px;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      letter-spacing: -0.28px;
    }

    .divid {
      width: 1px;
      height: 10px;
      margin: 0 8px;
      background: ${({ theme }) => theme.colors.blueGray200};
    }
  }

  .editButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    padding: 8px 16px;
    border-radius: 8px;
    background: var(--Blue100, #e8f3ff);
    color: ${({ theme }) => theme.colors.blue1000};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;

const Triangle = () => {
  return (
    <div style={{ position: "absolute", top: 100, left: -24 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="36"
        viewBox="0 0 32 36"
        fill="none"
      >
        <path
          d="M2 21.4641C-0.666667 19.9245 -0.666667 16.0755 2 14.5359L26 0.679495C28.6667 -0.860109 32 1.06439 32 4.1436L32 31.8564C32 34.9356 28.6667 36.8601 26 35.3205L2 21.4641Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
