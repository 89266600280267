import { Close16 } from "@components/core/icon/close";
import { Plus24 } from "@components/core/icon/plus";
import { usePostPresignedUrl } from "@hooks/consultation";
import { useRef, useState } from "react";
import styled from "styled-components";

export default function MultiFileUploadInput({
  files,
  onChange,
  label,
  folderName,
  style,
}) {
  const fileInput = useRef(null);
  const { mutate } = usePostPresignedUrl();

  const openWindow = (file) => {
    let blob;
    if (file.name) {
      blob = URL.createObjectURL(file);
      window.open(blob, "_blank", "noopener, noreferrer");
    } else {
      // window.open(file);
    }

    return () => {
      // 컴포넌트가 언마운트될 때 Blob URL을 해제
      URL.revokeObjectURL(blob);
    };
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    // 취소 눌렀을 때 return;
    if (!file) {
      onChange([...files]);
    } else {
      mutate(
        { folderName, fileName: file.name },
        {
          onSuccess: (res) => {
            const data = {
              presignedUrl: res[0],
              file: file,
              fileName: file.name,
            };
            onChange([...files, data]);
          },
          onError: (res) => console.log("에러"),
        }
      );
    }
  };

  const handleDelete = (idx) => {
    const copyArr = [...files];
    copyArr.splice(idx, 1);
    onChange(copyArr);
  };

  const EmptyThumbnail = () => {
    return (
      <Container style={{ ...style }}>
        <Label>{label}</Label>
        <InputBox onClick={handleClick}>
          <Plus24 />
          <p>파일 업로드</p>
        </InputBox>
      </Container>
    );
  };

  return (
    <>
      {files?.length > 0 ? (
        <Container>
          <Label>{label}</Label>
          <div style={{ height: 20 }} />
          {files.map((el, idx) => (
            <ClickableText key={idx}>
              <p onClick={() => openWindow(el.file)}>{el.fileName}</p>
              <span onClick={() => handleDelete(idx)}>
                <Close16 />
              </span>
            </ClickableText>
          ))}

          <BtnBox onClick={handleClick}>
            <p>파일 추가</p>
          </BtnBox>
        </Container>
      ) : (
        <EmptyThumbnail />
      )}

      <input
        type="file"
        accept="image/jpg, image/jpeg, image/png, .pdf"
        multiple
        ref={fileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        alt="Preview"
      />
    </>
  );
}

const Container = styled.div`
  position: relative;
  padding: 32px 0 28px;
`;

const Label = styled.h5`
  color: ${({ theme }) => theme.colors.blueGray600};
  font-size: 15px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  min-height: 160px;
  background-color: ${({ theme }) => theme.colors.blueGrayA100};
  border-radius: 8px;
  cursor: pointer;

  p {
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;

const ClickableText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;

  p {
    margin-right: 5px;
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;
    text-decoration-line: underline;
  }
`;

const BtnBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 20px;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: var(--White, #fff);
  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;
