import styled from "styled-components";

import CoinUser from "./CoinUser";
import CoinQuantity from "./CoinQuantity";
import CoinPush from "./CoinPush";
import StepWrapper from "@components/page/admin/manage/_components/StepWrapper";

export default function CoinContent({ coinInfo, setCoinInfo }) {
  return (
    <Container>
      <StepWrapper number={1} title="코인 충전 대상">
        <CoinUser coinInfo={coinInfo} setCoinInfo={setCoinInfo} />
      </StepWrapper>
      <StepWrapper number={2} title="코인 충전 금액 설정">
        <CoinQuantity coinInfo={coinInfo} setCoinInfo={setCoinInfo} />
      </StepWrapper>
      <StepWrapper number={3} title="푸시 내용 ">
        <CoinPush coinInfo={coinInfo} setCoinInfo={setCoinInfo} />
      </StepWrapper>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 40px;
`;
