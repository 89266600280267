export default function Plus16() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g>
        <g id="Frame 400443">
          <g id="Frame 400442">
            <g id="Component 21">
              <g id="Group 38403">
                <g id="Group 38402">
                  <g id="Group 38406">
                    <rect
                      id="Rectangle 21578"
                      x="7"
                      y="3"
                      width="2"
                      height="10"
                      fill="#808690"
                    />
                    <rect
                      id="Rectangle 21579"
                      x="13"
                      y="7"
                      width="2"
                      height="10"
                      transform="rotate(90 13 7)"
                      fill="#808690"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
