export default function XIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 39551">
        <g id="Group 39552">
          <circle id="Ellipse 2" cx="12" cy="12" r="12" fill="white" />
          <g id="Guide">
            <path id="Vector" opacity="0.2" d="M20 4H4V20H20V4Z" fill="white" />
            <path
              id="Vector_2"
              opacity="0.2"
              d="M17.3327 4.88867H6.66602V19.1109H17.3327V4.88867Z"
              fill="white"
            />
            <path
              id="Vector_3"
              opacity="0.2"
              d="M19.1113 17.3332V6.6665L4.88911 6.6665V17.3332H19.1113Z"
              fill="white"
            />
            <path
              id="Vector_4"
              opacity="0.2"
              d="M18.2218 5.77783H5.77734V18.2223H18.2218V5.77783Z"
              fill="white"
            />
            <path
              id="Vector_5"
              opacity="0.2"
              d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
              fill="white"
            />
          </g>
        </g>
        <g id="Group">
          <g id="Icon">
            <path
              id="Vector_6"
              d="M6.66602 6.6665L17.3327 17.3332"
              stroke="#808690"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              id="Vector_7"
              d="M6.66602 17.3332L17.3327 6.6665"
              stroke="#808690"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
