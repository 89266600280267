import B1TypeInputBoxTyping from "@components/core/BTypeInput/B1TypeInputBoxTyping";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import B1TypeDateInput from "@components/core/input/B1TypeDateInput";
import { Title } from "@components/styles";
import { FadeLoader } from "react-spinners";
import styled from "styled-components";

export default function NewsContent({ contentInfo, setContentInfo }) {
  return (
    <Container>
      <B1TypeInputBoxTyping
        inputType={InputTypeEnum.OnlyText}
        label={"뉴스 제목"}
        placeholder={"뉴스 제목을 입력하세요."}
        value={contentInfo.title}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setContentInfo((prev) => ({ ...prev, title: v }))
        }
        style={{ maxWidth: "30vw", minWidth: 670 }}
      />
      <B1TypeInputBoxTyping
        inputType={InputTypeEnum.OnlyText}
        label={"뉴스 링크"}
        placeholder={"뉴스 링크를 입력하세요."}
        value={contentInfo.url}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setContentInfo((prev) => ({ ...prev, url: v }))
        }
        style={{ maxWidth: "30vw", minWidth: 670 }}
      />
      <B1TypeDateInput
        value={contentInfo.releasedAt}
        onChangeValue={(v: any) => {
          setContentInfo((prev) => ({
            ...prev,
            releasedAt: v,
          }));
        }}
        disabled={false}
        style={{ maxWidth: "30vw", minWidth: 670 }}
        label={"기사 발행일"}
        placeholder="기사 발행일을 선택하세요."
      />
    </Container>
  );
}

const Container = styled.div``;
