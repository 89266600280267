import { Arrows22 } from "@components/core/icon/arrow";
import {
  ArrowColorEnum,
  ArrowDirectionEnum,
} from "@components/core/icon/arrow/Arrow.interfaces";
import styled from "styled-components";

export default function Indicator({ total, current, setCurrent }) {
  const MAX_PAGE = Math.ceil(total / 5);

  const 페이지_조절 = (plus) => {
    if (plus) {
      if (MAX_PAGE <= current) return;
      else setCurrent((prev) => prev + 1);
    } else {
      if (current <= 1) return;
      else setCurrent((prev) => prev - 1);
    }
  };

  return (
    <Container>
      <span onClick={() => 페이지_조절(false)}>
        <Arrows22
          direction={ArrowDirectionEnum.LEFT}
          color={current === 1 ? ArrowColorEnum.B20 : ArrowColorEnum.B50}
        />
      </span>
      <div className="numberBox">
        <p>{current}</p>
        <p>/</p>
        <p>{MAX_PAGE}</p>
      </div>
      <span onClick={() => 페이지_조절(true)}>
        <Arrows22
          direction={ArrowDirectionEnum.RIGHT}
          color={current === MAX_PAGE ? ArrowColorEnum.B20 : ArrowColorEnum.B50}
        />
      </span>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 32px;
  display: flex;

  span {
    margin: auto;
  }

  .numberBox {
    margin: 0 12px;
    display: flex;

    p {
      margin: 10px;
      color: ${({ theme }) => theme.colors.blueGray600};
      font-size: 18px;
      font-weight: 500;
    }
  }
`;
