import {
  createColumnHelper,
  flexRender,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import "@components/table.css";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "@components/core/paging/Pagination";
import { convertToKoreanTime } from "@hooks/calc/converToKoreanDate";

import { hyphenPhoneNum } from "@utils/phoneNumber";

import {
  ApplicantInfo,
  ConsultationInfo,
  ConsultationStatusEnum,
} from "@hooks/consultation/Consulting.interfaces";

import StatusChip from "@components/common/StatusChip";
import Empty from "@components/common/Empty";
import UnderLineCopyCell from "@components/common/UnderLineCopyCell";

export default function UploadReportTable({ data }) {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("state", {
        cell: ({ getValue, row }) => {
          const original = row.original as ApplicantInfo;
          return (
            <Center>
              <StatusChip status={getValue()} />
            </Center>
          );
        },
        header: "진행상태",
        maxSize: 11,
      }),
      columnHelper.accessor("title", {
        cell: ({ row }) => {
          const original = row.original as ApplicantInfo;
          return (
            <p style={{ marginLeft: 20, textAlign: "left" }}>
              {original.report.title}
            </p>
          );
        },
        header: "보고서 제목",
        maxSize: 48,
      }),
      columnHelper.accessor("applicantName", { header: "신청자", maxSize: 9 }),
      columnHelper.accessor("type", {
        header: "상담방식",
        maxSize: 9,
      }),

      columnHelper.accessor("phoneNumber", {
        cell: ({ getValue }) => (
          <UnderLineCopyCell content={hyphenPhoneNum(getValue())} />
        ),
        header: "신청자 연락처",
        maxSize: 13,
      }),
      columnHelper.accessor("write", {
        cell: ({ row }) => {
          const original = row.original as ApplicantInfo;
          const handleClick = () => {
            window.open(original.report.notionUrl, "_blank");
          };
          return <StyledLink onClick={handleClick}>보고서 작성</StyledLink>;
        },
        header: "-",
        maxSize: 10,
      }),
    ];
  }, []);

  // useReactTable로 테이블 구조 정의
  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const state = (row.original as ConsultationInfo).state;
            const disabled =
              state === ConsultationStatusEnum.상담취소 ||
              state === ConsultationStatusEnum.상담완료;
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="contentsData"
                    style={{
                      padding: "12px 0",
                      color: disabled ? "#B2B7BC" : "#4D5664",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Empty data={data} title={"작성할 수 있는 보고서가 없어요."} />
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div``;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primaryBlue100};
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;
