import { useGetContentsRecords } from "@api/contributor";
import { Title } from "@components/styles";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import styled from "styled-components";

export default function RecentContentsStat() {
  const { data } = useGetContentsRecords();

  if (!data) {
    return;
  }

  return (
    <Container>
      <Title>최근 컨텐츠 통계</Title>
      <InnerContainer>
        <ContentInfo data={data.records[0]} index={1} />
        <ContentInfo data={data.records[1]} index={2} />
      </InnerContainer>
    </Container>
  );
}

const ContentInfo = ({ data, index }) => {
  return (
    <>
      <Box $index={index}>
        <div className="flex">
          <p className="writeDate">
            {convertToKoreanDate(data?.content.released_at)}
          </p>
          <h3 className="title">{data?.content.title}</h3>
          <Stat>
            <div>
              <p className="head">조회수</p>
              <p className="body">{data?.views}</p>
            </div>
            <div>
              <p className="head">추천 수</p>
              <p className="body">{data?.likes}</p>
            </div>
          </Stat>
        </div>
      </Box>
      {index === 1 && <VerticalDivideLine />}
    </>
  );
};

const Container = styled.div``;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0 48px;
  width: 70%;
  max-width: 1300px;
  min-width: 900px;
`;

const Box = styled.div<{ $index: number }>`
  /* padding: 0 20px; */
  display: flex;
  width: 50%;
  justify-content: ${({ $index }) => ($index === 1 ? "flex-start" : "center")};

  .writeDate {
    margin-bottom: 4px;
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }

  .title {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  .flex {
  }
`;

const Stat = styled.div`
  display: flex;

  div {
    display: flex;
    align-items: center;
    margin: 32px 40px 0 0;
  }

  .head {
    margin-right: 16px;
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }

  .body {
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 32px;
    font-weight: 600;
    line-height: 42px; /* 131.25% */
    letter-spacing: -0.64px;
  }
`;

const VerticalDivideLine = styled.div`
  height: 72px;
  width: 2px;
  background-color: ${({ theme }) => theme.colors.blueGray200};
`;
