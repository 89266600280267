import styled from "styled-components";
import AmountBox from "./AmountBox";
import AccountingListTable from "./AccountingListTable";
import { useEffect, useState } from "react";
import { useGetInfluencerPaymentsList } from "@hooks/admin";
import { Calendar } from "../contents";

export default function AccountingListContent() {
  const [tableData, setTableData] = useState<{
    totalPayments: number;
    influencers: object[];
  }>({ totalPayments: 0, influencers: [] });
  const [rangeDate, setRangeDate] = useState({ start: "", end: "" });

  const { mutate, data } = useGetInfluencerPaymentsList();
  useEffect(() => {
    mutate({ start_date: rangeDate.start, end_date: rangeDate.end });
  }, [rangeDate, mutate]);

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  return (
    <Container>
      <HeaderRow>
        <AmountBox data={tableData.totalPayments} />

        <Calendar setRangeDate={setRangeDate} text="기준: 정산날짜" />
      </HeaderRow>
      <AccountingListTable data={tableData.influencers} />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 38px;
`;
