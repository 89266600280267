import { Title } from "@components/styles";
import styled from "styled-components";
import StoryInput from "../storyAdd/StoryInput";
import { useEffect, useState } from "react";
import { useGetStoryListById, useUpdateStory } from "@api/management/story";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAlert } from "@features/useAlert";

export default function StoryEditPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useGetStoryListById({ id });
  const { mutate } = useUpdateStory({ id });
  const { showGlobalAlert } = useAlert();
  const [storyInfo, setStoryInfo] = useState({
    title: "",
    notionUrl: "",
    area: "",
    householdNum: null,
    danjiCode: null,
    preview: "",
    metaTitle: "",
    slug: "",
    metaDescription: "",
  });

  useEffect(() => {
    if (!data) return;

    const { coverImageUrl, createdAt, html, id, ...rest } = data;
    setStoryInfo(rest);
  }, [data]);

  const handleConfirm = () => {
    mutate(storyInfo, {
      onSuccess: () => {
        toast("수정 완료!");
        navigate(-1);
      },
      onError: (res) =>
        showGlobalAlert({
          desc: `수정 실패\n${res.response.data["message"]}`,
          blue: false,
        }),
    });
  };

  return (
    <Container>
      <Title>임장 이야기 수정</Title>
      <div style={{ height: 32 }} />
      <StoryInput
        storyInfo={storyInfo}
        setStoryInfo={setStoryInfo}
        handleConfirm={handleConfirm}
        edit={true}
      />
    </Container>
  );
}

const Container = styled.div`
  padding: 0 243px;
  width: 100%;
`;
