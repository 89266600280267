import styled from "styled-components";
import cn from "classnames";

export default function AlertDialog({
  title,
  content,
  buttonArr,
  leftFunction = () => {},
  rightFunction = () => {},
  isCaution = false,
}) {
  const handleRightBtn = () => {
    rightFunction();
  };

  const handleLeftBtn = () => {
    leftFunction();
  };

  return (
    <Container>
      <Title>{title}</Title>
      {content && <Content>{content}</Content>}
      <BtnContainer>
        <BtnBox onClick={handleLeftBtn}>
          <p>{buttonArr[0]}</p>
        </BtnBox>
        <BtnBox
          onClick={handleRightBtn}
          className={cn([{ right: true }, { isCaution }])}
        >
          <p>{buttonArr[1]}</p>
        </BtnBox>
      </BtnContainer>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  margin: 0 auto;
  padding: 20px 16px 16px;
  width: 312px;
  border-radius: 32px;
  background-color: #ffffff;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blueGray800};
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;

const Content = styled.p`
  margin-top: 6px;
  color: ${({ theme }) => theme.colors.blueGray600};
  font-weight: 400;
  text-align: center;
  font-size: 15px;
  line-height: 22px; /* 144.444% */
  letter-spacing: -0.36px;
  white-space: pre-wrap;
`;

const BtnContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;
`;

const BtnBox = styled.button`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blueGrayA100};
  height: 48px;
  border-radius: 32px;
  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.blueGray600};
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
    font-size: 16px;
  }

  &.right {
    background-color: ${({ theme }) => theme.colors.primaryBlue100};

    p {
      color: white;
    }
  }

  &.isCaution {
    background-color: ${({ theme }) => theme.colors.red1000};
  }
`;
