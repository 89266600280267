import PencilIcon from "@assets/icon/PencilIcon";
import { Delete28 } from "@components/core/icon/delete";
import styled from "styled-components";
import SmallRating from "./SmallRating";

export default function ReviewCard({ data, handleEdit, handleDelete, style }) {
  return (
    <Container style={{ ...style }}>
      <TopContainer>
        <div className="left">
          <p className="name">{data.name}</p>
          <SmallRating rating={data.rating} />
        </div>
        <div className="right">
          <IconBox onClick={handleEdit}>
            <PencilIcon />
          </IconBox>
          <div style={{ width: 20 }} />
          <div className="iconBox" onClick={handleDelete}>
            <Delete28 />
          </div>
        </div>
      </TopContainer>
      <BottomContainer>
        <p>{data.bodyText}</p>
      </BottomContainer>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray100};
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  .left {
    .name {
      margin-bottom: 8px;
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 16px;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: -0.32px;
    }
  }

  .right {
    display: flex;
  }
`;

const BottomContainer = styled.div`
  p {
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;
  }
`;

const IconBox = styled.div``;
