import { ButtonBox, Title } from "@components/styles";
import styled from "styled-components";
import RateInput from "./RateInput";
import { useEffect, useState } from "react";
import ClosingRateChart from "./ClosingRateChart";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetDealSuccessRateData,
  usePostDealSuccessRateData,
} from "@api/imjang";
import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";

export default function ClosingRatePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data } = useGetDealSuccessRateData({ apply_id: id });
  const { mutate, isLoading } = usePostDealSuccessRateData({ apply_id: id });
  const [rateInfo, setRateInfo] = useState({
    isKakaoJoined: false,
    moveInDate: undefined,
    assetPrice: 0,
    danjiName: "",
    danjiCode: null,
    scaleCode: null,
    tradeType: null,
    danjiPrice: 0,
  });

  useEffect(() => {
    if (!data) return;

    setRateInfo(data.dealSuccessRateParams);
  }, [data]);

  const 거래성사율_수정 = () => {
    mutate(rateInfo, {
      onSuccess: () => {
        alert("성사율 정보가 업데이트 되었습니다.");
        navigate(-1);
      },
      onError: (res) =>
        alert(`업데이트에 실패했습니다. \n${res.response.data["message"]}`),
    });
  };

  return (
    <Container>
      <Title>거래성사율 관리</Title>
      <div className="contentsWrapper">
        <RateInput rateInfo={rateInfo} setRateInfo={setRateInfo} />
        <ClosingRateChart rateInfo={rateInfo} />
      </div>
      <ButtonBox style={{ marginTop: 20 }}>
        <button onClick={() => navigate(-1)}>취소</button>
        <button onClick={거래성사율_수정}>적용</button>
      </ButtonBox>
      {isLoading && <AbsoluteFillLoading />}
    </Container>
  );
}

const Container = styled.div`
  padding: 48px 243px 60px;

  .contentsWrapper {
    margin-top: 32px;
    display: flex;
    border-top: 2px solid ${({ theme }) => theme.colors.blueGray800};
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray100};
  }
`;
