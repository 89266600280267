import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Plus24 } from "@components/core/icon/plus";
import { usePostPresignedUrl } from "@hooks/consultation";
import { Close16 } from "@components/core/icon/close";

interface Props {
  width: number;
  height: number;
  image: File;
  folderName: string;
  fileName: string;
  profile?: boolean;
  type?: string;
  onChange: (image) => void;
}

export default function ImageUploadInput({
  width,
  height,
  image,
  folderName,
  fileName,
  profile,
  type,
  onChange,
}: Props) {
  const photoInput = useRef(null);
  const { mutate } = usePostPresignedUrl();
  const handleClick = () => {
    photoInput.current.click();
  };
  const [isActive, setActive] = useState(false);
  const handleDragStart = () => setActive(true);
  const handleDragEnd = () => setActive(false);
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    mutate(
      { folderName, fileName: fileName },
      {
        onSuccess: (res) => {
          onChange({ presignedUrl: res[0], data: file });
        },
        onError: (res) => alert("업로드용 url 발급 실패, 다시 시도해주십시오."),
      }
    );
    setActive(false);
  };

  const handleChange = (e) => {
    // 취소 눌렀을 때 return;
    if (!e.target.files[0]) {
      if (type === "text") {
        return;
      } else {
        onChange({ presignedUrl: null, data: e.target.files[0] });
      }
    } else {
      mutate(
        { folderName, fileName: fileName },
        {
          onSuccess: (res) => {
            onChange({ presignedUrl: res[0], data: e.target.files[0] });
          },
          onError: (res) =>
            alert("업로드용 url 발급 실패, 다시 시도해주십시오."),
        }
      );
    }
  };

  const handleDelete = () => {
    onChange({ presignedUrl: null, data: null });
  };

  const openWindow = (url) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  useEffect(() => {
    return () => {
      // 컴포넌트가 언마운트될 때 Blob URL을 해제
      URL.revokeObjectURL(previewImage);
    };
  }, []);

  const previewImage = useMemo(() => {
    if (typeof image === "string") {
      return image;
    } else if (typeof image === "object" && image !== null) {
      let blob = URL.createObjectURL(image);
      return blob;
    } else return null;
  }, [image]);

  const EmptyThumbnail = () => {
    if (profile) {
      return (
        <ProfileBox
          onClick={handleClick}
          style={{ width: width, height: height }}
          onDragEnter={handleDragStart} // dragstart 핸들러 추가
          onDragLeave={handleDragEnd} // dragend 핸들러 추가
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          $isActive={isActive}
        >
          <img
            src={require("../../../../assets/image/profile.png")}
            style={{ width: width, height: height }}
            alt={"비어있는 프로필 이미지"}
          />
        </ProfileBox>
      );
    } else {
      return (
        <GrayBox
          onClick={handleClick}
          onDragEnter={handleDragStart} // dragstart 핸들러 추가
          onDragLeave={handleDragEnd} // dragend 핸들러 추가
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          $isActive={isActive}
          style={{ width: width, height: height }}
        >
          <Plus24 />
          <p>이미지 업로드</p>
        </GrayBox>
      );
    }
  };

  useEffect(() => {
    return () => {
      // 컴포넌트가 언마운트될 때 Blob URL을 해제
      URL.revokeObjectURL(previewImage);
    };
  }, []);

  return (
    <>
      {previewImage ? (
        type === "text" ? (
          <>
            <ClickableText>
              <p onClick={() => openWindow(previewImage)}>
                {image.name || String(image)}
              </p>
              <span onClick={handleDelete}>
                <Close16 />
              </span>
            </ClickableText>
            <Container onClick={handleClick}>
              <BtnText>이미지 변경</BtnText>
            </Container>
          </>
        ) : (
          <img
            src={previewImage}
            onClick={handleClick}
            style={{
              width: width,
              height: height,
              borderRadius: profile ? "100%" : 8,
              border: profile ? "1px solid #E5E7E9" : "none",
              cursor: "pointer",
            }}
            alt="preview"
          />
        )
      ) : (
        <EmptyThumbnail />
      )}
      <input
        type="file"
        accept="image/jpg, image/jpeg, image/png"
        multiple
        ref={photoInput}
        onChange={handleChange}
        style={{ display: "none" }}
        alt="Preview"
      />
    </>
  );
}

const GrayBox = styled.div<{ $isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.blueGray200 : theme.colors.blueGrayA100};
  border: ${({ $isActive }) => ($isActive ? "1px dashed black" : "none")};
  cursor: pointer;

  p {
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;

const ProfileBox = styled.div<{ $isActive: boolean }>`
  cursor: pointer;
  transform: ${({ $isActive }) => ($isActive ? "scale(1.02)" : "none")};
`;

const ClickableText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;

  p {
    margin-right: 5px;
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;
    text-decoration-line: underline;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 20px;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: var(--White, #fff);
  cursor: pointer;
`;

const BtnText = styled.p`
  color: ${({ theme }) => theme.colors.blueGray600};
  font-size: 15px;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.3px;
`;
