import { useEffect } from "react";
import styled from "styled-components";
import TransparentBoodongLogo from "./TransparentBoodongLogo";
import { useNavigate } from "react-router-dom";

export default function Forbidden() {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = "#f4f6f8";

    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, []);

  return (
    <Container>
      <img
        className="bgImg"
        src={require("./forbidden.png")}
        alt="아파트 이미지"
      />
      <TextBox>
        <TransparentBoodongLogo />
        <h1>페이지에 접근할 수 있는 권한이 없습니다</h1>
        <h4>
          담당자가 권한을 부여하지 않았거나 가입 시 이메일이 맞는지
          확인해주세요.
          {"\n"}지속적인 문제가 발생할 시 우측 하단 채널톡으로 문의해 주세요.
        </h4>
        <button onClick={() => navigate("/login")}>로그인 화면으로</button>
      </TextBox>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100vw;
  min-width: 1440px;
  max-width: 1440px;
  height: 100vh;

  img {
    flex: 1;
    width: 833px;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 24px;
    color: ${({ theme }) => theme.colors.blueGray800};
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
  }

  h4 {
    margin-top: 14px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.3px;
    white-space: pre-wrap;
    text-align: center;
  }

  button {
    margin-top: 40px;
    padding: 12px 38px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.white};
    cursor: pointer;

    color: ${({ theme }) => theme.colors.blueGray800};
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;
