import UploadReportTable from "@components/page/influencer/consultation/consulting/UploadReportTable";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useGetApplicantsForReport } from "@hooks/consultation";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function ReportList() {
  const { setCurrentMenu } = useMenu();
  const navigate = useNavigate();
  const { data } = useGetApplicantsForReport({ isSubmitted: false });

  const goToUploadReport = () => {
    if (data?.applicants.length > 0) {
      navigate("/consulting/report/upload");
    } else {
      alert("보낼 수 있는 보고서가 없습니다.");
    }
  };

  useEffect(() => {
    setCurrentMenu("consulting");
  }, []);

  return (
    <Container>
      <Title>보고서 작성</Title>
      <UploadButton onClick={goToUploadReport}>
        <p>보고서 보내기</p>
      </UploadButton>
      <UploadReportTable data={data?.applicants || []} />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;

const UploadButton = styled.div`
  margin: 8px 0 16px auto;
  padding: 9px 16px;
  width: fit-content;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;

  p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: white;
  }
`;
