import {
  createColumnHelper,
  flexRender,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import "@components/table.css";
import { useNavigate } from "react-router-dom";
import Empty from "@components/common/Empty";

export default function AccountingListTable({ data: tableData }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [columnOrder, setColumnOrder] = useState([]);
  const columnHelper = createColumnHelper();

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("number", {
        cell: (info) => info.row.index + 1,
        header: "순번",
        maxSize: 10,
      }),
      columnHelper.accessor("author", {
        cell: ({ row }) => {
          return (
            <p style={{ textAlign: "left", marginLeft: 20 }}>
              {row.original["_influencer"].nickname}
            </p>
          );
        },
        header: "작성자",
        maxSize: 60,
      }),
      columnHelper.accessor("total", {
        cell: ({ row }) =>
          row.original["influencerTotalPayments"]?.toLocaleString() + "원",
        header: "총 정산금액",
        maxSize: 15,
      }),
      columnHelper.accessor("detail", {
        cell: ({ row }) => {
          const url = `/content/payment/${row.original["_influencer"]._id}`;
          const onClick = () => navigate(url);
          return <StyledLink onClick={onClick}>내역 상세보기</StyledLink>;
        },
        header: "",
        maxSize: 15,
      }),
    ];
  }, []);

  // useReactTable로 테이블 구조 정의
  const table = useReactTable({
    data,
    columns,
    state: {
      // columnVisibility,
      columnOrder,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="contentsData">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Empty data={data} title="해당기간 내 정산 내역이 없어요" />
    </Container>
  );
}

const Container = styled.div`
  padding-top: 24px;
`;

const StyledLink = styled.p`
  color: ${({ theme }) => theme.colors.primaryBlue100};
  text-decoration: underline;
  cursor: pointer;
`;
