import { useEffect, useMemo, useState } from "react";
import { navConstantsList } from "@constants/navConstants";
import BigNav from "./BigNav";
import { useGetMe, useGetAuthorities } from "@api/user";

export default function NavContainer() {
  const { data } = useGetMe();
  const { data: authorities } = useGetAuthorities();

  const [userInfo, setUserInfo] = useState({
    user_id: "",
    name: "",
    nickname: "",
    email: "",
    user_group: "",
    phone_number: "",
    memo: "",
    inActive: false,
    profile_color: "",
  });

  useEffect(() => {
    if (!data) return;

    setUserInfo(data);
  }, [data]);

  const navList = useMemo(() => {
    if (!authorities) {
      return [];
    }

    return navConstantsList.filter((el) => authorities.includes(el.value));
  }, [authorities]);

  return <BigNav navList={navList} userInfo={userInfo} />;
}
