import styled from "styled-components";

export default function Empty() {
  return (
    <Container>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clipPath="url(#clip0_21936_16236)">
          <path
            d="M20.3996 2.33322V15.5992L13.7996 19.1992V22.7992H4.72161C4.10367 22.7992 3.59961 22.2892 3.59961 21.6652V2.33322C3.59961 1.70922 4.10367 1.19922 4.72161 1.19922H19.2776C19.8955 1.19922 20.3996 1.70922 20.3996 2.33322Z"
            fill="#D5DBE4"
          />
          <path
            d="M6 7.79883H18"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M6 4.79883H14.4"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M6 10.7988H12"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M20.3998 15.5996V15.7376C20.3998 16.0316 20.2859 16.3196 20.0878 16.5416L14.7598 22.4096C14.5318 22.6556 14.2078 22.7996 13.8718 22.7996H13.7998V17.5996C13.7998 16.495 14.6952 15.5996 15.7998 15.5996H20.3998Z"
            fill="#BFC6D1"
          />
        </g>
        <defs>
          <clipPath id="clip0_21936_16236">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <span>메모를 남겨주세요</span>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;

  span {
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.blueGray400};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
  }
`;
