import PermissionLayout from "@components/router/PermissionLayout";
import { UserGroupPage, UserListPage } from "@pages/user";
import UserEditPage from "@pages/user/userEdit/UserEditPage";
import { Route, Routes } from "react-router-dom";

export default function UserRouter({ pageValue }) {
  return (
    <Routes>
      <Route
        element={
          <PermissionLayout
            pageValue={pageValue}
            style={{ padding: "48px 243px 60px" }}
          />
        }
      >
        <Route index element={<UserListPage />} />
        <Route path=":id" element={<UserEditPage />} />
        <Route path="group" element={<UserGroupPage />} />
      </Route>
    </Routes>
  );
}
