import { HTMLProps } from "react";
import styled from "styled-components";

import logo from "./logo10.png";

interface Props {
  name?: string;
  style?: HTMLProps<HTMLDivElement>["style"];
  img?: string;
}

export default function Profile24({ name, style, img }: Props) {
  if (img) {
    return (
      <Container style={style}>
        <img src={img} alt="profile" />
      </Container>
    );
  }
  if (name) {
    return (
      <Container style={style}>
        <span>{name}</span>
      </Container>
    );
  }

  return (
    <Container style={style}>
      <img src={logo} alt="profile" style={{ width: 10, height: 14 }} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 39px;
  background: #e3f0ff;

  span {
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.24px;
  }
`;
