import styled from "styled-components";
import SearchDanji from "./SearchDanji";
import MaemulFilter from "./MaemulFilter";
import { useMemo, useState } from "react";
import { usePostImjangDanji } from "@hooks/imjang";
import { tradeType } from "@hooks/imjang/Imjang.interfaces";
import { useParams } from "react-router-dom";

export default function MaemulContent({ setIsVisible }) {
  const { mutate } = usePostImjangDanji();
  const params = useParams();

  // 선택한 단지 정보
  const [selectedValue, setSelectedValue] = useState({
    _source: { 단지코드: null },
  });
  // 선택한 거래 및 평형 정보
  const [selectedFilter, setSelectedFilter] = useState<{
    tradeType: tradeType;
    scaleCodeList: number[];
  }>({
    tradeType: "none",
    scaleCodeList: [],
  });

  const isDone = useMemo(() => {
    return Boolean(
      selectedFilter.tradeType && selectedFilter.scaleCodeList.length
    );
  }, [selectedFilter]);

  const 임장_단지_추가 = () => {
    mutate(
      {
        applyId: params.id,
        danjiCode: selectedValue?._source.단지코드 as number,
        scaleCodeList: selectedFilter.scaleCodeList,
        tradeType: selectedFilter.tradeType,
      },
      {
        onSuccess: () => {
          alert("추가 완료");
          setSelectedValue({ _source: { 단지코드: null } }); // 초기화
          setIsVisible(false);
        },
      }
    );
  };

  return (
    <Container>
      {!selectedValue._source.단지코드 ? (
        <SearchDanji setSelectedValue={setSelectedValue} />
      ) : (
        <>
          <MaemulFilter
            data={selectedValue}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
          <Button
            $isDone={isDone}
            onClick={임장_단지_추가}
            aria-disabled={!isDone}
          >
            <p>추가하기</p>
          </Button>
        </>
      )}
    </Container>
  );
}

const Container = styled.div``;

const Button = styled.button<{ $isDone: boolean }>`
  margin: auto auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 48px;
  border-radius: 10px;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};

  p {
    color: ${({ theme, $isDone }) =>
      $isDone ? theme.colors.white : theme.colors.blueGray500};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;
