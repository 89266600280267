import { CheckStatusEnum } from "@components/core/icon/checked/Checked.interfaces";
import Checked20 from "@components/core/icon/checked/Checked20";
import { Close22 } from "@components/core/icon/close";
import { useGetRegionList } from "@hooks/imjang";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const siList = ["서울특별시", "인천광역시", "경기도"];
// const data = {
//   region_list: [
//     {
//       sido: "서울특별시",
//       sigugun: [
//         {
//           sigugun: "강남구",
//           pnu: 11680,
//         },
//         {
//           sigugun: "강동구",
//           pnu: 11740,
//         },
//         {
//           sigugun: "강북구",
//           pnu: 11305,
//         },
//         {
//           sigugun: "강서구",
//           pnu: 11500,
//         },
//         {
//           sigugun: "관악구",
//           pnu: 11620,
//         },
//         {
//           sigugun: "광진구",
//           pnu: 11215,
//         },
//         {
//           sigugun: "구로구",
//           pnu: 11530,
//         },
//         {
//           sigugun: "금천구",
//           pnu: 11545,
//         },
//         {
//           sigugun: "노원구",
//           pnu: 11350,
//         },
//         {
//           sigugun: "도봉구",
//           pnu: 11320,
//         },
//         {
//           sigugun: "동대문구",
//           pnu: 11230,
//         },
//         {
//           sigugun: "동작구",
//           pnu: 11590,
//         },
//         {
//           sigugun: "마포구",
//           pnu: 11440,
//         },
//         {
//           sigugun: "서대문구",
//           pnu: 11410,
//         },
//         {
//           sigugun: "서초구",
//           pnu: 11650,
//         },
//         {
//           sigugun: "성동구",
//           pnu: 11200,
//         },
//         {
//           sigugun: "성북구",
//           pnu: 11290,
//         },
//         {
//           sigugun: "송파구",
//           pnu: 11710,
//         },
//         {
//           sigugun: "양천구",
//           pnu: 11470,
//         },
//         {
//           sigugun: "영등포구",
//           pnu: 11560,
//         },
//         {
//           sigugun: "용산구",
//           pnu: 11170,
//         },
//         {
//           sigugun: "은평구",
//           pnu: 11380,
//         },
//         {
//           sigugun: "종로구",
//           pnu: 11110,
//         },
//         {
//           sigugun: "중구",
//           pnu: 11140,
//         },
//         {
//           sigugun: "중랑구",
//           pnu: 11260,
//         },
//       ],
//     },
//     {
//       sido: "경기도",
//       sigugun: [
//         {
//           sigugun: "가평군",
//           pnu: 41820,
//         },
//         {
//           sigugun: "고양시 덕양구",
//           pnu: 41281,
//         },
//         {
//           sigugun: "고양시 일산동구",
//           pnu: 41285,
//         },
//         {
//           sigugun: "고양시 일산서구",
//           pnu: 41287,
//         },
//         {
//           sigugun: "과천시",
//           pnu: 41290,
//         },
//         {
//           sigugun: "광명시",
//           pnu: 41210,
//         },
//         {
//           sigugun: "광주시",
//           pnu: 41610,
//         },
//         {
//           sigugun: "구리시",
//           pnu: 41310,
//         },
//         {
//           sigugun: "군포시",
//           pnu: 41410,
//         },
//         {
//           sigugun: "김포시",
//           pnu: 41570,
//         },
//         {
//           sigugun: "남양주시",
//           pnu: 41360,
//         },
//         {
//           sigugun: "동두천시",
//           pnu: 41250,
//         },
//         {
//           sigugun: "부천시",
//           pnu: 41190,
//         },
//         {
//           sigugun: "성남시 분당구",
//           pnu: 41135,
//         },
//         {
//           sigugun: "성남시 수정구",
//           pnu: 41131,
//         },
//         {
//           sigugun: "성남시 중원구",
//           pnu: 41133,
//         },
//         {
//           sigugun: "수원시 권선구",
//           pnu: 41113,
//         },
//         {
//           sigugun: "수원시 영통구",
//           pnu: 41117,
//         },
//         {
//           sigugun: "수원시 장안구",
//           pnu: 41111,
//         },
//         {
//           sigugun: "수원시 팔달구",
//           pnu: 41115,
//         },
//         {
//           sigugun: "시흥시",
//           pnu: 41390,
//         },
//         {
//           sigugun: "안산시 단원구",
//           pnu: 41273,
//         },
//         {
//           sigugun: "안산시 상록구",
//           pnu: 41271,
//         },
//         {
//           sigugun: "안성시",
//           pnu: 41550,
//         },
//         {
//           sigugun: "안양시 동안구",
//           pnu: 41173,
//         },
//         {
//           sigugun: "안양시 만안구",
//           pnu: 41171,
//         },
//         {
//           sigugun: "양주시",
//           pnu: 41630,
//         },
//         {
//           sigugun: "양평군",
//           pnu: 41830,
//         },
//         {
//           sigugun: "여주시",
//           pnu: 41670,
//         },
//         {
//           sigugun: "연천군",
//           pnu: 41800,
//         },
//         {
//           sigugun: "오산시",
//           pnu: 41370,
//         },
//         {
//           sigugun: "용인시 기흥구",
//           pnu: 41463,
//         },
//         {
//           sigugun: "용인시 수지구",
//           pnu: 41465,
//         },
//         {
//           sigugun: "용인시 처인구",
//           pnu: 41461,
//         },
//         {
//           sigugun: "의왕시",
//           pnu: 41430,
//         },
//         {
//           sigugun: "의정부시",
//           pnu: 41150,
//         },
//         {
//           sigugun: "이천시",
//           pnu: 41500,
//         },
//         {
//           sigugun: "파주시",
//           pnu: 41480,
//         },
//         {
//           sigugun: "평택시",
//           pnu: 41220,
//         },
//         {
//           sigugun: "포천시",
//           pnu: 41650,
//         },
//         {
//           sigugun: "하남시",
//           pnu: 41450,
//         },
//         {
//           sigugun: "화성시",
//           pnu: 41590,
//         },
//       ],
//     },
//   ],
// };

export default function AreaPopUp({ value, onClose, onChangeValue }) {
  console.log("🐙 ~ AreaPopUp ~ value:", value);
  const { data } = useGetRegionList();
  const [selectedValue, setSelectedValue] = useState({
    sido: "서울특별시",
    sigugunList: [],
  });

  console.log("🐙 ~ AreaPopUp ~ selectedValue:", selectedValue);

  const areaList = useMemo(() => {
    if (data) {
      const selectRegion = data?.regionList?.find(
        (el) => el.sido === selectedValue.sido
      );
      return selectRegion?.sigugunList;
    } else return [];
  }, [data, selectedValue.sido]);

  const handleClickSido = (v) => {
    setSelectedValue((prev) => ({ ...prev, sido: v }));

    if (selectedValue.sido !== v) {
      setSelectedValue((prev) => ({ ...prev, sigugunList: [] }));
    }
  };

  const handleClickGugun = (value) => {
    const copyArr = selectedValue.sigugunList;
    if (copyArr.some((el) => el.pnu === value.pnu)) {
      const index = copyArr.findIndex((el) => el.pnu === value.pnu);
      copyArr.splice(index, 1);
      setSelectedValue((prev) => ({ ...prev, sigugunList: copyArr }));
    } else {
      setSelectedValue((prev) => ({
        ...prev,
        sigugunList: [...copyArr, value],
      }));
    }
  };

  const handleComplete = () => {
    onChangeValue(selectedValue);
    onClose();
  };

  useEffect(() => {
    if (value.sigugunList.length > 0) {
      setSelectedValue({ sido: value.sido, sigugunList: value.sigugunList });
    }
  }, [value]);

  return (
    <Container>
      <Top>
        <p>지역선택</p>
        <span onClick={onClose}>
          <Close22 />
        </span>
      </Top>
      <Center>
        <div className="si">
          {siList.map((el, idx) => (
            <li
              key={idx}
              onClick={() => handleClickSido(el)}
              className={selectedValue.sido === el ? "selected" : "default"}
            >
              {el}
            </li>
          ))}
        </div>
        <div className="gungu">
          {areaList?.map((el, idx) => (
            <li
              onClick={() => {
                handleClickGugun(el);
              }}
              key={idx}
            >
              <p>{el.sigugun}</p>
              {selectedValue.sigugunList.some((v) => v.pnu === el.pnu) ? (
                <Checked20 status={CheckStatusEnum.Activate} />
              ) : null}
            </li>
          ))}
        </div>
      </Center>
      <Bottom onClick={handleComplete}>
        <p>완료</p>
      </Bottom>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 470px;
  background-color: white;
  border-radius: 20px;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  /* position: fixed;
  top: 0; */
  padding: 24px 24px 14px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray100};

  p {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 18px;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }
`;

const Center = styled.div`
  display: flex;
  flex: 1;

  .si {
    width: 45%;
    /* flex-grow: 1; */
    border-right: 1px solid ${({ theme }) => theme.colors.blueGray100};

    li {
      padding: 8px 24px;
      list-style: none;

      color: ${({ theme }) => theme.colors.blueGray600};
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: -0.32px;
      cursor: pointer;
    }

    .selected {
      background-color: ${({ theme }) => theme.colors.blue100};
      color: ${({ theme }) => theme.colors.primaryBlue100};
    }
  }

  .gungu {
    height: 360px;
    width: 55%;
    overflow: scroll;

    li {
      display: flex;
      justify-content: space-between;
      padding: 8px 16px 8px 10px;
      list-style: none;

      color: ${({ theme }) => theme.colors.blueGray600};
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: -0.32px;
      cursor: pointer;
    }
  }
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  height: 60px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primaryBlue100};

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 18px;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
    letter-spacing: -0.36px;
    text-align: center;
  }
`;
