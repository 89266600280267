import { useEffect, useRef, useState } from "react";
import {
  InputStatusEnum,
  InputTypeEnum,
  borderColorObj,
  labelColorObj,
} from "./BTypeInput.interfaces";
import { FormatOptions, numToKorean } from "num-to-korean";
import { Props } from "./BTypeInputBoxTyping.interfaces";
import { convertLocaleStringToNumber, isNumeric } from "@utils/cash";
import styled from "styled-components";

// 어드민 스타일에 맞게 살짝 변형된 인풋
export default function B1TypeInputBoxTyping({
  inputRef,
  inputType = InputTypeEnum.Cash,
  label,
  placeholder,
  value = null,
  onChangeValue = () => {},
  unitLabel = "",
  message = "",
  handleFocus,
  handleBlur,
  handleKeyDown,
  disabled = false,
  maxLength,
  style,
}: Props) {
  const [status, setStatus] = useState<InputStatusEnum>(
    disabled ? InputStatusEnum.Disabled : InputStatusEnum.Default
  );
  const labelRef: React.RefObject<HTMLLabelElement> = useRef(null);

  useEffect(() => {
    let intialStatus = InputStatusEnum.Default;

    if (disabled) {
      intialStatus = InputStatusEnum.Disabled;
    } else if (value !== null) {
      intialStatus = InputStatusEnum.Completion;
    }

    setStatus(intialStatus);
  }, [disabled]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0 && status !== InputStatusEnum.Active) {
      setStatus(InputStatusEnum.Active);
    }

    switch (inputType) {
      case InputTypeEnum.OnlyText:
        onChangeValue(e.target.value);
        break;

      case InputTypeEnum.Cash:
        // 숫자, 콤마 이외의 값이 들어 왔을 때 체크
        if (/[^\d,]/g.test(e.target.value)) {
          return;
        }

        if (value === "0") {
          onChangeValue(0);
          return;
        }

        if (value === "") {
          onChangeValue(null);
          return;
        }

        const num = convertLocaleStringToNumber(e.target.value);
        onChangeValue(num * 10000);

        break;

      case InputTypeEnum.Decimal:
        if (e.target.value === "") {
          onChangeValue(null);
        }

        if (!isNumeric(Number(e.target.value))) {
          return;
        }

        if (/^0\d/g.test(e.target.value)) {
          onChangeValue(e.target.value.substring(1));
          return;
        }

        if (value === "0") {
          onChangeValue(0);
          return;
        }

        if (/^\d+\.$/g.test(e.target.value)) {
          onChangeValue(e.target.value);
          return;
        }

        onChangeValue(Number(e.target.value));
        break;
    }
  };

  const onFocus = (e) => {
    setStatus(value ? InputStatusEnum.Active : InputStatusEnum.Focus);
    handleFocus && handleFocus(e);
  };

  const onBlur = (e) => {
    e.preventDefault();
    setStatus(value ? InputStatusEnum.Completion : InputStatusEnum.Default);
    handleBlur && handleBlur(e);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && handleKeyDown) {
      handleKeyDown();
    }
  };

  return (
    <Container style={{ ...style }}>
      <label
        className="label"
        style={{ color: labelColorObj[status] }}
        ref={labelRef}
      >
        {label}
      </label>
      <InputContainer
        className={[
          status === InputStatusEnum.Disabled ? "disabledBG" : "defaultBG",
        ].join(" ")}
        style={{
          borderColor: borderColorObj[status],
        }}
      >
        <Input
          ref={inputRef}
          className={[
            status === InputStatusEnum.Disabled
              ? ["disabledBG", "disabledInputColor"].join(" ")
              : ["defaultBG", "defaultInputColor"].join(" "),
          ].join(" ")}
          placeholder={placeholder}
          value={
            value !== null
              ? inputType === InputTypeEnum.Cash
                ? (Number(value) / 10000).toLocaleString()
                : value
              : ""
          }
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={status === InputStatusEnum.Disabled}
          maxLength={maxLength}
          autoComplete="none"
          autoCapitalize="off"
          autoCorrect="off"
          type={inputType === InputTypeEnum.Cash ? "text" : ""}
          pattern={inputType === InputTypeEnum.Cash ? "\\d*" : undefined}
          onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            if (event.key === "Enter") {
              target.blur();
            }
          }}
          onKeyDown={onKeyDown}
          inputMode="none"
        />
        <Unit
          className={[
            status === InputStatusEnum.Active ||
            status === InputStatusEnum.Focus ||
            status === InputStatusEnum.Completion ||
            (status === InputStatusEnum.Default && value)
              ? "visible"
              : "invisible",
          ].join(" ")}
        >
          {unitLabel}
        </Unit>
      </InputContainer>
      <Message>
        <span className={["message", "leftBottomMsg"].join(" ")}>
          {message}
        </span>
        {inputType === InputTypeEnum.Cash &&
          (status === InputStatusEnum.Active ||
            status === InputStatusEnum.Completion) && (
            <span className={["message", "leftBottomMsg"].join(" ")}>
              {Number(value) === 0
                ? "0만"
                : numToKorean(Number(value), FormatOptions.MIXED)}
              원
            </span>
          )}
      </Message>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  padding-top: 24px;

  .label {
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;

const InputContainer = styled.div`
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 15px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  border-radius: 10px;

  &.disabledBG {
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }

  &.defaultBG {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
  }
`;

const Input = styled.input`
  padding: 0;
  width: 100%;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primaryBlueGray};
  border: none;
  border-radius: 10px;
  outline: none;
  letter-spacing: -0.02em;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
  }

  &.disabledBG {
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }

  &.defaultBG {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
  }

  .defaultInputColor {
    color: ${({ theme }) => theme.colors.primaryBlueGray};
  }

  .disabledInputColor {
    color: ${({ theme }) => theme.colors.blueGray500};
  }
`;

const Unit = styled.span`
  min-width: fit-content;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.colors.blueGray600};
  white-space: "nowrap";

  &.visible {
    visibility: visible;
  }

  &.invisible {
    visibility: hidden;
  }
`;

const Message = styled.div`
  color: ${({ theme }) => theme.colors.blueGray400};
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;

  &.message {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -1px;
  }

  &.leftBottomMsg {
    position: absolute;
    left: 24px;
    bottom: 0px;
  }

  &.rightBottomMsg {
    position: absolute;
    right: 24px;
    bottom: 0px;
    text-align: right;
  }
`;
