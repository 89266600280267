import ConfigAccountingTable from "@components/page/admin/report/accounting/ConfigAccountingTable";
import SubmitAccountingBox from "@components/page/admin/report/accounting/SubmitAccountingBox";
import { useGetContentPayments } from "@hooks/admin";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Calendar } from "../contents";
import { SubmitDataState } from "./AccountingInterfaces";

export default function AccountingContent() {
  const [tableData, setTableData] = useState([]);
  const [submitData, setSubmitData] = useState<SubmitDataState[]>([]);
  const [rangeDate, setRangeDate] = useState({ start: "", end: "" });

  const { mutate, data } = useGetContentPayments();

  useEffect(() => {
    mutate({
      is_paid: false,
      start_date: rangeDate.start,
      end_date: rangeDate.end,
    });
  }, [rangeDate, mutate]);

  useEffect(() => {
    if (data) {
      setTableData(data.contents);
    }
  }, [data]);

  return (
    <>
      <Container>
        <div>
          <div style={{ margin: "0 0 24px auto", width: "fit-content" }}>
            <Calendar setRangeDate={setRangeDate} text="기준: 발행날짜" />
          </div>
          <ConfigAccountingTable
            tableData={tableData}
            submitData={submitData}
            setSubmitData={setSubmitData}
          />
        </div>
        <SubmitAccountingBox
          submitData={submitData}
          setSubmitData={setSubmitData}
        />
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  margin-top: 20px;
`;
