import styled from "styled-components";

import { genderArray } from "@utils/code";
import DropDownInput from "../DropDownInput";
import DateInput from "./DateInput";
import InputRow from "./InputRow";

interface Props {
  onChange: (e: { name: string; value: string | number }) => void;
  value: { birthDate: string; genderCode: string };
}

export default function BirthAndGender({ onChange, value }: Props) {
  return (
    <>
      <InputRow title="생년월일" require>
        <DateInput
          placeholder="생년월일 8자리"
          name="birthDate"
          onChange={onChange}
          value={value.birthDate || ""}
        />
      </InputRow>
      <InputRow title="성별" require>
        <DropDownInput
          placeholder="성별"
          onChange={(v) => onChange({ name: "genderCode", value: v })}
          value={value.genderCode || ""}
          optionList={genderArray}
        />
      </InputRow>
    </>
  );
}
