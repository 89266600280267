import styled from "styled-components";
import BTypeText48 from "@components/input/BTypeText48/BTypeText48";
import BTypeBtn48 from "@components/core/BTypeInput/BTypeBtn48/BTypeBtn48";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import { TextInputEnum } from "@components/input/BTypeText48/BTypeText.interfaces";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import { useGetUserGroupList } from "@api/permission";
import { useMemo } from "react";

export default function EditUserContainer({ userInfo, setUserInfo }) {
  const { data } = useGetUserGroupList();
  const userGroupList = useMemo(() => {
    if (!data) return [{ label: "", value: "" }];

    return data.map((el) => {
      return { label: el, value: el };
    });
  }, [data]);

  return (
    <Container>
      <TRow>
        <TData>
          <div className="head">
            <p>활동명</p>
          </div>
          <div className="body">
            <BTypeText48
              inputType={InputTypeEnum.OnlyText}
              value={userInfo.nickname}
              placeholder="활동명"
              onChangeValue={(value) =>
                setUserInfo((prev) => ({ ...prev, nickname: value }))
              }
              textType={TextInputEnum.NICKNAME}
            />
          </div>
        </TData>
        <TData>
          <div className="head">
            <p>휴대폰 번호</p>
            <span className="required">*</span>
          </div>
          <div className="body">
            <BTypeText48
              inputType={InputTypeEnum.OnlyText}
              value={userInfo.phone_number}
              placeholder="휴대폰 번호"
              onChangeValue={(value) =>
                setUserInfo((prev) => ({
                  ...prev,
                  phone_number: value,
                }))
              }
              textType={TextInputEnum.PHONE_NUM}
            />
          </div>
        </TData>
      </TRow>
      <TRow>
        <TData>
          <div className="head">
            <p>이름</p>
            <span className="required">*</span>
          </div>
          <div className="body">
            <BTypeText48
              inputType={InputTypeEnum.OnlyText}
              value={userInfo.name}
              placeholder="이름"
              onChangeValue={(value) =>
                setUserInfo((prev) => ({ ...prev, name: value }))
              }
              textType={TextInputEnum.NAME}
            />
          </div>
        </TData>
        <TData>
          <div className="head">
            <p>이메일 주소</p>
            <span className="required">*</span>
          </div>
          <div className="body">
            <BTypeText48
              inputType={InputTypeEnum.OnlyText}
              value={userInfo.email}
              placeholder="이메일 주소"
              onChangeValue={(value) =>
                setUserInfo((prev) => ({ ...prev, email: value }))
              }
              textType={TextInputEnum.EMAIL}
            />
          </div>
        </TData>
      </TRow>
      <TRow>
        <TData>
          <div className="head">
            <p>회원 상태</p>
            <span className="required">*</span>
          </div>
          <div className="body">
            <BTypeBtn48
              inputType={InputTypeEnum.OnlyText}
              value={userInfo.inActive}
              placeholder="활동명"
              onChangeValue={(value) =>
                setUserInfo((prev) => ({
                  ...prev,
                  inActive: value === true || value === "true",
                }))
              }
              optionList={[
                { label: "활성화", value: false },
                { label: "비활성화", value: true },
              ]}
            />
          </div>
        </TData>
        <TData>
          <div className="head">
            <p>회원 그룹</p>
            <span className="required">*</span>
          </div>
          <div className="body">
            <BTypeBtn48
              inputType={InputTypeEnum.OnlyText}
              value={userInfo.user_group}
              placeholder="회원 그룹"
              onChangeValue={(value) =>
                setUserInfo((prev) => ({ ...prev, user_group: value }))
              }
              optionList={userGroupList}
            />
          </div>
        </TData>
      </TRow>
      <TRow style={{ marginBottom: 24 }}>
        <TData style={{ width: "100%", display: "flex", marginTop: 24 }}>
          <div className="head">
            <p>기타 메모</p>
          </div>
          <div style={{ flex: 1 }}>
            <BTypeTextArea
              value={userInfo.memo}
              placeholder="기타 메모"
              onChangeValue={(v) =>
                setUserInfo((prev) => ({ ...prev, memo: v }))
              }
              textareaStyle={{ height: 130 }}
            />
          </div>
        </TData>
      </TRow>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 32px;
  padding-top: 14px;
  border-top: 2px solid ${({ theme }) => theme.colors.blueGray800};
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray100};
`;

const TRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const TData = styled.div`
  position: relative;
  display: flex;

  .head {
    display: flex;
    position: relative;
    top: 13px;
    width: 140px;
    height: fit-content;

    p {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 15px;
      font-weight: 600;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.3px;
    }
  }

  .body {
    width: 312px;
  }

  .required {
    margin-left: 2px;
    color: ${({ theme }) => theme.colors.red1000};
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
  }
`;
