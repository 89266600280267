import { CheckBoxEnum, Props } from "./CheckBox.interfaces";

export default function SquareCheckBox20({ status }: Props) {
  switch (status) {
    case CheckBoxEnum.Default:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="19"
            height="19"
            rx="3.5"
            stroke="#CCCFD3"
          />
        </svg>
      );

    case CheckBoxEnum.Activate:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <rect width="20" height="20" rx="4" fill="#1C84FF" />
          <path
            d="M7 9.89591L9.43056 12.5001L14.2917 7.29175"
            stroke="white"
            strokeWidth="1.5"
          />
        </svg>
      );
    default:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="19"
            height="19"
            rx="3.5"
            stroke="#CCCFD3"
          />
        </svg>
      );
  }
}
