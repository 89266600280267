import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";

export default function Section({
  title,
  desc,
  bodyComponent,
  style,
}: {
  title: string;
  desc?: string;
  bodyComponent?: ReactNode;
  style?: CSSProperties;
}) {
  return (
    <TRow style={{ ...style }}>
      <Head>
        <h3>{title}</h3>
        {desc && <p>{desc}</p>}
      </Head>
      <Body>{bodyComponent}</Body>
    </TRow>
  );
}

const TRow = styled.div`
  display: flex;

  width: 95%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray100};
`;

const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 24px 0;
  min-width: 150px;

  h3 {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }

  p {
    margin-top: 4px;
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 13px;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    letter-spacing: -0.26px;
  }
`;

const Body = styled.div`
  flex: 1;
  padding: 24px 0 24px 24px;
`;
