import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Title } from "@components/styles";
import coachImg from "@assets/image/coachImg.png";

import UploadInput from "./UploadInput";
import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import { usePostContent } from "@api/contributor";

export default function ReportUploadPage() {
  const navigate = useNavigate();
  const { mutate, isLoading } = usePostContent();
  const [contentInfo, setContentInfo] = useState({ title: "", page_url: "" });

  const onSubmit = () => {
    mutate(contentInfo, {
      onSuccess: () => {
        if (
          window.confirm(
            "정상적으로 제출을 완료했어요! 상세 내역으로 이동하시겠습니까?"
          )
        ) {
          navigate("/report/my");
        } else {
          setContentInfo({ title: "", page_url: "" });
        }
      },
    });
  };

  return (
    <>
      <Title>컨텐츠 업로드</Title>
      <ContentsWrapper>
        <UploadInput
          contentInfo={contentInfo}
          setContentInfo={setContentInfo}
          onSubmit={onSubmit}
        />
        <CoachImage src={coachImg} />
      </ContentsWrapper>
      {isLoading && <AbsoluteFillLoading />}
    </>
  );
}

const CoachImage = styled.img`
  position: relative;
  top: -15px;
  width: 42%;
  max-width: 591px;
  max-height: 830px;
`;

const ContentsWrapper = styled.div`
  display: flex;
  margin-right: 20px;
`;
