import { Whitespace } from "@components/core/icon/whiteSpace";
import { WhitespaceEnum } from "@components/core/icon/whiteSpace/WhiteSpace.interfaces";

import styled from "styled-components";

export default function Empty({
  data,
  title,
  iconType,
  style,
}: {
  data?: any[];
  title: string;
  iconType?: WhitespaceEnum;
  style?: React.CSSProperties;
}) {
  if (data?.length > 0) {
    return;
  }

  return (
    <Container style={{ ...style }}>
      <Whitespace status={iconType} />
      <Description>{title}</Description>
    </Container>
  );
}

const Container = styled.div`
  height: 55vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Description = styled.p`
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.blueGray600};
  font-size: 15px;
  font-weight: 500;
  line-height: 22px; /* 144.444% */
  letter-spacing: -0.36px;
  white-space: pre-wrap;
  text-align: center;
`;
