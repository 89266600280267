import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blue: true,
  desc: "",
  path: "",
  isVisible: false,
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showAlert: (state, action) => {
      Object.assign(state, action.payload, { isVisible: true });
    },
    closeAlert: (state) => {
      Object.assign(state, { isVisible: false });
    },
  },
});

export const { showAlert, closeAlert } = alertSlice.actions;

export default alertSlice.reducer;
