import styled from "styled-components";
import { useEffect, useState } from "react";
import { useMenu } from "@features/useMenu";
import { useParams } from "react-router-dom";
import { useGetSpecificInfluencerPaymentsList } from "@hooks/admin";
import { Title } from "@components/styles";
import AccountDetailContent from "@components/page/admin/report/accounting/AccountDetailContent";

export default function AccountingDetail() {
  const params = useParams();
  const id = params.id;

  const { setCurrentMenu, setCurrentSubMenu } = useMenu();
  const [rangeDate, setRangeDate] = useState({ start: "", end: "" });

  useEffect(() => {
    setCurrentMenu("settlement");
    setCurrentSubMenu("accountingList");

    return () => {
      setCurrentSubMenu("");
    };
  }, []);

  const { data } = useGetSpecificInfluencerPaymentsList({
    start_date: rangeDate.start,
    end_date: rangeDate.end,
    id,
  });

  if (!data) return;
  console.log("🐙 ~ AccountingDetail ~ data:", data);

  return (
    <Container>
      <Title>
        {data.influencers[0]._influencer.nickname || ""}의 정산 상세 내역
      </Title>
      <AccountDetailContent data={data} setRangeDate={setRangeDate} />
    </Container>
  );
}

const Container = styled.div`
  /* display: flex; */
`;
