import ExpertInput from "@components/page/admin/imjang/imjangExpert/ExpertInput";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import {
  useEditImjangAgentDetail,
  useGetImjangAgentDetail,
} from "@hooks/imjang";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

export default function ExpertEdit() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const { setCurrentMenu } = useMenu();

  useEffect(() => {
    setCurrentMenu("imjangExpert");
  }, []);

  const { data } = useGetImjangAgentDetail({ id });
  const { mutate } = useEditImjangAgentDetail();

  const [expertInfo, setExpertInfo] = useState({
    name: null,
    officeName: null,
    firstPhoneNumber: null,
    secondPhoneNumber: null,
    memo: null,
    visitDate: null,
    region: { sido: "서울특별시", sigugunList: [{ sigugun: "", pnu: 0 }] },
  });

  useEffect(() => {
    if (data) {
      setExpertInfo({
        name: data.name,
        officeName: data.officeName,
        firstPhoneNumber: data.firstPhoneNumber,
        secondPhoneNumber: data.secondPhoneNumber,
        memo: data.memo,
        visitDate: data.visitDate,
        region: data.region,
      });
    }
  }, [data]);

  const onSubmit = () => {
    mutate(
      { id, data: { ...expertInfo } },
      {
        onSuccess: (res) => {
          alert("수정 성공");
          navigate(-1);
        },
      }
    );
  };
  return (
    <Container>
      <Title>중개사 수정</Title>
      <ExpertInput
        expertInfo={expertInfo}
        setExpertInfo={setExpertInfo}
        onSubmit={onSubmit}
      />
    </Container>
  );
}

const Container = styled.div``;
