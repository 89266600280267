import { useGetPushStat } from "@hooks/management";
import moment from "moment";
import { useMemo } from "react";
import {
  Line,
  LineChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";

export default function PushGraph() {
  const { data } = useGetPushStat({
    startDate: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  });

  const chartData = useMemo(() => {
    if (data) {
      return data.results.reverse();
    } else return;
  }, [data]);

  return (
    <Container>
      <div style={{ height: 40 }} />
      {chartData && (
        <ResponsiveContainer>
          <LineChart
            data={chartData}
            margin={{
              top: 20,
              right: 5,
              left: 0,
              bottom: 5,
            }}
          >
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="daily"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="weekly"
              stroke="#FFA800"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="all"
              stroke="#1C84FF"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 20px;
  max-width: 1400px;
  width: 80%;
  height: 400px;
`;
