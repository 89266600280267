import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Minus16, Plus16 } from "@components/core/icon/plus";
import { Button, ButtonWrapper, Title } from "@components/styles";
import NoticeTable from "./NoticeTable";
import { useDeleteNotice, useGetNoticeList } from "@api/notice";

export default function NoticeListPage() {
  const navigate = useNavigate();
  const goAddNotice = () => {
    navigate("/notice/add");
  };

  const [selectedRow, setSelectedRow] = useState();

  const { data, isLoading } = useGetNoticeList();
  const { mutate: deleteNotice } = useDeleteNotice();

  const onDeleteUser = () => {
    if (!selectedRow) {
      alert("삭제할 공지를 선택해주세요.");
    } else {
      if (window.confirm("선택된 공지를 삭제합니다.")) {
        deleteNotice({ id: selectedRow });
      }
    }
  };
  return (
    <Container>
      <Title> 공지사항 업로드</Title>
      <ButtonWrapper>
        <div className="gap">
          <Button onClick={goAddNotice}>
            <Plus16 />
            <p>공지 추가</p>
          </Button>
          <Button onClick={onDeleteUser}>
            <Minus16 />
            <p>공지 삭제</p>
          </Button>
        </div>
      </ButtonWrapper>
      <NoticeTable
        data={data?.announcements || []}
        setSelectedRow={setSelectedRow}
        isLoading={isLoading}
      />
    </Container>
  );
}

const Container = styled.div``;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: fit-content;
`;
