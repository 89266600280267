export default function Close22() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clipPath="url(#clip0_9482_22428)">
        <path
          opacity="0.2"
          d="M22 0H0V22H22V0Z"
          fill="#FF7BA1"
          fillOpacity="0.01"
        />
        <path
          opacity="0.2"
          d="M18 2H4V20H18V2Z"
          fill="#FF7BA1"
          fillOpacity="0.01"
        />
        <path
          opacity="0.2"
          d="M20 18V4L2 4V18H20Z"
          fill="#FF7BA1"
          fillOpacity="0.01"
        />
        <path
          opacity="0.2"
          d="M19 3H3V19H19V3Z"
          fill="#FF7BA1"
          fillOpacity="0.01"
        />
        <path
          opacity="0.2"
          d="M11 15.125C13.2782 15.125 15.125 13.2782 15.125 11C15.125 8.72183 13.2782 6.875 11 6.875C8.72183 6.875 6.875 8.72183 6.875 11C6.875 13.2782 8.72183 15.125 11 15.125Z"
          fill="#FF7BA1"
          fillOpacity="0.01"
        />
        <path
          d="M3 3L19 19"
          stroke="#333E4D"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M3 19L19 3"
          stroke="#333E4D"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9482_22428">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
