export const GoogleSquare = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
    >
      <rect width="44" height="44" rx="8" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.52 22.2719C33.52 21.421 33.4436 20.6028 33.3018 19.8174H22V24.4592H28.4582C28.18 25.9592 27.3345 27.2301 26.0636 28.081V31.0919H29.9418C32.2109 29.0028 33.52 25.9265 33.52 22.2719Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.999 34.0003C25.239 34.0003 27.9554 32.9257 29.9408 31.093L26.0627 28.0821C24.9881 28.8021 23.6136 29.2275 21.999 29.2275C18.8736 29.2275 16.2281 27.1166 15.2845 24.2803H11.2754V27.3894C13.2499 31.3112 17.3081 34.0003 21.999 34.0003Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2855 24.2794C15.0455 23.5594 14.9091 22.7904 14.9091 21.9994C14.9091 21.2085 15.0455 20.4394 15.2855 19.7194V16.6104H11.2764C10.4636 18.2304 10 20.0631 10 21.9994C10 23.9358 10.4636 25.7685 11.2764 27.3885L15.2855 24.2794Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 14.7727C23.7618 14.7727 25.3436 15.3782 26.5873 16.5673L30.0291 13.1255C27.9509 11.1891 25.2345 10 22 10C17.3091 10 13.2509 12.6891 11.2764 16.6109L15.2855 19.72C16.2291 16.8836 18.8745 14.7727 22 14.7727Z"
        fill="#EA4335"
      />
    </svg>
  );
};
