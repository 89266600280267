import PermissionLayout from "@components/router/PermissionLayout";
import { Route, Routes } from "react-router-dom";
import {
  CompletedReportList,
  ReportList,
  ReuploadReport,
  SourceList,
  UploadReport,
  ViewComment,
  ViewSource,
} from "@pages/consultation";

export default function ConsultingRouter({ pageValue }) {
  return (
    <Routes>
      <Route element={<PermissionLayout pageValue={pageValue} />}>
        <Route path="report" element={<ReportList />} />
        <Route path="report/upload" element={<UploadReport />} />
        <Route path="report/upload/:id" element={<ReuploadReport />} />
        <Route path="report/complete" element={<CompletedReportList />} />
        <Route path="approval/:id" element={<ViewComment />} />
        <Route path="source" element={<SourceList />} />
        <Route path="source/:id" element={<ViewSource />} />
      </Route>
    </Routes>
  );
}
