import { useGetDealSuccessRate } from "@api/imjang";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function ClosingRate({ id }) {
  const { data } = useGetDealSuccessRate({ apply_id: id });

  return (
    <Container>
      <p className="title">거래성사율</p>
      <p className="percentage">{data?.dealSuccessRate || "-"}%</p>
      <LinkButton to="closing-rate">자세히 보기</LinkButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  padding: 32px 32px 24px;
  border-radius: 16px;
  background-color: white;

  .title {
    color: var(--BlueGray1000, #000e21);
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  .percentage {
    margin-top: 12px;
    color: ${({ theme }) => theme.colors.blueA700};
    font-size: 48px;
    font-weight: 600;
    line-height: 58px; /* 120.833% */
    letter-spacing: -0.96px;
  }
`;

const LinkButton = styled(Link)`
  margin-top: 16px;
  padding: 8px 12px;
  width: 100%;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.blue100};

  color: ${({ theme }) => theme.colors.primaryBlue100};
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
`;
