import { CheckBox22, CheckBoxEnum } from "@components/core/icon/checkBox";
import styled from "styled-components";

const methodArr: {
  title: string;
  value: "single" | "multiple";
  description: string;
}[] = [
  {
    title: "단일 푸시 발송",
    value: "single",
    description: "사용자 닉네임 필요",
  },
  {
    title: "다중 푸시 발송",
    value: "multiple",
    description: "코호트 등록 필요",
  },
];

export default function PushMethod({ pushInfo, setPushInfo }) {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      {methodArr.map((el) => (
        <CheckedRow
          onClick={() => setPushInfo((prev) => ({ ...prev, method: el.value }))}
          active={pushInfo.method === el.value}
          key={el.title}
        >
          <CheckBox22
            status={
              pushInfo.method === el.value
                ? CheckBoxEnum.Activate
                : CheckBoxEnum.Default
            }
          />
          <p className="title">{el.title}</p>
          <p className="desc">{el.description}</p>
        </CheckedRow>
      ))}
    </div>
  );
}

const CheckedRow = styled.div<{ active: boolean }>`
  display: flex;
  padding: 12px 20px 12px 16px;
  border-radius: 10px;
  border: 1px solid
    ${({ theme, active }) =>
      active ? theme.colors.primaryBlue100 : theme.colors.blueGray100};

  .title {
    margin-left: 12px;
    color: var(--BlueGray700, #4d5664);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }

  .desc {
    margin-left: auto;
    color: ${({ theme }) => theme.colors.blueGray400};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
  }
`;
