import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  title: string;
  require?: boolean;
}

export default function InputRow({ children, title, require = false }: Props) {
  return (
    <Container>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span className="title">{title}</span>
        {require && <span className="require">*</span>}
      </div>
      <div style={{ flex: 1, maxWidth: 270 }}>{children}</div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  .title {
    color: ${({ theme }) => theme.colors.gray900};
    font-size: 14px;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
  }

  .require {
    margin: 0px 2px 2px 2px;
    color: ${({ theme }) => theme.colors.red1000};
    font-size: 15px;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;
