import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

import Profile24 from "@components/core/profile/Profile24";
import Empty from "./Empty";
import {
  useDeleteThread,
  useGetImjangApplicantDetail,
  useGetThread,
  useGetThreadFile,
  useGetThreadPresignedUrl,
  usePostThread,
} from "@hooks/imjang";
import moment from "moment";
import { uploadThreadFileToS3 } from "@utils/file";
import useClickOutside from "@hooks/useClickOutside";
import { toast } from "react-toastify";

export default function Memo({ id }) {
  const [memo, setMemo] = useState("");
  const { data } = useGetThread({ id });
  const { mutate, isLoading } = usePostThread({ id });
  const { data: detailData } = useGetImjangApplicantDetail({ id });

  const divRef = useRef(null);

  const handleChange = (e) => {
    setMemo(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (isLoading) return;
    if (e.isComposing || e.keyCode === 229) return;
    if (e.shiftKey && e.key === "Enter") {
      return; // 줄바꿈 허용
    }
    if (e.key === "Enter") {
      e.preventDefault(); // 줄바꿈 방지
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (!confirmSend()) {
      return;
    }
    if (isLoading) return;

    mutate(
      { text: memo },
      {
        onSuccess: () => {
          setMemo("");
        },
        onError: () => {
          alert("메모 전송에 실패했습니다.");
        },
      }
    );
  };

  const confirmSend = useCallback(() => {
    if (memo.length === 0 || memo.trim().length === 0) {
      return false;
    } else {
      return true;
    }
  }, [memo]);

  const threadLen = useMemo(() => {
    return data?.threadList.length;
  }, [data]);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [data]);

  return (
    <Container>
      <h2>메모 ({detailData?.commentNum})</h2>
      {threadLen ? (
        <Content ref={divRef}>
          {data?.threadList.map((item, index) => (
            <Fragment key={item.id}>
              <Row key={item.id} content={item} id={id} />
              {index !== threadLen - 1 && <Divider />}
            </Fragment>
          ))}
        </Content>
      ) : (
        <Empty />
      )}
      <Input
        placeholder="메모 입력"
        value={memo}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <SendRow onClick={handleSubmit} confirmSend={confirmSend()} id={id} />
    </Container>
  );
}

const Row = ({ content, id }) => {
  const { mutate } = useGetThreadFile();
  const { mutate: deleteMutate } = useDeleteThread({ id });
  const clickRef = useRef();
  const [isView, setIsView] = useState(false);
  const loadFile = (key: string) => {
    mutate(
      {
        objectKey: key,
      },
      {
        onSuccess: (res) => {
          window.open(res?.presigned_url, "_blank");
        },
        onError: () => {
          console.log("🪄  loadFile  key", key);
        },
      }
    );
  };

  useClickOutside([clickRef], () => setIsView(false));

  const handleView = () => {
    setIsView(!isView);
  };

  const handleDelete = () => {
    deleteMutate(
      {
        threadId: content.id,
      },
      {
        onSuccess: () => {
          toast("메모 삭제가 완료되었습니다.");
        },
        onError: () => {
          console.log("삭제 실패");
        },
      }
    );
  };

  return (
    <RowBody>
      <div className="head">
        {content.isBot ? (
          <Profile24 />
        ) : (
          <Profile24
            name={content.authorName.slice(-2)}
            style={{ background: content.profileColor }}
          />
        )}
        <div className="title">
          <div className="left">
            <span className="name">
              {content.isBot ? "알림봇" : content.authorName}
            </span>
            <span className="date">
              {moment(content.createdAt)
                .add(9, "hours")
                .format("YY.MM.DD HH:mm")}
            </span>
          </div>
          {content.isMyThread && (
            <>
              <div className="right" onClick={handleView}>
                <ThreeDots />
                {isView && (
                  <button
                    className="delete"
                    onClick={handleDelete}
                    ref={clickRef}
                  >
                    메모 삭제
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {content.files ? (
        content.files.map((file) => (
          <div
            className="file"
            onClick={() => loadFile(file.s3ObjectKey)}
            key={file.id}
          >
            <ClipIcon />
            <p>{file.name}</p>
          </div>
        ))
      ) : (
        <p className="text">
          {content.text.split("\n").map((line, index) => (
            <Fragment key={index}>
              {line}
              <br />
            </Fragment>
          ))}
        </p>
      )}
    </RowBody>
  );
};

const Divider = () => {
  return (
    <div
      style={{
        backgroundColor: "#E5E7E9",
        width: "100%",
        height: 1,
        margin: "24px 0",
      }}
    />
  );
};

const SendRow = ({ onClick, confirmSend, id }) => {
  const { mutate: mutateUrl } = useGetThreadPresignedUrl();
  const { mutate } = usePostThread({ id });
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      uploadFile(file);
    }
  };

  const openFileDialog = () => {
    inputRef.current?.click();
  };

  const uploadFile = (file: File) => {
    const date = new Date();
    const timestamp = date.getTime();
    const key = `threads/${timestamp}-${id}-${file.name}`;
    mutateUrl(
      { objectKey: key },
      {
        onSuccess: async (res) => {
          const check = await uploadThreadFileToS3(res.presigned_url, file);
          if (!check) return;
          mutate({
            text: file.name,
            files: [{ name: file.name, s3ObjectKey: key }],
          });
        },
        onError: () => {
          return undefined;
        },
      }
    );
  };
  return (
    <BottomRow>
      <input
        id="file"
        type="file"
        onChange={handleFileChange}
        ref={inputRef}
        style={{ display: "none" }}
      />
      <Clip onClick={openFileDialog}>
        <ClipIcon />
      </Clip>
      <button
        onClick={onClick}
        className={`${confirmSend ? "able" : "disabled"} send`}
        type="submit"
      >
        보내기
      </button>
    </BottomRow>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 436px;
  height: 100%;
  margin-left: 24px;
  padding: 32px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.white};
  align-items: flex-start;
  align-self: stretch;
  overflow: scroll;

  h2 {
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.36px;
  }
`;

const Content = styled.div`
  padding-bottom: 30px;
  width: 100%;
  flex: 1;
  overflow-y: auto;
`;

const RowBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .head {
    display: flex;
    align-items: center;

    .title {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-left: 8px;
      flex: 1;

      .name {
        color: ${({ theme }) => theme.colors.blueGray800};
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.32px;
      }

      .date {
        margin-left: 6px;
        color: ${({ theme }) => theme.colors.blueGray400};
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.24px;
      }

      .right {
        cursor: pointer;
      }

      .delete {
        position: absolute;
        right: 10px;
        bottom: -60px;
        padding: 16px;
        border-radius: 4px;
        background: ${({ theme }) => theme.colors.white};
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
      }
    }
  }

  .file {
    display: flex;
    align-items: center;
    margin-top: 8px;
    cursor: pointer;

    p {
      flex: 1;
      word-break: break-all;
      margin-left: 8px;
      color: ${({ theme }) => theme.colors.blueA700};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.32px;
      text-decoration-line: underline;
    }
  }

  .text {
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
  }
`;

const Input = styled.textarea`
  width: 100%;
  margin-top: 20px;
  min-height: 100px;
  padding: 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.blueGray50};
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.28px;
  resize: none;
`;

const BottomRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
  width: 100%;

  .send {
    margin-left: 8px;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.28px;
  }

  .disabled {
    color: ${({ theme }) => theme.colors.blueGray500};
    background: ${({ theme }) => theme.colors.blueGray100};
  }

  .able {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.blue1000};
  }
`;

const Clip = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: ${({ theme }) => theme.colors.white};
`;

const ClipIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_21936_18079)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3783 2.93224C11.7777 1.53286 14.0465 1.53286 15.4459 2.93224C16.8453 4.33162 16.8453 6.60046 15.4459 7.99984L8.52216 14.9236C7.69229 15.7535 6.34682 15.7535 5.51695 14.9236C4.68709 14.0937 4.68709 12.7483 5.51695 11.9184L11.8514 5.58389C12.1118 5.32354 12.5339 5.32354 12.7943 5.58389C13.0546 5.84424 13.0546 6.26635 12.7943 6.5267L6.45976 12.8612C6.1506 13.1704 6.1506 13.6716 6.45976 13.9808C6.76893 14.2899 7.27018 14.2899 7.57935 13.9808L14.5031 7.05703C15.3818 6.17835 15.3818 4.75373 14.5031 3.87505C13.6244 2.99637 12.1998 2.99637 11.3211 3.87505L4.39737 10.7988C2.94917 12.247 2.94917 14.595 4.39737 16.0432C5.84556 17.4914 8.19355 17.4914 9.64174 16.0432L15.9762 9.70868C16.2366 9.44833 16.6587 9.44833 16.919 9.70868C17.1794 9.96903 17.1794 10.3911 16.919 10.6515L10.5846 16.986C8.61566 18.9549 5.42345 18.9549 3.45456 16.986C1.48566 15.0171 1.48566 11.8249 3.45456 9.85599L10.3783 2.93224Z"
          fill="#808690"
        />
      </g>
      <defs>
        <clipPath id="clip0_21936_18079">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ThreeDots = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10.0008" cy="4.00078" r="1.2" fill="#CCCFD3" />
      <circle cx="10.0008" cy="10.0008" r="1.2" fill="#CCCFD3" />
      <circle cx="10.0008" cy="16.0008" r="1.2" fill="#CCCFD3" />
    </svg>
  );
};
