import styled from "styled-components";
import Lottie from "lottie-react";
import LoadingAnimation from "../../assets/lottie/Loading.json";
import success from "../../assets/lottie/성공.json";
import fail from "../../assets/lottie/실패.json";
import { useEffect } from "react";

export default function UpdateLoading({ isLoading, isError, setIsUpdate }) {
  return (
    <Container>
      {isLoading ? (
        <>
          <Lottie animationData={LoadingAnimation} loop={true} />
          <h3>고객 정보를 등록하고 있어요</h3>
          <span>고객 정보 등록 완료까지 창을 닫지마세요.</span>
        </>
      ) : isError ? (
        <>
          <Lottie
            animationData={fail}
            loop={false}
            style={{ width: 72, marginBottom: 24 }}
          />
          <h3>고객 정보 등록을 실패했어요</h3>
          <span>
            데이터 처리 중 알 수 없는 오류가 발생했어요.
            <br />
            부동부동 관리자에게 문의해 주세요.
          </span>
          <button onClick={() => setIsUpdate(false)}>
            <p>다시 시도</p>
          </button>
        </>
      ) : (
        <>
          <Lottie
            animationData={success}
            loop={false}
            style={{ width: 72, marginBottom: 24 }}
          />
          <h3>고객 정보 등록을 완료했어요</h3>
          <span>등록하신 고객 정보는 창을 닫고 다시 열면 볼 수 있어요.</span>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;

  h3 {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.colors.blueGray800};
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
  }

  span {
    color: ${({ theme }) => theme.colors.blueGray600};
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }

  button {
    margin-top: 16px;
    display: flex;
    height: 40px;
    padding: 2px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.blue100};

    p {
      color: ${({ theme }) => theme.colors.primaryBlue100};
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: -0.32px;
    }
  }
`;
