import styled from "styled-components";

export default function DropDownList({
  optionList,
  handleSelect,
  selectedValue,
  scrollHeight,
}) {
  return (
    <Content style={{ maxHeight: scrollHeight ? scrollHeight : "fit-content" }}>
      {optionList.map((item, index) => {
        return (
          <span
            key={index}
            onClick={() => {
              handleSelect(item.value);
            }}
            className={item.value === selectedValue ? "selected" : ""}
          >
            {item.title}
          </span>
        );
      })}
    </Content>
  );
}

const Content = styled.div`
  position: absolute;
  top: 48px;
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  height: fit-content;
  width: fit-content;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
  background: ${({ theme }) => theme.colors.white};
  z-index: 10;
  overflow-y: scroll;

  span {
    margin-bottom: 8px;
    padding: 4px 8px;
    min-width: 116px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.32px;
    border-radius: 8px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: var(--BlueGrayA100, #f4f6f8);
    }
  }

  .selected {
    background: ${({ theme }) => theme.colors.blue100};
    color: ${({ theme }) => theme.colors.blue1000};
    &:hover {
      background: ${({ theme }) => theme.colors.blue100}; /* hover 효과 무시 */
    }
  }
`;
