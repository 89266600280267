import { useDispatch, useSelector } from "react-redux";

import { RootState } from "app/store";
import {
  BasicInfo,
  HouseholdMember,
  Region,
  setBasicInfo,
  setHouseholdMemberList,
  setJjimList,
  setRegionList,
  setUserInfo,
  UserInfo,
} from "./userInfoSlice";

export const useUserInfo = () => {
  const globalUserInfo = useSelector(
    (state: RootState) => state.userInfoSlice
  ).data;

  const dispatch = useDispatch();
  const setGlobalBasicInfo = (el: BasicInfo) => {
    dispatch(setBasicInfo(el));
  };
  const setGlobalHouseholdMemberList = (el: HouseholdMember[]) => {
    dispatch(setHouseholdMemberList(el));
  };
  const setGloabalJjimList = (el: boolean) => {
    dispatch(setJjimList(el));
  };
  const setGlobalRegionList = (el: Region[]) => {
    dispatch(setRegionList(el));
  };

  const setGlobalUserInfo = (el: UserInfo) => {
    dispatch(setUserInfo(el));
  };

  return {
    globalUserInfo,
    setGlobalBasicInfo,
    setGlobalHouseholdMemberList,
    setGloabalJjimList,
    setGlobalRegionList,
    setGlobalUserInfo,
  };
};
