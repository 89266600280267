import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import "@components/table.css";
import Empty from "@components/common/Empty";
import Pagination from "@components/core/paging/Pagination";

export default function PushTable({ data }) {
  const columnHelper = createColumnHelper();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("number", {
        cell: (info) => info.row.index + 1,
        header: "순번",
        maxSize: 5,
      }),
      columnHelper.accessor("date", {
        header: "날짜",
        maxSize: 9,
      }),
      columnHelper.accessor("daily", {
        cell: ({ getValue }) => getValue().toLocaleString(),
        header: "daily",
        maxSize: 15,
      }),
      columnHelper.accessor("weekly", {
        cell: ({ getValue }) => getValue().toLocaleString(),
        header: "weekly",
        maxSize: 15,
      }),
      columnHelper.accessor("all", {
        cell: ({ getValue }) => getValue().toLocaleString(),
        header: "전체",
        maxSize: 15,
      }),
    ];
  }, []);

  // useReactTable로 테이블 구조 정의
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(20);
  }, [table]);

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="contentsData">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Empty data={data} title="해당기간 내 푸시 내역이 없어요" />
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div`
  padding-top: 24px;
`;
