import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import GradientButton from "@components/button/GradientButton";
import { CheckedCircleStatusEnum } from "@components/core/icon/checked/CheckedCircle.interfaces";
import CheckedCircle24 from "@components/core/icon/checked/CheckedCircle24";
import { Plus16 } from "@components/core/icon/plus";
import ModalContainer from "@components/core/modal/ModalContainer";
import { useGetImjangApplicantDetail, useUpdateSchedule } from "@hooks/imjang";
import { DanjiDetail, tradeTypeObj } from "@hooks/imjang/Imjang.interfaces";
import ScheduleInputList from "./ScheduleInputList";
import "./boxHeight.css";

const defaultUserInfo = {
  danjiCode: 0,
  scaleCode: 0,
  danjiName: "",
  pyeongType: "",
  tradeType: "",
  description: "",
  meetingDate: "",
  meetingTime: "",
  meetingPlace: "",
  agentOfficeName: "",
  agentOfficeContact: "",
};

export default function AddSchedule() {
  const { id } = useParams();
  const { data } = useGetImjangApplicantDetail({ id });
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <AddButton
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Plus16 />
        <p> 스케줄 추가</p>
        {!data?.scheduleList && (
          <AddTooltip>
            <span>
              임장 스케줄이 확정되면 스케줄 추가를
              <br /> 통해 방문일을 추가할 수 있어요
            </span>
            <Angle />
          </AddTooltip>
        )}
      </AddButton>
      <Modal isVisible={isOpen} setIsVisible={setIsOpen} id={id} data={data} />
    </>
  );
}

const Modal = ({ isVisible, setIsVisible, id, data }) => {
  const { mutate, isLoading } = useUpdateSchedule({ id });
  const [selected, setSelected] = useState<null | string>(null);
  const [step, setStep] = useState<number>(1);
  const [userInfo, setUserInfo] = useState(defaultUserInfo);

  const handleSelect = (id: string) => {
    setSelected(id);
  };

  const handleAddSchedule = () => {
    if (step === 1) {
      const findDanji = data?.danjiInfo.find(
        (item) => item.danjiInfo.id === selected
      );
      const obj = {
        danjiName: findDanji?.danjiInfo.대표단지명,
        danjiCode: findDanji?.danjiCode,
        scaleCode: findDanji?.scaleCode,
        pyeongType: findDanji?.pyeongInfo.평타입,
        tradeType: tradeTypeObj[findDanji?.tradeType],
      };
      setUserInfo((prev) => ({ ...prev, ...obj }));
      setStep(2);
    } else {
      if (isLoading) return;
      mutate(userInfo, {
        onSuccess: () => {
          setIsVisible(false);
        },
        onError: (res) => {
          console.log("err", res);
        },
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSelected(null);
      setUserInfo(defaultUserInfo);
      setStep(1);
    }, 300);
  }, [isVisible]);

  const isDone = useMemo(() => {
    if (step === 1) return selected !== null;
    return Object.values(userInfo).every((item) => item !== "");
  }, [step, selected, userInfo]);

  return (
    <ModalContainer
      title="스케줄 추가"
      onCloseButton={true}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      bottomButton={
        <GradientButton
          text={step === 1 ? "다음" : "추가하기"}
          isDone={isDone}
          onClick={handleAddSchedule}
        />
      }
    >
      <Content step={step}>
        {step === 1 && (
          <>
            <span className="sub">매물 선택</span>
            <div className="list">
              {data?.danjiInfo.map((item, index) => {
                const check = selected === item.danjiInfo.id;
                return (
                  <div
                    key={index}
                    onClick={() => handleSelect(item.danjiInfo.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <Row item={item} active={check} />
                  </div>
                );
              })}
            </div>
          </>
        )}
        {step === 2 && (
          <ScheduleInputList userInfo={userInfo} setUserInfo={setUserInfo} />
        )}
      </Content>
    </ModalContainer>
  );
};

const Row = ({ item, active }: { item: DanjiDetail; active: boolean }) => {
  return (
    <RowContainer>
      <CheckedCircle24
        status={
          active
            ? CheckedCircleStatusEnum.Activate
            : CheckedCircleStatusEnum.Default
        }
      />
      <div className="content">
        <span className="name">{item.danjiInfo.대표단지명}</span>
        <div className="type">
          <span>{tradeTypeObj[item.tradeType]}</span>
          <div className="divid" />
          <span>{item.pyeongInfo.평타입}평</span>
        </div>
      </div>
    </RowContainer>
  );
};

const Content = ({ step, children }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
    }
  }, [step]);

  return (
    <ContentBody ref={contentRef} className="content">
      {children}
    </ContentBody>
  );
};

const AddButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  border-radius: 10px;
  cursor: pointer;

  p {
    margin-left: 6px;
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 14px;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
  }
`;

const ContentBody = styled.div`
  padding-bottom: 30px;
  width: 480px;
  overflow-y: auto;

  transition: height 0.5s ease;

  .sub {
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.3px;
    font-feature-settings: "liga" off, "clig" off;
  }

  .list {
    display: flex;
    flex-direction: column;
  }
`;

const RowContainer = styled.div`
  display: flex;
  padding: 12px 12px 12px 8px;

  .content {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .name {
      margin-bottom: 2px;
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.32px;
      font-feature-settings: "liga" off, "clig" off;
    }

    .type {
      display: flex;
      align-items: center;

      span {
        color: ${({ theme }) => theme.colors.blueGray600};
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.28px;
        text-align: center;
      }

      .divid {
        margin: 0 8px;
        width: 1px;
        height: 10px;
        background: var(--BlueGray200, #cccfd3);
      }
    }
  }
`;

const AddTooltip = styled.div`
  position: absolute;
  top: 160%;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  width: 288px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blue1000};
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);

  span {
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.32px;
  }
`;

const Angle = () => {
  return (
    <div style={{ position: "absolute", top: -16 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="32"
        viewBox="0 0 36 32"
        fill="none"
      >
        <path
          d="M14.5359 2C16.0755 -0.666666 19.9245 -0.666666 21.4641 2L35.3205 26C36.8601 28.6667 34.9356 32 31.8564 32L4.14359 32C1.06439 32 -0.86011 28.6667 0.67949 26L14.5359 2Z"
          fill="#1C84FF"
        />
      </svg>
    </div>
  );
};
