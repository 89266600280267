import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import "@components/table.css";
import { copyText } from "@utils/copy";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import MaemulSearchPopup from "./MaemulSearchPopup";
import { CheckBoxEnum, SquareCheckBox20 } from "@components/core/icon/checkBox";
import { tradeTypeObj } from "@hooks/imjang/Imjang.interfaces";
import ActionButtons from "./ActionButtons";
import NoFixedPagination from "@components/core/paging/NoFixedPagination";

export default function ImjangHouseTable({ data }) {
  const [isVisible단지추가, setIsVisible단지추가] = useState(false);

  const 전체_상태 = useMemo(() => {
    if (data) {
      return false;
    }
  }, [data]);

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("checkBox", {
        header: () => (
          <Center onClick={table.getToggleAllRowsSelectedHandler()}>
            <SquareCheckBox20
              status={
                table.getIsAllRowsSelected()
                  ? CheckBoxEnum.Activate
                  : CheckBoxEnum.Default
              }
            />
          </Center>
        ),
        cell: ({ row }) => {
          return (
            <Center onClick={row.getToggleSelectedHandler()}>
              <SquareCheckBox20
                status={
                  row.getIsSelected()
                    ? CheckBoxEnum.Activate
                    : CheckBoxEnum.Default
                }
              />
            </Center>
          );
        },
        maxSize: 5,
      }),
      columnHelper.accessor("어드민 여무", {
        cell: ({ row }) => {
          return row.original["danjiInfo"]["어드민추가여부"]
            ? "부동부동"
            : "고객";
        },
        header: "단지추가",
        maxSize: 10,
      }),
      columnHelper.accessor("buildingType", {
        cell: ({ row }) => {
          return row.original["danjiInfo"]["건물유형"] || "-";
        },
        header: "구분",
        maxSize: 10,
      }),
      columnHelper.accessor("tradeType", {
        cell: ({ row }) => {
          return tradeTypeObj[row.original["tradeType"]] || "-";
        },
        header: "거래유형",
        maxSize: 10,
      }),
      columnHelper.accessor("name", {
        cell: ({ row }) => {
          return row.original["danjiInfo"]["대표단지명"] || "-";
        },
        header: "이름",
        maxSize: 20,
      }),
      columnHelper.accessor("roadAddress", {
        cell: ({ row }) => {
          return row.original["danjiInfo"]["도로명주소"];
        },
        header: "주소",
        maxSize: 25,
      }),
      columnHelper.accessor("pyeongNum", {
        cell: ({ row }) => {
          return row.original["pyeongInfo"]["평타입"] + "평";
        },
        header: "평형",
        maxSize: 10,
      }),
      columnHelper.accessor("-", {
        cell: ({ row }) => {
          return (
            <BlueText
              onClick={() =>
                copyText(
                  row.original["danjiInfo"]["도로명주소"],
                  "주소가 복사되었습니다"
                )
              }
            >
              주소 복사
            </BlueText>
          );
        },
        header: "-",
        maxSize: 10,
      }),
    ];
  }, [전체_상태]);

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    table.setPageSize(5);
  }, []);

  const getSelectedRow = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    return selectedRows.map((row) => row.original);
  };

  const handleDeselectAll = () => {
    table.toggleAllRowsSelected(false);
  };

  return (
    <Container>
      <Row style={{ marginBottom: 10 }}>
        <SubTitle>방문 신청 단지</SubTitle>
        <ActionButtons
          setIsVisible단지추가={setIsVisible단지추가}
          getSelectedRow={getSelectedRow}
          handleDeselectAll={handleDeselectAll}
        />
      </Row>
      <Table>
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup, idx) => (
            <tr key={`${headerGroup.id}_${idx}`}>
              {headerGroup.headers.map((header, idx) => (
                <th
                  key={`${header.id}_${idx}`}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, idx) => (
            <tr key={`${row.id}_${idx}`}>
              {row.getVisibleCells().map((cell, idx) => (
                <td key={`${cell.id}_${idx}`} className="contentsData">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <NoFixedPagination table={table} />
      <PopUpContainer
        isVisible={isVisible단지추가}
        setIsVisible={setIsVisible단지추가}
        style={{
          maxHeight: "80vh",
          borderRadius: 16,
          overflow: "scroll",
        }}
      >
        <MaemulSearchPopup setIsVisible={setIsVisible단지추가} />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 60px;
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
`;

const SubTitle = styled.h2`
  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;

const BlueText = styled.p`
  color: ${({ theme }) => theme.colors.primaryBlue100};
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.3px;
  cursor: pointer;
`;

const Table = styled.table`
  width: 100%;

  td {
    vertical-align: middle;
  }

  th {
    vertical-align: middle;
  }
`;
