// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUKU-vR8ZeNmtLbnRqsDTjyk4yVhnZKs8",
  authDomain: "boodong-boodong.firebaseapp.com",
  projectId: "boodong-boodong",
  storageBucket: "boodong-boodong.appspot.com",
  messagingSenderId: "199572843526",
  appId: "1:199572843526:web:a0402a3e3ec9da4c692787",
  measurementId: "G-6Z5PQVDV6J",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app); // db 생성

export const fireAuth = getAuth(app);
