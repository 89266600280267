import styled from "styled-components";
import { ArrowDirectionEnum } from "./Arrow.interfaces";

export default function Arrows18({ direction = ArrowDirectionEnum.DOWN }) {
  return (
    <Container direction={direction}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 7L9 12L4 7"
          stroke="#808690"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
}

const Container = styled.div<{ direction: string }>`
  ${({ direction }) => {
    switch (direction) {
      case ArrowDirectionEnum.LEFT:
        return "transform: rotate(90deg);";
      case ArrowDirectionEnum.RIGHT:
        return "transform: rotate(270deg);";
      case ArrowDirectionEnum.UP:
        return "transform: rotate(180deg);";
      default:
        return;
    }
  }}
`;
