export const NaverSquare = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
    >
      <rect width="44" height="44" rx="8" fill="#03C75A" />
      <path
        d="M26.2731 22.845L17.3756 10H10V34H17.7269V21.155L26.6244 34H34V10H26.2731V22.845Z"
        fill="white"
      />
    </svg>
  );
};
