import { Instance } from "@api/index";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { NewsReq } from "./news.interfaces";

export const useGetNews = () =>
  useQuery({
    queryKey: ["news"],
    queryFn: async () => {
      const response = await Instance({
        url: `/admin/news`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 1000 * 60 * 60,
  });

export const usePostNews = () => {
  const queryClient = useQueryClient();
  return useMutation<number, AxiosError, NewsReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: "/admin/news",
        method: "POST",
        data,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["news"],
      });
    },
  });
};

export const useEditNews = ({ id }) => {
  const queryClient = useQueryClient();
  return useMutation<number, AxiosError, NewsReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/admin/news/${id}`,
        method: "PUT",
        data,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["news"],
      });
    },
  });
};

export const useDeleteNews = () => {
  const queryClient = useQueryClient();
  return useMutation<number, AxiosError, { id: string }>({
    mutationFn: async ({ id }) => {
      const response = await Instance({
        url: `/admin/news/${id}`,
        method: "DELETE",
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["news"],
      });
      // queryClient.invalidateQueries({
      //   queryKey: ["cohortList"],
      // });
    },
  });
};
