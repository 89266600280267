import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import BTypeInputBoxTyping from "@components/core/BTypeInput/BTypeInputBoxTyping";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";

import { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import MultiFileUploadInput from "../MultiFileUploadInput";

export default function ApprovalInput({
  approvalInfo,
  setApprovalInfo,
  approvalStatus,
  onSubmit,
}) {
  const inputRef_1 = useRef<HTMLInputElement>(null);
  const isDone = useMemo(() => {
    return approvalInfo.title && approvalInfo.content;
  }, [approvalInfo]);

  return (
    <Container>
      <BTypeInputBoxTyping
        inputRef={inputRef_1}
        inputType={InputTypeEnum.OnlyText}
        label={"제목"}
        placeholder={"보고서 제목"}
        value={approvalInfo.title}
        disabled
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setApprovalInfo((prev) => ({ ...prev, title: v }))
        }
        style={{ width: "70%", maxWidth: 1200 }}
      />
      <BTypeTextArea
        label="내용"
        placeholder="내용을 입력하세요"
        textareaStyle={{ width: "70%", maxWidth: 1200, height: 350 }}
        value={approvalInfo.content}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setApprovalInfo((prev) => ({ ...prev, content: v }))
        }
      />
      {approvalStatus === "approve" && (
        <MultiFileUploadInput
          label="완료 보고서 업로드"
          style={{ width: "70%", maxWidth: 1200 }}
          files={approvalInfo.pdf || []}
          onChange={(v: any) => {
            console.log("선택", v);
            setApprovalInfo((prev) => ({
              ...prev,
              pdf: v,
            }));
          }}
          folderName={"consultingReport"}
        />
      )}
      <div style={{ padding: "20px 0 0", width: "70%", maxWidth: 1200 }}>
        <Button $isDone={isDone} disabled={!isDone} onClick={onSubmit}>
          보내기
        </Button>
      </div>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 30px;
  min-width: 1100px;
  flex: 1;

  .row {
    display: flex;
    flex-grow: 1;
  }
`;

const Button = styled.button<{ $isDone: boolean }>`
  padding: 10px 0;
  width: 100%;
  border-radius: 8px;
  border: none;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;
  color: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.white : theme.colors.blueGray500};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;

  &:disabled {
    cursor: not-allowed;
  }
`;
