export default function ClockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M5.20348 25.232C4.47517 24.7166 4.30255 23.7084 4.81792 22.9801L6.70161 20.3181C7.21698 19.5898 8.22517 19.4172 8.95348 19.9325V19.9325C9.68178 20.4479 9.85441 21.4561 9.33904 22.1844L7.45535 24.8464C6.93998 25.5747 5.93179 25.7473 5.20348 25.232V25.232Z"
        fill="#5D6873"
      />
      <path
        d="M23.069 25.232C23.7973 24.7166 23.9699 23.7084 23.4545 22.9801L21.5708 20.3181C21.0555 19.5898 20.0473 19.4172 19.319 19.9325V19.9325C18.5907 20.4479 18.4181 21.4561 18.9334 22.1844L20.8171 24.8464C21.3325 25.5747 22.3407 25.7473 23.069 25.232V25.232Z"
        fill="#5D6873"
      />
      <circle cx="7" cy="7" r="4" fill="#5D6873" />
      <circle cx="21" cy="7" r="4" fill="#5D6873" />
      <circle
        cx="14"
        cy="14"
        r="9"
        fill="white"
        stroke="#B2BEC9"
        strokeWidth="2"
      />
      <path
        d="M11.5303 10.4696C11.2374 10.1768 10.7625 10.1768 10.4696 10.4697C10.1768 10.7626 10.1768 11.2375 10.4697 11.5304L11.5303 10.4696ZM10.4697 11.5304L13.47 14.5301L14.5305 13.4693L11.5303 10.4696L10.4697 11.5304Z"
        fill="#5D6873"
      />
      <path
        d="M18.9983 10.5606C19.3079 10.2854 19.3357 9.81131 19.0606 9.50173C18.7854 9.19214 18.3113 9.16425 18.0017 9.43944L18.9983 10.5606ZM18.0017 9.43944L13.5017 13.4394L14.4983 14.5606L18.9983 10.5606L18.0017 9.43944Z"
        fill="#5D6873"
      />
      <path
        d="M12 2.5C12 1.94772 12.4477 1.5 13 1.5H15C15.5523 1.5 16 1.94772 16 2.5V2.5C16 3.05228 15.5523 3.5 15 3.5H13C12.4477 3.5 12 3.05228 12 2.5V2.5Z"
        fill="#5D6873"
      />
      <circle cx="14" cy="14" r="1" fill="#B2BEC9" />
    </svg>
  );
}
