import { useMemo } from "react";
import styled from "styled-components";

import { ActionEnum } from "@components/core/icon/action/Action.interfaces";
import Action24 from "@components/core/icon/action/Action24";
import { CheckBox24, CheckBoxEnum } from "@components/core/icon/checkBox";
import ManualPagination from "@components/core/paging/ManualPagination";
import { useGetImjangApplicantDetail } from "@hooks/imjang";
import useCustomSearchParams from "@hooks/useCustomSearchParams";

export default function FavoriteDanji({ selectedArr, setSelectedArr }) {
  const { searchParams } = useCustomSearchParams();
  const id = searchParams.applyId;
  const page = Number(searchParams.page || 1);
  const { data } = useGetImjangApplicantDetail({ id });

  const 유저선택단지arr = useMemo(() => {
    if (!data?.danjiInfo) return [];

    return data.danjiInfo;
  }, [data]);

  const handle클릭 = (item) => {
    const id = item.danjiInfo.id;

    if (selectedArr.some((el) => el.danjiInfo.id === id)) {
      const newArr = selectedArr.filter((el) => el.danjiInfo.id !== id);
      setSelectedArr(newArr);
    } else {
      setSelectedArr((prev) => [...prev, item]);
    }
  };

  const 전체선택 = () => {
    if (selectedArr.length === 유저선택단지arr.length) {
      setSelectedArr([]);
    } else setSelectedArr(유저선택단지arr);
  };

  return (
    <Container>
      <div className="notice">
        <Action24 status={ActionEnum.WARNING} />
        <p>관심 단지는 임장 신청 단지 정보를 불러옵니다.</p>
      </div>
      <div className="row" onClick={전체선택}>
        <span>
          <CheckBox24
            status={
              selectedArr.length === 유저선택단지arr.length
                ? CheckBoxEnum.Activate
                : CheckBoxEnum.Default
            }
          />
        </span>
        <p className="all">전체 선택</p>
      </div>
      <div className="cardList">
        {유저선택단지arr.slice(page * 3 - 3, page * 3).map((el, idx) => (
          <Card key={idx} onClick={() => handle클릭(el)}>
            <div>
              <p className="pyeong">{el.pyeongInfo.평타입}평</p>
              <p className="name">{el.danjiInfo.대표단지명 || "-"}</p>
              <p className="address">{el.danjiInfo.도로명주소}</p>
            </div>
            <span className="checkBox">
              <CheckBox24
                status={
                  selectedArr.some(
                    (obj) => obj.danjiInfo.id === el.danjiInfo.id
                  )
                    ? CheckBoxEnum.Activate
                    : CheckBoxEnum.Default
                }
              />
            </span>
          </Card>
        ))}
      </div>
      <ManualPagination total={유저선택단지arr.length} itemPerPage={3} />
    </Container>
  );
}

const Container = styled.div`
  padding: 32px 20px 0 24px;

  .notice {
    margin-bottom: 32px;
    display: flex;
    padding: 12px 16px;
    background: ${({ theme }) => theme.colors.blueGrayA100};
    border-radius: 8px;
    gap: 10px;

    p {
      color: ${({ theme }) => theme.colors.blueGray600};
      font-size: 15px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.3px;
    }
  }

  .row {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .all {
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.32px;
  }

  .cardList {
    div:last-child {
      border: none;
    }
  }
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrayA200};

  .pyeong {
    margin-bottom: 2px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.3px;
  }

  .name {
    margin-bottom: 4px;
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.32px;
  }

  .address {
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.26px;
  }

  .checkBox {
    margin-left: auto;
  }
`;
