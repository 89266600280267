import styled from "styled-components";

import { useRef } from "react";
import { Props } from "./BTypeTextArea.interfaces";

export default function BTypeTextArea({
  disabled = false,
  label,
  placeholder,
  textareaStyle,
  readOnly = false,
  value,
  maxLength = 1000,
  onChangeValue,
}: Props) {
  const labelRef = useRef(null);
  const onChange = (e) => {
    onChangeValue(e.target.value);
  };
  return (
    <Container>
      {label ? (
        <p className="label" ref={labelRef}>
          {label}
        </p>
      ) : null}
      <InnerText
        disabled={disabled}
        placeholder={placeholder}
        value={value || ""}
        readOnly={readOnly}
        onChange={onChange}
        maxLength={maxLength}
        style={{ ...textareaStyle }}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;

  /* margin: 0 24px; */
  .label {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }
`;

const InnerText = styled.textarea`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 16px;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.blueGrayA100};
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  border-radius: 10px;
  resize: none;
  outline: none;

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  &.disabledBG {
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }

  &.defaultBG {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
  }
`;
