import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import { TextInputEnum } from "@components/input/BTypeText48/BTypeText.interfaces";
import BTypeText48 from "@components/input/BTypeText48/BTypeText48";
import styled from "styled-components";

export default function JoinForm({ userInfo, setUserInfo, onSubmit }) {
  return (
    <FormContainer onSubmit={onSubmit}>
      <h1 className="title"> 회원가입 신청</h1>
      <form className="form">
        <TRow>
          <div className="head">
            <p>이름</p>
            <span className="required">*</span>
          </div>
          <div className="body">
            <BTypeText48
              inputType={InputTypeEnum.OnlyText}
              value={userInfo.name}
              placeholder="이름을 입력해주세요"
              onChangeValue={(value) =>
                setUserInfo((prev) => ({ ...prev, name: value }))
              }
              textType={TextInputEnum.NAME}
            />
          </div>
        </TRow>
        <TRow>
          <div className="head">
            <p>활동명</p>
          </div>
          <div className="body">
            <BTypeText48
              inputType={InputTypeEnum.OnlyText}
              value={userInfo.nickname}
              placeholder="활동명이 있으신가요?"
              onChangeValue={(value) =>
                setUserInfo((prev) => ({
                  ...prev,
                  nickname: value || null,
                }))
              }
              textType={TextInputEnum.NICKNAME}
            />
          </div>
        </TRow>
        <TRow>
          <div className="head">
            <p>휴대폰 번호</p>
            <span className="required">*</span>
          </div>
          <div className="body">
            <BTypeText48
              inputType={InputTypeEnum.OnlyText}
              value={userInfo.phone_number}
              placeholder="예) 01012345678"
              onChangeValue={(value) =>
                setUserInfo((prev) => ({
                  ...prev,
                  phone_number: value,
                }))
              }
              textType={TextInputEnum.PHONE_NUM}
            />
          </div>
        </TRow>
        <TRow>
          <div className="head">
            <p>이메일 주소</p>
            <span className="required">*</span>
          </div>
          <div className="body">
            <BTypeText48
              inputType={InputTypeEnum.OnlyText}
              value={userInfo.email}
              placeholder="이메일을 입력해주세요"
              onChangeValue={(value) =>
                setUserInfo((prev) => ({ ...prev, email: value }))
              }
              disabled
              textType={TextInputEnum.EMAIL}
            />
          </div>
        </TRow>
        <TRow>
          <div className="head">
            <p>초대코드</p>
            <span className="required">*</span>
          </div>
          <div className="body">
            <BTypeText48
              inputType={InputTypeEnum.OnlyText}
              value={userInfo.invite_code}
              placeholder="전달받은 초대코드가 있나요"
              onChangeValue={(value) =>
                setUserInfo((prev) => ({
                  ...prev,
                  invite_code: value,
                }))
              }
              textType={TextInputEnum.DEFAULT}
            />
          </div>
        </TRow>
        <button className="button" onClick={onSubmit}>
          신청하기
        </button>
      </form>
    </FormContainer>
  );
}

const FormContainer = styled.div`
  padding: 40px 20px 24px;
  width: 502px;
  background-color: white;
  border-radius: 24px;

  .title {
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: -0.64px;
    text-align: center;
  }

  .form {
    margin-top: 40px;
  }

  .button {
    margin-top: 24px;
    height: 48px;
    width: 100%;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.primaryBlue100};

    color: ${({ theme }) => theme.colors.white};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-align: center;
    cursor: pointer;
  }
`;

const TRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  .head {
    display: flex;
    position: relative;
    top: 13px;
    width: 140px;

    p {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 15px;
      font-weight: 600;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.3px;
    }

    .required {
      margin-left: 2px;
      color: ${({ theme }) => theme.colors.red1000};
      font-size: 15px;
      font-weight: 600;
      line-height: 22px;
    }
  }

  .body {
    width: 312px;
  }
`;
