import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import BTypeInputBoxTyping from "@components/core/BTypeInput/BTypeInputBoxTyping";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import styled from "styled-components";
import PermissionBox from "./PermissionBox";
import cn from "classnames";
import { useGetPermissionMapping, useUpdateUserGroup } from "@api/permission";
import { Fragment, useMemo } from "react";

export default function GroupSetting({
  settingInfo,
  setSettingInfo,
  onSubmit,
  onCancel,
}) {
  const { data: permissionMappingData } = useGetPermissionMapping();

  const permissionsList = useMemo(() => {
    if (!permissionMappingData) return [{ title: "", value: "" }];

    return Object.entries(permissionMappingData.permissions).map(
      ([value, title]) => {
        return {
          title,
          value,
        };
      }
    );
  }, [permissionMappingData]);

  const isDone = useMemo(() => {
    if (settingInfo.name === "마스터") return false;
    return settingInfo.name;
  }, [settingInfo]);

  return (
    <Container>
      <Title>
        <h3>그룹 상세 설정</h3>
      </Title>

      <Section>
        <div className="head" style={{ display: "flex", alignItems: "center" }}>
          <p>그룹 이름</p>
          <span className="required">*</span>
        </div>
        <div className="body">
          <BTypeInputBoxTyping
            inputType={InputTypeEnum.OnlyText}
            placeholder={"그룹 명을 설정하세요"}
            value={settingInfo.name}
            onChangeValue={(v: InputTypeEnum.OnlyText) =>
              setSettingInfo((prev) => ({ ...prev, name: v }))
            }
            style={{ padding: 0, paddingLeft: 0, maxWidth: 500 }}
          />
        </div>
      </Section>

      <Section>
        <div className="head">
          <p>그룹 설명</p>
        </div>
        <div className="body">
          <BTypeTextArea
            placeholder="설명글을 입력하세요."
            textareaStyle={{
              maxWidth: 500,
              maxHeight: 110,
              height: 300,
              margin: 0,
            }}
            value={settingInfo.description}
            onChangeValue={(v: InputTypeEnum.OnlyText) =>
              setSettingInfo((prev) => ({ ...prev, description: v }))
            }
          />
        </div>
      </Section>

      <Section>
        <div className="head">
          <p>관리자 권한 설정</p>
        </div>
        <div className="body" style={{ paddingTop: 5 }}>
          <div className="permissionWrapper">
            {permissionsList.map((el, idx) => {
              if (el.value.includes("B")) return <Fragment key={idx} />;
              return (
                <PermissionBox
                  data={el}
                  setSettingInfo={setSettingInfo}
                  permissionSetting={settingInfo.authorities}
                  key={idx}
                />
              );
            })}
          </div>
        </div>
      </Section>

      <Section>
        <div className="head">
          <p>인플루언서 권한 설정</p>
        </div>
        <div className="body" style={{ paddingTop: 5 }}>
          <div className="permissionWrapper">
            {permissionsList.map((el, idx) => {
              if (el.value.includes("A")) return <Fragment key={idx} />;
              return (
                <PermissionBox
                  data={el}
                  setSettingInfo={setSettingInfo}
                  permissionSetting={settingInfo.authorities}
                  key={idx}
                />
              );
            })}
          </div>
        </div>
      </Section>
      <ButtonBox>
        <button onClick={onCancel}>취소</button>
        <button
          className={cn({ disabled: !isDone })}
          disabled={!isDone}
          onClick={onSubmit}
        >
          적용
        </button>
      </ButtonBox>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Title = styled.div`
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.blueGray800};

  h3 {
    padding-bottom: 10px;
    color: var(--BlueGray800, #333e4d);
    font-feature-settings: "liga" off, "clig" off;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;

const Section = styled.div`
  display: flex;
  /* align-items: center; */
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrayA200};

  .required {
    margin-left: 2px;
    color: ${({ theme }) => theme.colors.red1000};
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
  }

  .head {
    padding: 14px 0;
    width: 188px;

    p {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 15px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: -0.3px;
    }
  }

  .body {
    flex: 1;
    padding: 14px 0;
  }

  .permissionWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 20px;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: 16px;
  gap: 8px;

  .disabled {
    background-color: ${({ theme }) => theme.colors.blueGray100};
    color: ${({ theme }) => theme.colors.blueGray500};
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.primaryBlue100};
    border-radius: 8px;

    color: var(--White, #fff);
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-align: center;

    &:first-child {
      background: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.blueGray100};
      color: ${({ theme }) => theme.colors.blueGray800};
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`;
