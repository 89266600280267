export default function PencilIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_10280_87392)">
        <path
          d="M20.3805 9.88115L17.243 6.74357C16.9013 6.40188 16.3472 6.40188 16.0055 6.74357L4.57252 18.1766C4.44354 18.3056 4.35814 18.4715 4.32813 18.6514L3.59987 23.0206C3.55052 23.3167 3.80724 23.5734 4.10334 23.5241L8.47257 22.7959C8.65247 22.7659 8.81845 22.6805 8.94743 22.5515L20.3804 11.1185C20.7221 10.7768 20.7222 10.2228 20.3805 9.88115Z"
          fill="#B2BEC9"
        />
        <path
          d="M18.6313 4.11874L18.1187 4.63128C17.777 4.97299 17.777 5.52701 18.1187 5.86872L21.2563 9.0063C21.598 9.34801 22.152 9.34801 22.4937 9.0063L23.0063 8.49376C23.348 8.15205 23.348 7.59803 23.0063 7.25632L19.8687 4.11874C19.527 3.77703 18.973 3.77703 18.6313 4.11874Z"
          fill="#8E9FAE"
        />
      </g>
      <defs>
        <clipPath id="clip0_10280_87392">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
