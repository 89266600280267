import styled from "styled-components";

export default function StepWrapper({ number, title, children }) {
  return (
    <Container>
      <Row>
        <Circle>
          <p>{number}</p>
        </Circle>
        <Title>{title}</Title>
      </Row>
      <Content> {children}</Content>
    </Container>
  );
}

const Container = styled.div``;

const Row = styled.div`
  display: flex;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.primaryBlue100};

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 112.5% */
    letter-spacing: -0.32px;
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;

const Content = styled.div`
  position: relative;
  right: -10px;
  margin: 8px 0;
  padding: 16px 0 32px 22px;
  border-left: 2px solid ${({ theme }) => theme.colors.blueGray100};
`;
