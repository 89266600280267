import styled from "styled-components";
import UserItem from "./UserItem";

export default function UsersList({ tabStatus, data }) {
  return (
    <Container>
      {data.user_list.map((el, idx) => {
        const hasNotBorder = () => {
          if (data.user_list.length % 2) {
            return idx === data.user_list.length - 1;
          } else {
            return (
              idx === data.user_list.length - 1 ||
              idx === data.user_list.length - 2
            );
          }
        };

        return (
          <div
            style={{
              padding: "4px 0",
              borderBottom: "1px solid #F0F2F5",
              borderBottomWidth: hasNotBorder() ? 0 : 1,
            }}
            key={idx}
          >
            <UserItem data={el} type={tabStatus} />
          </div>
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;
`;
