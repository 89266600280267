import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { HTMLProps, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import "@components/table.css";
import Pagination from "@components/core/paging/Pagination";
import { useNavigate } from "react-router-dom";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import { CheckBoxEnum, SquareCheckBox20 } from "@components/core/icon/checkBox";
import TableSkeleton from "@components/skeleton/TableSkeleton";
import Empty from "@components/common/Empty";

export default function NoticeTable({ data, setSelectedRow, isLoading }) {
  const navigate = useNavigate();

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("checkBox", {
        cell: ({ row, column }) => {
          return (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                onChange: row.getToggleSelectedHandler(),
                setSelectedRow: setSelectedRow,
                noticeId: row.original["_id"],
                key: row.original["_id"],
              }}
            />
          );
        },
        header: "",
        maxSize: 5,
      }),
      columnHelper.accessor("title", {
        cell: ({ row }) => {
          const onClick = () => {
            navigate(`/notice/${row.original["_id"]}`);
          };
          return <Title onClick={onClick}> {row.original["title"]}</Title>;
        },
        header: "컨텐츠 제목",
        maxSize: 80,
      }),

      columnHelper.accessor("created_at", {
        cell: ({ getValue }) => {
          return (
            <p style={{ textAlign: "center" }}>
              {convertToKoreanDate(getValue())}
            </p>
          );
        },
        header: "등록 날짜",
        maxSize: 15,
      }),
    ];
  }, []);

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  useEffect(() => {
    table.reset();
  }, [data]);

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.original["_id"]}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="noticeTableTd">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Empty title="공지사항이 없어요" data={data} />
      )}
      <Pagination table={table} />
    </Container>
  );
}

function IndeterminateCheckbox({
  className = "",
  onChange,
  setSelectedRow,
  noticeId,
  ...rest
}: {
  setSelectedRow?: React.Dispatch<React.SetStateAction<number>>;
  noticeId?: number;
} & HTMLProps<HTMLInputElement>) {
  const onClick = (e) => {
    onChange(e);
    setSelectedRow(noticeId);
  };

  return (
    <div onClick={onClick}>
      <SquareCheckBox20
        status={rest.checked ? CheckBoxEnum.Activate : CheckBoxEnum.Default}
      />
    </div>
  );
}

const Container = styled.div`
  /* padding-top: 80px; */
`;

const Title = styled.h2`
  text-align: left;
  padding: 16px 0;
  cursor: pointer;
  width: 55vw;
  max-width: 60vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
