import { Button, ButtonWrapper, Title } from "@components/styles";
import styled from "styled-components";
import StoryTable from "./StoryTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Minus16, Plus16 } from "@components/core/icon/plus";
import { useDeleteStoryList, useGetStoryList } from "@api/management/story";

export default function StoryListPage() {
  const navigate = useNavigate();
  const { data, isLoading } = useGetStoryList();
  const { mutate } = useDeleteStoryList();
  const tableRef = useRef();

  const getSelectedRow = () => {
    if (!tableRef.current) return [];

    const selectedRows = (
      tableRef.current as {
        getSelectedRowModel: () => { rows: { original: {} }[] };
      }
    ).getSelectedRowModel().rows;
    return selectedRows.map((row) => row.original["id"]);
  };

  useEffect(() => {
    console.log(getSelectedRow());
  }, []);

  const 삭제 = () => mutate({ storyIdList: getSelectedRow() });

  return (
    <Container>
      <Title>임장 이야기 관리</Title>
      <ButtonWrapper>
        <div className="gap">
          <Button onClick={() => navigate("add")}>
            <Plus16 />
            <p>글 추가하기</p>
          </Button>
          <Button onClick={삭제}>
            <Minus16 />
            <p>글 삭제하기</p>
          </Button>
        </div>
      </ButtonWrapper>
      <StoryTable data={data || []} ref={tableRef} isLoading={isLoading} />
    </Container>
  );
}

const Container = styled.div``;

const ButtonRow = styled.div``;
