import { useEffect, useState } from "react";
import { devNavList } from "@constants/navConstants";
import { db } from "../../firebase";
import BigNav from "./BigNav";
import { useGetAuthorities, useGetMe } from "@api/user";
import { collection, getDocs } from "firebase/firestore";

export default function DevNavContainer() {
  const { data } = useGetMe();
  const [navList, setNavList] = useState([]);

  const [userInfo, setUserInfo] = useState({
    user_id: "",
    name: "",
    nickname: "",
    email: "",
    user_group: "",
    phone_number: "",
    memo: "",
    inActive: false,
    profile_color: "",
  });

  const getData = async () => {
    const arr = [];
    const querySnapshot = await getDocs(collection(db, "authentication"));

    querySnapshot.forEach((doc) => {
      arr.push(doc.id);
    });

    // authentication에 등록된 이메일만 nav 리스트가 보임
    if (arr.includes(userInfo.email)) {
      setNavList(devNavList);
    } else setNavList([]);
  };

  useEffect(() => {
    if (!data) return;

    setUserInfo(data);
  }, [data]);

  useEffect(() => {
    if (!userInfo.email) return;

    getData();
  }, [userInfo]);

  return <BigNav navList={navList} userInfo={userInfo} />;
}
