import styled from "styled-components";

import B1TypeInputBoxTyping from "@components/core/BTypeInput/B1TypeInputBoxTyping";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import B1TypeDateInput from "@components/core/input/B1TypeDateInput";
import { useUserInfo } from "@features/useUserInfo";

export default function BasicInfo() {
  const { globalUserInfo, setGlobalBasicInfo } = useUserInfo();

  if (!globalUserInfo) return null;

  const basicInfo = globalUserInfo.basicInfo;

  return (
    <Container>
      <B1TypeDateInput
        value={basicInfo.plannedMovingDate}
        onChangeValue={(v: any) => {
          setGlobalBasicInfo({
            ...basicInfo,
            plannedMovingDate: v,
          });
        }}
        disabled={false}
        style={{ width: 327 }}
        isPastAvailable={false}
        label={"이사예정일"}
        placeholder="이사예정일을 선택하세요."
      />
      <B1TypeInputBoxTyping
        label="가용현금"
        inputType={InputTypeEnum.Cash}
        value={basicInfo.availableCash}
        placeholder="가용현금"
        unitLabel="만원"
        onChangeValue={(v: any) => {
          setGlobalBasicInfo({
            ...basicInfo,
            availableCash: v,
          });
        }}
        style={{ width: 327 }}
      />
    </Container>
  );
}

const Container = styled.div`
  padding: 0 24px;
`;
