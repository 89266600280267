import styled from "styled-components";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import React from "react";
import moment from "moment";

export default function DayPickCalender({ setStartDate, date }) {
  const DateFormet = (pick: Date) => {
    const result = moment(pick).format("YYYY-MM-DD");
    return result;
  };

  const CustomInput = (
    props: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>
  ) => (
    <input
      {...props}
      placeholder={date ? DateFormet(date) : "날짜를 입력하세요"}
      className={date ? "datepick" : "date"}
    />
  );

  return (
    <>
      <DatePicker
        maxDate={new Date()}
        locale={ko}
        onChange={(date) => setStartDate(DateFormet(date))}
        customInput={React.createElement(React.forwardRef(CustomInput))}
      />
    </>
  );
}
