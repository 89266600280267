import GradientButton from "@components/button/GradientButton";
import CircleCheckRow from "@components/core/list/CircleCheckRow";
import ModalContainer from "@components/core/modal/ModalContainer";
import {
  useGetImjangApplicantDetail,
  useGetImjangStatus,
  usePatchImjangStatus,
} from "@hooks/imjang";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

export default function ProgressModal({ isVisible, setIsVisible }) {
  const { id } = useParams();
  const { data } = useGetImjangStatus({ filter: "detail" });
  const { data: detailData } = useGetImjangApplicantDetail({ id });
  const [selected, setSelected] = useState<null | string>(null);
  const [step, setStep] = useState(0);
  const [text, setText] = useState("");
  const [checkBoon, setCheckBoon] = useState(true);
  const { mutate } = usePatchImjangStatus({ id: detailData?.applyId });

  const handleSelect = (id: string) => {
    setSelected(id);
  };

  const handleNext = () => {
    if (step === 0) {
      if (selected !== "COMPLETED") {
        mutate(
          { status: selected },
          {
            onSuccess: () => {
              setIsVisible(false);
            },
          }
        );
      } else {
        setStep(1);
      }
    }
    if (step === 1) {
      mutate(
        {
          status: "COMPLETED",
          isRewarded: checkBoon,
          reward: text,
        },
        {
          onSuccess: () => {
            setIsVisible(false);
          },
        }
      );
    }
  };

  const handleBoon = () => {
    if (checkBoon) {
      setCheckBoon(false);
    } else {
      setCheckBoon(true);
    }
  };

  useEffect(() => {
    if (detailData?.state) {
      setSelected(detailData?.state);
    }
  }, [detailData]);

  useEffect(() => {
    setStep(0);
    setText("");
  }, [isVisible]);

  const isDone = useMemo(() => {
    if (step === 0) {
      return true;
    }
    if (step === 1) {
      if (checkBoon) {
        if (text.length === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }, [checkBoon, step, text]);

  return (
    <ModalContainer
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      title={step === 0 ? "진행 상태 변경" : "혜택 지급 여부"}
      onCloseButton
      bottomButton={
        <GradientButton
          text={step === 0 ? "확인" : "완료"}
          isDone={isDone}
          onClick={handleNext}
        />
      }
    >
      <Content step={step}>
        {step === 0 &&
          data?.map((item, index) => {
            const check = selected === item.value;
            return (
              <CircleCheckRow
                text={item.name}
                key={index}
                active={check}
                onClick={() => handleSelect(item.value)}
              />
            );
          })}
        {step === 1 && (
          <>
            <CircleCheckRow
              text="혜택 지급 완료"
              active={checkBoon}
              onClick={handleBoon}
            />
            <TextArea
              placeholder="지급한 혜택 입력"
              onClick={() => setCheckBoon(true)}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <CircleCheckRow
              text="지급하지 않음"
              active={!checkBoon}
              onClick={handleBoon}
            />
          </>
        )}
      </Content>
    </ModalContainer>
  );
}

const Content = ({ step, children }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
    }
    if (step === 1) {
      contentRef.current.style.height = `264px`;
    }
  }, [step]);

  return <ModalBody ref={contentRef}>{children}</ModalBody>;
};

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 360px;
  padding-bottom: 30px;
  overflow-y: auto;
  transition: height 0.5s ease;
`;

const TextArea = styled.textarea`
  width: 90%;
  height: 100px;
  margin: 0 8px 16px 0;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};
  border-radius: 8px;
  resize: none;
  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.3px;
`;
