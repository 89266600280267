export default function Delete28() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_10662_89969)">
        <path
          d="M6.125 9.625H21.875V22.75C21.875 23.7158 21.0908 24.5 20.125 24.5H7.875C6.90917 24.5 6.125 23.7158 6.125 22.75V9.625Z"
          fill="#B2BEC9"
        />
        <path
          d="M23.625 6.125H4.375C3.89175 6.125 3.5 6.51675 3.5 7V8.75C3.5 9.23325 3.89175 9.625 4.375 9.625H23.625C24.1082 9.625 24.5 9.23325 24.5 8.75V7C24.5 6.51675 24.1082 6.125 23.625 6.125Z"
          fill="#B2BEC9"
        />
        <path
          d="M18.375 7H9.625V5.25C9.625 4.76674 10.0167 4.375 10.5 4.375H17.5C17.9833 4.375 18.375 4.76674 18.375 5.25V7ZM18.375 2.625H9.625C8.65848 2.625 7.875 3.40848 7.875 4.375V7C7.875 7.96653 8.65848 8.75 9.625 8.75H18.375C19.3415 8.75 20.125 7.96653 20.125 7V4.375C20.125 3.40848 19.3415 2.625 18.375 2.625Z"
          fill="#B2BEC9"
        />
        <path
          d="M19.25 16.625C19.25 16.1418 18.8582 15.75 18.375 15.75C17.8918 15.75 17.5 16.1418 17.5 16.625V21.875C17.5 22.3582 17.8918 22.75 18.375 22.75C18.8582 22.75 19.25 22.3582 19.25 21.875V16.625Z"
          fill="#8E9FAE"
        />
        <path
          d="M14.875 16.625C14.875 16.1418 14.4832 15.75 14 15.75C13.5168 15.75 13.125 16.1418 13.125 16.625V21.875C13.125 22.3582 13.5168 22.75 14 22.75C14.4832 22.75 14.875 22.3582 14.875 21.875V16.625Z"
          fill="#8E9FAE"
        />
        <path
          d="M10.5 16.625C10.5 16.1418 10.1082 15.75 9.625 15.75C9.14175 15.75 8.75 16.1418 8.75 16.625V21.875C8.75 22.3582 9.14175 22.75 9.625 22.75C10.1082 22.75 10.5 22.3582 10.5 21.875V16.625Z"
          fill="#8E9FAE"
        />
      </g>
      <defs>
        <clipPath id="clip0_10662_89969">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
