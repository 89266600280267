import { Checked22 } from "@components/core/icon/checked";
import { CheckStatusEnum } from "@components/core/icon/checked/Checked.interfaces";
import { usePatchCallChecked } from "@hooks/imjang";
import { debounce } from "@utils/debounce";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

export default function CheckingCall({ callChecked, applyId, scheduleId }) {
  const [active, setActive] = useState(callChecked || false);
  const { mutate } = usePatchCallChecked({ applyId, scheduleId });

  const 변경_디바운스 = useCallback(
    debounce((active) => {
      mutate(
        { callChecked: active },
        { onError: (res) => alert(`변경 실패 ${res.response.data["message"]}`) }
      );
    }, 700),
    []
  );

  const 통화_state_변경 = () => {
    setActive((prev) => !prev);
  };

  useEffect(() => {
    변경_디바운스(active);
  }, [active]);

  return (
    <Container onClick={통화_state_변경}>
      <CheckBox $active={active}>
        <Checked22
          status={active ? CheckStatusEnum.Activate : CheckStatusEnum.Default}
        />
        <p>통화 완료</p>
      </CheckBox>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const CheckBox = styled.div<{ $active: boolean }>`
  display: flex;
  gap: 4px;
  padding: 8px 16px 8px 12px;
  width: fit-content;
  align-items: center;
  border-radius: 10px;
  border: 1px solid
    ${({ theme, $active }) =>
      $active ? theme.colors.primaryBlue100 : theme.colors.blueGrayA200};

  p {
    color: ${({ theme, $active }) =>
      $active ? theme.colors.primaryBlue100 : theme.colors.blueGray700};
  }
`;
