import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import ConsultingReportUploadInput from "@components/page/influencer/consultation/consulting/ConsultingReportUploadInput";
import { Title } from "@components/styles";
import {
  useGetApplicantsForReport,
  usePostConsultingReport,
} from "@hooks/consultation";
import { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

export default function UploadReport() {
  const navigate = useNavigate();
  const { data } = useGetApplicantsForReport({ isSubmitted: false });
  const { mutate, isLoading } = usePostConsultingReport();
  const [consultingReport, setConsultingReport] = useState({
    applicantId: "",
    notionUrl: "",
  });

  const listData = useMemo(() => {
    if (data) {
      const arr = data.applicants.map((el) => {
        return { ...el, label: el.report.title, value: el.applicantId };
      });
      return arr;
    } else return [{ label: "", value: "" }];
  }, [data]);

  const onSubmit = () => {
    mutate(consultingReport, {
      onSuccess: (res) => {
        alert("컨설팅 보고서를 제출했습니다.");
        navigate(-1);
      },
    });
  };

  return (
    <Container>
      <Title>보고서 보내기</Title>
      <div style={{ height: 24 }} />
      <ConsultingReportUploadInput
        consultingReport={consultingReport}
        setConsultingReport={setConsultingReport}
        listData={listData}
        onSubmit={onSubmit}
      />
      {isLoading ? <AbsoluteFillLoading /> : null}
    </Container>
  );
}

const Container = styled.div``;
