export enum InputStatusEnum {
  Default = "default",
  Focus = "focus",
  Active = "active",
  Error = "error",
  Completion = "completion",
  Disabled = "disabled",
}

export enum InputTypeEnum {
  OnlyText = "only text",
  Number = "number",
  Cash = "cash",
  Decimal = "decimal",
}

export type ValueByInputType<T extends InputTypeEnum> =
  T extends InputTypeEnum.Cash ? number | null : string | null;

export const labelColorObj = {
  [InputStatusEnum.Default]: "#666E7A",
  [InputStatusEnum.Focus]: "#1C84FF",
  [InputStatusEnum.Active]: "#1C84FF",
  [InputStatusEnum.Error]: "#F04452",
  [InputStatusEnum.Completion]: "#666E7A",
  [InputStatusEnum.Disabled]: "#666E7A",
};

export const borderColorObj = {
  [InputStatusEnum.Default]: "#E5E7E9",
  [InputStatusEnum.Focus]: "#80BAFF",
  [InputStatusEnum.Active]: "#80BAFF",
  [InputStatusEnum.Error]: "#F04452",
  [InputStatusEnum.Completion]: "#E5E7E9",
  [InputStatusEnum.Disabled]: "#E5E7E9",
};

export const darkBorderColorObj = {
  [InputStatusEnum.Default]: "#1b1a21",
  [InputStatusEnum.Focus]: "#80BAFF",
  [InputStatusEnum.Active]: "#80BAFF",
  [InputStatusEnum.Error]: "#F04452",
  [InputStatusEnum.Completion]: "#1b1a21",
  [InputStatusEnum.Disabled]: "#F0F2F5",
};

export const borderBottomColorObj = {
  [InputStatusEnum.Focus]: "#1C84FF",
  [InputStatusEnum.Active]: "#1C84FF",
  [InputStatusEnum.Disabled]: "#F0F2F5",
  [InputStatusEnum.Completion]: "#F5F5F5",
  [InputStatusEnum.Default]: "#F5F5F5",
  [InputStatusEnum.Error]: "#F04452",
};
