import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import BTypeInputBoxTyping from "@components/core/BTypeInput/BTypeInputBoxTyping";
import { useConsultantInfo } from "@features/useConsultantInfo";
import { useEffect, useState } from "react";
import styled from "styled-components";

export default function KeywordsInputPopUp({ setIsVisible }) {
  const { globalConsultantInfo, setGlobalConsultantInfo } = useConsultantInfo();
  const [keywordsArr, setKeywordsArr] = useState(["", "", "", ""]);

  useEffect(() => {
    // 길이 4개 유지
    if (globalConsultantInfo.keywords.length > 0) {
      const arr = [];

      for (let i = 0; i <= 3; i++) {
        arr.push(globalConsultantInfo.keywords[i] || "");
      }
      setKeywordsArr(arr);
    } else {
      return;
    }
  }, []);

  const onChange = (idx, v) => {
    if (v.length > 10) return;

    let copyArr = [...keywordsArr];
    copyArr[idx] = v;
    setKeywordsArr(copyArr);
  };

  const handleSaveBtn = () => {
    // 공백 제거
    const newArr = keywordsArr.filter((el) => el);
    setGlobalConsultantInfo({ keywords: newArr });
    setIsVisible(false);
  };

  return (
    <Container>
      <Title>키워드 추가</Title>
      <div style={{ height: 20 }} />
      {keywordsArr.map((el, idx) => (
        <BTypeInputBoxTyping
          key={idx}
          inputType={InputTypeEnum.OnlyText}
          placeholder={"컨설팅 키워드(최대10글자)"}
          value={keywordsArr[idx]}
          onChangeValue={(v: InputTypeEnum.OnlyText) => onChange(idx, v)}
          style={{ padding: 0, flex: 1, marginBottom: 10 }}
          inputStyle={{ paddingTop: 11, paddingBottom: 11 }}
        />
      ))}
      <Btn onClick={handleSaveBtn}>
        <p>저장</p>
      </Btn>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  width: 368px;
  border-radius: 20px;
  background-color: white;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blueGray800};
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;

const Btn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  padding: 9px 0;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primaryBlue100};

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;
