import { Props } from "./Checked.interfaces";

const lineColor = {
  Disabled: "#CCCFD3",
  Default: "#CCE3FF",
  Activate: "#1C84FF",
  Destructive: "#F04452",
};

export default function Checked22({ status }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
    >
      <path
        d="M18.0417 6.875L8.41675 15.6406L4.29175 12.375"
        stroke={lineColor[status]}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
