import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import styled from "styled-components";

export default function ImjangApplyReason({ data }: { data: string }) {
  return (
    <Container>
      <SubTitle>임장 선택 이유</SubTitle>
      <Divider />
      <BTypeTextArea
        placeholder=""
        textareaStyle={{
          maxWidth: 1800,
          maxHeight: 200,
          height: 300,
          marginTop: 24,
        }}
        value={data || "-"}
        disabled={true}
        onChangeValue={() => {}}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const SubTitle = styled.h2`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;

const Divider = styled.div`
  height: 2px;
  max-width: 1800px;
  background-color: #666e7a;
`;
