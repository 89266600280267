import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import BTypeInputBoxTyping from "@components/core/BTypeInput/BTypeInputBoxTyping";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";

import { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import DateInputBtnBox from "@components/core/input/DateBoxBtnInput";
import RegionBoxBtnInput from "@components/core/input/RegionBoxBtnInput";

export default function ExpertInput({ expertInfo, setExpertInfo, onSubmit }) {
  const inputRef_1 = useRef<HTMLInputElement>(null);
  const inputRef_2 = useRef<HTMLInputElement>(null);

  const autoHyphen2 = (v) => {
    if (!v) {
      return;
    }

    const result = v
      .slice(0, 13)
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/(\-{1,2})$/g, "");

    return result;
  };

  const isDone = useMemo(() => {
    return Boolean(
      expertInfo.name && expertInfo.officeName && expertInfo.firstPhoneNumber
    );
  }, [expertInfo]);

  return (
    <Container>
      <div className="row">
        <RegionBoxBtnInput
          label={"지역"}
          placeholder={"지역"}
          value={expertInfo.region}
          onChangeValue={(v) =>
            setExpertInfo((prev) => ({ ...prev, region: v }))
          }
          style={{ flex: 1 }}
        />
        <DateInputBtnBox
          label={"방문 날짜"}
          value={expertInfo.visitDate}
          onChangeValue={(v) =>
            setExpertInfo((prev) => ({ ...prev, visitDate: v }))
          }
          style={{ flex: 1 }}
        />
      </div>
      <div className="row">
        <BTypeInputBoxTyping
          inputRef={inputRef_1}
          inputType={InputTypeEnum.OnlyText}
          label={"이름"}
          placeholder={"이름"}
          value={expertInfo.name}
          onChangeValue={(v: InputTypeEnum.OnlyText) =>
            setExpertInfo((prev) => ({ ...prev, name: v }))
          }
          style={{ flex: 1 }}
        />
        <BTypeInputBoxTyping
          inputRef={inputRef_1}
          inputType={InputTypeEnum.OnlyText}
          label={"중개사무소 명"}
          placeholder={"중개사무소 명"}
          value={expertInfo.officeName}
          onChangeValue={(v: InputTypeEnum.OnlyText) =>
            setExpertInfo((prev) => ({ ...prev, officeName: v }))
          }
          style={{ flex: 1 }}
        />
      </div>
      <div className="row">
        <BTypeInputBoxTyping
          inputRef={inputRef_2}
          inputType={InputTypeEnum.OnlyText}
          label={"연락처"}
          placeholder={"연락처"}
          value={autoHyphen2(expertInfo.firstPhoneNumber)}
          onChangeValue={(v: InputTypeEnum.OnlyText) =>
            setExpertInfo((prev) => ({ ...prev, firstPhoneNumber: v }))
          }
          style={{ flex: 1 }}
        />
        <BTypeInputBoxTyping
          inputRef={inputRef_2}
          inputType={InputTypeEnum.OnlyText}
          label={"대체 연락처"}
          placeholder={"대체 연락처"}
          value={autoHyphen2(expertInfo.secondPhoneNumber)}
          onChangeValue={(v: InputTypeEnum.OnlyText) =>
            setExpertInfo((prev) => ({ ...prev, secondPhoneNumber: v }))
          }
          style={{ flex: 1 }}
        />
      </div>
      <BTypeTextArea
        label="기타메모"
        placeholder="기타메모를 작성하세요."
        textareaStyle={{ height: 200 }}
        value={expertInfo.memo}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setExpertInfo((prev) => ({ ...prev, memo: v }))
        }
      />
      <div style={{ height: 20 }} />
      <Button $isDone={isDone} onClick={onSubmit} disabled={!isDone}>
        완료
      </Button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  min-width: 1000px;
  max-width: 1000px;
  flex: 1;

  .row {
    display: flex;
    gap: 40px;
  }
`;

const Button = styled.button<{ $isDone: boolean }>`
  padding: 10px 35px;
  width: 100%;
  border-radius: 8px;
  border: none;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;

  color: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.white : theme.colors.blueGray500};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;

  &:disabled {
    cursor: not-allowed;
  }
`;
