import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { Fragment, useEffect, useState } from "react";
import addIcon from "@assets/icon/addUser.png";
import deleteIcon from "@assets/icon/deleteUser.png";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useDeleteConsulting,
  useGetConsultingList,
  usePostLiveDB,
} from "@hooks/consultation";
import ConsultingTable from "@components/page/admin/consultation/consulting/ConsultingTable";
import useCustomSearchParams from "@hooks/useCustomSearchParams";

// 상담소 컨설팅 관리
// 보여주는 DB의 종류는 url의 db 쿼리로 관리중
export default function ConsultingList() {
  const navigate = useNavigate();
  const { searchParams, setSearchParams } = useCustomSearchParams();
  const DB_VERSION = searchParams.db || "dev";

  const [selectedRow, setSelectedRow] = useState("");
  const { data } = useGetConsultingList({ dbVersion: DB_VERSION });
  const { mutate: deleteMutate } = useDeleteConsulting({
    dbVersion: DB_VERSION,
  });

  const 컨설팅_추가 = () => {
    navigate(`/consultation/consulting/add?db=${DB_VERSION}`);
  };

  const 컨설팅_삭제 = () => {
    if (window.confirm("선택한 컨설팅을 삭제합니다.")) {
      deleteMutate({ consultingId: selectedRow });
    }
  };

  return (
    <Container>
      <Title>상담소 컨설팅 관리</Title>
      <ControlContainer>
        <Toggle>
          {[
            { title: "라이브 DB 리스트", value: "live" },
            { title: "개발 DB 리스트", value: "dev" },
          ].map((el, idx) => (
            <Fragment key={idx}>
              <ToggleText
                $active={DB_VERSION === el.value}
                onClick={() => {
                  setSearchParams({ db: el.value }, true);
                }}
              >
                {el.title}
              </ToggleText>
              {el.value === "live" && <span />}
            </Fragment>
          ))}
        </Toggle>

        <ButtonWrapper>
          <img
            onClick={컨설팅_추가}
            src={addIcon}
            alt="plusIcon"
            style={{ width: 32, height: 32 }}
          />
          <div style={{ width: 20 }} />
          <img
            onClick={컨설팅_삭제}
            src={deleteIcon}
            alt="minusIcon"
            style={{ width: 32, height: 32 }}
          />
        </ButtonWrapper>
      </ControlContainer>
      <ConsultingTable
        setSelectedRow={setSelectedRow}
        data={data?.consultings || []}
      />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;

const ControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  min-height: 32px;
`;

const Toggle = styled.div`
  display: flex;
  align-items: center;

  span {
    margin: 0 12px;
    width: 1px;
    height: 14px;
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }
`;

const ToggleText = styled.h5<{ $active: boolean }>`
  color: ${({ theme, $active }) =>
    $active ? theme.colors.blue1000 : theme.colors.blueGray400};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

const UploadBtn = styled.div`
  margin-top: 20px;
  margin-right: 10px;
  padding: 9px 32px;
  background-color: ${({ theme }) => theme.colors.blue100};
  width: fit-content;
  border-radius: 8px;
  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.primaryBlue100};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;
