import moment from "moment";
import "moment/locale/ko";
import styled from "styled-components";

export default function SendPreview({ pushInfo }) {
  return (
    <Container>
      <Device>
        <img
          className="bgImg"
          src={require("../../assets/배경화면.png")}
          alt="핸드폰 배경화면"
        />
        <div className="timeInfo">
          <h5 className="date">{moment(new Date()).format("M월 DD일 dddd")}</h5>
          <h3 className="clock">{moment(new Date()).format("HH:mm")}</h3>
        </div>
        <MessageBox>
          <img
            className="icon"
            src={require("../../assets/appLogo.png")}
            alt="앱 썸네일"
            width={35}
            height={35}
            style={{ marginRight: 8 }}
          />
          <p className="time">지금</p>
          <div className="column">
            <p className="title">{pushInfo.push_title || "Title"}</p>
            <p className="description">{pushInfo.push_body}</p>
          </div>
        </MessageBox>
      </Device>
    </Container>
  );
}

const Container = styled.div`
  @media only screen and (max-width: 1440px) {
    .bgImg {
      width: 324px;
    }
  }

  @media only screen and (min-width: 1441px) {
    .bgImg {
      width: 375px;
    }
  }
`;

const Device = styled.div`
  position: relative;
  width: fit-content;

  .timeInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: absolute;
    top: 90px;
    left: -10px;
  }

  .date {
    color: var(--White, #fff);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.2px;
  }

  .clock {
    color: var(--White, #fff);
    text-align: center;
    font-size: 84px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.2px;
  }

  @media only screen and (max-width: 1440px) {
    .timeInfo {
      left: 0;
    }

    .clock {
      font-size: 72px;
    }

    .date {
      font-size: 16px;
    }
  }
`;

const MessageBox = styled.div`
  position: absolute;
  top: 30%;
  left: 13px;

  display: flex;
  /* flex: 1; */
  align-items: center;
  padding: 11px;

  width: 90%;
  min-height: 60px;
  border-radius: 20px;
  background-color: rgba(230, 234, 234, 0.8);

  .column {
    display: flex;
    width: 85%;
    flex-direction: column;
    justify-content: center;
  }

  .title {
    width: 90%;
    color: ${({ theme }) => theme.colors.blueGray900};
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .description {
    margin-top: 2px;
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 13px;
    font-weight: 500;
    line-height: 16px; /* 123.077% */
    white-space: break-spaces;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }

  .time {
    position: absolute;
    right: 18px;
    top: 12px;
    color: #919596;
    text-align: right;
    font-size: 11px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;
  }

  @media only screen and (max-width: 1440px) {
    .icon {
      width: 30px;
      height: 30px;
    }
    .title {
      font-size: 11.5px;
    }

    .description {
      font-size: 11px;
      line-height: 13px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.blueGray700};
    }

    .time {
      font-size: 10px;
    }

    min-height: 48px;
    padding: 10px;
    left: 11px;
  }
`;
