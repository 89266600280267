import { Close16 } from "@components/core/icon/close";
import { Plus24 } from "@components/core/icon/plus";
import { usePostPresignedUrl } from "@hooks/consultation";
import { useRef, useState } from "react";
import styled from "styled-components";

// pdf 가능
export default function FileUploadInput({
  label,
  value,
  folderName,
  onChange,
  style,
}) {
  const fileInput = useRef(null);
  const { mutate } = usePostPresignedUrl();
  const [isActive, setActive] = useState(false);
  const handleDragStart = () => setActive(true);
  const handleDragEnd = () => setActive(false);
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    handleChange(file);
    setActive(false);
  };

  const openWindow = (file) => {
    let blob;
    if (value.fileName) {
      blob = URL.createObjectURL(file);
      window.open(blob, "_blank", "noopener, noreferrer");
    } else {
      window.open(file);
    }

    return () => {
      // 컴포넌트가 언마운트될 때 Blob URL을 해제
      URL.revokeObjectURL(blob);
    };
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleChange = (file) => {
    if (!file) {
      return;
    } else {
      mutate(
        { folderName, fileName: file.name },
        {
          onSuccess: (res) =>
            onChange({
              presignedUrl: res[0],
              data: {
                fileName: file.name,
                file: file,
              },
            }),
        }
      );
    }
  };

  const handleDelete = () => {
    onChange({
      presignedUrl: null,
      data: {
        fileName: "",
        file: null,
      },
    });
  };

  const EmptyThumbnail = () => {
    return (
      <Container style={{ ...style }}>
        <Label>{label}</Label>
        <InputBox
          onClick={handleClick}
          onDragEnter={handleDragStart} // dragstart 핸들러 추가
          onDragLeave={handleDragEnd} // dragend 핸들러 추가
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          $isActive={isActive}
        >
          <Plus24 />
          <p>파일 업로드</p>
        </InputBox>
      </Container>
    );
  };

  return (
    <>
      {value.fileName ? (
        <Container>
          <Label>{label}</Label>
          <div style={{ height: 20 }} />
          <ClickableText>
            <p onClick={() => openWindow(value)}>{value.fileName}</p>
            <span onClick={handleDelete}>
              <Close16 />
            </span>
          </ClickableText>
          <BtnBox onClick={handleClick}>
            <p>변경하기</p>
          </BtnBox>
        </Container>
      ) : (
        <EmptyThumbnail />
      )}

      <Input
        // onDrop={handleDrop}
        type="file"
        accept="image/jpg, image/jpeg, image/png, .pdf"
        multiple
        ref={fileInput}
        onChange={(e) => {
          e.preventDefault();
          handleChange(e.target.files[0]);
        }}
        style={{ display: "none" }}
        alt="Preview"
      />
    </>
  );
}

const Container = styled.div`
  position: relative;
  padding: 32px 0 28px;
`;

const Label = styled.h5`
  color: ${({ theme }) => theme.colors.blueGray600};
  font-size: 15px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;

const InputBox = styled.div<{ $isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  min-height: 160px;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.blueGray200 : theme.colors.blueGrayA100};
  /* transform: ${({ $isActive }) =>
    $isActive ? "scale(1.02)" : "scale(1)"}; */
  border: ${({ $isActive }) => ($isActive ? "1px dashed black" : "none")};
  border-radius: 8px;
  cursor: pointer;

  p {
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;

const Input = styled.input``;

const ClickableText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;

  p {
    margin-right: 5px;
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;
    text-decoration-line: underline;
  }
`;

const BtnBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 20px;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: var(--White, #fff);
  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;
