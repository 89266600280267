import styled from "styled-components";
import AmountBox from "./AmountBox";
import AccountingDetailTable from "./AccountingDetailTable";
import { Calendar } from "../contents";

export default function AccountDetailContent({ data, setRangeDate }) {
  return (
    <Container>
      <HeaderRow>
        <AmountBox data={data.totalPayments} />
        <Calendar setRangeDate={setRangeDate} text="기준: 정산날짜" />
      </HeaderRow>

      <AccountingDetailTable data={data.influencers} />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
  margin-bottom: 40px;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 38px;
`;
