import moment from "moment";
import styled from "styled-components";

export const CustomYAxisTick = (props) => {
  const { x, y, payload, side } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={5}
        textAnchor={side === "right" ? "start" : "end"}
        fill="#4D5664"
        fontSize={15}
        fontWeight={500}
      >
        {payload.value.toLocaleString()}
      </text>
    </g>
  );
};

export const CustomXAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={15}
        textAnchor="middle"
        fill="#666"
        fontSize={15}
        fontWeight={500}
      >
        {payload.value ? `${moment(payload.value).format("M")}월` : ""}
      </text>
    </g>
  );
};

export const CustomDot = (props) => {
  const { cx, cy, color } = props;
  const today = moment();

  return today.format("YYYY-MM-DD") === props.payload.date ? (
    <svg x={cx - 4} y={cy - 4} width={8} height={8} viewBox="0 0 8 8">
      <circle cx="4" cy="4" r="4" transform="rotate(-180 4 4)" fill={color} />
    </svg>
  ) : null;
};

export interface Payload {
  payload: { views: number; likes: number; date: string };
  stroke: string;
  value: number;
}
export interface CustomTooltipProps {
  active?: boolean;
  payload?: Payload[];
  label?: string;
}

export const CustomTooltip = ({
  active,
  payload,
  label,
}: CustomTooltipProps) => {
  if (active && payload && payload.length && label) {
    return (
      <TooltipBox>
        <h4>{moment(label).format("YYYY년 MM월 DD일")}</h4>
        <TooltipRow style={{ marginBottom: 12 }}>
          <p>누적 추천 수</p>
          <span style={{ color: "#1C84FF" }}>
            {payload[0].payload.likes.toLocaleString()}
          </span>
        </TooltipRow>
        <TooltipRow>
          <p>누적 조회 수</p>
          <span>{payload[0].payload.views.toLocaleString()}</span>
        </TooltipRow>
      </TooltipBox>
    );
  }
};

const TooltipBox = styled.div`
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #cccfd3;
  background: #fff;
  h4 {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
    color: ${({ theme }) => theme.colors.blueGray900};
  }
`;

const TooltipRow = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: ${({ theme }) => theme.colors.blueGray600};
  }
  span {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: ${({ theme }) => theme.colors.blueGray700};
  }
`;
