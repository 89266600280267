import { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import {
  borderColorObj,
  InputStatusEnum,
  InputTypeEnum,
} from "../BTypeInput.interfaces";

interface Props {
  inputType?: InputTypeEnum;
  // label: string;
  value: any;
  placeholder: string;
  onChangeValue: (v: any) => void;
  handleFocus?: () => void;
  handleBlur?: () => void;
  disabled?: boolean;
  optionList: { label: string; value: any }[];
}

export default function BTypeBtn48({
  value = null,
  placeholder,
  onChangeValue,
  handleFocus = () => {},
  handleBlur = () => {},
  disabled = false,
  optionList = [],
}: Props) {
  const selectRef = useRef(null);
  const [status, setStatus] = useState<InputStatusEnum>(
    InputStatusEnum.Default
  );

  const onFocus = () => {
    setStatus(value ? InputStatusEnum.Active : InputStatusEnum.Focus);
    handleFocus && handleFocus();
  };

  const onBlur = (e) => {
    e.preventDefault();
    setStatus(value ? InputStatusEnum.Completion : InputStatusEnum.Default);
    handleBlur && handleBlur();
  };

  const 클릭 = () => {
    selectRef.current.focus();
  };

  return (
    <InputContainer
      className={[
        status === InputStatusEnum.Disabled ? "disabledBG" : "defaultBG",
      ].join(" ")}
      style={{
        borderColor: borderColorObj[status],
      }}
      onClick={클릭}
      $disabled={disabled}
      value={value}
    >
      <select
        ref={selectRef}
        onChange={(e) => onChangeValue(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        value={value}
      >
        {optionList.map((el, idx) => {
          if (typeof el.value === "undefined") {
            return (
              <option value={el.value} key={idx} disabled selected>
                {el.label}
              </option>
            );
          } else
            return (
              <option value={el.value} key={idx}>
                {el.label}
              </option>
            );
        })}
      </select>
    </InputContainer>
  );
}

const InputContainer = styled.div<{
  value: string | number;
  $disabled: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  height: 48px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background-color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.blueGray100 : theme.colors.blueGrayA100};

  select {
    width: 100%;
    border: none;
    outline: none;
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.blueGray100 : theme.colors.blueGrayA100};
    color: ${({ theme, value }) =>
      value ? theme.colors.blueGray800 : theme.colors.blueGray500};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;
