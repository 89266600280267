import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Title } from "@components/styles";
import { CirclePlus22 } from "@components/core/icon/plus";
import { useGetUserList } from "@api/user";
import { useGetUserGroupList } from "@api/permission";
import Empty from "@components/common/Empty";
import ManualPagination from "@components/core/paging/ManualPagination";
import { WhitespaceEnum } from "@components/core/icon/whiteSpace/WhiteSpace.interfaces";
import UsersList from "./UsersList";
import LineTabButton from "./LineTabButton";
import DropDown, {
  dropDownTypeEnum,
} from "@components/dropdown/DropDownButton";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import InviteCodePopup from "./InviteCodePopup";

const tabData = [
  { title: "전체 회원", value: "전체" },
  { title: "미승인 회원", value: "미승인" },
  { title: "비활성 회원", value: "비활성" },
];

export default function UserListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page")) || 1;
  const [tabStatus, setTabStatue] = useState("전체");
  const [userGroup, setUserGroup] = useState("전체 회원");
  const [isVisible초대코드, setIsVisible초대코드] = useState(false);

  const { data: userGroupData } = useGetUserGroupList();
  const { data: userData } = useGetUserList({
    user_group: userGroup,
    inActive: tabStatus === "비활성",
    offset: (page - 1) * 16,
    limit: 16,
  });

  const userGroupList = useMemo(() => {
    if (!userGroupData) return [{ title: "전체 회원", value: "전체 회원" }];
    const userGroupArr = userGroupData.map((el) => {
      return { title: el, value: el };
    });
    return [{ title: "전체 회원", value: "전체 회원" }, ...userGroupArr];
  }, [userGroupData]);

  useEffect(() => {
    // 탭 메뉴 3개가 동일한 급이 아님
    // 리스트는 userGroup에 의존, userGroup은 tabStatus에 의존
    setSearchParams({ page: "1" });
    if (tabStatus === "미승인") {
      setUserGroup("미승인");
    } else if (tabStatus === "전체") {
      setUserGroup("전체 회원");
    }
  }, [tabStatus]);

  return (
    <Container>
      <Title>전체 회원 관리</Title>
      <AddButton onClick={() => setIsVisible초대코드(true)}>
        <CirclePlus22 />
        <p>초대코드 생성</p>
      </AddButton>
      <PopUpContainer isVisible={isVisible초대코드} setIsVisible={() => {}}>
        <InviteCodePopup handleClose={() => setIsVisible초대코드(false)} />
      </PopUpContainer>

      <LineTabButton
        tabData={tabData}
        tabStatus={tabStatus}
        setTabStatue={setTabStatue}
      />
      <DropDown
        optionList={userGroupList}
        selectedValue={userGroup}
        onChangeValue={setUserGroup}
        type={dropDownTypeEnum.B}
        scrollHeight={200}
        style={{
          marginBottom: 20,
          display: tabStatus === "전체" ? "inline-block" : "none",
        }}
      />
      {!userData || !userData.user_list.length ? (
        <Empty
          title={`${tabStatus} 회원이 없어요`}
          iconType={WhitespaceEnum.Default}
        />
      ) : (
        <UsersList tabStatus={tabStatus} data={userData} />
      )}
      <ManualPagination total={userData?.total_count || 0} itemPerPage={16} />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const AddButton = styled.button`
  position: absolute;
  right: 0;
  top: 21px;

  display: flex;
  gap: 9.5px;
  padding: 9px 12px 9px 10px;
  height: 40px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.primaryBlue100};

  p {
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;
