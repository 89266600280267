import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import DownArrow from "@assets/arrow/DownArrow";

import { DayPickCalender } from "../calendar";
import { BtnInput, DateInput } from "../BTypeInput/BTypeInputBoxBtn.interfaces";

export default function DateInputBtnBox({
  label,
  value,
  disabled,
  style,
  inputStyle,
  onChangeValue,
}: DateInput) {
  const [startDate, setStartDate] = useState(value || null);

  useEffect(() => {
    onChangeValue(startDate);
  }, [startDate]);

  useEffect(() => {
    if (value) {
      setStartDate(value);
    }
  }, [value]);

  return (
    <Container style={{ ...style }} value={value} $disabled={disabled}>
      {label ? <label className="label">{label}</label> : null}
      <InputContainer $disabled={disabled} style={{ ...inputStyle }}>
        {/* <p className="value">{label || value}</p> */}
        <DayPickCalender date={startDate} setStartDate={setStartDate} />
        <DownArrow />
      </InputContainer>
    </Container>
  );
}

const Container = styled.div<{
  value: string | number;
  $disabled: boolean;
}>`
  position: relative;
  padding: 22px 0 28px;

  .label {
    position: absolute;
    top: 0;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  .value {
    width: 100%;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.blueGray100 : theme.colors.blueGrayA100};

    color: ${({ theme, value }) =>
      value ? theme.colors.blueGray800 : theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }
`;

const InputContainer = styled.div<{ $disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding: 15px 16px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background-color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.blueGray100 : theme.colors.blueGrayA100};
  border-radius: 10px;
  box-sizing: content-box;

  /* &.disabledBG {
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }

  &.defaultBG {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
  } */

  input {
    width: 100%;
    font-family: Pretendard-Medium;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.blueGray900};
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
    border: none;
    outline: none;
    letter-spacing: -0.02em;

    &.date {
      caret-color: transparent;

      &::placeholder {
        color: ${({ theme }) => theme.colors.blueGray400};
        font-size: 18px;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
        letter-spacing: -0.36px;
      }
    }

    &.datepick {
      font-family: Pretendard-Medium;
      text-align: left;

      &::placeholder {
        color: ${({ theme }) => theme.colors.blueGray900};
        font-size: 18px;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
        letter-spacing: -0.36px;
      }
    }
  }
`;
