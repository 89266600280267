import { useGetImjangStatus } from "@hooks/imjang";
import { forwardRef, ReactElement } from "react";
import styled from "styled-components";

const Tooltip = forwardRef<HTMLDivElement>((props, ref) => {
  const { data } = useGetImjangStatus({ filter: "main" });

  if (!data) return null;

  return (
    <Container ref={ref}>
      <Angle>
        <svg width="36" height="32" viewBox="0 0 36 32" fill="none">
          <path
            d="M14.5359 2C16.0755 -0.666666 19.9245 -0.666666 21.4641 2L35.3205 26C36.8601 28.6667 34.9356 32 31.8564 32L4.14359 32C1.06439 32 -0.86011 28.6667 0.67949 26L14.5359 2Z"
            fill="white"
          />
        </svg>
      </Angle>
      <Table>
        <tbody>
          {data.map((item) => (
            <tr key={item.value}>
              <td>{item.name}</td>
              <td>{item.desc}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
});

export default Tooltip;

const Container = styled.div`
  position: absolute;
  bottom: -520px;
  right: -460px;
  padding: 20px;
  display: flex;
  border-radius: 16px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
  background: var(--White, #fff);
  flex-direction: column;
  z-index: 10;
`;

const Angle = styled.div`
  position: absolute;
  top: -18px;
  left: 44px;
`;

const Table = styled.table`
  width: 520px;
  tr {
    border-bottom: 1px solid var(--BlueGrayA200, #e1e4e8);
    height: 46px;
    display: flex;
    align-items: center;

    td:first-child {
      flex: 0.4;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background: var(--BlueGrayA100, #f4f6f8);
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.3px;
    }

    td:last-child {
      flex: 0.6;
      padding: 10px 12px;
      color: ${({ theme }) => theme.colors.blueGray700};
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 173.333% */
      letter-spacing: -0.3px;
    }
  }

  tr:last-child {
    border-bottom: none;
  }
`;
