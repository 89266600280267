import { useDispatch, useSelector } from "react-redux";

import { RootState } from "app/store";
import { setEditPermission, setSearchFilter } from "./imjangSlice";

export const useImjang = () => {
  const globalImjangStatus = useSelector(
    (state: RootState) => state.imjangSlice
  );

  const dispatch = useDispatch();
  const setGlobalEditPermission = (el: boolean) => {
    dispatch(setEditPermission(el));
  };

  const setGlobalSearchFilter = (el: {
    applyUserName?: string;
    madminId?: string;
  }) => {
    dispatch(setSearchFilter(el));
  };

  return {
    globalImjangStatus,
    setGlobalEditPermission,
    setGlobalSearchFilter,
  };
};
