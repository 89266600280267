export default function Naver() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
    >
      <path
        d="M10.0942 7.49292L4.90402 0H0.601562V14H5.1089V6.50708L10.2991 14H14.6016V0H10.0942V7.49292Z"
        fill="white"
      />
    </svg>
  );
}
