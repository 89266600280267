export default function Plus24() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="12" fill="#E5E7E9" />
      <rect x="11" y="6" width="2" height="12" fill="#808690" />
      <rect
        x="18"
        y="11"
        width="2"
        height="12"
        transform="rotate(90 18 11)"
        fill="#808690"
      />
    </svg>
  );
}
