import ExpertInput from "@components/page/admin/imjang/imjangExpert/ExpertInput";
import { Title } from "@components/styles";
import { usePostAgentInfo } from "@hooks/imjang";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function ExpertAdd() {
  const navigate = useNavigate();
  const [expertInfo, setExpertInfo] = useState({
    name: null,
    officeName: null,
    firstPhoneNumber: null,
    secondPhoneNumber: null,
    memo: null,
    visitDate: null,
    region: { sido: "", sigugunList: [{ sigugun: "", pnu: 0 }] },
  });
  const { mutate } = usePostAgentInfo();

  const onSubmit = () => {
    mutate(
      { ...expertInfo },
      {
        onSuccess: (res) => {
          alert("등록 성공");
          navigate(-1);
        },
      }
    );
  };

  return (
    <Container>
      <Title>중개사 추가</Title>
      <ExpertInput
        expertInfo={expertInfo}
        setExpertInfo={setExpertInfo}
        onSubmit={onSubmit}
      />
    </Container>
  );
}

const Container = styled.div``;
