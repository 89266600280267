import styled from "styled-components";

export default function AddButton({ onClick }) {
  return (
    <Container onClick={onClick}>
      <PlusIcon />
      <BtnText>추가하기</BtnText>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 20px;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

const BtnText = styled.p`
  margin-left: 6px;
  color: ${({ theme }) => theme.colors.blueGray600};
  font-size: 15px;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.3px;
`;

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect x="7" y="3" width="2" height="10" fill="#808690" />
      <rect
        x="13"
        y="7"
        width="2"
        height="10"
        transform="rotate(90 13 7)"
        fill="#808690"
      />
    </svg>
  );
};
