import styled from "styled-components";

export default function Progress({ title, count }) {
  return (
    <Container>
      <Title>
        <span>{title}</span>
        <div className="chip">
          <span>+{count}</span>
        </div>
      </Title>
      <svg width="282" height="2">
        <path
          d="M1 1H281"
          stroke="#E5E7E9"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  span {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.32px;
  }

  .chip {
    display: flex;
    margin-left: 10px;
    padding: 2px 8px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
    justify-content: center;
    align-items: center;

    span {
      color: ${({ theme }) => theme.colors.blueGray600};
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      line-height: 20px; /* 166.667% */
      letter-spacing: -0.24px;
    }
  }
`;
