import { createSlice } from "@reduxjs/toolkit";

export const initialUserInfo = {
  data: {
    basicInfo: { plannedMovingDate: "", availableCash: 0 },
    householdMemberList: [
      {
        annualSalary: 0,
        birthDate: null,
        genderCode: "",
        relationCode: "",
        id: "",
        jobCode: "",
        office: null,
      },
    ],
    jjimList: null,
    regionList: [],
  },
};

export interface BasicInfo {
  plannedMovingDate: string;
  availableCash: number;
}

export interface HouseholdMember {
  annualSalary: number;
  birthDate: string | null;
  relationCode: string;
  genderCode: string;
  id: string;
  jobCode: string;
  office: {
    address: string;
    bjdCode: number;
    firstTransportationCode: string;
    wishCommuteTimeMin: number;
    latitude: number;
    longitude: number;
  } | null;
}

export interface Region {
  sido: string; // 시도 이름
  sigugunList: Sigugun[]; // 시군구 리스트
}

// 시군구 정보에 대한 타입 정의
export interface Sigugun {
  sigugun: string; // 시군구 이름
  pnu: number; // PNU 코드
}

export interface UserInfo {
  basicInfo: BasicInfo;
  householdMemberList: HouseholdMember[];
  jjimList: null | { danjiCode: number; scaleCode: number }[];
  regionList: Region[];
}

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: initialUserInfo,
  reducers: {
    setBasicInfo: (state, action) => {
      state.data.basicInfo = { ...action.payload };
    },
    setHouseholdMemberList: (state, action) => {
      state.data.householdMemberList = [...action.payload];
    },
    setJjimList: (state, action) => {
      state.data.jjimList = action.payload;
    },
    setRegionList: (state, action) => {
      state.data.regionList = action.payload;
    },
    setUserInfo: (state, action) => {
      state.data = { ...action.payload };
    },
  },
});

export const {
  setBasicInfo,
  setHouseholdMemberList,
  setJjimList,
  setRegionList,
  setUserInfo,
} = userInfoSlice.actions;

export default userInfoSlice.reducer;
