import styled from "styled-components";

export default function AccountingStatus({ data, total }) {
  const contentsList = data[0]?._influencer?.contents;
  const 정산_완료_목록 = contentsList?.filter(
    (el) => el.content_status === "paid"
  );

  let 가장_최신_컨텐츠_정산금, 두번째_최신_컨텐츠_정산금;
  let percentage;

  if (정산_완료_목록 && 정산_완료_목록.length >= 2) {
    정산_완료_목록.sort(
      (a, b) =>
        (new Date(b.payments[0].paid_at) as any) -
        (new Date(a.payments[0].paid_at) as any)
    );

    const 가장_최신_컨텐츠 = 정산_완료_목록[0]; // 가장 최근 컨텐츠
    const 두번째_최신_컨텐츠 = 정산_완료_목록[1]; // 2번째 최근 컨텐츠

    가장_최신_컨텐츠_정산금 = 가장_최신_컨텐츠.payments[0].price;
    두번째_최신_컨텐츠_정산금 = 두번째_최신_컨텐츠.payments[0].price;

    const priceDifference = 가장_최신_컨텐츠_정산금 - 두번째_최신_컨텐츠_정산금;
    const percentageChange =
      (priceDifference / 두번째_최신_컨텐츠_정산금) * 100;
    percentage = percentageChange;
  } else if (정산_완료_목록 && 정산_완료_목록.length === 1) {
    const 가장_최신_컨텐츠 = 정산_완료_목록[0];
    가장_최신_컨텐츠_정산금 = 가장_최신_컨텐츠.payments[0].price;
  }

  let latestAveragePayment;
  let secondLatestAveragePayment;

  if (정산_완료_목록 && 정산_완료_목록.length >= 1) {
    latestAveragePayment = Math.floor(total / 정산_완료_목록.length);

    secondLatestAveragePayment =
      (total - 가장_최신_컨텐츠_정산금) / (정산_완료_목록.length - 1);
  }

  let floorPercentage;

  if (정산_완료_목록 && 정산_완료_목록.length >= 2) {
    const priceDifference = latestAveragePayment - secondLatestAveragePayment;
    floorPercentage = (priceDifference / secondLatestAveragePayment) * 100;
  }

  console.log(
    latestAveragePayment,
    secondLatestAveragePayment,
    floorPercentage
  );

  return (
    <Container>
      <AmountInfo head="총금액" body={total?.toLocaleString()} />
      <VerticalDivideLine />
      <AmountInfo
        head="최근 정산 금액"
        body={가장_최신_컨텐츠_정산금?.toLocaleString() || 0}
        chip={percentage?.toFixed(2)}
      />
      <VerticalDivideLine />
      <AmountInfo
        head="1회당 평균 정산 금액"
        body={latestAveragePayment?.toLocaleString() || 0}
        chip={floorPercentage?.toFixed(2)}
      />
    </Container>
  );
}

const AmountInfo = ({
  head,
  body,
  chip,
}: {
  head: string;
  body: number;
  chip?: number;
}) => {
  return (
    <Box>
      <p className="head">{head}</p>
      <div className="body">
        <h3 className="amountValue">{body}원</h3>
        {chip && !isNaN(chip) ? (
          <Chip color={chip > 0}>
            <p className="percent">
              {chip > 0 ? "+" : ""}
              {chip}%
            </p>
          </Chip>
        ) : null}
      </div>
    </Box>
  );
};

const Container = styled.div`
  position: relative;
  left: -40px;
  display: flex;
  align-items: center;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px 0 48px;
  padding-left: 40px;
  width: 350px;

  .head {
    margin-bottom: 4px;
    ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }

  .body {
    display: flex;
    align-items: center;
  }

  .amountValue {
    margin-right: 16px;
    ${({ theme }) => theme.colors.blueGray800};
    font-size: 32px;
    font-weight: 600;
    line-height: 42px; /* 131.25% */
    letter-spacing: -0.96px;
  }
`;

const Chip = styled.div<{ color: boolean }>`
  padding: 3px 12px;
  width: fit-content;
  height: 28px;
  border-radius: 100px;
  background-color: ${({ theme, color }) =>
    color ? theme.colors.red1000 : theme.colors.primaryBlue100};

  .percent {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;

const VerticalDivideLine = styled.div`
  margin: 0 20px;
  width: 2px;
  height: 27px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.blueGray800};
`;
