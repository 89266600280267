import styled from "styled-components";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import React, { LegacyRef } from "react";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import CalendarIcon from "@assets/icon/CalendarIcon";

export default function ModifyDay({ setStartDate, date }) {
  const CustomInput = (
    {
      onClick,
      value,
    }: {
      value: string;
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    },
    ref: LegacyRef<HTMLButtonElement>
  ) => {
    return (
      <button
        onClick={onClick}
        ref={ref}
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 40,
          padding: 0,
        }}
      >
        <Title>{convertToKoreanDate(date)}</Title>
        <CalendarIcon />
      </button>
    );
  };

  return (
    <DatePicker
      maxDate={new Date()}
      selected={date}
      dateFormat="yyyy.MM.dd"
      locale={ko}
      onChange={(day) => setStartDate(day)}
      customInput={React.createElement(React.forwardRef(CustomInput))}
    />
  );
}

const Title = styled.h2`
  margin-right: 10px;
  font-family: Pretendard-SemiBold;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px; /* 131.25% */
  letter-spacing: -0.96px;
  color: ${({ theme }) => theme.colors.blueGray800};
`;
