import { Radio24 } from "@components/core/icon/radio";
import { RadioEnum } from "@components/core/icon/radio/Radio.interfaces";
import cn from "classnames";
import styled from "styled-components";
import MovingDateInput from "./MovingDateInput";
import BTypeCash48 from "@components/input/BTypeCash48/BTypeCash48";
import ApartInput from "./ApartInput";

export default function RateInput({ rateInfo, setRateInfo }) {
  return (
    <Container>
      <TData>
        <div className="head">
          <p>카톡방 진입</p>
        </div>
        <div className={cn("body", "row")} style={{ gap: 32 }}>
          {[
            { title: "YES", value: true },
            { title: "NO", value: false || null },
          ].map((el, idx) => (
            <span
              className="radio"
              style={{ gap: 8 }}
              onClick={() =>
                setRateInfo((prev) => ({ ...prev, isKakaoJoined: el.value }))
              }
              key={idx}
            >
              <Radio24
                status={
                  rateInfo.isKakaoJoined === el.value
                    ? RadioEnum.Activate
                    : RadioEnum.Default
                }
              />
              <p>{el.title}</p>
            </span>
          ))}
        </div>
      </TData>

      <TData>
        <div className="head">
          <p>이사 날짜</p>
        </div>
        <div className="body">
          <MovingDateInput
            value={rateInfo.moveInDate}
            onChangeValue={(value) =>
              setRateInfo((prev) => ({
                ...prev,
                moveInDate: value,
              }))
            }
          />
        </div>
      </TData>

      <TData>
        <div className="head">
          <p>자산</p>
        </div>
        <div className="body">
          <BTypeCash48
            placeholder="자산"
            containerStyle={{ height: "fit-content" }}
            value={rateInfo.assetPrice}
            onChangeValue={(value) =>
              setRateInfo((prev) => ({
                ...prev,
                assetPrice: value,
              }))
            }
          />
        </div>
      </TData>

      <TData style={{ alignItems: "flex-start" }}>
        <div
          className="head"
          style={{ display: "flex", alignItems: "center", height: 48 }}
        >
          <p>대표 아파트</p>
        </div>
        <div className="body">
          <ApartInput rateInfo={rateInfo} setRateInfo={setRateInfo} />
        </div>
      </TData>
    </Container>
  );
}

const Container = styled.div`
  flex-grow: 3;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 14px 0 24px;

  .row {
    display: flex;
    align-items: center;
  }

  .radio {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 16px;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: -0.32px;
    }
  }
`;

const TData = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 48px;

  .head {
    display: flex;
    width: 140px;
    height: fit-content;

    p {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 15px;
      font-weight: 600;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.3px;
    }
  }

  .body {
    width: 312px;
  }

  .required {
    margin-left: 2px;
    color: ${({ theme }) => theme.colors.red1000};
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
  }
`;
