import styled from "styled-components";
import MasterBadge from "./MasterBadge";
import { Arrows22 } from "@components/core/icon/arrow";
import { useNavigate } from "react-router-dom";
import { ArrowDirectionEnum } from "@components/core/icon/arrow/Arrow.interfaces";
import LockIcon from "./LockIcon";
import moment from "moment";

export default function UserItem({ data, type }) {
  const navigate = useNavigate();

  switch (type) {
    case "전체":
      return (
        <Item onClick={() => navigate(data.user_id)}>
          <p className="name">{data.nickname}</p>
          <p className="email">{data.email}</p>
          <div className="group">
            {data.user_group === "마스터" && <MasterBadge />}
            <p className="groupName">{data.user_group}</p>
            {data.user_group !== "마스터" && (
              <Arrows22 direction={ArrowDirectionEnum.RIGHT} />
            )}
          </div>
        </Item>
      );
    case "미승인":
      return (
        <Item onClick={() => navigate(data.user_id)}>
          <p className="name">{data.nickname}</p>
          <p className="email">{data.email}</p>
          <p className="date">
            {moment(data.created_at).format("YYYY.MM.DD")} 가입
          </p>
        </Item>
      );
    case "비활성":
      return (
        <Item onClick={() => navigate(data.user_id)}>
          <span style={{ marginRight: 8 }}>
            <LockIcon />
          </span>
          <p className="name">{data.nickname}</p>
          <p className="email">{data.email}</p>
          <div className="group">
            <p className="groupName">{data.user_group}</p>
            <Arrows22 direction={ArrowDirectionEnum.RIGHT} />
          </div>
        </Item>
      );
  }
  return <Container></Container>;
}

const Container = styled.div``;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 12px;
  width: 469px;
  height: 56px;

  .name {
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.3px;

    &::after {
      content: "";
      display: inline-block;
      width: 1px;
      height: 12px;
      background-color: ${({ theme }) => theme.colors.blueGray400};
      margin: 0 8px;
    }
  }

  .email {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.3px;
  }

  .date {
    margin-left: auto;
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.3px;
  }

  .group {
    display: flex;
    margin-left: auto;
  }

  .groupName {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.3px;
  }
`;
