import { Title } from "@components/styles";
import { db } from "../../../../../firebase";
import {
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useEffect, useState } from "react";

import VersionInput from "./VersionInput";
import BreakTimeInput from "./BreakTimeInput";
import { toast } from "react-toastify";
import { Delete32, DeleteEnum } from "@components/core/icon/delete";
import DeleteVersionPopup from "./DeleteVersionPopup";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";

export const unixTime = (time) => {
  // seconds는 초 단위이므로 1000을 곱해 밀리초로 변환
  const milliseconds =
    time.seconds * 1000 + Math.floor(time.nanoseconds / 1000000);
  return new Date(milliseconds).toLocaleString();
};

export default function VersionDetailPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [versionData, setVersionData] = useState({});
  const [breakData, setBreakData] = useState([]);
  const [isVisible삭제팝업, setIsVisible삭제팝업] = useState(false);

  // 버전 정보 가져오기
  useEffect(() => {
    const fetchVersionData = async () => {
      const docRef = doc(db, "versions", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setVersionData({ id: docSnap.id, ...docSnap.data() });
      } else {
        alert("버전 정보가 없습니다.");
      }
    };

    fetchVersionData();
  }, [id]);

  // 브레이크 타임 정보 가져오기
  useEffect(() => {
    const docRef = doc(db, "breakTimes", id);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setBreakData(doc.data()?.data);
      } else {
        alert(
          "브레이크 타입 정보가 없습니다. firebase에서 문서를 생성해주세요."
        );
      }
    });

    // 컴포넌트 언마운트 시 구독 해제
    return () => unsubscribe();
  }, [id]);

  const 정보_수정 = async () => {
    const docRef = doc(db, "versions", id); // 수정할 문서 참조

    try {
      await updateDoc(docRef, versionData); // 문서 업데이트
      toast("문서가 성공적으로 업데이트되었습니다.");
      navigate(-1);
    } catch (error) {
      toast("문서 업데이트 중 오류가 발생했습니다: ", error);
    }
  };

  const 버전_삭제 = () => {
    setIsVisible삭제팝업(false);
    const deleteVersionData = async (collection, id) => {
      // 삭제할 문서의 참조를 생성합니다.
      const docRef = doc(db, collection, id);

      try {
        await deleteDoc(docRef); // 문서 삭제
        toast(`${collection}에서 ${id}문서가 성공적으로 삭제되었습니다.`);
      } catch (error) {
        toast("문서 삭제 중 오류가 발생했습니다:", error);
      }
    };

    deleteVersionData("versions", id);
    deleteVersionData("breakTimes", id);
    // 성공 시 실행할 로직 (onSuccess처럼)

    navigate(-1);
  };

  return (
    <Container>
      <Title>{id} 버전 정보</Title>
      <div style={{ marginTop: 40, position: "relative" }}>
        <h2 className="tableTitle">버전 정보</h2>

        <div className="buttonBox">
          <button className="button" onClick={() => setIsVisible삭제팝업(true)}>
            <Delete32 status={DeleteEnum.Default} />
          </button>
          <UploadButton $isDone={true} onClick={정보_수정}>
            <p>정보 수정</p>
          </UploadButton>
        </div>
        <VersionInput
          versionData={versionData}
          setVersionData={setVersionData}
        />
      </div>
      <div style={{ marginTop: 28 }}>
        <h2 className="tableTitle">브레이크 타임</h2>
        <BreakTimeInput data={breakData} id={id} />
      </div>
      <PopUpContainer
        isVisible={isVisible삭제팝업}
        setIsVisible={setIsVisible삭제팝업}
      >
        <DeleteVersionPopup onClick={버전_삭제} />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 0 243px;

  .tableTitle {
    padding-bottom: 12px;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 24px;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.blueGray800};
  }

  .divider {
    margin: 20px 0;
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }

  .buttonBox {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: -10px;
    right: 0;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border: 1.4px solid ${({ theme }) => theme.colors.blueGray100};
      border-radius: 8px;
    }
  }
`;

const UploadButton = styled.button<{ $isDone: boolean }>`
  padding: 9px 16px;
  width: fit-content;
  border-radius: 8px;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: ${({ theme, $isDone }) =>
      $isDone ? theme.colors.white : theme.colors.blueGray500};
  }
`;
