import styled from "styled-components";
import Lottie from "lottie-react";
import LoadingAnimation from "../../assets/lottie/Loading.json";

export default function Loading() {
  return (
    <Container>
      <Lottie animationData={LoadingAnimation} loop={true} />
      <span>정보를 불러오고 있어요</span>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;

  span {
    color: ${({ theme }) => theme.colors.blueGray700};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;
