import { toast } from "react-toastify";
import styled from "styled-components";

export default function CoinQuantity({ coinInfo, setCoinInfo }) {
  const handlePlusCoin = (number) => {
    if (coinInfo.chargeAmount + number >= 10000) {
      toast("회당 코인 충전 금액은 10,000개를 넘을 수 없습니다.");
      return;
    }

    setCoinInfo((prev) => ({
      ...prev,
      chargeAmount: prev.chargeAmount + number,
    }));
  };

  const onChangeValue = (e) => {
    const onChange = (value) =>
      setCoinInfo((prev) => ({
        ...prev,
        chargeAmount: parseInt(value),
      }));

    if (e.target.value === "") {
      onChange(0);
    } else {
      onChange(e.target.value);
    }
  };

  return (
    <Container>
      <div style={{ display: "flex", gap: 10 }}>
        <Input
          placeholder="코인 개수"
          value={coinInfo.chargeAmount}
          onChange={onChangeValue}
          maxLength={4}
        />
        <Input
          placeholder="유저에게 노출되는 지급 사유 (필수)"
          value={coinInfo.chargeItem}
          onChange={(e) => {
            setCoinInfo((prev) => ({ ...prev, chargeItem: e.target.value }));
          }}
        />
      </div>

      <Row style={{ gap: 8, marginTop: 8 }}>
        <Chip onClick={() => handlePlusCoin(10)}>+ 10코인</Chip>
        <Chip onClick={() => handlePlusCoin(50)}>+ 50코인</Chip>
        <Chip onClick={() => handlePlusCoin(100)}>+ 100코인</Chip>
      </Row>
    </Container>
  );
}

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  padding: 10px 20px;
  flex: 1;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  &:focus {
    outline: none;
  }
`;

const Chip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 36px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.blueGray50};
  cursor: pointer;

  color: ${({ theme }) => theme.colors.blueGray700};
  font-size: 14px;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
`;
