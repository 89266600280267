export default function Search15() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.562 3.55169C10.4946 2.47485 9.06822 1.8892 7.55685 1.8892C6.04548 1.8892 4.61912 2.4843 3.55172 3.55169C2.47487 4.61909 1.88921 6.04544 1.88921 7.5568C1.88921 9.06816 2.48431 10.4945 3.55172 11.5619C4.61912 12.6387 6.04548 13.2244 7.55685 13.2244C9.06822 13.2244 10.4946 12.6293 11.562 11.5619C12.6388 10.4945 13.2245 9.06816 13.2245 7.5568C13.2245 6.04544 12.6294 4.61909 11.562 3.55169ZM2.21038 2.21036C3.69341 0.736788 5.62041 0 7.55685 0V0.009446C9.49329 0.009446 11.4297 0.746234 12.9033 2.21981C15.6108 4.92729 15.831 9.18934 13.5639 12.1497L17.71 16.2958C18.1006 16.6863 18.1006 17.3195 17.71 17.71C17.3195 18.1006 16.6864 18.1006 16.2958 17.71L12.1482 13.5624C10.7947 14.5965 9.17907 15.1136 7.55685 15.1136C5.62041 15.1136 3.68396 14.3768 2.21038 12.9032C-0.736793 9.94663 -0.736793 5.16696 2.21038 2.21036Z"
        fill="#808690"
      />
    </svg>
  );
}
