import { useMemo } from "react";
import styled from "styled-components";

import DropDown from "@components/dropdown/DropDownButton";
import Search from "@pages/admin/imjang/application/components/Search";
import { useGetAuthorityUsers } from "@hooks/admin";
import useCustomSearchParams from "@hooks/useCustomSearchParams";

const statusList = [
  { title: "진행중인 고객", value: "ONGOING" },
  { title: "중단된 고객", value: "CANCELLED" },
  { title: "전체 고객", value: "ALL" },
];

export default function ApplicantSearch() {
  const { searchParams, setSearchParams } = useCustomSearchParams();
  const madminId = searchParams.madminId;
  const status = searchParams.status || "ONGOING";
  const { data: manager } = useGetAuthorityUsers({ code: "B03" });

  const list = useMemo(() => {
    if (!manager) return [];
    return manager?.map((m) => ({ title: m.name, value: m._id }));
  }, [manager]);

  const onChangeValue = (id: string) => {
    setSearchParams({ madminId: id || "" }, true);
  };

  const onChangeStatus = (status: string) => {
    setSearchParams({ status }, true);
  };

  return (
    <Container>
      <h2>임장 비서 고객 관리</h2>
      <div className="searchBar">
        <DropDown
          optionList={statusList}
          selectedValue={status}
          scrollHeight={200}
          onChangeValue={onChangeStatus}
        />
        <DropDown
          optionList={[{ title: "담당자 전체", value: "" }, ...list]}
          selectedValue={madminId || ""}
          scrollHeight={200}
          onChangeValue={onChangeValue}
        />

        <Search />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  h2 {
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.4px;
  }

  .searchBar {
    display: flex;
    margin-top: 10px;
    gap: 8px;
  }
`;
