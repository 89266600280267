export const copyText = (text: string, alertText?: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      alertText && alert(alertText);
    })
    .catch((error) => {
      console.error("복사 실패:", error);
    });
};
