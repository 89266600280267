import { useMemo } from "react";
import styled from "styled-components";

export default function TooltipPopup({ type, setIsVisible }) {
  console.log("🐙 ~ TooltipPopup ~ type:", type);
  const content = useMemo(() => {
    switch (type) {
      case "meta-title":
        return { text: "meta-Title은 브라우저 탭에\n표시되는 정보에요" };
      case "meta-description":
        return { text: "meta-Desctiption은 공유할 때\n부가설명으로 사용돼요" };
      case "slug":
        return { text: "Slug는 검색어에 잘 걸릴 수 \n있도록하는 기능이에요" };
      default:
        return { text: "" };
    }
  }, [type]);

  return (
    <Container>
      <h2>{content.text}</h2>
      <img src={require(`../assets/${type}.png`)} alt="설명 이미지" />
      <button onClick={() => setIsVisible(false)}>확인했어요</button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 311px;
  padding: 24px 16px 20px 16px;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  background-color: white;

  h2 {
    color: ${({ theme }) => theme.colors.blueGray800};
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px; /* 150% */
    letter-spacing: -0.4px;
    white-space: pre-wrap;
  }

  button {
    display: flex;
    margin-top: 32px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.primaryBlue100};

    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;
