import styled from "styled-components";

export default function Profile32({ name, style }) {
  return (
    <Container style={style}>
      <span>{name}</span>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 39px;
  background: #3f89ff;

  span {
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
  }
`;
