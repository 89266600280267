import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import StatusChangePopUp from "@components/common/StatusChangePopUp";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import ApplicantListTable from "@components/page/admin/consultation/consultationStatus/ApplicantListTable";
import TableSkeleton from "@components/skeleton/TableSkeleton";

import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import {
  useGetApplicantsList,
  usePatchApplicantState,
} from "@hooks/consultation";

import { useEffect, useState } from "react";
import styled from "styled-components";

// 상담 신청 현황
export default function ApplicantList() {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState({
    applicantId: "",
    currentState: "",
    selectedState: "",
  });
  const { setCurrentMenu } = useMenu();
  const { data, isLoading } = useGetApplicantsList();
  const { mutate } = usePatchApplicantState();

  useEffect(() => {
    setCurrentMenu("consultationStatus");
  }, []);

  const handleState = ({ applicantId, currentState, selectedState }) => {
    if (applicantId) {
      setSelectedValue((prev) => ({ ...prev, applicantId, currentState }));
    } else if (selectedState) {
      setSelectedValue((prev) => ({ ...prev, selectedState }));
      mutate(
        { id: selectedValue.applicantId, state: selectedState },
        {
          onSuccess: (res) => console.log(res),
          onError: (res) => console.log(res.response.data),
        }
      );
    }
  };

  return (
    <Container>
      <Title>상담 신청 현황</Title>
      <div style={{ height: 40 }} />

      <ApplicantListTable
        data={data?.applicants || []}
        setIsVisible={setIsVisible}
        handleState={handleState}
        isLoading={isLoading}
      />

      <PopUpContainer isVisible={isVisible} setIsVisible={setIsVisible}>
        <StatusChangePopUp
          setIsVisible={setIsVisible}
          currentState={selectedValue.currentState}
          handleState={handleState}
        />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div``;
