export default function PersonPlus32() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19939_37475)">
        <path opacity="0.2" d="M32 0H0V32H32V0Z" fill="white" />
        <path opacity="0.2" d="M27 3H5V29H27V3Z" fill="white" />
        <path opacity="0.2" d="M29 27V5L3 5V27H29Z" fill="white" />
        <path opacity="0.2" d="M28 4H4V28H28V4Z" fill="white" />
        <path
          opacity="0.2"
          d="M16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22Z"
          fill="white"
        />
        <path
          d="M14.7744 15C17.812 15 20.2744 12.5376 20.2744 9.5C20.2744 6.46243 17.812 4 14.7744 4C11.7368 4 9.27441 6.46243 9.27441 9.5C9.27441 12.5376 11.7368 15 14.7744 15Z"
          fill="#B2BEC9"
        />
        <path
          d="M6.00241 27C4.70941 27 3.76331 25.7924 4.05221 24.5321C5.17241 19.6453 9.54761 16 14.7743 16C20.001 16 24.3761 19.6452 25.4964 24.5321C25.7853 25.7924 24.8392 27 23.5462 27H6.00241Z"
          fill="#B2BEC9"
        />
        <path
          d="M27 6.5H22"
          stroke="#1C84FF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M24.5 4V9"
          stroke="#1C84FF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_19939_37475">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
