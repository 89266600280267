import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { useState } from "react";
import styled from "styled-components";

import { Search15 } from "@components/core/icon/search";
import GetUserIdModal from "@components/page/admin/manage/_components/GetUserIdModal";

export default function CoinUser({ coinInfo, setCoinInfo }) {
  const [isVisible모달, setIsVisible모달] = useState(false);

  const onConfirm = (userId) => {
    setCoinInfo((prev) => ({ ...prev, target: userId }));
    setIsVisible모달(false);
  };

  return (
    <Container>
      <Chip onClick={() => setIsVisible모달(true)}>🔍︎ 유저 아이디 찾기</Chip>
      <Input
        placeholder="유저 아이디"
        value={coinInfo.target}
        onChange={(e) => {
          setCoinInfo((prev) => ({ ...prev, target: e.target.value }));
        }}
      />
      <PopUpContainer isVisible={isVisible모달} setIsVisible={setIsVisible모달}>
        <GetUserIdModal onConfirm={onConfirm} setIsVisible={setIsVisible모달} />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
`;

const Chip = styled.div`
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  margin-bottom: 8px;
  width: fit-content;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.blueA100};
  cursor: pointer;

  color: ${({ theme }) => theme.colors.primaryBlue100};
  font-size: 14px;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
`;

const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  &:focus {
    outline: none;
  }
`;
