import PermissionLayout from "@components/router/PermissionLayout";
import {
  ApplicantDetail,
  ClosingRatePage,
  ExpertAdd,
  ExpertEdit,
  ImjangApplicant,
  ImjangExpert,
} from "@pages/admin/imjang";
import { Route, Routes } from "react-router-dom";

export default function ImjangRouter({ pageValue }) {
  return (
    <Routes>
      <Route
        element={
          <PermissionLayout pageValue={pageValue} style={{ padding: 0 }} />
        }
      >
        <Route path="applicants" element={<ImjangApplicant />} />
        <Route path="applicant/:id" element={<ApplicantDetail />} />
        <Route
          path="applicant/:id/closing-rate"
          element={<ClosingRatePage />}
        />
        <Route path="experts" element={<ImjangExpert />} />
        <Route path="expert/:id" element={<ExpertEdit />} />
        <Route path="expert/add" element={<ExpertAdd />} />
      </Route>
    </Routes>
  );
}
