import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import styled from "styled-components";

export default function NoticePopup({ data, setIsVisible }) {
  const { title, content, created_at } = data;
  const closePopup = () => {
    setIsVisible(false);
  };

  return (
    <Container>
      <NoticeTitle>{title || "제목없음"}</NoticeTitle>
      <Head>
        <p className="head">공지내용</p>
        <div>
          <p className="head">공지날짜</p>
          <p className="body">{convertToKoreanDate(created_at)}</p>
        </div>
      </Head>
      <Body>
        <p>{content || "불러오기 오류"}</p>
      </Body>
      <Button onClick={closePopup}>확인</Button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 700px;
  height: 600px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
`;

const NoticeTitle = styled.h1`
  color: ${({ theme }) => theme.colors.blueGray900};
  font-size: 32px;
  font-weight: 600;
  line-height: 42px; /* 131.25% */
  letter-spacing: -0.64px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0 20px;

  .head {
    margin-right: 20px;
    color: ${({ theme }) => theme.colors.blueGray400};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  div {
    display: flex;
  }

  .body {
    color: ${({ theme }) => theme.colors.blueGray800};
    text-align: right;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }
`;

const Body = styled.div`
  padding: 20px;
  height: 340px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: #fff;
  overflow-y: scroll;

  p {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 18px;
    font-weight: 500;
    line-height: 32px; /* 177.778% */
    letter-spacing: -0.36px;
    white-space: pre-wrap;
  }
`;

const Button = styled.button`
  margin: 24px auto 0;
  padding: 10px 35px;
  width: fit-content;
  border-radius: 8px;
  border: none;
  background: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;

  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;
`;
