import { useDispatch, useSelector } from "react-redux";
import { closeAlert, showAlert } from "./alertSlice";

export const useAlert = () => {
  const globalAlert = useSelector((state) => state["alert"]);

  const dispatch = useDispatch();
  const showGlobalAlert = (el) => {
    dispatch(showAlert(el));
  };
  const closeGlobalAlert = () => {
    dispatch(closeAlert());
  };

  return {
    globalAlert,
    showGlobalAlert,
    closeGlobalAlert,
  };
};
