import useCustomSearchParams from "@hooks/useCustomSearchParams";

import styled from "styled-components";
import FamilyInfo from "./FamilyInfo";
import { useGetBdbdUser, useUpdateBdBdUser } from "@hooks/imjang";
import FavoriteDanji from "./FavoriteDanji";
import Area from "./Area";
import BasicInfo from "./BasicInfo/BasicInfo";
import { useUserInfo } from "@features/useUserInfo";
import { useEffect, useState } from "react";
import moment from "moment";
import Loading from "./Loading";
import UpdateLoading from "./UpdateLoading";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { AlertDialog } from "@components/core/dialog";

const menuArr = [
  { title: "기본 정보", value: "basic" },
  { title: "가구원 정보", value: "family" },
  { title: "선호 지역", value: "area" },
  { title: "관심 단지", value: "danji" },
];

type TabStatusType = "basic" | "family" | "area" | "danji";

export default function AddInfoPage() {
  const { searchParams, setSearchParams } = useCustomSearchParams();
  const { userId } = searchParams;
  const { applyId } = searchParams;
  const { globalUserInfo, setGlobalUserInfo } = useUserInfo();
  const { data, isLoading: userLoading } = useGetBdbdUser({ userId });
  const { mutate, isLoading, isError } = useUpdateBdBdUser({ userId });
  const active = (searchParams.status as TabStatusType) || "basic";
  const [selectedArr, setSelectedArr] = useState<
    {
      danjiCode: number;
      scaleCode: number;
    }[]
  >([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isVisible정보저장확인팝업, setIsVisible정보저장확인팝업] =
    useState(false);

  const updateUserInfo = () => {
    setIsUpdate(true);
    if (globalUserInfo.regionList.length === 0) {
      alert("선호 지역을 하나 이상 선택해주세요.");
      setIsUpdate(false);
      return;
    }
    const errorMessage = [];

    const householdMemberList = globalUserInfo.householdMemberList.map((el) => {
      if (!el.birthDate) {
        if (!errorMessage.includes("생년월일은 필수입니다.")) {
          errorMessage.push("생년월일은 필수입니다.");
        }
      } else {
        const birthDateMoment = moment(el.birthDate, "YYYY-MM-DD", true);
        if (
          !birthDateMoment.isValid() ||
          !birthDateMoment.isBetween(
            "1940-01-01",
            moment().format("YYYY-MM-DD")
          )
        ) {
          if (!errorMessage.includes("생년월일 형식이 올바르지 않습니다.")) {
            errorMessage.push("생년월일 형식이 올바르지 않습니다.");
          }
        }
      }
      if (!el.genderCode) {
        if (!errorMessage.includes("성별은 필수입니다.")) {
          errorMessage.push("성별은 필수입니다.");
        }
      }
      if (!el.jobCode) {
        if (!errorMessage.includes("직업은 필수입니다.")) {
          errorMessage.push("직업은 필수입니다.");
        }
      }
      if (!el.relationCode) {
        if (!errorMessage.includes("관계는 필수입니다.")) {
          errorMessage.push("관계는 필수입니다.");
        }
      }

      if (el.office && !el.office.firstTransportationCode) {
        return {
          ...el,
          office: {
            ...el.office,
            firstTransportationCode: "T01",
            wishCommuteTimeMin: 60,
          },
        };
      }
      return el;
    });
    if (errorMessage.length > 0) {
      alert(errorMessage.join("\n"));
      setIsUpdate(false);
      return;
    }

    mutate(
      {
        ...globalUserInfo,
        jjimList: selectedArr.map((el) => {
          return {
            danjiCode: el.danjiCode,
            scaleCode: el.scaleCode,
          };
        }),
        householdMemberList,
        applyId,
      },
      {
        onSuccess: () => {
          window.opener.location.reload();
        },
        onError: (res) => {
          alert(`정보 업데이트에 실패하였습니다.`);
        },
      }
    );
  };

  useEffect(() => {
    if (!data) return;

    const basicInfo = {
      plannedMovingDate: data.basicInfo.plannedMovingDate
        ? moment(data.basicInfo.plannedMovingDate).format("YYYY-MM-DD")
        : "",
      availableCash: data.basicInfo.availableCash,
    };

    setGlobalUserInfo({ ...data, basicInfo });
  }, [data]);

  return (
    <>
      {isUpdate ? (
        <UpdateLoading
          isLoading={isLoading}
          isError={isError}
          setIsUpdate={setIsUpdate}
        />
      ) : null}
      {userLoading ? <Loading /> : null}
      <Container>
        <Header>
          <p>고객 추가 정보 입력</p>
          <button onClick={() => setIsVisible정보저장확인팝업(true)}>
            고객 정보 등록
          </button>
        </Header>
        <Content>
          <div className="menuBar">
            {menuArr.map((el) => (
              <Menu
                $active={el.value === active}
                onClick={() => setSearchParams({ status: el.value })}
                key={el.value}
              >
                <p>{el.title}</p>
              </Menu>
            ))}
          </div>
          <div className="contents">
            {active === "basic" && <BasicInfo />}
            {active === "family" && <FamilyInfo />}
            {active === "area" && <Area />}
            {active === "danji" && (
              <FavoriteDanji
                selectedArr={selectedArr}
                setSelectedArr={setSelectedArr}
              />
            )}
          </div>
        </Content>
        <PopUpContainer
          isVisible={isVisible정보저장확인팝업}
          setIsVisible={setIsVisible정보저장확인팝업}
        >
          <AlertDialog
            title={"모든 고객 정보를 등록합니다"}
            content={
              "입력하신 정보가 기존 등록된 정보를\n대체하게 됩니다. 그대로 진행할까요?"
            }
            buttonArr={["취소", "등록하기"]}
            rightFunction={() => {
              updateUserInfo();
              setIsVisible정보저장확인팝업(false);
            }}
            leftFunction={() => setIsVisible정보저장확인팝업(false)}
          />
        </PopUpContainer>
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  max-width: 561px;
  min-width: 561px;
  max-height: 722px;
  min-height: 722px;
  background-color: white;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 32px 0 24px;
  min-height: 68px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrayA200};

  p {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 20px;
    font-weight: 600;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
  }

  button {
    margin-left: auto;
    display: inline-flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.primaryBlue100};
    border-radius: 8px;

    color: var(--White, #fff);
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;

  .menuBar {
    width: 154px;
    border-right: 1px solid ${({ theme }) => theme.colors.blueGrayA200};
  }

  .contents {
    flex: 1;
    max-height: calc(722px - 68px);
    overflow: scroll;
  }
`;

const Menu = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 24px;
  height: 56px;
  background-color: ${({ theme, $active }) =>
    $active ? theme.colors.blue100 : theme.colors.white};
  border-bottom: 1px solid
    ${({ theme, $active }) =>
      $active ? theme.colors.white : theme.colors.blueGrayA200};
  cursor: pointer;

  p {
    color: ${({ theme, $active }) =>
      $active ? theme.colors.primaryBlue100 : theme.colors.blueGray400};
    font-size: 16px;
    font-weight: ${({ theme, $active }) => ($active ? 600 : 500)};
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;
