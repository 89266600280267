import { Route, Routes } from "react-router-dom";
import { AuthCallback } from "@pages/login/AuthCallback";
import Forbidden from "@pages/forbidden/Forbidden";
import JoinPage from "@pages/login/join/JoinPage";
import Login from "@pages/login/Login";
import LoginReq from "@pages/login/LoginReq";

export default function PublicRouter() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/join" element={<JoinPage />} />
      <Route path="/login/req" element={<LoginReq />} />
      <Route path="/auth" element={<AuthCallback />} />
      <Route path="/403" element={<Forbidden />} />
    </Routes>
  );
}
