import styled from "styled-components";
import CheckNickname from "./step/CheckNickname";
import PushMethod from "./step/PushMethod";
import PushContent from "./step/PushContent";
import PushTime from "./step/PushTime";
import SelectCohortGroup from "./step/SelectCohortGroup";
import StepWrapper from "../../_components/StepWrapper";

export default function SendPushContent({
  pushInfo,
  setPushInfo,
  setId입력여부,
}) {
  return (
    <Container>
      <StepWrapper number={1} title={"푸시 방법 선택"}>
        <PushMethod pushInfo={pushInfo} setPushInfo={setPushInfo} />
      </StepWrapper>
      <StepWrapper number={2} title={"푸시 대상 설정"}>
        {pushInfo.method === "single" && (
          <CheckNickname pushInfo={pushInfo} setPushInfo={setPushInfo} />
        )}
        {pushInfo.method === "multiple" && (
          <SelectCohortGroup pushInfo={pushInfo} setPushInfo={setPushInfo} />
        )}
      </StepWrapper>
      <StepWrapper number={3} title={"전송 내용 작성"}>
        <PushContent
          pushInfo={pushInfo}
          setPushInfo={setPushInfo}
          setId입력여부={setId입력여부}
        />
      </StepWrapper>
      <StepWrapper number={4} title={"발송 시점 선택"}>
        <PushTime pushInfo={pushInfo} setPushInfo={setPushInfo} />
      </StepWrapper>
    </Container>
  );
}

const Container = styled.section``;
