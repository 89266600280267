import styled from "styled-components";
import LogoutIcon from "./LogoutIcon";
import { hyphenPhoneNum } from "@utils/phoneNumber";
import { useLogout } from "@api/login";
import { Cookies } from "react-cookie";
import { forwardRef } from "react";
import { UserInfoRes } from "@api/user/user.interfaces";

const UserInfo = forwardRef<HTMLDivElement, { userInfo: UserInfoRes }>(
  ({ userInfo }, ref) => {
    const cookies = new Cookies();

    const { mutate } = useLogout();
    const 로그아웃 = () => {
      cookies.remove("access_token");
      mutate(undefined, { onSuccess: () => window.location.replace("/login") });
    };

    return (
      <Container ref={ref}>
        <Top>
          <p className="nickname">{userInfo.nickname}</p>
          <p className="text" style={{ marginBottom: 6 }}>
            {userInfo.name}
          </p>
          <p className="text">{hyphenPhoneNum(userInfo.phone_number)}</p>
        </Top>
        <Bottom onClick={로그아웃}>
          <span className="icon">
            <LogoutIcon />
          </span>
          <p>로그아웃</p>
        </Bottom>
      </Container>
    );
  }
);

export default UserInfo;

const Container = styled.div`
  position: absolute;
  right: 0;
  top: 60px;
  width: 194px;
  border-radius: 16px;
  background: white;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
`;

const Top = styled.div`
  padding: 16px 16px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrayA200};

  .nickname {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 15px;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }

  .text {
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  cursor: pointer;

  .icon {
    margin-right: 4px;
  }

  p {
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
  }
`;
