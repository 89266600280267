import { DeleteEnum, Props } from "./Delete.interfaces";

export default function Delete32({ status, onClick }: Props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_1692_3678)">
        <path
          d="M7 11H25V26C25 27.1038 24.1038 28 23 28H9C7.8962 28 7 27.1038 7 26V11Z"
          fill={status === DeleteEnum.Default ? "#B2BEC9" : "#F36975"}
        />
        <path
          d="M27 7H5C4.44772 7 4 7.44772 4 8V10C4 10.5523 4.44772 11 5 11H27C27.5523 11 28 10.5523 28 10V8C28 7.44772 27.5523 7 27 7Z"
          fill={status === DeleteEnum.Default ? "#B2BEC9" : "#F36975"}
        />
        <path
          d="M21 8H11V6C11 5.4477 11.4477 5 12 5H20C20.5523 5 21 5.4477 21 6V8ZM21 3H11C9.8954 3 9 3.8954 9 5V8C9 9.1046 9.8954 10 11 10H21C22.1046 10 23 9.1046 23 8V5C23 3.8954 22.1046 3 21 3Z"
          fill={status === DeleteEnum.Default ? "#B2BEC9" : "#F36975"}
        />
        <path
          d="M22 19C22 18.4477 21.5523 18 21 18C20.4477 18 20 18.4477 20 19V25C20 25.5523 20.4477 26 21 26C21.5523 26 22 25.5523 22 25V19Z"
          fill={status === DeleteEnum.Default ? "#8E9FAE" : "#fff"}
        />
        <path
          d="M17 19C17 18.4477 16.5523 18 16 18C15.4477 18 15 18.4477 15 19V25C15 25.5523 15.4477 26 16 26C16.5523 26 17 25.5523 17 25V19Z"
          fill={status === DeleteEnum.Default ? "#8E9FAE" : "#fff"}
        />
        <path
          d="M12 19C12 18.4477 11.5523 18 11 18C10.4477 18 10 18.4477 10 19V25C10 25.5523 10.4477 26 11 26C11.5523 26 12 25.5523 12 25V19Z"
          fill={status === DeleteEnum.Default ? "#8E9FAE" : "#fff"}
        />
      </g>
      <defs>
        <clipPath id="clip0_1692_3678">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
