import { useState } from "react";
import { Title } from "@components/styles";
import styled from "styled-components";
import StoryInput from "./StoryInput";
import { usePostImjangStory } from "@api/management/story";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAlert } from "@features/useAlert";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { AlertDialog } from "@components/core/dialog";

export default function StoryAddPage() {
  const navigate = useNavigate();
  const { mutate } = usePostImjangStory();
  const { showGlobalAlert } = useAlert();
  const [isVisible, setIsVisible] = useState(false);
  const [storyInfo, setStoryInfo] = useState({
    title: "",
    notionUrl: "",
    area: "",
    householdNum: null,
    danjiCode: null,
    preview: "",
    metaTitle: "",
    slug: "",
    metaDescription: "",
  });

  const handleConfirm = () => {
    mutate(storyInfo, {
      onSuccess: () => {
        toast("등록 성공");
        setIsVisible(false);
        navigate(-1);
      },
      onError: (res) =>
        showGlobalAlert({
          desc: `등록 실패\n${res.response.data["message"]}`,
          blue: false,
        }),
    });
  };

  return (
    <Container>
      <Title>임장 이야기 추가</Title>
      <div style={{ height: 32 }} />
      <StoryInput
        storyInfo={storyInfo}
        setStoryInfo={setStoryInfo}
        handleConfirm={() => setIsVisible(true)}
        edit={false}
      />
      <PopUpContainer isVisible={isVisible} setIsVisible={setIsVisible}>
        <AlertDialog
          title={"Slug에 등록한 정보는 수정할 수 없어요 \n이대로 등록할까요?"}
          content={"등록한 정보가 맞는지 확인해 주세요."}
          buttonArr={["취소", "등록"]}
          rightFunction={handleConfirm}
          leftFunction={() => setIsVisible(false)}
        />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 0 243px;
  width: 100%;
`;
