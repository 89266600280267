import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { debounce } from "lodash";

import Profile32 from "@components/core/profile/Profile32";
import { useDeleteScrap, useUpdateScrap } from "@hooks/imjang";
import { ImjangApplyDetail } from "@hooks/imjang/Imjang.interfaces";
import { useScrollRestoration } from "@hooks/useScrollRestoration";

interface Props {
  value: ImjangApplyDetail;
  refetch: () => void;
}

export default function CustomerCard({ value, refetch }: Props) {
  const {
    createdAt,
    isWebUser,
    name,
    phoneNumber,
    applyId,
    isNew,
    isWarning,
    commentNum,
    isScrapped,
    isCancelled,
    madminList,
  } = value;

  const location = useLocation();
  const navigate = useNavigate();
  const { handleSaveScroll } = useScrollRestoration();
  const [scrap, setScrap] = useState(false);
  const { mutate: scrapUpdate } = useUpdateScrap();
  const { mutate: scrapDelete } = useDeleteScrap();

  const goToDetail = () => {
    handleSaveScroll(); // 스크롤 위치 저장
    // 어드민과 인플루언서 페이지 분리
    if (location.pathname.includes("/visit")) {
      navigate(`/visit/${applyId}`);
    } else navigate(`/imjang/applicant/${applyId}`);
  };

  const debouncedScrapUpdate = debounce(() => {
    if (!isScrapped) {
      scrapUpdate({ id: applyId }, { onSuccess: refetch });
    } else {
      scrapDelete({ id: applyId }, { onSuccess: refetch });
    }
  }, 500);

  const handleScrapping = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setScrap((prev) => !prev);
      debouncedScrapUpdate();
    },
    [isScrapped, applyId]
  );

  useEffect(() => {
    setScrap(isScrapped);
  }, [value]);

  return (
    <Container onClick={goToDetail} $cancel={isCancelled} $new={isNew}>
      <Header>
        {isWarning && (
          <div style={{ marginRight: 6 }}>
            <Siren />
          </div>
        )}
        <span className="name">{name}</span>
        {isNew && (
          <div className="chip">
            <span>NEW</span>
          </div>
        )}
      </Header>
      <Content>
        {format(parseISO(createdAt), "yyyy.MM.dd")}{" "}
        {isWebUser ? "웹신청" : "앱신청"}
      </Content>
      <Content>
        {phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")}
      </Content>
      <BottomBox>
        <div className="comment">
          <CommentSvg />
          <span>{commentNum}</span>
        </div>
        <div className="people">
          {madminList.length > 3 && (
            <div className="count">
              <span>+{madminList.length - 3}</span>
            </div>
          )}
          <div>
            {madminList.slice(0, 3).map((item, index) => (
              <Profile32
                name={item.name.slice(-2)}
                key={`${item.madminId}_${index}`}
                style={{
                  position: "absolute",
                  right: `${index * 22}px`,
                  zIndex: 3 - index,
                  border: "1px solid #fff",
                  background: item.profileColor,
                }}
              />
            ))}
          </div>
        </div>
      </BottomBox>
      <StarButton onClick={handleScrapping}>
        <Star fill={scrap ? true : false} />
      </StarButton>
      {/* {isCancelled && <Blur />} */}
    </Container>
  );
}

const Container = styled.div<{ $cancel: boolean; $new: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 12px 12px 16px;
  width: 280px;
  border-radius: 16px;
  background: ${({ theme, $cancel }) =>
    $cancel ? "rgba(0, 14, 33, 0.08)" : theme.colors.white};
  box-shadow: ${({ $cancel, $new }) =>
    $cancel
      ? "none"
      : $new
      ? "0px 0px 16px 0px rgba(28, 132, 255, 0.20)"
      : "0px 0px 16px 0px rgba(51, 62, 77, 0.08)"};

  &:hover {
    cursor: pointer;
  }

  .cancel {
    opacity: 0.4;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;

  .name {
    margin-right: 6px;
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.32px;
  }

  .chip {
    display: flex;
    padding: 2px 8px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.red100};
    justify-content: center;
    align-items: center;

    span {
      color: ${({ theme }) => theme.colors.red1000};
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.24px;
    }
  }
`;

const Content = styled.span`
  width: 100%;
  color: ${({ theme }) => theme.colors.blueGray600};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.28px;
  text-align: left;
`;

const BottomBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
  width: 100%;
  height: 34px;

  div {
    display: flex;
    align-items: center;
  }

  .comment {
    span {
      margin-left: 4px;
      color: ${({ theme }) => theme.colors.blueGray500};
      font-size: 15px;
      font-weight: 500;
      line-height: 20px; /* 133.333% */
      letter-spacing: -0.3px;
    }
  }

  .people {
    position: relative;

    .count {
      position: absolute;
      right: 81px;
      margin-right: 5px;
      padding: 2px 8px;
      border-radius: 20px;
      background: ${({ theme }) => theme.colors.blueGrayA200};

      span {
        color: ${({ theme }) => theme.colors.blueGray700};
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.24px;
        text-align: center;
      }
    }

    .person {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 39px;
      border: 1px solid var(--White, #fff);
      background: #3f89ff;

      span {
        color: ${({ theme }) => theme.colors.white};
        font-size: 14px;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.28px;
      }
    }
  }
`;

const StarButton = styled.button`
  position: absolute;
  top: 16px;
  right: 12px;
  cursor: pointer;
`;

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.blueGray1000};
  opacity: 0.08;
  border-radius: 16px;
  z-index: 10;
`;

const Star = ({ fill = false }) => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none">
      <path
        d="M9.04894 0.927052C9.3483 0.00574112 10.6517 0.00573993 10.9511 0.927051L12.4697 5.60081C12.6035 6.01284 12.9875 6.2918 13.4207 6.2918H18.335C19.3037 6.2918 19.7065 7.53141 18.9228 8.10081L14.947 10.9894C14.5966 11.244 14.4499 11.6954 14.5838 12.1074L16.1024 16.7812C16.4017 17.7025 15.3472 18.4686 14.5635 17.8992L10.5878 15.0106C10.2373 14.756 9.7627 14.756 9.41221 15.0106L5.43648 17.8992C4.65276 18.4686 3.59828 17.7025 3.89763 16.7812L5.41623 12.1074C5.55011 11.6954 5.40345 11.244 5.05296 10.9894L1.07722 8.10081C0.293507 7.53141 0.696283 6.2918 1.66501 6.2918H6.57929C7.01252 6.2918 7.39647 6.01284 7.53035 5.60081L9.04894 0.927052Z"
        fill={fill ? "#FF9C00" : "#E5E7E9"}
      />
    </svg>
  );
};

const CommentSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_21926_10442)">
        <path
          d="M15.7537 12.9746C16.5377 11.8446 17 10.4747 17 8.99552C17 5.132 13.8661 2 10 2C6.13393 2 3 5.132 3 8.99552C3 12.859 6.13393 15.991 10 15.991C10.9443 15.991 11.8437 15.8016 12.6658 15.4628L16.2803 16.9638C16.5459 17.0965 16.8395 16.842 16.7456 16.5605L15.7537 12.9746Z"
          fill="#C3D0DB"
        />
      </g>
      <defs>
        <clipPath id="clip0_21926_10442">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Siren = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_21926_10419)">
        <path
          d="M19.1998 16.8004H4.7998V9.60039C4.7998 5.62395 8.02336 2.40039 11.9998 2.40039C15.9762 2.40039 19.1998 5.62395 19.1998 9.60039V16.8004Z"
          fill="#F04452"
        />
        <path
          d="M11.9999 4.2002C9.01749 4.2002 6.59985 6.61784 6.59985 9.60019V16.8002H17.3999V9.60019C17.3999 6.61784 14.9822 4.2002 11.9999 4.2002Z"
          fill="#FB5D6A"
        />
        <path
          d="M20.9988 18H3.00098C2.66902 18 2.3999 18.2691 2.3999 18.6011V19.7989C2.3999 20.1309 2.66902 20.4 3.00098 20.4H20.9988C21.3308 20.4 21.5999 20.1309 21.5999 19.7989V18.6011C21.5999 18.2691 21.3308 18 20.9988 18Z"
          fill="#666E7A"
        />
        <path
          d="M20.3999 18.0008H3.59985C3.59985 17.338 4.13709 16.8008 4.79985 16.8008H19.1999C19.8626 16.8008 20.3999 17.338 20.3999 18.0008Z"
          fill="#333E4D"
        />
        <path
          d="M13.8 10.8002C14.7941 10.8002 15.6 9.99431 15.6 9.0002C15.6 8.00608 14.7941 7.2002 13.8 7.2002C12.8059 7.2002 12 8.00608 12 9.0002C12 9.99431 12.8059 10.8002 13.8 10.8002Z"
          fill="#E43E4C"
        />
        <path
          d="M11.0999 13.8004C12.5911 13.8004 13.7999 12.5916 13.7999 11.1004C13.7999 9.60922 12.5911 8.40039 11.0999 8.40039C9.60873 8.40039 8.3999 9.60922 8.3999 11.1004C8.3999 12.5916 9.60873 13.8004 11.0999 13.8004Z"
          fill="#F82839"
        />
        <path
          d="M10.8 6.60039C11.1313 6.60039 11.4 6.33176 11.4 6.00039C11.4 5.66902 11.1313 5.40039 10.8 5.40039C10.4686 5.40039 10.2 5.66902 10.2 6.00039C10.2 6.33176 10.4686 6.60039 10.8 6.60039Z"
          fill="#F4C0C0"
        />
        <path
          d="M11.9999 15C12.331 15 12.5999 15.2689 12.5999 15.6V16.8H11.3999V15.6C11.3999 15.2689 11.6688 15 11.9999 15Z"
          fill="#E43E4C"
        />
      </g>
      <defs>
        <clipPath id="clip0_21926_10419">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
