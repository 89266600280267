import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useEffect, useMemo } from "react";
import styled from "styled-components";

export default function Url() {
  const { setCurrentMenu } = useMenu();

  useEffect(() => {
    setCurrentMenu("url");
  }, []);

  return (
    <Container>
      <Title>URL 관리</Title>
    </Container>
  );
}

const Container = styled.div``;
