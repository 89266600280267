import { Title } from "@components/styles";
import styled from "styled-components";
import GroupList from "./GroupList";
import GroupSetting from "./GroupSetting";
import { useEffect, useMemo, useState } from "react";
import {
  useGetUserGroupSettingInfo,
  useGetUserGroupList,
  useUpdateUserGroup,
} from "@api/permission";
import { UserGroupSettingInfoRes } from "@api/permission/permission.interfaces";
import { useAlert } from "@features/useAlert";

export default function UserGroupPage() {
  const { showGlobalAlert } = useAlert();
  const [selectedGroup, setSelectedGroup] = useState("마스터");
  const [settingInfo, setSettingInfo] = useState<
    Pick<UserGroupSettingInfoRes, "name" | "description" | "authorities">
  >({
    name: "",
    description: "",
    authorities: [],
  });

  const { data: userGroupData } = useGetUserGroupList();
  const { data: groupSettingInfo } = useGetUserGroupSettingInfo({
    selectedGroup,
  });
  const { mutate } = useUpdateUserGroup({ selectedGroup });

  const 그룹_수정 = () => {
    mutate(settingInfo, {
      onSuccess: () => {
        showGlobalAlert({ desc: "변경하신 내용이 저장되었습니다", blue: true });
        setSelectedGroup(settingInfo.name);
      },
    });
  };

  const 수정_취소 = () => {
    setSettingInfo(groupSettingInfo);
  };

  useEffect(() => {
    if (!groupSettingInfo) return;
    setSettingInfo(groupSettingInfo);
  }, [groupSettingInfo]);

  return (
    <Container>
      <Title>그룹 관리</Title>
      <Content>
        <GroupList
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          data={userGroupData || []}
        />
        <GroupSetting
          settingInfo={settingInfo}
          setSettingInfo={setSettingInfo}
          onSubmit={그룹_수정}
          onCancel={수정_취소}
        />
      </Content>
    </Container>
  );
}

const Container = styled.div``;

const Content = styled.div`
  display: flex;
  margin-top: 32px;
  gap: 40px;
`;
