import DatePicker from "react-datepicker";
import styled from "styled-components";
import { ko } from "date-fns/esm/locale";
import CalendarIcon from "@assets/icon/CalendarIcon";
import B1TypeInputBoxTyping from "@components/core/BTypeInput/B1TypeInputBoxTyping";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDeleteContent, useEditContent, useGetContent } from "@hooks/admin";
import { toast } from "react-toastify";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import DeleteVersionPopup from "./ContentDeletePopup";

export default function InputContainer() {
  const navigate = useNavigate();
  const inputRef = useRef();
  const { id } = useParams();

  const { data } = useGetContent({ id });
  const { mutate: editContentInfo } = useEditContent();
  const { mutate: deleteContentInfo } = useDeleteContent();
  const [isVisible삭제팝업, setIsVisible삭제팝업] = useState(false);
  const [contentInfo, setContentInfo] = useState({
    title: "",
    released_at: "",
  });

  useEffect(() => {
    if (data) {
      const content = data.content;
      setContentInfo({
        title: content.title,
        released_at: content.released_at
          ? DateFormat(content.released_at)
          : null,
      });
    }
  }, [data]);

  const 정보_수정 = () => {
    let body;

    if (data.content.released_at) {
      body = { ...contentInfo, id };
    } else {
      // 정보를 수정하면 컨텐츠 status가 초기화되므로 직접 deployed 추가
      // content_status 값 안 넣으면 이전 값 그대로 유지
      body = { ...contentInfo, id, content_status: "deployed" };
    }

    editContentInfo(body, {
      onSuccess: (res) => {
        toast("컨텐츠 정보 수정 완료", {
          type: "success",
          hideProgressBar: true,
        });
        navigate(-1);
      },
    });
  };

  const 정보_삭제 = () => {
    deleteContentInfo(
      { id },
      {
        onSuccess: () => {
          toast("삭제가 완료되었습니다.", {
            type: "success",
            hideProgressBar: true,
          });
          navigate(-1);
        },
        onError: (res) => {
          alert(
            `데이터 처리과정에 에러가 발생했습니다. \n${res.response.data["message"]}`
          );
          navigate(-1);
        },
      }
    );
  };

  const onChangeDateInput = (date: Date) => {
    setContentInfo((prev) => ({ ...prev, released_at: DateFormat(date) }));
  };

  const DateFormat = (pick: Date | string) => {
    const result = moment(pick).format("YYYY-MM-DD");
    return result;
  };

  const CustomInput = (
    props: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>
  ) => (
    <CustomInputBox>
      <CalendarIcon />
      <input
        {...props}
        placeholder={
          contentInfo.released_at
            ? DateFormat(contentInfo.released_at)
            : "발행날짜를 선택해주세요"
        }
        className={contentInfo.released_at ? "datepick" : "date"}
      />
    </CustomInputBox>
  );

  return (
    <Container>
      <B1TypeInputBoxTyping
        inputRef={inputRef}
        inputType={InputTypeEnum.OnlyText}
        label={"컨텐츠 제목"}
        placeholder={"컨텐츠 제목을 입력하세요."}
        value={contentInfo.title}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setContentInfo((prev) => ({ ...prev, title: v }))
        }
        style={{ maxWidth: "30vw", minWidth: "670px" }}
      />
      <Label>발행날짜</Label>
      <DateInput>
        <DatePicker
          locale={ko}
          onChange={onChangeDateInput}
          customInput={React.createElement(React.forwardRef(CustomInput))}
        />
      </DateInput>
      <ButtonBox>
        <button onClick={() => setIsVisible삭제팝업(true)} className="red">
          삭제
        </button>
        <button onClick={정보_수정}>완료</button>
      </ButtonBox>
      <PopUpContainer
        isVisible={isVisible삭제팝업}
        setIsVisible={setIsVisible삭제팝업}
      >
        <DeleteVersionPopup onClick={정보_삭제} />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 36px 0;
  width: fit-content;
`;

const Label = styled.p`
  margin-top: 32px;
  color: ${({ theme }) => theme.colors.blueGray600};
  font-size: 15px;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.3px;
`;

const ButtonBox = styled.div`
  margin-top: 200px;
  display: flex;
  gap: 10px;

  button {
    flex: 2;
    padding: 10px 35px;
    width: 100%;
    border-radius: 8px;
    border: none;
    background: ${({ theme }) => theme.colors.primaryBlue100};
    cursor: pointer;

    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
    letter-spacing: -0.36px;

    &.red {
      flex: 1;
      background: ${({ theme }) => theme.colors.red1000};
    }
  }
`;

const DateInput = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
  padding: 15px 16px;
  width: 50%;
  background-color: ${({ theme }) => theme.colors.blueGrayA100};
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  border-radius: 10px;

  .react-datepicker-wrapper {
    display: flex;
    align-items: center;
  }

  input {
    width: 100%;
    font-family: Pretendard-Medium;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.blueGray900};
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
    border: none;
    outline: none;
    letter-spacing: -0.02em;

    &.price {
      text-align: right;
    }
  }

  .unit {
    margin-left: 6px;
    color: ${({ theme }) => theme.colors.blueGray400};
  }
`;

const CustomInputBox = styled.div`
  display: flex;

  input {
    margin-left: 10px;
    &.date {
      text-align: left;
      caret-color: transparent;

      &::placeholder {
        color: ${({ theme }) => theme.colors.blueGray500};
        font-size: 18px;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
        letter-spacing: -0.36px;
      }
    }

    &.datepick {
      text-align: left;
      font-family: Pretendard-Medium;

      &::placeholder {
        color: ${({ theme }) => theme.colors.blueGray900};
        font-size: 18px;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
        letter-spacing: -0.36px;
      }
    }
  }
`;
