import DevProfile28 from "@components/core/icon/profile/DevProfile28";
import EmptyProfile28 from "@components/core/icon/profile/EmptyProfile28";
import styled from "styled-components";
import { PlugIcon } from "./PlugIcon";
import { Arrows18 } from "@components/core/icon/arrow";
import { Link, useLocation } from "react-router-dom";
import { UnplugIcon } from "./UnplugIcon";

export default function SimpleUserInfo({ userInfo, onClick }) {
  const { pathname } = useLocation();

  if (userInfo.user_group === "개발자") {
    return (
      <Container>
        <div className="userInfo" onClick={onClick}>
          <DevProfile28 />
          <p className="group">개발자</p>
          <p className="email">{userInfo.email}</p>
          {pathname.includes("dev") ? (
            <Link to="/" style={{ marginLeft: 4 }}>
              <UnplugIcon />
            </Link>
          ) : (
            <Link to="dev" style={{ marginLeft: 4 }}>
              <PlugIcon />
            </Link>
          )}
        </div>
      </Container>
    );
  } else
    return (
      <Container>
        <div className="userInfo" onClick={onClick}>
          <EmptyProfile28 />
          <p className="group">{userInfo.user_group}</p>
          <p className="email">{userInfo.email}</p>
          <Arrows18 />
        </div>
      </Container>
    );
}

const Container = styled.div`
  .userInfo {
    display: flex;
    align-items: center;
    margin-left: auto;
    cursor: pointer;

    .group {
      margin: 0 8px;
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 15px;
      font-weight: 600;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.3px;
    }

    .email {
      margin-right: 2px;
      color: ${({ theme }) => theme.colors.blueGray500};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.28px;
    }
  }
`;
