import PermissionLayout from "@components/router/PermissionLayout";
import NotificationPage from "@pages/notification";
import { Route, Routes } from "react-router-dom";

export default function NotificationRouter({ pageValue }) {
  return (
    <Routes>
      <Route element={<PermissionLayout pageValue={pageValue} />}>
        <Route index element={<NotificationPage />} />
      </Route>
    </Routes>
  );
}
