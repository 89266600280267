import DropDownInput from "../DropDownInput";
import { jobArray } from "@utils/code";
import InputRow from "./InputRow";

export default function CompanyInput({ onChange, value }) {
  return (
    <InputRow title="직업군" require>
      <DropDownInput
        placeholder="직업군"
        onChange={(v) => onChange({ name: "jobCode", value: v })}
        value={value}
        optionList={jobArray}
      />
    </InputRow>
  );
}
