import { Instance } from "@api/index";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  BdbdUserRes,
  ImjangApplicantDetail,
  ImjangApplyListReq,
  ImjangApplyListRes,
  ImjangCancelReq,
  ImjangThread,
  ImjangThreadReq,
  tradeType,
  TreadFilePresignedRes,
} from "./Imjang.interfaces";

// 임장 신청 목록
export const useGetImjangApplicantsList = () =>
  useQuery({
    queryKey: ["imjangApplicantList"],
    queryFn: async () => {
      const response = await Instance({
        url: `/brokerage/apply`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 0,
  });

// 임장 신청 목록
export const useGetScaleCodeList = ({ danjiCode }) =>
  useQuery({
    queryKey: ["scaleCodeList"],
    queryFn: async () => {
      const response = await Instance({
        url: `/danjis/info/scale_code_list?danjiCode=${danjiCode}`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 0,
  });

export const useGetImjangApplicantDetail = ({ id }: { id: string }) => {
  return useQuery<ImjangApplicantDetail>({
    queryKey: ["imjangApplicant", id],
    queryFn: async () => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${id}`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 60 * 60 * 5,
  });
};

// BdBd 유저 조회
export const useGetBdbdUser = ({ userId }: { userId: string }) =>
  useQuery<BdbdUserRes>({
    queryKey: ["imjangApplicantList", "user", userId],
    queryFn: async () => {
      const response = await Instance({
        url: `/admin/bdbduser/${userId}`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 60 * 60 * 5,
  });

export const useUpdateBdBdUser = ({ userId }: { userId: string }) => {
  return useMutation<
    { text: string },
    AxiosError<{ message: string }>,
    BdbdUserRes
  >({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/admin/bdbduser/${userId}/update`,
        method: "PUT",
        data,
      });
      return response.data;
    },
    retry: false,
  });
};

export const useDeleteImjang = ({ id }: { id: string }) => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError>({
    mutationFn: async () => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${id}`,
        method: "DELETE",
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant"],
      });
    },
  });
};

// 임장 단지 추가 (어드민)
export const usePostImjangDanji = () => {
  const queryClient = useQueryClient();
  return useMutation<
    {},
    AxiosError,
    {
      applyId: string;
      danjiCode: number;
      scaleCodeList: number[];
      tradeType: tradeType;
    }
  >({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/danji`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant"],
      });
    },
  });
};

// 임장 단지 추가 (어드민)
export const useDeleteImjangDanji = () => {
  const queryClient = useQueryClient();
  return useMutation<
    {},
    AxiosError,
    {
      applyId: string;
      danjiIdList: string[];
    }
  >({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/danji`,
        method: "DELETE",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant"],
      });
    },
  });
};

// 중개소 목록 조회
export const useGetImjangAgentList = () =>
  useQuery({
    queryKey: ["imjangAgentList"],
    queryFn: async () => {
      const response = await Instance({
        url: `/brokerage/agent`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 0,
  });

// 중개소 삭제
export const useDeleteImjangAgents = () => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, { agentIds: string[] }>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/agent/deleteAgents`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangAgentList"],
      });
    },
  });
};

export const useGetRegionList = () =>
  useQuery({
    queryKey: ["region"],
    queryFn: async () => {
      const response = await Instance({
        url: `/recommendation/setting/regions_pnu`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 0,
  });

export const useGetImjangAgentDetail = ({ id }: { id: string }) =>
  useQuery({
    queryKey: ["imjangAgent", id],
    queryFn: async () => {
      const response = await Instance({
        url: `/brokerage/agent/${id}`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 0,
  });

export const useEditImjangAgentDetail = () => {
  // const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, { id: string; data: object }>({
    mutationFn: async ({ id, data }) => {
      const response = await Instance({
        url: `/brokerage/agent/${id}`,
        method: "PUT",
        data,
      });
      return response.data;
    },
    retry: false,
    // onSuccess: () => {
    //   queryClient.invalidateQueries({
    //     queryKey: ["imjangList"],
    //   });
    // },
  });
};

// 중개사 등록
export const usePostAgentInfo = () => {
  return useMutation<{}, AxiosError, {}>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/agent`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
  });
};

// 임장 비서 관리 상태 알림 조회
export const useGetEventList = ({
  start,
  end,
}: {
  start: string;
  end: string;
}) =>
  useQuery({
    queryKey: ["imjangApplicant", "imjangEvent", start, end],
    queryFn: async () => {
      const response = await Instance({
        url: `/brokerage/admin/apply/date_events?start_date=${start}&end_date=${end}`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 60 * 60 * 10,
  });

// 임장 비서 관리 상태 목록 조회
export const useGetImjangStatus = ({
  filter = "",
}: {
  filter?: "main" | "detail" | "";
}) =>
  useQuery({
    queryKey: ["imjangApplicant", filter],
    queryFn: async () => {
      const response = await Instance({
        url: `/brokerage/admin/apply/status${
          filter.length ? `?filter=${filter}` : ""
        } `,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 60 * 60 * 5,
  });

// 임장 비서 관리 상태 변경
export const usePatchImjangStatus = ({ id }: { id: string }) => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, {}>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${id}/status`,
        method: "PATCH",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant"],
      });
    },
  });
};

//임장 비서 신청 목록 조회 ( 필터 )
export const useImjangApplyList = () => {
  return useMutation<ImjangApplyListRes, AxiosError, ImjangApplyListReq>({
    mutationFn: async (
      data = {
        filter: {
          statusList: [],
          madminId: "",
          applyUserName: "",
          activityStatus: "",
        },
      }
    ) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/list`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
  });
};

//임장 비서 신청 목록 조회 ( 필터 )
export const useGetImjangApplyList = ({
  filter = {
    statusList: [],
    madminId: "",
    applyUserName: "",
    activityStatus: "",
  },
}: ImjangApplyListReq) =>
  useQuery<ImjangApplyListRes, {}>({
    queryKey: [
      "imjangApplicant",
      filter.statusList,
      filter.madminId || "",
      filter.applyUserName || "",
      filter.activityStatus,
    ],
    queryFn: async () => {
      const response = await Instance({
        url: `/brokerage/admin/apply/list`,
        method: "POST",
        data: { filter },
      });

      return response.data;
    },
    retry: false,
    staleTime: 60 * 60 * 5,
  });

// 임장 비서 메인 카드에서 스크랩 추가
export const useUpdateScrap = () => {
  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${id}/scrap`,
        method: "POST",
      });
      return response.data;
    },
    retry: false,
  });
};

// 임장 비서 메인 카드에서 스크랩 삭제
export const useDeleteScrap = () => {
  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${id}/scrap`,
        method: "DELETE",
      });
      return response.data;
    },
    retry: false,
  });
};

// 임장 중단 여부 변경
export const usePatchImjangCancel = ({ id }: { id: string }) => {
  const queryClient = useQueryClient();
  return useMutation<{ message: string }, AxiosError, ImjangCancelReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${id}/cancel`,
        method: "PATCH",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant"],
      });
    },
  });
};

// 임장 신청 스케쥴 등록
export const useUpdateSchedule = ({ id }: { id: string }) => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, {}>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${id}/schedule`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant", id],
      });
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant", "imjangEvent"],
      });
    },
  });
};

// 임장 신청 스케쥴 복수 삭제
export const useDeleteSchedule = ({ id }: { id: string }) => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, {}>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${id}/schedule`,
        method: "DELETE",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant", id],
      });
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant", "imjangEvent"],
      });
    },
  });
};

// 임장 신청 스케쥴 수정
export const useEditSchedule = ({
  detailId,
  scheduleId,
}: {
  detailId: string;
  scheduleId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, {}>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${detailId}/schedule/${scheduleId}`,
        method: "PATCH",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant", detailId],
      });
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant", "imjangEvent"],
      });
    },
  });
};

// 통화여부 수정
export const usePatchCallChecked = ({ applyId, scheduleId }) => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, { callChecked: boolean }>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${applyId}/schedule/${scheduleId}/call_checked`,
        method: "PATCH",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant", applyId],
      });
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant", "imjangEvent"],
      });
    },
  });
};

//

// 임장 신청 스레드 조회
export const useGetThread = ({ id }: { id: string }) =>
  useQuery<ImjangThread>({
    queryKey: ["imjangApplicant", "thread", id],
    queryFn: async () => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${id}/thread`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 60 * 60 * 5,
  });

// 임장 스레드 추가
export const usePostThread = ({ id }: { id: string }) => {
  const queryClient = useQueryClient();
  return useMutation<{ message: string }, AxiosError, ImjangThreadReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${id}/thread`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant", id],
      });
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant", "thread", id],
      });
    },
  });
};

// 임장 스레드 추가
export const useDeleteThread = ({ id }: { id: string }) => {
  const queryClient = useQueryClient();
  return useMutation<{ message: string }, AxiosError, { threadId: string }>({
    mutationFn: async ({ threadId }) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${id}/thread/${threadId}`,
        method: "DELETE",
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant", "thread", id],
      });
    },
  });
};

// 임장 스레드에 파일 추가
export const useGetThreadPresignedUrl = () => {
  return useMutation<TreadFilePresignedRes, AxiosError, { objectKey: string }>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/post-presigned-url`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
    // onSuccess: () => {
    //   queryClient.invalidateQueries({
    //     queryKey: ["imjangApplicant", "thread", id],
    //   });
    // },
  });
};

// 임장 스레드에 있는 파일 presigned url 로드
export const useGetThreadFile = () => {
  return useMutation<
    { presigned_url: string },
    AxiosError,
    { objectKey: string }
  >({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/get-presigned-url`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
  });
};

// 임장 담당자 추가
export const useAssignMadmin = () => {
  const queryClient = useQueryClient();
  return useMutation<
    { message: string },
    AxiosError,
    { applyIdList: string[]; madminIdList: string[] }
  >({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/assign`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant"],
      });
    },
  });
};

// 임장 신청 담당자 변경
export const useEditMadmin = () => {
  const queryClient = useQueryClient();
  return useMutation<
    { message: string },
    AxiosError,
    { applyId: string; madminIdList: string[] }
  >({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/assign`,
        method: "PATCH",
        data,
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["imjangApplicant"],
      });
    },
  });
};
