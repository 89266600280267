export const useScrollRestoration = () => {
  const handleMoveScroll = () => {
    const element = document.getElementById("scrollableElement");
    const savedScrollX = localStorage.getItem("scroll")?.split("_")[0];
    const savedScrollY = localStorage.getItem("scroll")?.split("_")[1];

    if (savedScrollX !== null && element) {
      element.scrollTo({
        left: parseInt(savedScrollX, 10),
      }); // 가로 이동
      window.scrollTo({ top: parseInt(savedScrollY, 10) }); // 세로 이동

      setTimeout(() => localStorage.setItem("scroll", "0_0"), 300);
    }
  };

  const handleSaveScroll = () => {
    const element = document.getElementById("scrollableElement");

    localStorage.setItem(
      "scroll",
      `${element.scrollLeft.toString()}_${window.scrollY}`
    );
  };

  return { handleSaveScroll, handleMoveScroll };
};
