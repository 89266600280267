import styled from "styled-components";
import AddButton from "../../AddButton";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import ReviewPopUp from "./ReviewPopUp";
import { useState } from "react";
import ReviewCard from "./ReviewCard";
import { useConsultantInfo } from "@features/useConsultantInfo";

export default function Review() {
  const { globalConsultantInfo, setGlobalConsultantInfo } = useConsultantInfo();
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const openPopUp = () => {
    setIsVisible(true);
  };
  const copyArr = [...globalConsultantInfo.reviews];

  const handleSubmit = (reviewInfo) => {
    // 리뷰 수정
    if (selectedIdx || selectedIdx === 0) {
      copyArr[selectedIdx] = reviewInfo;
      setGlobalConsultantInfo({ reviews: copyArr });
    } else {
      // 리뷰 생성
      const newArr = [...copyArr, reviewInfo];
      setGlobalConsultantInfo({ reviews: newArr });
    }
  };

  const handleEdit = (idx) => {
    setSelectedIdx(idx);
    openPopUp();
  };

  const handleDelete = (idx) => {
    if (window.confirm("선택한 리뷰를 삭제합니다.")) {
      const reviewsArr = [...globalConsultantInfo.reviews];
      reviewsArr.splice(idx, 1);
      setGlobalConsultantInfo({ reviews: reviewsArr });
    }
  };

  return (
    <Container>
      <AddButton
        onClick={() => {
          openPopUp();
          setSelectedIdx(null);
        }}
      />
      {copyArr.map((el, idx) => {
        return (
          <ReviewCard
            key={idx}
            data={el}
            style={{ border: idx === copyArr.length - 1 && "none" }}
            handleEdit={() => {
              handleEdit(idx);
            }}
            handleDelete={() => handleDelete(idx)}
          />
        );
      })}

      {/* <ReviewCard /> */}
      <PopUpContainer isVisible={isVisible} setIsVisible={setIsVisible}>
        <ReviewPopUp
          setIsVisible={setIsVisible}
          handleSubmit={handleSubmit}
          selectedIdx={selectedIdx}
        />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div``;
