import { useGetCoinHistory } from "@api/management/coin";
import styled from "styled-components";
import CoinHistoryTable from "./CoinHistoryTable";
import { Title } from "@components/styles";
import { useNavigate } from "react-router-dom";
import Skeleton from "@components/skeleton/Skeleton";

export default function CoinHistoryPage() {
  const navigate = useNavigate();
  const { data, isLoading } = useGetCoinHistory();

  return (
    <Container>
      <Title>코인 충전 내역</Title>
      <UploadButton onClick={() => navigate("add")}>
        <p>코인 충전하기</p>
      </UploadButton>
      <CoinHistoryTable data={data?.transactions || []} isLoading={isLoading} />
    </Container>
  );
}

const Container = styled.div``;

const UploadButton = styled.div`
  margin: 24px 0;
  padding: 9px 16px;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;

  p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: white;
  }
`;
