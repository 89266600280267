import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getCoreRowModel } from "@tanstack/react-table";
import "@components/table.css";
import Empty from "@components/common/Empty";
import { convertToKoreanTime } from "@hooks/calc/converToKoreanDate";
import Pagination from "@components/core/paging/Pagination";
import TableSkeleton from "@components/skeleton/TableSkeleton";

export default function CoinHistoryTable({ data, isLoading }) {
  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("index", {
        cell: (info) => data.length - info.row.index,
        header: "순번",
        maxSize: 5,
      }),
      columnHelper.accessor("nickname", {
        header: "지급 대상",
        maxSize: 20,
      }),
      columnHelper.accessor("item", {
        header: "지급 사유",
        maxSize: 20,
      }),
      columnHelper.accessor("amount", {
        cell: ({ getValue }) => {
          return getValue() ? getValue().toLocaleString() : "-";
        },
        header: "지급 개수",
        maxSize: 20,
      }),

      columnHelper.accessor("timestamp", {
        cell: ({ getValue }) => {
          return (
            <p style={{ textAlign: "center" }}>
              {convertToKoreanTime(getValue())}
            </p>
          );
        },
        header: "지급 시간",
        maxSize: 15,
      }),
    ];
  }, [data]);

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  useEffect(() => {
    table.reset();
  }, [data]);

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="contentsData">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Empty data={data} title="코인 충전 내역이 없습니다" />
      )}

      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div`
  /* padding-top: 80px; */
`;

const Title = styled.h2`
  text-align: left;
  padding: 16px 0;
  cursor: pointer;
  width: 55vw;
  max-width: 60vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
