import { Title } from "@components/styles";
import { useEffect, useState } from "react";

import styled from "styled-components";
import AccountingStatus from "./AccountingStatus";
import ViewAccountingTable from "./ViewAccountingTable";
import { useGetContentPaymentsList } from "@api/contributor";
import { useGetInfluencerPaymentsList } from "@hooks/admin";

export default function MyContentsPage() {
  const [statData, setStatData] = useState({
    totalPayments: 0,
    influencers: [],
  });
  const { data } = useGetContentPaymentsList({ start_date: "", end_date: "" });
  const { mutate: getAccountStat, data: accountStat } =
    useGetInfluencerPaymentsList();

  useEffect(() => {
    getAccountStat({ start_date: "", end_date: "" });
  }, []);

  useEffect(() => {
    if (accountStat) {
      setStatData(accountStat);
    }
  }, [accountStat]);

  return (
    <Container>
      <Title>정산내역</Title>
      <AccountingStatus
        total={statData?.totalPayments}
        data={statData?.influencers}
      />
      <ViewAccountingTable data={data?.contents || []} />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;
