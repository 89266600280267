import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { Cookies } from "react-cookie";

// 기본 URL
export const communityBaseURL = process.env.REACT_APP_BACKEND_COMMUNITY_HOST;
export const baseUrl = process.env.REACT_APP_BACKEND_HOST;

const cookies = new Cookies();
let isRefreshing = false;
let refreshSubscribers: Array<(token: string) => void> = [];

// 구독자 등록 함수
function subscribeTokenRefresh(cb: (token: string) => void) {
  refreshSubscribers.push(cb);
}

// 새로운 토큰이 발급되면 구독자에게 알림
function onRefreshed(token: string) {
  refreshSubscribers.forEach((cb) => cb(token));
  refreshSubscribers = [];
}

// 401 에러 처리 함수
async function handle401Error(error: any, axiosInstance: AxiosInstance) {
  const originalRequest = error.config;

  // 401 에러 발생 시
  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    if (!isRefreshing) {
      isRefreshing = true;

      try {
        const access_token = cookies.get("access_token");
        const data = await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}auth/login/refresh/${access_token}`,
          { withCredentials: true }
        );

        const accessToken = data.data.jwt.split(" ")[1];

        cookies.set("access_token", accessToken, { path: "/" });
        isRefreshing = false;
        onRefreshed(accessToken);
      } catch (err) {
        console.error("토큰 리프레시 실패", err.message);

        if (err.status === 400) {
          window.location.replace("/login");
          return;
        }

        isRefreshing = false;
        return Promise.reject(err);
      }
    }

    // 토큰 갱신을 기다리고 원래 요청을 다시 시도
    return new Promise((resolve) => {
      subscribeTokenRefresh((token: string) => {
        originalRequest.headers["Authorization"] = `Bearer ${token}`;
        resolve(axiosInstance(originalRequest));
      });
    });
  }

  return Promise.reject(error);
}

// axios 인스턴스 생성 함수
function CreateInstance(
  baseURL: string,
  withCredentials: boolean
): AxiosInstance {
  const axiosInstance = axios.create({ baseURL, withCredentials });

  axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const token = cookies.get("access_token");
      if (token && token !== "undefined") {
        config.headers["Authorization"] = `Bearer ${token}`;
      } else {
        console.log("토큰 없음");
      }
      return config;
    },
    (error) => {
      console.error(error);
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      switch (error?.status) {
        case 401:
          handle401Error(error, axiosInstance); // 분리된 401 처리 함수 사용
          break;
        case 406:
          window.location.replace("/login");
          break;
        default:
          console.log("에러발생", error);
          return Promise.reject(error); // 에러를 Promise.reject로 반환하여 호출한 쪽에서 에러를 처리할 수 있도록 함
      }
    }
  );

  return axiosInstance;
}

// axios 인스턴스 생성 및 export
export const AuthInstance = CreateInstance(communityBaseURL, false);
export const Instance = CreateInstance(baseUrl, false);
export const AuthInstanceWithCredentials = CreateInstance(baseUrl, true);
