import styled from "styled-components";
import InputRow from "./InputRow";

export default function CashInput({ value, onChange }) {
  return (
    <InputRow title="연봉">
      <Container>
        <Input
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          value={value ? Number(value / 10000).toLocaleString() : ""}
          onChange={(e) => {
            const numericValue = e.target.value.replace(/,/g, "");
            onChange({
              name: "annualSalary",
              value: Number(numericValue) * 10000,
            });
          }}
          placeholder="0"
        />
        <Unit>만원</Unit>
      </Container>
    </InputRow>
  );
}
const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 36px 8px 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  color: ${({ theme }) => theme.colors.blueGray700};
  font-size: 15px;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
`;

const Unit = styled.span`
  position: absolute;
  right: 12px;
  color: ${({ theme }) => theme.colors.blueGray500};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
`;
