import moment from "moment";

export const convertToKoreanDate = (date) => {
  if (!date) {
    return "-";
  }

  const koreanDate = moment(date).utcOffset(9);
  return koreanDate.format("YYYY.MM.DD");
};

export const convertToKoreanTime = (time) => {
  if (!time) {
    return "-";
  }

  const inputMoment = moment(time);
  const outputDateString = inputMoment.format("YYYY.MM.DD. HH:mm");
  return outputDateString;
};

export const convertToKoreanTimeFromUTC = (time) => {
  if (!time) {
    return "-";
  }

  const moment = require("moment-timezone");
  const koreaTime = moment.tz(time, "Asia/Seoul").add(9, "hours");
  const outputDateString = koreaTime.format("YYYY.MM.DD. HH:mm");
  return outputDateString;
};
