import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";

type ParamsObj = { [key: string]: string };

const useCustomSearchParams = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const setNewParams = (newParams: ParamsObj) => {
    for (const [key, value] of Object.entries(newParams)) {
      if (value) searchParams.set(key, value);
      else searchParams.delete(key);
    }
    return searchParams.toString();
  };

  const setSearchParams = (
    newParams: ParamsObj | ((prev: ParamsObj) => ParamsObj),
    isReplace?: boolean
  ) => {
    const _newParams =
      typeof newParams === "function"
        ? newParams(Object.fromEntries(searchParams))
        : newParams;

    const newUrl = `${location.pathname}?${setNewParams(_newParams)}`;

    if (isReplace) navigate(newUrl, { replace: true });
    else navigate(newUrl);
  };

  return { searchParams: Object.fromEntries(searchParams), setSearchParams };
};

export default useCustomSearchParams;
