import { BTypeTyping48 } from "@components/core/BTypeInput";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import { TextInputEnum } from "@components/input/BTypeText48/BTypeText.interfaces";
import BTypeText48 from "@components/input/BTypeText48/BTypeText48";
import styled from "styled-components";
import VisitDate from "./VisitDate";

export default function ScheduleInputList({ userInfo, setUserInfo }) {
  return (
    <div className="list">
      <InputRow>
        <span className="inputName">스케줄명</span>
        <BTypeText48
          inputType={InputTypeEnum.OnlyText}
          value={userInfo.description}
          placeholder="동,층,호 등"
          onChangeValue={(value) =>
            setUserInfo((prev) => ({
              ...prev,
              description: value,
            }))
          }
          textType={TextInputEnum.DEFAULT}
        />
      </InputRow>
      <InputRow>
        <span className="inputName">방문 날짜</span>
        <VisitDate info={userInfo} setInfo={setUserInfo} />
      </InputRow>
      <InputRow>
        <span className="inputName">약속 장소</span>
        <BTypeText48
          inputType={InputTypeEnum.OnlyText}
          value={userInfo.meetingPlace}
          placeholder="약속 장소 입력"
          onChangeValue={(value) =>
            setUserInfo((prev) => ({
              ...prev,
              meetingPlace: value,
            }))
          }
          textType={TextInputEnum.DEFAULT}
        />
      </InputRow>
      <InputRow>
        <span className="inputName">중개소명</span>
        <BTypeText48
          inputType={InputTypeEnum.OnlyText}
          value={userInfo.agentOfficeName}
          placeholder="중개소명 입력"
          onChangeValue={(value) =>
            setUserInfo((prev) => ({
              ...prev,
              agentOfficeName: value,
            }))
          }
          textType={TextInputEnum.DEFAULT}
        />
      </InputRow>
      <InputRow>
        <span className="inputName">중개소 연락처</span>
        <BTypeText48
          inputType={InputTypeEnum.OnlyText}
          value={userInfo.agentOfficeContact}
          placeholder="중개소 연락처 입력"
          onChangeValue={(value) =>
            setUserInfo((prev) => ({
              ...prev,
              agentOfficeContact: value,
            }))
          }
          textType={TextInputEnum.DEFAULT}
        />
      </InputRow>
    </div>
  );
}

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 8px;

  .inputName {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;
