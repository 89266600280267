import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import BTypeInputBoxTyping from "@components/core/BTypeInput/BTypeInputBoxTyping";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import { useConsultantInfo } from "@features/useConsultantInfo";
import { useMemo } from "react";
import styled from "styled-components";

export default function YoutubeDetails() {
  const { globalConsultantInfo, setGlobalConsultantInfo } = useConsultantInfo();

  const youtubeThumbnail = useMemo(() => {
    if (
      globalConsultantInfo.youtubeDetails.url &&
      globalConsultantInfo.youtubeDetails.url.includes("https://")
    ) {
      const url = new URL(globalConsultantInfo.youtubeDetails.url);
      const searchParams = new URLSearchParams(url.search);
      const videoId = searchParams.get("v");

      return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
    } else return "";
  }, [globalConsultantInfo.youtubeDetails.url]);

  return (
    <Container>
      <BTypeInputBoxTyping
        inputType={InputTypeEnum.OnlyText}
        placeholder={"영상 URL"}
        value={globalConsultantInfo.youtubeDetails.url}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setGlobalConsultantInfo({
            youtubeDetails: {
              url: v || null,
              description: globalConsultantInfo.youtubeDetails.description,
            },
          })
        }
        style={{ padding: 0, paddingLeft: 0, maxWidth: 500 }}
      />
      <BTypeTextArea
        placeholder="한줄 설명"
        textareaStyle={{
          maxWidth: 500,
          maxHeight: 110,
          height: 300,
        }}
        value={globalConsultantInfo.youtubeDetails.description}
        onChangeValue={(v: InputTypeEnum.OnlyText) =>
          setGlobalConsultantInfo({
            youtubeDetails: {
              url: globalConsultantInfo.youtubeDetails.url,
              description: v || null,
            },
          })
        }
      />
      {youtubeThumbnail ? (
        <img
          src={youtubeThumbnail}
          style={{
            marginTop: 24,
            width: 200,
            height: 110,
            borderRadius: 8,
          }}
          alt="유튜브 썸네일"
        />
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
