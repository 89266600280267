import { useTokenRefresh } from "@api/login";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { Cookies, useCookies } from "react-cookie";

const useApiError = () => {
  const cookies = new Cookies();
  const access_token = cookies.get("access_token");

  const handleError = useCallback(async (axiosError: AxiosError) => {
    const statusCode = axiosError.response?.status;

    // if (message?.includes("검증되지 않은")) {
    //   return;
    // } else if (message?.includes("존재하지")) {
    //   return;
    // }

    switch (statusCode) {
      // 토큰 에러
      case 401:
        // removeCookie("access_token");
        // alert(
        //   `잘못된 요청입니다. 로그인 화면으로 이동합니다. \n🚨 서버 에러메시지 : ${
        //     (axiosError.response.data as { message: string }).message
        //   }`
        // );
        // window.location.replace("/login");
        break;
      case 402:
        // const response = await fetchWithCredentials(access_token);
        // console.log("🐙 ~ handleError ~ response:", response);
        // const 쿠키에_토큰저장 = (token) => {
        //   const accessToken = token.split(" ")[1];
        //   cookies.set("access_token", accessToken, { path: "/" });
        // };

        // 쿠키에_토큰저장(response.jwt);
        break;
      case 403:
        // alert(
        //   `접근 권한이 없습니다. 로그인 화면으로 이동합니다. \n🚨 서버 에러메시지 : ${
        //     (axiosError.response.data as { message: string }).message
        //   }`
        // );
        window.location.replace("/403");
        break;
      default:
        console.log("에러");
        break;
      // default:
      //   alert(
      //     `일시적인 오류가 발생했어요. \n🚨 서버 에러메시지 : ${
      //       (axiosError.response.data as { message: string }).message ||
      //       (axiosError.response.data as { msg: string }).msg
      //     }`
      //   );
      //   break;
    }
  }, []);
  return { handleError } as const;
};

export default useApiError;
