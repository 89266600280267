import { Calendar } from "@components/core/calendar";
import ConsultationReportTable from "@components/page/admin/consultation/report/ConsultationReportTable";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useGetApplicantsForReport } from "@hooks/consultation";
import { useEffect, useState } from "react";
import styled from "styled-components";

export default function ConsultationReportList() {
  const { setCurrentMenu } = useMenu();
  const [rangeDate, setRangeDate] = useState({ start: "", end: "" });
  const { data } = useGetApplicantsForReport({
    isSubmitted: true,
    ...rangeDate,
  });

  useEffect(() => {
    setCurrentMenu("consultationReport");
  }, []);

  return (
    <Container>
      <Title>보고서 관리</Title>
      <div style={{ margin: "0 0 24px auto", width: "fit-content" }}>
        <Calendar setRangeDate={setRangeDate} text="기준: 제출날짜" />
      </div>
      <ConsultationReportTable data={data?.applicants || []} />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;
