import { Close16 } from "@components/core/icon/close";
import { useGetRegionList } from "@hooks/imjang";
import { useMemo, useState } from "react";
import styled from "styled-components";
import DropDownInput from "./DropDownInput";
import { useAlert } from "@features/useAlert";
import { useUserInfo } from "@features/useUserInfo";

export default function Area() {
  const { globalUserInfo, setGlobalRegionList } = useUserInfo();
  const { data } = useGetRegionList();
  const { showGlobalAlert } = useAlert();
  const [sido, setSido] = useState("서울특별시");

  const selectedPnu = useMemo(() => {
    if (!globalUserInfo.regionList) return [];
    return globalUserInfo.regionList;
  }, [globalUserInfo]);

  // { sido: region.sido, sigugun: el.sigugun, pnu: el.pnu } 형태의 선택 보여주기용 배열
  const 선택배열 = useMemo(() => {
    if (!selectedPnu || selectedPnu.length <= 0) return [];

    return selectedPnu.flatMap((region) =>
      region.sigugunList.map((el) => {
        return { sido: region.sido, sigugun: el.sigugun, pnu: el.pnu };
      })
    );
  }, [selectedPnu]);

  const sidoList = useMemo(() => {
    if (!data) return [];
    return data.regionList.map((el) => {
      return { title: el.sido, value: el.sido };
    });
  }, [data]);

  const areaList = useMemo(() => {
    if (data) {
      const selectRegion = data?.regionList?.find((el) => el.sido === sido);
      return selectRegion?.sigugunList;
    } else return [];
  }, [data, sido]);

  const findRegionByPnu = (pnu: number) => {
    for (const region of data.regionList) {
      const target = region.sigugunList.find((item) => item.pnu === pnu);
      if (target) {
        return { sido: region.sido, sigugun: target.sigugun, pnu: target.pnu };
      }
    }
    return null; // 찾지 못했을 경우 null 반환
  };

  const formatRegionsForServer = (selectedRegions) => {
    const result = [];

    selectedRegions.forEach(({ sido, sigugun, pnu }) => {
      const existingRegion = result.find((region) => region.sido === sido);
      if (existingRegion) {
        // 기존에 sido가 있는 경우, sigugunList에 추가
        existingRegion.sigugunList.push({ sigugun, pnu });
      } else {
        // 새로운 sido를 추가
        result.push({
          sido,
          sigugunList: [{ sigugun, pnu }],
        });
      }
    });

    return result;
  };

  const 지역선택 = (item: { sigugun: string; pnu: number }) => {
    if (선택배열.some((el) => el.pnu === item.pnu)) {
      const newArr = 선택배열.filter((el) => el.pnu !== item.pnu);
      setGlobalRegionList(formatRegionsForServer(newArr));
    } else {
      if (선택배열.length >= 3) {
        showGlobalAlert({
          desc: "추천 지역은 3개까지 선택 가능해요.",
          blue: true,
        });
      } else {
        const newArr = [...선택배열, findRegionByPnu(item.pnu)];
        setGlobalRegionList(formatRegionsForServer(newArr));
      }
    }
  };

  return (
    <Container>
      <div className="fixedBox">
        {선택배열.map((el, idx) => (
          <SelectedValue key={idx}>
            <p>{`${el.sido} ${el.sigugun}`}</p>
            <span onClick={() => 지역선택(el)}>
              <Close16 />
            </span>
          </SelectedValue>
        ))}
      </div>
      <div className="dropDown">
        <DropDownInput
          placeholder="지역을 선택해주세요"
          value={sido}
          onChange={(v: string) => setSido(v)}
          optionList={sidoList}
          style={{ marginBottom: 0, width: 327 }}
        />
      </div>
      <div className="areaList">
        {areaList.map((el, idx) => (
          <AreaItem
            onClick={() => 지역선택(el)}
            $active={선택배열.some((pnu) => el.pnu === pnu.pnu)}
            key={idx}
          >
            <p>{el.sigugun}</p>
          </AreaItem>
        ))}
      </div>
    </Container>
  );
}

const Container = styled.div`
  padding-bottom: 60px;

  .fixedBox {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 64px;
    width: 100%;
    background: ${({ theme }) => theme.colors.blueGrayA100};
    overflow: scroll;

    div:first-child {
      margin-left: 24px;
    }

    div:last-child {
      margin-right: 24px;
    }
  }

  .areaList {
    display: flex;
    padding: 0 24px;
    gap: 12px 10px;
    flex-wrap: wrap;
  }

  .dropDown {
    padding: 24px 24px 13px;
  }
`;

const SelectedValue = styled.div`
  position: relative;
  display: flex;
  gap: 4px;
  height: 32px;
  min-width: fit-content;
  padding: 5px 32px 5px 10px;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray200};
  background: white;

  p {
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }

  span {
    position: absolute;
    display: flex;
    right: 0;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
  }
`;

const AreaItem = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 102px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, $active }) =>
      $active ? theme.colors.primaryBlue100 : theme.colors.blueGray200};
  background: #fff;
  cursor: pointer;

  p {
    color: ${({ theme, $active }) =>
      $active ? theme.colors.primaryBlue100 : theme.colors.blueGray600};
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;
