import { createSlice } from "@reduxjs/toolkit";

export const routerSlice = createSlice({
  name: "router",
  initialState: { menu: "", subMenu: "", themeMode: "dark" },
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    setSubMenu: (state, action) => {
      state.subMenu = action.payload;
    },
    setThemeMode: (state, action) => {
      state.themeMode = action.payload;
    },
  },
});

export const { setMenu, setSubMenu, setThemeMode } = routerSlice.actions;

export default routerSlice.reducer;
