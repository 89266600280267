import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { SyncLoader } from "react-spinners";

export default function AbsoluteFillLoading() {
  return (
    <PopUpContainer isVisible={true} setIsVisible={() => {}}>
      <SyncLoader />
    </PopUpContainer>
  );
}
