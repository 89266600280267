import { useEffect, useState } from "react";
import styled from "styled-components";

export default function SmallRating({ rating }) {
  const [starArr, setStarArr] = useState([false, false, false, false, false]);

  const fillStar = () => {
    const copyArr = [...starArr];
    const newArr = copyArr.map((el, index) => {
      if (rating > index) {
        return true;
      }
    });
    setStarArr(newArr);
  };

  useEffect(() => {
    fillStar();
  }, [rating]);

  return (
    <Container>
      {starArr.map((el, idx) => (
        <MiniStar active={el} key={idx} />
      ))}
    </Container>
  );
}

const Container = styled.div``;

const MiniStar = ({ active }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M8.04894 0.927049C8.3483 0.00573802 9.6517 0.00574017 9.95106 0.927051L11.2451 4.90983C11.379 5.32185 11.763 5.60081 12.1962 5.60081H16.3839C17.3527 5.60081 17.7554 6.84043 16.9717 7.40983L13.5838 9.87132C13.2333 10.126 13.0866 10.5773 13.2205 10.9894L14.5146 14.9721C14.8139 15.8934 13.7595 16.6596 12.9757 16.0902L9.58778 13.6287C9.2373 13.374 8.7627 13.374 8.41221 13.6287L5.02426 16.0902C4.24054 16.6596 3.18607 15.8934 3.48542 14.9721L4.7795 10.9894C4.91338 10.5773 4.76672 10.126 4.41623 9.87132L1.02827 7.40983C0.244561 6.84043 0.647338 5.60081 1.61606 5.60081H5.8038C6.23703 5.60081 6.62099 5.32185 6.75486 4.90983L8.04894 0.927049Z"
        fill={active ? "#FF9C00" : "#E5E7E9"}
      />
    </svg>
  );
};
