export default function ExcelIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.4296 3.71387H8.00049V12.0001H13.4296V3.71387Z"
        fill="#247044"
      />
      <path
        d="M13.4286 3.71386V12.0001H7.9995V3.71386H13.4286ZM13.4286 3.14258H7.9995C7.68426 3.14258 7.42822 3.39785 7.42822 3.71386V12.0001C7.42822 12.3154 7.68349 12.5714 7.9995 12.5714H13.4286C13.7438 12.5714 13.9999 12.3162 13.9999 12.0001V3.71386C13.9999 3.39862 13.7446 3.14258 13.4286 3.14258Z"
        fill="white"
      />
      <path
        d="M12.5712 4.57117H10.5713V5.42847H12.5712V4.57117Z"
        fill="white"
      />
      <path
        d="M12.5712 5.99976H10.5713V6.85706H12.5712V5.99976Z"
        fill="white"
      />
      <path
        d="M12.5712 7.42908H10.5713V8.28638H12.5712V7.42908Z"
        fill="white"
      />
      <path
        d="M12.5712 8.85767H10.5713V9.71497H12.5712V8.85767Z"
        fill="white"
      />
      <path
        d="M12.5712 10.2855H10.5713V11.1428H12.5712V10.2855Z"
        fill="white"
      />
      <path
        d="M10.0004 4.57117H8.00049V5.42847H10.0004V4.57117Z"
        fill="white"
      />
      <path
        d="M10.0004 5.99976H8.00049V6.85706H10.0004V5.99976Z"
        fill="white"
      />
      <path
        d="M10.0004 7.42908H8.00049V8.28638H10.0004V7.42908Z"
        fill="white"
      />
      <path
        d="M10.0004 8.85767H8.00049V9.71497H10.0004V8.85767Z"
        fill="white"
      />
      <path
        d="M10.0004 10.2855H8.00049V11.1428H10.0004V10.2855Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.14295 2L2 3.25098V12.7483L9.14295 14V2Z"
        fill="white"
      />
      <path
        d="M7.10327 5.42842L6.10602 5.48839L5.49706 6.91698L5.45708 7.04538L5.42786 7.14226L5.40172 7.23607L5.38173 7.31373C5.38173 7.27528 5.37327 7.23914 5.35559 7.20531L5.32945 7.10536L5.30023 7.01155L5.2687 6.92544L4.74586 5.56835L3.78322 5.62833L4.80891 7.85118L3.6748 10.074L4.61438 10.1309L5.24564 8.67388L5.27486 8.57393L5.29792 8.48474L5.31791 8.41016V8.35018L5.3379 8.45552L5.3579 8.54471L5.37558 8.61314L5.39326 8.66158L6.05066 10.2155L7.14248 10.2839L5.9138 7.8358L7.10173 5.42688"
        fill="#247044"
      />
    </svg>
  );
}
