import { ActionEnum } from "@components/core/icon/action/Action.interfaces";
import Action24 from "@components/core/icon/action/Action24";
import { useDeletePushGroup } from "@hooks/management";
import { FadeLoader } from "react-spinners";

import styled from "styled-components";

export default function DeleteFilePopup({ setIsVisible, selectedRow }) {
  const { mutate, isLoading } = useDeletePushGroup();

  const 그룹_삭제 = () => {
    mutate(
      { target_name: selectedRow.target_name },
      {
        onSuccess: () => {
          alert("그룹 삭제 완료");
          setIsVisible(false);
        },
        onError: (res) => alert(res.response.data),
      }
    );
  };

  return (
    <Container>
      <Content>
        <InputContainer>
          <p className="label">등록 파일</p>
          <input
            value={selectedRow?.target_name}
            onChange={() => {}}
            readOnly
          />
        </InputContainer>
        <Caution>
          <Action24 status={ActionEnum.WARNING} />
          <p>
            푸시 대기 중인 그룹은 삭제할 수 없어요.{"\n"}
            푸시를 먼저 삭제하거나, 푸시 발송 이후 그룹을 삭제할 수 있어요.
          </p>
        </Caution>
        <Btn onClick={그룹_삭제}>
          {!isLoading ? (
            <p>그룹 삭제</p>
          ) : (
            <FadeLoader
              cssOverride={{
                width: 10,
                height: 10,
                transform: "scale(0.3)",
                top: 0,
                left: 0,
              }}
              color="#f04452"
            />
          )}
        </Btn>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  width: 460px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .button {
    width: 125px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.blue100};
    cursor: pointer;

    color: var(--Blue1000, #1c84ff);
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }

  .light {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
  }
`;

const Caution = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;

  p {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    white-space: pre;
  }
`;

const Btn = styled.button`
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  padding: 9px 0;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.red100};
  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.red1000};
    font-size: 15px;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrayA200};
  margin-bottom: 40px;

  .label {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }

  input {
    display: flex;
    flex-grow: 2;
    margin: 8px 0 4px;
    border: none;
    height: 32px;

    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-weight: 400;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;

    &:focus {
      outline: none;
    }
  }
`;
