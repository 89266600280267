import { useState } from "react";
import { Cookies } from "react-cookie";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { SyncLoader } from "react-spinners";

export default function PrivateRouter({ authentication }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuth = async () => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("access_token");
      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}auth/common/authorities`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!result.data.length) {
        window.location.replace("/403");
      } else return true;
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    if (authentication) {
      (async () => {
        const result = await checkAuth();

        console.log({ result });
        setIsLoading(false);
        setIsAuthenticated(result);
      })();
    }
  }, [authentication]);

  if (!authentication) {
    // 인증 필요없는 화면
    return <Outlet />;
  }

  // 토큰 인증 필요한 화면
  if (isLoading) {
    return (
      <PopUpContainer isVisible={true} setIsVisible={() => {}}>
        <SyncLoader />
        <p
          style={{
            marginTop: 20,
            fontSize: 20,
            color: "white",
            fontWeight: 500,
          }}
        >
          권한 확인 중
        </p>
      </PopUpContainer>
    );
  }

  if (isAuthenticated) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
}
