import { useState } from "react";
import styled from "styled-components";
import { Title } from "@components/styles";
import ViewNoticeTable from "./ViewNoticeTable";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import NoticePopup from "./NoticePopup";
import { useGetNotificationList } from "@api/notice";

export default function NotificationPage() {
  const { data, isLoading } = useGetNotificationList();
  const [isVisible, setIsVisible] = useState(false);
  const [popupContent, setPopupContent] = useState({
    original: { idx: 0, content: "", title: "", publishedDate: "" },
  });

  const onClick = (data) => {
    setIsVisible(true);
    setPopupContent(data);
  };

  return (
    <Container>
      <Title>공지사항</Title>
      <ViewNoticeTable
        data={data?.announcements || []}
        onClick={onClick}
        isLoading={isLoading}
      />
      <PopUpContainer isVisible={isVisible} setIsVisible={setIsVisible}>
        <NoticePopup data={popupContent.original} setIsVisible={setIsVisible} />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div``;
