import styled from "styled-components";
import { ko } from "date-fns/esm/locale";
import React, { LegacyRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "@assets/icon/CalendarIcon";

export default function Clock({
  value,
  onChangeValue,
}: {
  onChangeValue: (v: any) => void;
  value: any;
  text?: string;
}) {
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    // 데이트 객체 형식으로 넣기
    if (!value) return;
    if (value instanceof Date) {
      setStartDate(value);
    } else {
      setStartDate(
        new Date(value?.seconds * 1000 + value?.nanoseconds / 1000000)
      );
    }
  }, [value]);

  const onChange = (date) => {
    setStartDate(date);
  };

  const ExampleCustomInput = (
    {
      onClick,
      value,
    }: {
      value: string;
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    },
    ref: LegacyRef<HTMLButtonElement>
  ) => {
    return (
      <button
        className="example-custom-input"
        onClick={onClick}
        ref={ref}
        style={{ display: "flex", alignItems: "center" }}
      >
        {startDate ? (
          <>
            <CalendarIcon />
            <DateValue style={{ marginRight: 32, marginLeft: 5, fontSize: 15 }}>
              {new Date(startDate).toLocaleString()}
            </DateValue>
          </>
        ) : (
          <CalendarIcon />
        )}
      </button>
    );
  };

  return (
    <Container>
      <DatePicker
        selected={new Date(startDate)}
        onChange={onChange}
        dateFormat="yyyy.MM.dd"
        showTimeInput
        locale={ko}
        // isClearable={startDate ? true : false}
        shouldCloseOnSelect={false}
        onCalendarClose={() => {
          onChangeValue(startDate);
        }}
        customInput={React.createElement(React.forwardRef(ExampleCustomInput))}
      />
    </Container>
  );
}

const Container = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
`;

// const Box = styled.label`
//   display: flex;
//   align-items: center;
//   padding: 4px 10px 3px;
//   border-radius: 8px;
//   border: 1px solid #d9d9d9;
//   cursor: pointer;
// `;

const DateValue = styled.p`
  color: ${({ theme }) => theme.colors.blueGray900};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;
`;

const Text = styled.p`
  margin: 6px 0 6px auto;
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blueGray500};
`;
