export const navConstantsList = [
  {
    title: "회원 관리",
    value: "A01",
    content: [
      {
        menu: "회원 관리",
        linkUrl: "/users",
      },
      {
        menu: "그룹 관리",
        linkUrl: "/users/group",
      },
    ],
  },
  {
    title: "기고문 관리",
    value: "A02",
    content: [
      {
        menu: "컨텐츠 업로드 관리",
        linkUrl: "/content",
      },

      {
        menu: "정산 관리",
        linkUrl: "/content/payment-form",
      },
      {
        menu: "정산 내역",
        linkUrl: "/content/payments",
      },
    ],
  },
  {
    title: "상담소 관리",
    value: "A03",
    content: [
      {
        menu: "상담 신청 현황",
        linkUrl: "/consultation/applications",
      },
      {
        menu: "컨설팅 관리",
        linkUrl: "/consultation/consultings",
      },
      {
        menu: "보고서 관리",
        linkUrl: "/consultation/reports",
      },
      {
        menu: "참고 자료 관리",
        linkUrl: "/consultation/reference",
      },
    ],
  },
  {
    title: "임장 비서 관리",
    value: "A04",
    content: [
      {
        menu: "임장 비서 신청 관리",
        linkUrl: "/imjang/applicants",
      },
      {
        menu: "중개사 관리",
        linkUrl: "/imjang/experts",
      },
    ],
  },
  {
    title: "운영 관리",
    value: "A05",
    content: [
      {
        menu: "푸시 발송 내역",
        linkUrl: "/manage/notification/log",
      },
      {
        menu: "푸시 그룹 관리",
        linkUrl: "/manage/push/group",
      },
      {
        menu: "푸시 스케줄 관리",
        linkUrl: "/manage/push",
      },
      {
        menu: "투자 요청 관리",
        linkUrl: "/manage/ir",
      },
      {
        menu: "뉴스 업로드 관리",
        linkUrl: "/manage/news",
      },
      {
        menu: "코인 충전 관리",
        linkUrl: "/manage/coin",
      },
      {
        menu: "임장 이야기 관리",
        linkUrl: "/manage/story",
      },

      // {
      //   menu: "매물 찾기",
      //   value: "maemul",
      //   linkUrl: "/admin/maemul",

      // },
    ],
  },
  {
    title: "공지사항 관리",
    value: "A06",
    content: [
      {
        menu: "공지사항 업로드",
        linkUrl: "/notice",
      },
    ],
  },
  {
    title: "기고문 등록",
    value: "B01",
    content: [
      {
        menu: "컨텐츠 업로드",
        linkUrl: `/report/upload`,
      },
      {
        menu: "컨텐츠 통계",
        linkUrl: `/report/stat`,
      },
      {
        menu: "정산내역",
        linkUrl: `/report/my`,
      },
    ],
  },
  {
    title: "내 상담소",
    value: "B02",
    content: [
      {
        menu: "보고서 작성",
        linkUrl: `/consulting/report`,
      },
      {
        menu: "보고서 관리",
        linkUrl: `/consulting/report/complete`,
      },
      {
        menu: "참고 자료",
        linkUrl: `/consulting/source`,
      },
    ],
  },
  {
    title: "임장 고객 관리",
    value: "B03",
    content: [
      {
        menu: "임장 고객 관리",
        linkUrl: "/visit",
      },
    ],
  },
  {
    title: "공지사항",
    value: "B04",
    content: [
      {
        menu: "공지사항",
        linkUrl: "/notification",
      },
    ],
  },
];

export const influencerNavList = [];

export const devNavList = [
  {
    title: "앱 운영 관리",
    content: [
      {
        menu: "앱 버전관리",
        value: "version",
        linkUrl: `/dev/version`,
      },
      {
        menu: "URL 관리",
        value: "url",
        linkUrl: `/dev/url`,
      },
      {
        menu: "white list 관리",
        value: "whiteList",
        linkUrl: `/dev/white-list`,
      },
    ],
  },
];
