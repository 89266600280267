import { useEffect, useState } from "react";
import styled from "styled-components";

import ModalContainer from "@components/core/modal/ModalContainer";
import GradientButton from "@components/button/GradientButton";
import CircleCheckRow from "@components/core/list/CircleCheckRow";
import { useGetAuthorityUsers } from "@hooks/admin";
import { useEditMadmin, useGetImjangApplicantDetail } from "@hooks/imjang";
import { useParams } from "react-router-dom";
import { useImjang } from "@features/useImjang";

export default function ControlBox({ managerList }) {
  const { id } = useParams();
  const { globalImjangStatus } = useImjang();
  const [isVisible, setIsVisible] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const { data } = useGetAuthorityUsers({ code: "B03" });
  const { data: detailInfo } = useGetImjangApplicantDetail({ id });
  const { mutate, isLoading } = useEditMadmin();

  const isDone = selected.length > 0;

  const handleOpen = () => {
    setIsVisible(true);
  };

  const handleSelect = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((s) => s !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleEdit = () => {
    if (selected.length === 0) return;
    if (isLoading) return;
    mutate({ applyId: id, madminIdList: selected });
    setIsVisible(false);
  };

  const 유저정보변경_팝업창_open = () => {
    const width = 560;
    const height = 722;
    const left = window.screenX + 100; // 현재 창의 X 좌표 기준
    const top = window.screenY + 100; // 현재 창의 Y 좌표 기준

    if (!detailInfo.userId) {
      alert("탈퇴한 유저이므로 회원 정보를 불러올 수 없습니다.");
      return;
    }

    window.open(
      `/add-info?applyId=${id}&userId=${detailInfo.userId}`, // 팝업에 표시할 URL 경로
      "popupWindow", // 창 이름
      `width=${width},height=${height},left=${left},top=${top},scrollbars=no` // 창 크기 및 옵션 설정
    );
  };

  useEffect(() => {
    if (managerList.length > 0 && data) {
      const aleadySelected = managerList.map((m) => m.madminId);
      const filtered = data.filter((d) => aleadySelected.includes(d._id));

      setSelected(filtered.map((m) => m._id));
    }
  }, [managerList, data]);

  return (
    <Container>
      {/* 앱 유저이면서 담당자거나 마스터에게만 노출 */}
      {!detailInfo?.isWebUser && globalImjangStatus.editPermission && (
        <ChangeButton onClick={유저정보변경_팝업창_open}>
          고객 추가 정보 입력
        </ChangeButton>
      )}
      {globalImjangStatus.editPermission && (
        <ChangeButton onClick={handleOpen}>담당자 변경</ChangeButton>
      )}

      <ModalContainer
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        title="담당자 변경"
        onCloseButton
        bottomButton={
          <GradientButton
            text="변경하기"
            isDone={isDone}
            onClick={handleEdit}
          />
        }
      >
        <Content>
          {data?.map((item) => {
            // console.log("🪄  {data?.map  item", item);
            const check = selected.includes(item._id);
            return (
              <CircleCheckRow
                key={item._id}
                text={item.name}
                active={check}
                onClick={() => handleSelect(item._id)}
              />
            );
          })}
        </Content>
      </ModalContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin-left: auto;
  gap: 10px;
`;

const ChangeButton = styled.button`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blueGray900};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
`;

const Content = styled.div`
  padding-bottom: 30px;
  width: 360px;
  height: 420px;
  overflow-y: auto;
`;
