import styled from "styled-components";

export default function UnderLineCopyCell({ content }) {
  const handleCopyClick = (textToCopy) => {
    if (textToCopy === "-") {
      return;
    }

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        if (textToCopy.includes("@")) {
          alert("신청자 이메일이 복사됐어요.");
        } else {
          alert("신청자 연락처가 복사됐어요.");
        }
      })
      .catch((error) => {
        console.error("복사 실패:", error);
      });
  };

  return (
    <Container onClick={() => handleCopyClick(content)}>
      {content ? <Text>{content}</Text> : <p>-</p>}
    </Container>
  );
}

const Container = styled.div`
  cursor: pointer;
`;

const Text = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
  text-underline-position: under;
`;
