import WhiteListTable from "@components/page/dev/whiteList/WhiteListTable";
import { Title } from "@components/styles";
import { db } from "../../firebase";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import styled from "styled-components";

export default function WhiteList() {
  const [data, setData] = useState([]);

  const getData = async () => {
    const arr = [];
    const querySnapshot = await getDocs(collection(db, "whiteList"));

    querySnapshot.forEach((doc) => {
      arr.push({ memo: doc.data().memo, ip: doc.id });
    });
    setData(arr);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <Title>white list 관리</Title>
      <div style={{ height: 40 }} />
      <WhiteListTable data={data} />
    </Container>
  );
}

const Container = styled.div``;
