import * as React from "react";

import { Props } from "./CheckBox.interfaces";

const bgColor = {
  Activate: "#1C84FF",
  Default: "#CFE4FD",
  Disabled: "#E5E7E9",
};
const lineColor = {
  Activate: "white",
  Default: "white",
  Disabled: "#B2B7BC",
};

export default function CheckBox24({ status }: Props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="12" fill={bgColor[status]} />
      <path
        d="M17 9L10.25 16L7 13.3922"
        stroke={lineColor[status]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
