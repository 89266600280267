import styled from "styled-components";

export default function Legend() {
  return (
    <LegendRow>
      <LegendBox color={"#FFA800"} />
      <p style={{ marginRight: 20 }}>조회 수(좌)</p>
      <LegendBox color={"#1C84FF"} />
      <p>추천 수(우)</p>
    </LegendRow>
  );
}
const LegendRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  p {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: ${({ theme }) => theme.colors.blueGray900};
  }
`;

const LegendBox = styled.div`
  margin-right: 10px;
  width: 16px;
  height: 16px;
  background-color: ${({ color }) => color};
`;
