import { useEffect } from "react";

function useClickOutside(refs, onClickOutside) {
  useEffect(() => {
    function handleClickOutside(event) {
      // refs 배열을 순회하면서 클릭된 요소가 어떤 ref에도 포함되지 않았는지 확인
      const isOutside = refs.every(
        (ref) => ref.current && !ref.current.contains(event.target)
      );

      if (isOutside) {
        onClickOutside();
      }
    }

    // 문서에 클릭 이벤트 리스너 추가
    document.addEventListener("mousedown", handleClickOutside);

    // 컴포넌트가 언마운트될 때 이벤트 제거
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs, onClickOutside]);
}

export default useClickOutside;
