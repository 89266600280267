import React, { useState, useRef, useEffect } from "react";
import { InputStatusEnum, InputTypeEnum } from "./BTypeInput.interfaces";

import styled from "styled-components";
import { Props } from "./BTypeInputBoxBtn.interfaces";

export default function BTypeInputBoxBtn({
  inputType = InputTypeEnum.Cash,
  label,
  value,
  onChangeValue,
  listData,
  disabled,
  style,
  inputStyle,
}: Props) {
  const selectRef = useRef(null);
  const [isFocus, setIsFocus] = useState(false);
  const [selectValue, setSelectValue] = useState("빈값");

  const onFocus = () => {
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  useEffect(() => {
    if (typeof value === "undefined") {
      setSelectValue("빈값");
    } else {
      setSelectValue(value);
    }
  }, [value]);

  return (
    <Container
      style={{ ...style }}
      value={value}
      $isFocus={isFocus}
      $disabled={disabled}
    >
      {label ? <label className="label">{label}</label> : null}
      <InputContainer
        $isFocus={isFocus}
        $disabled={disabled}
        style={{ ...inputStyle }}
      >
        <select
          ref={selectRef}
          onChange={onChangeValue}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          value={selectValue}
        >
          {listData.map((el, idx) => {
            if (idx === 0) {
              return (
                <option disabled value={"빈값"} key={idx}>
                  {el.label}
                </option>
              );
            } else {
              return (
                <option value={el.value} key={idx}>
                  {el.label}
                </option>
              );
            }
          })}
        </select>
      </InputContainer>
    </Container>
  );
}

const Container = styled.div<{
  value: string | number;
  $isFocus: boolean;
  $disabled: boolean;
}>`
  position: relative;
  padding: 28px 24px;

  .label {
    position: absolute;
    left: 24px;
    top: 2px;
    color: ${({ theme, $isFocus }) =>
      $isFocus ? theme.colors.blue1000 : theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  select {
    width: 100%;
    height: 28px;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.blueGray100 : theme.colors.blueGrayA100};

    color: ${({ theme, value }) =>
      value ? theme.colors.blueGray800 : theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }
`;

const InputContainer = styled.div<{ $isFocus: boolean; $disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding: 13px 16px;
  border: 1px solid
    ${({ theme, $isFocus }) =>
      $isFocus ? theme.colors.blue600 : theme.colors.blueGray100};
  background-color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.blueGray100 : theme.colors.blueGrayA100};
  border-radius: 10px;

  &.disabledBG {
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }

  &.defaultBG {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
  }
`;

const Input = styled.input`
  width: 80%;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primaryBlueGray};
  border: none;
  border-radius: 10px;
  outline: none;
  letter-spacing: -0.02em;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
  }

  &.disabledBG {
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }

  &.defaultBG {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
  }

  .defaultInputColor {
    color: ${({ theme }) => theme.colors.primaryBlueGray};
  }

  .disabledInputColor {
    color: ${({ theme }) => theme.colors.blueGray500};
  }
`;

//    const handleChangeSelect = () => {};
//    return (
//      <Container>
//        <label className="label">{label}</label>
//        <select className="select" onChange={handleChangeSelect} value={"4"}>
//          <option value="1">0.1톤</option>
//          <option value="2">0.2톤</option>
//          <option value="3">0.3톤</option>
//          <option value="4">0.4톤</option>
//          <option value="5">0.5톤</option>
//        </select>
//      </Container>
//    );
//  }

//  const Container = styled.div`
//    display: flex;
//    position: relative;
//    flex-direction: column;
//    padding: 28px 24px;

//    .label {
//      color: ${({ theme }) => theme.colors.blueGray600};
//      font-size: 15px;
//      font-weight: 500;
//      line-height: 26px; /* 144.444% */
//      letter-spacing: -0.36px;
//    }

//    select {
//      padding: 15px 16px;
//      width: 25%;
//      border-radius: 10px;
//      border: 1px solid ${({ theme }) => theme.colors.blueGray100};
//      background: ${({ theme }) => theme.colors.blueGrayA100};

//      color: var(--50, var(--blue-gray-blue-gray-500, #808690));
//      font-feature-settings: "clig" off, "liga" off;
//      font-family: Pretendard;
//      font-size: 18px;
//      font-style: normal;
//      font-weight: 500;
//      line-height: 26px; /* 144.444% */
//      letter-spacing: -0.36px;
//    }

//    option {
//    }
//  `;
