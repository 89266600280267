import { useState } from "react";
import { Title } from "@components/styles";

import styled from "styled-components";
import NoticeInput from "./NoticeInput";
import { useNavigate } from "react-router-dom";
import { usePostNotice } from "@api/notice";

export default function NoticeAddPage() {
  const navigate = useNavigate();
  const { mutate } = usePostNotice();
  const [noticeInfo, setNoticeInfo] = useState({
    title: "",
    content: "",
  });

  const onSubmit = () => {
    mutate(noticeInfo, {
      onSuccess: () => navigate(-1),
    });
  };

  return (
    <Container>
      <Title>공지사항 등록</Title>
      <NoticeInput
        noticeInfo={noticeInfo}
        setNoticeInfo={setNoticeInfo}
        onSubmit={onSubmit}
      />
    </Container>
  );
}

const Container = styled.div``;
