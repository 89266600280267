import {
  createColumnHelper,
  flexRender,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
  CellContext,
} from "@tanstack/react-table";
import { HTMLProps, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { getCoreRowModel } from "@tanstack/react-table";
import "@components/table.css";
import { Link, useSearchParams } from "react-router-dom";
import Pagination from "@components/core/paging/Pagination";
import { copyText } from "@utils/copy";
import { CheckBoxEnum, SquareCheckBox20 } from "@components/core/icon/checkBox";

export default function ConsultingTable({ setSelectedRow, data }) {
  const [searchParams] = useSearchParams();
  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("checkBox", {
        cell: ({ row }) => {
          const onChange = row.getToggleSelectedHandler();
          return (
            <Center
              onClick={(e) => {
                onChange(e);
                setSelectedRow(row.original["consultingId"]);
              }}
            >
              <SquareCheckBox20
                status={
                  row.getIsSelected()
                    ? CheckBoxEnum.Activate
                    : CheckBoxEnum.Default
                }
              />
            </Center>
            // <IndeterminateCheckbox
            //   {...{
            //     checked: row.getIsSelected(),
            //     disabled: !row.getCanSelect(),
            //     indeterminate: row.getIsSomeSelected(),
            //     onChange: row.getToggleSelectedHandler(),
            //     setSelectedRow: setSelectedRow,
            //     consultingId: row.original["consultingId"],
            //   }}
            // />
          );
        },
        header: "",
        maxSize: 4,
      }),
      columnHelper.accessor("influencer", {
        cell: ({ row }) => {
          return (
            <p
              style={{ textAlign: "left", marginLeft: 20, cursor: "default" }}
              title={row.original["consultingId"]}
              onClick={() =>
                copyText(row.original["consultingId"], "컨설턴트 ID 복사 완료")
              }
            >
              {row.original["influencer"].name}
            </p>
          );
        },
        header: "상담 전문가",
        maxSize: 84,
      }),
      columnHelper.accessor("detail", {
        cell: ({ row }) => {
          const id = row.original["consultingId"];
          const dbVersion = searchParams.get("db") || "dev";

          return (
            <StyledLink to={`/consultation/consulting/${id}?db=${dbVersion}`}>
              상세보기
            </StyledLink>
          );
        },
        header: "-",
        maxSize: 12,
      }),
    ];
  }, [searchParams]);

  // useReactTable로 테이블 구조 정의
  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  if (!data) return;

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="contentsData">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryBlue100};
  text-decoration: underline;
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  cursor: pointer;
`;
