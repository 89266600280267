import styled from "styled-components";

export default function AmountBox({ data }) {
  return (
    <Container>
      <p className="header">총금액</p>
      <p className="body">{data?.toLocaleString()}원</p>
    </Container>
  );
}

const Container = styled.div`
  padding: 10px 0;

  .header {
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }

  .body {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 32px;
    font-weight: 600;
    line-height: 42px; /* 131.25% */
    letter-spacing: -0.96px;
  }
`;
