// /brokerage/admin/apply/{apply_id}/move_in_date

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Instance } from "..";
import {
  DealSuccessRateParamsReq,
  DealSuccessRateParamsRes,
  MoveInDateType,
} from "./imjang.interfaces";

// 희망 이사 시기 조회
export const useGetMoveInDate = () =>
  useMutation<{ moveInDate: MoveInDateType }, AxiosError, { apply_id: string }>(
    {
      mutationFn: async ({ apply_id }) => {
        const response = await Instance({
          url: `/brokerage/admin/apply/${apply_id}/move_in_date`,
          method: "GET",
        });
        return response.data;
      },
    }
  );

// 거래 성사율 데이터 조회
export const useGetDealSuccessRateData = ({ apply_id }) =>
  useQuery<DealSuccessRateParamsRes, AxiosError>({
    queryKey: ["dealSuccess", apply_id],
    queryFn: async () => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${apply_id}/deal_success_rate_params`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 1000 * 60 * 5,
  });

export const usePostDealSuccessRateData = ({ apply_id }) => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, DealSuccessRateParamsReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${apply_id}/deal_success_rate_params`,
        method: "POST",
        data,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["dealSuccess"],
      });
    },
  });
};

// 거래 성사율 퍼센트 조회
export const useGetDealSuccessRate = ({ apply_id }) =>
  useQuery<{ dealSuccessRate: number }, AxiosError>({
    queryKey: ["dealSuccess", "rate", apply_id],
    queryFn: async () => {
      const response = await Instance({
        url: `/brokerage/admin/apply/${apply_id}/deal_success_rate`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 1000 * 60 * 5,
  });

// 매물 호가 조회
export const useGetMaemulList = () => {
  return useMutation<{}, AxiosError, { danjiCode: number; scaleCode: number }>({
    mutationFn: async ({ danjiCode, scaleCode }) => {
      const response = await Instance({
        url: `/danjis/info/brief_maemul_list?danjiCode=${danjiCode}&scaleCode=${scaleCode}`,
        method: "GET",
      });
      return response.data;
    },
  });
};
