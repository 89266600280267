import { CheckBox24, CheckBoxEnum } from "@components/core/icon/checkBox";
import { useConsultantInfo } from "@features/useConsultantInfo";
import styled from "styled-components";

export default function Exposures() {
  const { globalConsultantInfo, setGlobalConsultantInfo } = useConsultantInfo();

  const titleArr = [
    {
      title: "최근주목받는 전문가",
      value: "spotlight",
    },
    { title: "전문가의 부동산 상담", value: "expertAdvice" },
  ];

  const onClick = (idx) => {
    const updatedExposures = [...globalConsultantInfo.exposures]; // 배열 복제
    updatedExposures[idx] = {
      ...updatedExposures[idx],
      state: !updatedExposures[idx].state,
    };
    setGlobalConsultantInfo({ exposures: updatedExposures });
  };

  return (
    <Container>
      {globalConsultantInfo.exposures.map((el, idx) => (
        <Row
          onClick={() => {
            onClick(idx);
          }}
          key={idx}
        >
          <CheckBox24
            status={el.state ? CheckBoxEnum.Activate : CheckBoxEnum.Default}
          />
          <Title>{titleArr[idx].title}</Title>
        </Row>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;

const Row = styled.div`
  display: flex;
  margin-right: 24px;
  cursor: pointer;
`;

const Title = styled.p`
  margin-left: 12px;
  color: ${({ theme }) => theme.colors.blueGray600};
  font-size: 15px;
  font-weight: 500;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.3px;
`;
