import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { CheckBoxEnum, SquareCheckBox20 } from "@components/core/icon/checkBox";
import { Minus16 } from "@components/core/icon/plus";
import AddSchedule from "./AddSchedule";
import { useDeleteSchedule } from "@hooks/imjang";
import ScheduleTooltip from "./ScheduleTooltip";
import { useImjang } from "@features/useImjang";
import { ScheduleEditPopup } from "./ScheduleEditPopup";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import CheckingCall from "./CheckingCall";
import ScheduleTable from "./ScheduleTable";

export default function ScheduleSection({ list, id }) {
  const columnHelper = createColumnHelper();
  const { mutate } = useDeleteSchedule({ id });
  const { globalImjangStatus } = useImjang();
  const [selectedRow, setSelectedRow] = useState({});
  const [isVisible스케줄수정팝업, setIsVisible스케줄수정팝업] = useState(false);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("checkBox", {
        header: () => <div></div>,
        cell: ({ row }) => {
          return (
            <Center onClick={row.getToggleSelectedHandler()}>
              <SquareCheckBox20
                status={
                  row.getIsSelected()
                    ? CheckBoxEnum.Activate
                    : CheckBoxEnum.Default
                }
              />
            </Center>
          );
        },
        maxSize: 5,
      }),
      columnHelper.accessor("meetingDate", {
        cell: ({ getValue }) => {
          return getValue();
        },
        header: "날짜",
        maxSize: 10,
      }),
      columnHelper.accessor("meetingTime", {
        cell: ({ getValue }) => {
          return getValue();
        },
        header: "시간",
        maxSize: 10,
      }),
      columnHelper.accessor("name", {
        cell: ({ row }) => {
          return (
            <Maemul>
              <span className="danjiName">{row.original["danjiName"]}</span>
              <div className="type">
                <span>{row.original["tradeType"]}</span>
                <div className="divide" />
                <span>{row.original["pyeongType"]}평</span>
              </div>

              <div className="info">
                <ScheduleTooltip
                  row={row.original}
                  handle스케줄수정={() => {
                    setSelectedRow(row.original);
                    setIsVisible스케줄수정팝업(true);
                  }}
                />
              </div>
            </Maemul>
          );
        },
        header: "매물",
        maxSize: 20,
      }),
      columnHelper.accessor("meetingPlace", {
        cell: ({ getValue }) => {
          return getValue();
        },
        header: "약속 장소",
        maxSize: 20,
      }),
      columnHelper.accessor("checkingCall", {
        // column 이름으로 툴팁이 뜰지 안뜰지 체크하고 있기 때문에 'checkingCall'에서 이름이 변하면 안됨
        cell: ({ row }) => {
          return (
            <CheckingCall
              callChecked={row.original["callChecked"]}
              applyId={id}
              scheduleId={row.original["id"]}
            />
          );
        },
        header: "확인 전화 여부",
        maxSize: 15,
      }),
    ];
  }, []);

  const table = useReactTable({
    data: list,
    columns,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleDelete = () => {
    const selectedRows = table.getSelectedRowModel();
    const selectedIds = selectedRows.rows.map((row) => row.original["id"]);

    if (selectedIds.length === 0) {
      alert(
        "스케줄이 선택되지 않았어요. 삭제할 스케줄 선택 후 다시 시도해주세요."
      );
      return;
    }

    mutate(
      { scheduleIdList: selectedIds },
      {
        onSuccess: () => {
          table.toggleAllRowsSelected(false);
          alert("스케줄 삭제가 완료되었습니다.");
        },
        onError: (res) => {
          alert(`스케줄 삭제 실패 : ${res.response?.data}`);
        },
      }
    );
  };

  useEffect(() => {
    table.setPageSize(5);
  }, []);

  return (
    <Container>
      <Row
        style={{
          marginBottom: 12,
          width: "100%",
          position: "relative",
          zIndex: 1,
        }}
      >
        <SubTitle>스케줄 확정일</SubTitle>
        {globalImjangStatus.editPermission && (
          <Row>
            <AddSchedule />
            <AddButton onClick={handleDelete}>
              <Minus16 />
              <p> 스케줄 삭제</p>
            </AddButton>
          </Row>
        )}
      </Row>
      <ScheduleTable table={table} />
      <PopUpContainer
        isVisible={isVisible스케줄수정팝업}
        setIsVisible={setIsVisible스케줄수정팝업}
      >
        <ScheduleEditPopup
          row={selectedRow}
          isVisible={isVisible스케줄수정팝업}
          setIsVisible={setIsVisible스케줄수정팝업}
        />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 32px 40px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.white};
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubTitle = styled.h2`
  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  border-radius: 10px;
  cursor: pointer;

  p {
    margin-left: 6px;
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 14px;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
  }
`;

const Maemul = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .danjiName {
    color: ${({ theme }) => theme.colors.blueGray700};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.32px;
  }

  .type {
    display: flex;
    align-items: center;
    margin-top: 2px;
    color: ${({ theme }) => theme.colors.blueGray600};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.28px;

    .divide {
      margin: 0 8px;
      width: 1px;
      height: 10px;
      background: ${({ theme }) => theme.colors.blueGray200};
    }
  }

  .infoView {
    display: none;
    /* display: block; */
  }
`;
