import { Props, WhitespaceEnum, WhitespaceSize } from "./WhiteSpace.interfaces";

export default function Whitespace({
  status = WhitespaceEnum.Color,
  size = WhitespaceSize.Default,
}: Props) {
  if (size === WhitespaceSize.Default) {
    return (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
        <g clipPath="url(#clip0_8360_8610)">
          <path opacity="0.2" d="M40 0H0V40H40V0Z" fill="white" />
          <path opacity="0.2" d="M34 2H6V38H34V2Z" fill="white" />
          <path opacity="0.2" d="M38 34V6L2 6V34H38Z" fill="white" />
          <path opacity="0.2" d="M36 4H4V36H36V4Z" fill="white" />
          <path
            opacity="0.2"
            d="M20 28C24.4183 28 28 24.4183 28 20C28 15.5817 24.4183 12 20 12C15.5817 12 12 15.5817 12 20C12 24.4183 15.5817 28 20 28Z"
            fill="white"
          />
          <path
            d="M34 3.89V26L23 32V38H7.87C6.8401 38 6 37.15 6 36.11V3.89C6 2.85 6.8401 2 7.87 2H32.13C33.1599 2 34 2.85 34 3.89Z"
            fill={status === "Color" ? "#80BAFF" : "#D5DBE4"}
          />
          <path
            d="M10 13H30"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M10 8H24"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M10 18H20"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M34 26V26.23C34 26.72 33.8101 27.2 33.48 27.57L24.6 37.35C24.22 37.76 23.68 38 23.12 38H23V28C23 26.8954 23.8954 26 25 26H34Z"
            fill={status === "Color" ? "#1C84FF" : "#BFC6D1"}
          />
        </g>
        <defs>
          <clipPath id="clip0_8360_8610">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (size === WhitespaceSize.Small) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clipPath="url(#clip0_22023_31062)">
          <path
            d="M20.3996 2.33322V15.5992L13.7996 19.1992V22.7992H4.72161C4.10367 22.7992 3.59961 22.2892 3.59961 21.6652V2.33322C3.59961 1.70922 4.10367 1.19922 4.72161 1.19922H19.2776C19.8955 1.19922 20.3996 1.70922 20.3996 2.33322Z"
            fill={status === "Color" ? "#80BAFF" : "#D5DBE4"}
          />
          <path
            d="M6 7.79883H18"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M6 4.79883H14.4"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M6 10.7988H12"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M20.3998 15.5996V15.7376C20.3998 16.0316 20.2859 16.3196 20.0878 16.5416L14.7598 22.4096C14.5318 22.6556 14.2078 22.7996 13.8718 22.7996H13.7998V17.5996C13.7998 16.495 14.6952 15.5996 15.7998 15.5996H20.3998Z"
            fill={status === "Color" ? "#1C84FF" : "#BFC6D1"}
          />
        </g>
        <defs>
          <clipPath id="clip0_22023_31062">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
