import { useCreateUserGroup, useDeleteUserGroup } from "@api/permission";
import { CircleMinus20, CirclePlus20 } from "@components/core/icon/plus";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { useAlert } from "@features/useAlert";
import { useState } from "react";
import styled from "styled-components";
import DeleteGroupPopup from "./DeleteGroupPopup";
import { addNextGroup } from ".";

export default function GroupList({ selectedGroup, setSelectedGroup, data }) {
  const { mutate: createGroup } = useCreateUserGroup();
  const { mutate: deleteGroup } = useDeleteUserGroup();
  const { showGlobalAlert } = useAlert();
  const [isVisible삭제팝업, setIsVisible삭제팝업] = useState(false);

  const 그룹_선택 = (el, idx) => {
    setSelectedGroup(el);
  };

  const 그룹_추가 = () => {
    createGroup({ name: addNextGroup(data) });
  };

  const 그룹_삭제 = () => {
    if (selectedGroup === "마스터" || selectedGroup === "미승인") {
      showGlobalAlert({
        desc: "마스터와 미설정 그룹은 삭제할 수 없어요.",
        blue: true,
      });
      return;
    }

    const findIndex = data.findIndex((el) => el === selectedGroup);

    deleteGroup(selectedGroup, {
      onSuccess: () => {
        setIsVisible삭제팝업(false);
        //그룹 삭제 시 삭제한 그룹 바로 하단 그룹이 선택되어 선택된 상태가 디폴트
        if (findIndex === data.length - 1) {
          setSelectedGroup(data[findIndex - 1]);
        } else setSelectedGroup(data[findIndex + 1]);
      },
      onError: (res) => {
        setIsVisible삭제팝업(false);
        if (res.response.data?.["message"].includes("유저가 존재")) {
          alert(
            "그룹에 등록된 사용자가 있습니다. \n등록된 사용자를 모두 삭제하거나 그룹을 변경한 후 다시 진행해주세요."
          );
        } else {
          alert((res.response.data as { message: string }).message);
        }
      },
    });
  };

  return (
    <>
      <Container>
        <h3 className="title">그룹 관리</h3>
        <GroupBox>
          {data.map((el, idx) => (
            <Group
              $active={el === selectedGroup}
              onClick={() => 그룹_선택(el, idx)}
              key={idx}
            >
              <p>{el}</p>
            </Group>
          ))}
        </GroupBox>
        <ButtonBox>
          <button className="button" onClick={그룹_추가}>
            <CirclePlus20 />
            <p>그룹 추가</p>
          </button>
          <button className="button" onClick={() => setIsVisible삭제팝업(true)}>
            <CircleMinus20 />
            <p>그룹 삭제</p>
          </button>
        </ButtonBox>
      </Container>
      <PopUpContainer
        isVisible={isVisible삭제팝업}
        setIsVisible={setIsVisible삭제팝업}
      >
        <DeleteGroupPopup onClick={그룹_삭제} />
      </PopUpContainer>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 226px;
  height: 494px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.blueGrayA700};

  .title {
    padding: 16px 16px 0;
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;

const GroupBox = styled.ul`
  flex: 1;
  margin-top: 19px;
  padding: 0 8px;
  overflow-y: scroll;
`;

const Group = styled.li<{ $active: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px;
  width: 210px;
  height: 40px;
  background-color: ${({ theme, $active }) =>
    $active ? theme.colors.white : theme.colors.blueGrayA700};
  border-radius: 8px;
  cursor: pointer;

  p {
    color: ${({ theme, $active }) =>
      $active ? theme.colors.blueGray800 : theme.colors.blueGray400};
    font-size: 15px;
    font-weight: ${({ $active }) => ($active ? 600 : 500)};
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.blueGray600};

  .button {
    display: flex;
    flex: 1;
    gap: 4px;
    padding: 10px 18px;
    cursor: pointer;

    p {
      color: ${({ theme }) => theme.colors.blueGrayA100};
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.28px;
    }
  }

  .button:first-child {
    content: "";
    border-right: 1px solid ${({ theme }) => theme.colors.blueGray600};
  }
`;
