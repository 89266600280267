export enum WhitespaceEnum {
  Default = "Default",
  Color = "Color",
}

export enum WhitespaceSize {
  Default = 40,
  Small = 20,
}

export interface Props {
  status?: WhitespaceEnum;
  size?: WhitespaceSize;
}
