import { Arrows22 } from "@components/core/icon/arrow";
import styled from "styled-components";

const status = { pending: "대기", approve: "승인", reject: "반려" };

export default function ApprovalStatus({ goWriteComment, approvalState }) {
  if (approvalState === "pending") {
    return (
      <Container>
        <BtnBox $approve onClick={() => goWriteComment("approve")}>
          <p>승인</p>
        </BtnBox>
        <div style={{ width: 10 }} />
        <BtnBox $approve={false} onClick={() => goWriteComment("reject")}>
          <p>반려</p>
        </BtnBox>
      </Container>
    );
  } else {
    return (
      <TextBox onClick={() => goWriteComment(approvalState)}>
        <p>{status[approvalState]}</p>
        <Arrows22 />
      </TextBox>
    );
  }
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const BtnBox = styled.div<{ $approve: boolean }>`
  padding: 5px 24px;
  border-radius: 8px;
  background-color: ${({ theme, $approve }) =>
    $approve ? theme.colors.blue100 : theme.colors.red100};
  cursor: pointer;

  p {
    color: ${({ theme, $approve }) =>
      $approve ? theme.colors.primaryBlue100 : theme.colors.red1000};
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
  }
`;

const TextBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  cursor: pointer;

  p {
    color: ${({ theme }) => theme.colors.blueGray400};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;
