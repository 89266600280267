import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import ConsultingReportUploadInput from "@components/page/influencer/consultation/consulting/ConsultingReportUploadInput";
import { Title } from "@components/styles";
import {
  useGetApplicantInfo,
  usePostConsultingReport,
} from "@hooks/consultation";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

export default function ReuploadReport() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { mutate, isLoading } = usePostConsultingReport();
  const { data } = useGetApplicantInfo({ applicantId: id });
  const [consultingReport, setConsultingReport] = useState({
    applicantId: "",
    notionUrl: "",
  });

  const listData = useMemo(() => {
    if (data) {
      return [{ ...data, label: data.report.title, value: data.applicantId }];
    }
    return [{ label: "", value: "" }];
  }, [data]);

  const onSubmit = () => {
    mutate(consultingReport, {
      onSuccess: (res) => {
        alert("컨설팅 보고서를 제출했습니다.");
        navigate(-1);
      },
    });
  };

  useEffect(() => {
    if (data) {
      setConsultingReport({
        applicantId: data.applicantId,
        notionUrl: data.report.notionUrl,
      });
    }
  }, [data]);

  return (
    <Container>
      <Title>보고서 보내기</Title>
      <div style={{ height: 24 }} />
      <ConsultingReportUploadInput
        consultingReport={consultingReport}
        setConsultingReport={setConsultingReport}
        listData={listData}
        onSubmit={onSubmit}
      />
      {isLoading ? <AbsoluteFillLoading /> : null}
    </Container>
  );
}

const Container = styled.div``;
