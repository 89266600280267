import GradientButton from "@components/button/GradientButton";
import { CheckedCircleStatusEnum } from "@components/core/icon/checked/CheckedCircle.interfaces";
import CheckedCircle24 from "@components/core/icon/checked/CheckedCircle24";
import { Close32 } from "@components/core/icon/close";
import { tradeTypeObj } from "@hooks/imjang/Imjang.interfaces";
import { useState } from "react";
import styled from "styled-components";

export default function ApartmentSelectModal({
  value,
  onChangeValue,
  setIsVisible,
  data,
}) {
  const [selectedValue, setSelectedValue] = useState(value);

  const 확인 = () => {
    onChangeValue(selectedValue);
    setIsVisible(false);
  };

  return (
    <Container>
      <h3>대표 아파트 선택</h3>
      <p className="subTitle">아파트 선택</p>
      <span className="close" onClick={() => setIsVisible(false)}>
        <Close32 />
      </span>
      <div className="list">
        {data.map((item, index) => {
          const active =
            `${selectedValue.scaleCode}_${selectedValue.tradeType}` ===
            `${item.scaleCode}_${item.tradeType}`;
          return (
            <RowContainer
              key={index}
              onClick={() =>
                setSelectedValue({
                  danjiName: item.danjiInfo.대표단지명,
                  danjiCode: item.danjiCode,
                  scaleCode: item.scaleCode,
                  tradeType: item.tradeType,
                })
              }
            >
              <CheckedCircle24
                status={
                  active
                    ? CheckedCircleStatusEnum.Activate
                    : CheckedCircleStatusEnum.Default
                }
              />
              <div className="content">
                <span className="name">{item.danjiInfo.대표단지명}</span>
                <div className="type">
                  <span>{tradeTypeObj[item.tradeType]}</span>
                  <div className="divide" />
                  <span>{item.pyeongInfo.평타입}평</span>
                </div>
              </div>
            </RowContainer>
          );
        })}
      </div>
      <GradientButton
        text={"완료"}
        isDone={selectedValue}
        onClick={확인}
        containerStyle={{ bottom: 0 }}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  padding: 24px 16px 20px;
  min-width: 480px;
  background-color: white;
  border-radius: 20px;

  h3 {
    color: ${({ theme }) => theme.colors.blueGray1000};
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
  }

  .subTitle {
    margin-top: 24px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 16px;
    cursor: pointer;
  }

  .list {
    margin: 10px 0 26px;
    height: 400px;
    overflow: auto;
  }
`;

const RowContainer = styled.div`
  display: flex;
  padding: 12px 12px 12px 8px;
  cursor: pointer;

  .content {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .name {
      margin-bottom: 2px;
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.32px;
      font-feature-settings: "liga" off, "clig" off;
    }

    .type {
      display: flex;
      align-items: center;

      span {
        color: ${({ theme }) => theme.colors.blueGray600};
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.28px;
        text-align: center;
      }

      .divide {
        margin: 0 8px;
        width: 1px;
        height: 10px;
        background: var(--BlueGray200, #cccfd3);
      }
    }
  }
`;
