export default function Close24() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="white" fillOpacity="0.01" />
      <path
        d="M6 6L18 18"
        stroke="#999FA6"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18 6L6 18"
        stroke="#999FA6"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
