import { DefaultTheme } from "styled-components";

export const lightTheme = {
  colors: {
    white: "#fff",
    primaryBlue100: "#1c84ff",
    primaryBlueGray: "#333e4d",
    blue1000: "#1c84ff",
    blue900: "#3390ff",
    blue800: "#4d9eff",
    blue700: "#66acff",
    blue600: "#80baff",
    blue500: "#99c8ff",
    blue400: "#b2d6ff",
    blue300: "#cce3ff",
    blue200: "#e5f1ff",
    blue100: "#e8f3ff",
    blueA700: "#0069e5",
    blueA200: "#cfe4fd",
    blueA100: "#e3f0ff",
    blueGray1000: "#000e21",
    blueGray900: "#1a2637",
    blueGray800: "#333e4d",
    blueGray700: "#4d5664",
    blueGray600: "#666e7a",
    blueGray500: "#808690",
    blueGray400: "#999fa6",
    blueGray300: "#b2b7bc",
    blueGray200: "#cccfd3",
    blueGray100: "#e5e7e9",
    blueGray50: "#f5f5f5",
    blueGrayA700: "#414853",
    blueGrayA200: "#f0f2f5",
    blueGrayA100: "#f4f6f8",
    red1000: "#f04452",
    red900: "#f25763",
    red800: "#f36975",
    red700: "#f57c86",
    red600: "#f68f97",
    red500: "#f7a1a8",
    red400: "#f9b4ba",
    red300: "#fbc7cb",
    red200: "#fcdadc",
    red100: "#fdecee",
    redA800: "#d01a1a",
    redA600: "#f04444",
    redA400: "#f68f8f",
    green1000: "#22c014",
    green900: "#38c72c",
    green800: "#4ecd43",
    green700: "#64d35b",
    green600: "#7ad972",
    green500: "#90e08a",
    green400: "#a7e6a1",
    green300: "#bdecb9",
    green200: "#d3f2d0",
    green100: "#e9f9e8",
    greenA600: "#00ff75",
    greenA400: "#4eff9f",
    greenA200: "#afffd4",
    bgColor: "#00000099",
  },
};

// export const darkTheme = {
//   colors: {
//     white: "#18171c;",
//     primaryBlue100: "#1c84ff",
//     primaryBlueGray: "#cccfd3",
//     blue1000: "#1c84ff",
//     blue900: "#3390ff",
//     blue800: "#4d9eff",
//     blue700: "#66acff",
//     blue600: "#80baff",
//     blue500: "#99c8ff",
//     blue400: "#b2d6ff",
//     blue300: "#cce3ff",
//     blue200: "#e5f1ff",
//     blue100: "#e8f3ff",
//     blueA700: "#0069e5",
//     blueA200: "#cfe4fd",
//     blueA100: "#e3f0ff",
//     blueGray1000: "#e5e7e9",
//     blueGray900: "#cccfd3",
//     blueGray800: "#cccfd3",
//     blueGray700: "#b2b7bc",
//     blueGray600: "#999fa6",
//     blueGray500: "#808690",
//     blueGray400: "#666e7a",
//     blueGray300: "#4d5664",
//     blueGray200: "#1a2637",
//     blueGray100: "#3b3d43",
//     blueGray50: "#f5f5f5",
//     blueGrayA700: "#414853",
//     blueGrayA200: "#18171c",
//     blueGrayA100: "#09080e",
//     red1000: "#f04452",
//     red900: "#f25763",
//     red800: "#f36975",
//     red700: "#f57c86",
//     red600: "#f68f97",
//     red500: "#f7a1a8",
//     red400: "#f9b4ba",
//     red300: "#fbc7cb",
//     red200: "#fcdadc",
//     red100: "#fdecee",
//     redA800: "#d01a1a",
//     redA600: "#f04444",
//     redA400: "#f68f8f",
//     green1000: "#22c014",
//     green900: "#38c72c",
//     green800: "#4ecd43",
//     green700: "#64d35b",
//     green600: "#7ad972",
//     green500: "#90e08a",
//     green400: "#a7e6a1",
//     green300: "#bdecb9",
//     green200: "#d3f2d0",
//     green100: "#e9f9e8",
//     greenA600: "#00ff75",
//     greenA400: "#4eff9f",
//     greenA200: "#afffd4",
//     bgColor: "#00000063",
//   },
// };

export type ColorTypes = typeof lightTheme.colors;

export const theme: DefaultTheme = lightTheme;
