import { useEffect, useRef } from "react";
import styled from "styled-components";

export default function DeleteImjangPopup({ onClick }) {
  const buttonRef = useRef(null); // 버튼에 대한 ref 생성
  const onSubmit = (e) => {
    e.preventDefault();
    onClick();
  };

  useEffect(() => {
    // 컴포넌트가 마운트될 때 버튼에 포커스 설정
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, []);

  return (
    <Container onSubmit={onSubmit}>
      <h1 className="title">상담을 정말로 삭제할까요?</h1>
      <List>
        <li>
          상담을 삭제할 시 설정한 값이 모두 삭제되며, {"\n"}삭제된 상담은 복구가
          불가능합니다.
        </li>
      </List>
      <button type="submit" onClick={onClick} ref={buttonRef}>
        삭제하기
      </button>
    </Container>
  );
}

const Container = styled.form`
  padding: 24px 16px 20px;
  width: 360px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};

  .title {
    margin-bottom: 24px;
    color: ${({ theme }) => theme.colors.blueGray1000};
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.4px;
  }

  p {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
  }

  button {
    width: 100%;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.red1000};
    border-radius: 10px;

    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  gap: 4px;

  li {
    display: flex;
    padding: 4px 0;

    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.3px;
    list-style: none;
    white-space: pre-wrap;

    &::before {
      content: "";
      margin: 9px 10px 9px 2px;
      width: 6px;
      height: 6px;
      border-radius: 25px;
      background-color: ${({ theme }) => theme.colors.blueGray200};
    }
  }
`;
