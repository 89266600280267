import { Close24 } from "@components/core/icon/close";
import EmptyProfile28 from "@components/core/icon/profile/EmptyProfile28";
import styled from "styled-components";
import { GoogleSquare } from "./GoogleSquare";
import { NaverSquare } from "./NaverSquare";
import { useSignUpLink } from "@api/login";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { useAlert } from "@features/useAlert";

const social = { naver: "네이버", google: "구글" };
export default function ConnectAccount({ setIsVisible, userInfo, data }) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const { mutate } = useSignUpLink();
  const { showGlobalAlert } = useAlert();

  const 회원_통합 = () =>
    mutate(
      {
        name: userInfo.name,
        email: userInfo.email,
        phone_number: userInfo.phone_number,
      },
      {
        onSuccess: (res) => {
          const accessToken = res.jwt.split(" ")[1];
          cookies.set("access_token", accessToken, { path: "/" });
          navigate("/");
        },
        onError: (res) => {
          if (res.response.data?.["message"].includes("세션")) {
            showGlobalAlert({
              desc: "세션이 만료되었습니다. \n다시 시도해 주세요.",
              blue: false,
              path: "/login",
            });
          }
        },
      }
    );

  if (!data) return;

  const Button = () => {
    if (data.oauthConflict) return <></>;
    else if (data.existingLoginPlatform === "naver")
      return (
        <>
          <SocialButton>
            <div className="icon">
              <GoogleSquare />
            </div>
            <p>구글 계정과 연동하기</p>
          </SocialButton>
          <Description>
            계정 연동시 구글, 네이버 어떤 것으로 로그인해도 {"\n"}같은 회원으로
            사용할 수 있습니다
          </Description>
        </>
      );
    else if (data.existingLoginPlatform === "google")
      return (
        <>
          <SocialButton style={{ background: "#03C75A" }}>
            <div className="icon">
              <NaverSquare />
            </div>
            <p>네이버 계정과 연동하기</p>
          </SocialButton>
          <Description>
            계정 연동시 구글, 네이버 어떤 것으로 로그인해도 {"\n"}같은 회원으로
            사용할 수 있습니다
          </Description>
        </>
      );
    else {
      return (
        <>
          <SocialButton style={{ background: "#1c84ff", marginTop: 32 }}>
            <p>휴면계정 해제하기</p>
          </SocialButton>
        </>
      );
    }
  };

  const Profile = () => {
    if (!data.existingLoginPlatform) {
      return (
        <AccountInfo>
          <EmptyProfile28 />
          <div style={{ marginLeft: 10 }}>
            <p className="email">{data.existingEmail}</p>
          </div>
        </AccountInfo>
      );
    } else {
      return (
        <AccountInfo>
          <EmptyProfile28 />
          <div style={{ marginLeft: 10 }}>
            <p className="social">
              {social[data.existingLoginPlatform]} 로그인
            </p>
            <p className="email">{data.existingEmail}</p>
          </div>
          <button className="button" onClick={() => navigate("/login")}>
            {data.oauthConflict ? "다시 로그인하기" : "로그인 하기"}
          </button>
        </AccountInfo>
      );
    }
  };

  return (
    <Container>
      <h2 className="title">
        {data.existingLoginPlatform
          ? "가입한 정보가 있어요"
          : "장시간 접속하지 않아\n휴면계정으로 전환되었습니다"}
      </h2>
      <span className="icon" onClick={() => setIsVisible(false)}>
        <Close24 />
      </span>
      <Profile />
      <div onClick={회원_통합}>
        <Button />
      </div>
    </Container>
  );
}

const Container = styled.div`
  padding: 24px 16px 20px;
  width: 422px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 24px;

  .title {
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.4px;
    text-align: center;
    white-space: pre-wrap;
  }

  .icon {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

const AccountInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding: 11px 12px 11px 14px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.blueGrayA100};

  .social {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.3px;
  }

  .email {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.28px;
  }

  .button {
    margin-left: auto;
    padding: 6px 8px;
    background-color: ${({ theme }) => theme.colors.blue100};
    border-radius: 8px;

    color: ${({ theme }) => theme.colors.primaryBlue100};
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px; /* 153.846% */
    letter-spacing: -0.26px;
  }
`;

const Description = styled.p`
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.blueGray500};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
  white-space: pre-wrap;
`;

const SocialButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 46px;
  height: 48px;
  width: 100%;

  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primaryBlue100};

  .icon {
    position: absolute;
    top: 2px;
    left: 2px;
    width: fit-content;
  }

  p {
    color: white;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-align: center;
  }
`;
