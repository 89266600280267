export enum ArrowColorEnum {
  B20 = "B20",
  B30 = "B30",
  B40 = "B40",
  B50 = "B50",
}

export enum ArrowDirectionEnum {
  RIGHT = "RIGHT",
  LEFT = "LEFT",
  UP = "UP",
  DOWN = "DOWN",
}

export interface Props {
  color?: ArrowColorEnum;
  customColor?: string;
  direction?: ArrowDirectionEnum;
}
