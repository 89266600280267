import { useEffect, useMemo, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getCoreRowModel } from "@tanstack/react-table";
import styled from "styled-components";
import Pagination from "@components/core/paging/Pagination";
import "@components/table.css";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";

import Empty from "@components/common/Empty";
import { Link, useNavigate } from "react-router-dom";
import ApprovalStatus from "./ApprovalStatus";

export default function ConsultationReportTable({ data }) {
  const navigate = useNavigate();
  // const { mutate, data: tableData } = useGetContentsList();

  // useEffect(() => {
  //   mutate(rangeDate);
  // }, [rangeDate, mutate]);

  // useEffect(() => {
  //   if (tableData) {
  //     setData(tableData.contents);
  //   }
  // }, [tableData]);

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("number", {
        cell: (info) => info.row.index + 1,
        header: "순번",
        maxSize: 5,
      }),
      columnHelper.accessor("title", {
        cell: ({ row }) => {
          return <Title>{row.original["report"]["title"]}</Title>;
        },
        header: "컨설팅 보고서 제목",
        maxSize: 45,
      }),
      columnHelper.accessor("notionUrl", {
        cell: ({ row }) => {
          return (
            <StyledLink
              href={row.original["report"]["notionUrl"]}
              target="_blank"
            >
              이동
            </StyledLink>
          );
        },
        header: "보고서 링크",
        maxSize: 10,
      }),
      columnHelper.accessor("influencer", {
        cell: ({ getValue }) => {
          return getValue()["name"];
        },
        header: "보낸이",
        maxSize: 10,
      }),

      columnHelper.accessor("report", {
        cell: ({ getValue }) => {
          return convertToKoreanDate(getValue()["updatedAt"]);
        },
        header: "제출날짜",
        maxSize: 10,
      }),
      columnHelper.accessor("approvalState", {
        cell: ({ row }) => {
          const approvalState = row.original["report"]["approvalState"];
          const applicantId = row.original["applicantId"];

          const goWriteComment = (status) => {
            navigate(`/consultation/report/${applicantId}?approval=${status}`);
          };

          return (
            <ApprovalStatus
              goWriteComment={goWriteComment}
              approvalState={approvalState}
            />
          );
        },
        header: "상태관리",
        maxSize: 15,
      }),
    ];
  }, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      // columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="contentsData"
                  style={{ padding: "12px 0" }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Empty data={data} title="완료된 보고서가 없어요." />
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primaryBlue100};
`;

const EditContents = styled.p`
  color: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;
`;

const Title = styled.h2`
  margin-left: 20px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
