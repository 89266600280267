import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import styled from "styled-components";
import AddBreakTimePopUp from "./AddBreakTimePopUp";
import AddButton from "@components/page/admin/consultation/AddButton";
import { unixTime } from "./VersionDetailPage";
import { useState } from "react";
import PencilIcon from "@assets/icon/PencilIcon";
import { Delete28 } from "@components/core/icon/delete";
import { arrayRemove, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../firebase";

export default function BreakTimeInput({ data, id }) {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});

  const docRef = doc(db, "breakTimes", id);

  const onDelete = async (el) => {
    if (window.confirm("정말로 삭제할까요?")) {
      await updateDoc(docRef, {
        data: arrayRemove(el),
      });
      alert("삭제 완료");
    }
  };

  return (
    <Container>
      {data?.map((el, idx) => (
        <div key={idx}>
          <MethodCard>
            <Column>
              <Row>
                <h5 className="head">제목</h5>
                <p className="body">{el.title}</p>
                <span />
                <h5 className="head">내용</h5>
                <p className="body">{el.content}</p>
              </Row>
              <div style={{ height: 12 }} />
              <Row>
                <h5 className="head">시작시간</h5>
                <p className="body">{unixTime(el.startTime)}</p>
                <span />
                <h5 className="head">종료시간</h5>
                <p className="body">{unixTime(el.endTime)}</p>
              </Row>
              <div style={{ height: 12 }} />
              <Row>
                <h5 className="head">메모</h5>
                <p className="body" style={{ maxWidth: 1000, width: "60%" }}>
                  {el.memo || "-"}
                </p>
              </Row>
            </Column>
            <Row style={{ marginLeft: "auto", justifyContent: "flex-end" }}>
              <div
                className="iconBox"
                onClick={() => {
                  setSelectedValue(el);
                  setIsVisible(true);
                }}
              >
                <PencilIcon />
              </div>
              <div style={{ width: 20 }} />
              <div
                className="iconBox"
                onClick={() => {
                  onDelete(el);
                }}
              >
                <Delete28 />
              </div>
            </Row>
          </MethodCard>
          <div
            className="divider"
            style={{
              height: data.length - 1 === idx ? 0 : 1,
            }}
          />
        </div>
      ))}
      <AddButton
        onClick={() => {
          setIsVisible(true);
          setSelectedValue(null);
        }}
      />
      <PopUpContainer isVisible={isVisible} setIsVisible={setIsVisible}>
        <AddBreakTimePopUp setIsVisible={setIsVisible} data={selectedValue} />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div``;

const MethodCard = styled.div`
  display: flex;
  justify-content: space-between;

  .head {
    margin-right: 24px;
    width: fit-content;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }

  .body {
    width: fit-content;
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }

  span {
    margin: 0 16px;
    width: 1px;
    height: 22px;
    background: ${({ theme }) => theme.colors.blueGray100};
  }

  .iconBox {
    cursor: pointer;
  }
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
