// /auth/login/signup

import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { AuthInstanceWithCredentials, Instance } from "..";
import { alreadySignupReq, LoginRes, signupReq } from "./login.interfaces";

// 네이버 로그인
export const useNaverLogin = () =>
  useMutation<LoginRes, AxiosError, { code: string }>({
    mutationFn: async (data) => {
      const response = await AuthInstanceWithCredentials({
        url: "/auth/login/naver",
        method: "POST",
        data,
      });

      return response.data;
    },
  });

// 구글 로그인
export const useGoogleLogin = () =>
  useMutation<LoginRes, AxiosError, { code: string }>({
    mutationFn: async (data) => {
      const response = await AuthInstanceWithCredentials({
        url: "/auth/login/google",
        method: "POST",
        data,
      });

      return response.data;
    },
    retry: false,
  });

// 회원가입
export const useSignUp = () => {
  // const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, signupReq>({
    mutationFn: async (data) => {
      const response = await AuthInstanceWithCredentials({
        url: `/auth/login/signup`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
    // onSuccess: () => {
    //   queryClient.invalidateQueries({
    //     queryKey: ["imjangList"],
    //   });
    // },
  });
};

// 기존 회원가입 여부 조회
export const useCheckSignUp = () =>
  useMutation<{}, AxiosError, alreadySignupReq>({
    mutationFn: async (data) => {
      const response = await AuthInstanceWithCredentials({
        url: `/auth/login/check-sigunup-already`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
  });

// 계정 통합
export const useSignUpLink = () =>
  useMutation<{ jwt: string }, AxiosError, alreadySignupReq>({
    mutationFn: async (data) => {
      const response = await AuthInstanceWithCredentials({
        url: `/auth/login/signup-link`,
        method: "POST",
        data,
      });
      return response.data;
    },
    retry: false,
  });

// 토큰 리프레쉬
export const useTokenRefresh = () =>
  useMutation<null, AxiosError, { access_token: string }>({
    mutationFn: async ({ access_token }) => {
      const response = await AuthInstanceWithCredentials({
        url: `/auth/login/refresh/${access_token}`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
  });

let stop = false;
export const refreshAccessToken = async (access_token) => {
  try {
    if (stop) return;
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_HOST}auth/login/refresh/${access_token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (response.status !== 200) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return response;
  } catch (error) {
    if (error.status === 400) {
      console.log("🆔🆔🆔🆔 로그인 페이지로 이동");
      stop = true;
      // window.location.replace("/login");
    }

    throw error;
  }
};

// 계정 로그아웃
export const useLogout = () =>
  useMutation<{}, AxiosError, alreadySignupReq>({
    mutationFn: async (data) => {
      const response = await AuthInstanceWithCredentials({
        url: `/auth/login/logout`,
        method: "GET",
        data,
      });
      return response.data;
    },
    retry: false,
  });
