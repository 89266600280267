import { useImjang } from "@features/useImjang";
import { usePatchImjangCancel } from "@hooks/imjang";
import { useRef } from "react";
import styled from "styled-components";

interface Props {
  status?: {
    state: string;
    reason: string;
  };
  id: string;
}

export default function StatusSection({ status, id }: Props) {
  const { globalImjangStatus } = useImjang();
  const { mutate, isLoading } = usePatchImjangCancel({ id });
  const clickRef = useRef(false);

  if (!status) return null;

  if (status.state === "completed") {
    return (
      <Container $isBlue>
        <Side>
          <Icon $isBlue />
          <Content $isBlue>
            <span className="title">임장 비서가 완료되었습니다</span>
            <span className="reason">{status.reason}</span>
          </Content>
        </Side>
      </Container>
    );
  }

  if (status.state === "cancelled") {
    const handleRetry = () => {
      if (isLoading) return;
      if (clickRef.current) return;
      clickRef.current = true;
      mutate(
        {
          isCancelled: false,
          reason: "",
        },
        {
          onSuccess: () => {
            setTimeout(() => {
              clickRef.current = false;
            }, 300);
          },
          onError: () => {
            clickRef.current = false;
          },
        }
      );
    };

    return (
      <Container>
        <Side>
          <Icon />
          <Content>
            <span className="title">해당 신청은 중단되었습니다</span>
            <span className="reason">사유: {status.reason}</span>
          </Content>
        </Side>
        {globalImjangStatus.editPermission && (
          <RetryButton onClick={handleRetry}>다시 진행하기</RetryButton>
        )}
      </Container>
    );
  }
}

const Container = styled.div<{ $isBlue?: boolean }>`
  display: flex;
  justify-content: space-between;

  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 16px;
  border: 2px solid
    ${({ theme, $isBlue }) =>
      $isBlue ? theme.colors.blue700 : theme.colors.red700};
  background: ${({ theme, $isBlue }) =>
    $isBlue ? theme.colors.blueA100 : theme.colors.red100};
  align-items: center;
`;

const Side = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div<{ $isBlue?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  .title {
    margin-bottom: 4px;
    color: ${({ theme, $isBlue }) =>
      $isBlue ? theme.colors.blue1000 : theme.colors.red1000};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.4px;
    font-feature-settings: "liga" off, "clig" off;
  }

  .reason {
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.4px;
    font-feature-settings: "liga" off, "clig" off;
  }
`;

const RetryButton = styled.button`
  display: flex;
  height: 48px;
  padding: 8px 20px;
  border-radius: 10px;
  border: 1px solid var(--BlueGray300, #b2b7bc);
  background: var(--White, #fff);
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.blueGray900};
  font-feature-settings: "liga" off, "clig" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
`;

const Icon = ({ $isBlue }: { $isBlue?: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M13.9997 2.33464C20.443 2.33464 25.6663 7.55798 25.6663 14.0013C25.6663 20.4446 20.443 25.668 13.9997 25.668C7.55635 25.668 2.33301 20.4446 2.33301 14.0013C2.33301 7.55798 7.55635 2.33464 13.9997 2.33464Z"
        stroke={$isBlue ? "#1C84FF" : "#F04452"}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M15.1663 19.138C15.1663 19.7824 14.644 20.3047 13.9997 20.3047C13.3553 20.3047 12.833 19.7824 12.833 19.138V12.138C12.833 11.4937 13.3553 10.9714 13.9997 10.9714C14.644 10.9714 15.1663 11.4937 15.1663 12.138V19.138Z"
        fill={$isBlue ? "#1C84FF" : "#F04452"}
      />
      <path
        d="M15.1663 8.63802C15.1663 9.28235 14.644 9.80469 13.9997 9.80469C13.3553 9.80469 12.833 9.28235 12.833 8.63802C12.833 7.99369 13.3553 7.47135 13.9997 7.47135C14.644 7.47135 15.1663 7.99369 15.1663 8.63802Z"
        fill={$isBlue ? "#1C84FF" : "#F04452"}
      />
    </svg>
  );
};
