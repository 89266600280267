import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import { useGoogleLogin, useNaverLogin } from "@api/login";
import { useAlert } from "@features/useAlert";
import { btoaUnicode } from "@utils/text";

export const AuthCallback = () => {
  const cookies = new Cookies();

  const url = window.location.origin;
  const navigate = useNavigate();
  const { mutate: naverMutate } = useNaverLogin();
  const { mutate: googleMutate } = useGoogleLogin();
  const { showGlobalAlert } = useAlert();
  const 초대코드 = cookies.get("inv_code");

  const 쿠키에_토큰저장 = (token: string) => {
    const accessToken = token.split(" ")[1];
    cookies.set("access_token", accessToken, { path: "/" });
  };

  const loginMutate = { naverMutate, googleMutate };

  useEffect(() => {
    (async () => {
      const code = new URL(window.location.href).searchParams.get("code");
      const social = new URL(window.location.href).searchParams.get("social");
      if (code) {
        loginMutate[`${social}Mutate`](
          { code, redirectUri: `${url}/auth?social=${social}` },
          {
            onSuccess: (res) => {
              console.log("성공", res);
              쿠키에_토큰저장(res.jwt);
              navigate("/");
            },
            onError: (res) => {
              if (!res.response) {
                console.log("실패", res);
                showGlobalAlert({
                  desc: "로그인 과정에 오류가 발생했습니다. \n우측 하단 채널톡으로 문의 부탁드립니다.",
                  blue: true,
                  path: "/login",
                });
                return;
              }

              if (res.response.data?.message.includes("탈퇴")) {
                showGlobalAlert({
                  desc: "해당 계정은 비활성화 상태입니다.\n우측 하단 채널톡으로 문의 부탁드립니다.",
                  blue: true,
                  path: "/login",
                });
                return;
              }

              switch (res?.status) {
                case 400:
                  try {
                    const data = res.response.data.data;
                    const name = data.name ? btoaUnicode(data.name) : "";
                    const email = data.email ? btoa(data.email) : "";
                    const phone = data.phone ? btoa(data.phone) : "";
                    const inv = 초대코드 ? btoa(초대코드) : "";
                    const query = `nm=${name}&em=${email}&ph=${phone}&inv=${inv}`;

                    showGlobalAlert({
                      desc: "등록한 회원정보가 없어 \n회원 가입 페이지로 이동합니다.",
                      blue: true,
                      path: `/join?${query}`,
                    });
                  } catch (err) {
                    showGlobalAlert({
                      desc: "사용자 정보 로드 중 오류가 발생했습니다. \n관리자에게 문의 부탁드립니다",
                      blue: true,
                      path: "/login",
                    });
                    console.log(err);
                  }

                  break;
                default:
                  showGlobalAlert({
                    desc: `일시적인 오류가 발생했습니다. \n에러코드: ${res.status}`,
                    blue: true,
                    path: "/login",
                  });
                  break;
              }
            },
          }
        );
      }
    })();
  }, []);

  return <></>;
};
