import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export default function ClosingRateChart({ rateInfo }) {
  const [percentage, setPercentage] = useState(0);
  const RADIUS = 62;
  const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

  const barRef = useRef(null);

  useEffect(() => {
    const progress = percentage / 100;
    const dashoffset = CIRCUMFERENCE * (1 - progress);

    if (barRef.current) {
      barRef.current.style.strokeDashoffset = dashoffset;
    }
  }, [percentage, CIRCUMFERENCE]);

  useEffect(() => {
    let score = 20;

    if (rateInfo.isKakaoJoined) {
      score += 35;
    }

    if (rateInfo.moveInDate) {
      switch (rateInfo.moveInDate) {
        case "WITHIN_1_MONTH":
          score += 25;
          break;
        case "WITHIN_3_MONTHS":
          score += 20;
          break;
        case "WITHIN_6_MONTHS":
          score += 15;
          break;
        case "WITHIN_1_YEAR":
          score += 5;
          break;
        case "UNDETERMINED":
          score -= 5;
          break;
      }
    }

    if (
      rateInfo.assetPrice &&
      rateInfo.danjiPrice &&
      rateInfo.assetPrice !== "0" &&
      rateInfo.danjiPrice !== "0"
    ) {
      rateInfo.danjiPrice <= rateInfo.assetPrice * 1.1
        ? (score += 20)
        : (score -= 5);
    }

    setPercentage(score);
  }, [rateInfo]);

  return (
    <Container>
      <h3 className="title">거래성사율 미리보기</h3>
      <div className="circle_progress_wrap">
        <svg
          className="circle_progress"
          width="140"
          height="140"
          viewBox="0 0 140 140"
        >
          <circle
            className="frame"
            cx="70"
            cy="70"
            r={RADIUS}
            strokeWidth="14"
          />
          <circle
            className="bar"
            ref={barRef}
            cx="70"
            cy="70"
            r={RADIUS}
            strokeWidth="14"
            style={{
              strokeDasharray: CIRCUMFERENCE,
              strokeDashoffset: CIRCUMFERENCE,
              transition: "stroke-dashoffset 0.35s",
            }}
          />
        </svg>
        <strong className="value">{percentage}%</strong>
        {/* <input
          id="control"
          type="range"
          min="0"
          max="100"
          value={percentage}
          onChange={handleChange}
          style={{ width: "100%", marginTop: "10px" }}
        /> */}
      </div>
      <p className="notice">
        이사 날짜, 카톡방 진입 여부, 가용 금액, 대표 아파트, {"\n"}거래 유형에
        따라 거래성사율을 퍼센트(%)로 나타낸 값입니다.
      </p>
    </Container>
  );
}

const Container = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .title {
    color: ${({ theme }) => theme.colors.blueGray900};
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.44px;
  }

  .notice {
    color: ${({ theme }) => theme.colors.blueGray700};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;
    white-space: pre-wrap;
  }

  .circle_progress_wrap {
    position: relative;
    width: 140px;
    height: 140px;
  }
  .circle_progress {
    transform: rotate(-90deg);
  }
  .frame,
  .bar {
    fill: none;
  }
  .frame {
    stroke: #e6e6e6;
  }
  .bar {
    stroke: ${({ theme }) => theme.colors.primaryBlue100};
    stroke-linecap: round;
  }
  .value {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50px;
    color: ${({ theme }) => theme.colors.blueA700};
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -0.72px;
  }
`;
