import { presignedBody } from "@hooks/imjang/Imjang.interfaces";
import { toast } from "react-toastify";

export const makeLocalUrlForPreview = (img) => {
  if (typeof img === "string") {
    const blob = fetch(img, { method: "GET" })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        return url;
      })
      .catch((err) => {
        console.error("err", err);
        alert("미리보기를 실행할 수 없어요.");
        return null;
      });
    return blob;
  } else if (typeof img === "object" && img !== null) {
    let blob = URL.createObjectURL(img);
    return blob;
  } else return null;
};

export function uploadThreadFileToS3(presignedUrl: presignedBody, file: File) {
  const formData = new FormData();
  formData.append("key", presignedUrl.fields.key);
  formData.append("x-amz-algorithm", presignedUrl.fields["x-amz-algorithm"]);
  formData.append("x-amz-credential", presignedUrl.fields["x-amz-credential"]);
  formData.append("x-amz-date", presignedUrl.fields["x-amz-date"]);
  formData.append("policy", presignedUrl.fields.policy);
  formData.append("x-amz-signature", presignedUrl.fields["x-amz-signature"]);
  formData.append("file", file);
  formData.append("Content-Type", file.type);

  return fetch(presignedUrl.url, {
    method: "POST",
    body: formData,
  })
    .then((res) => {
      if (res.ok) {
        return true;
      } else {
        console.error("File upload failed with status:", res.status);
        return false;
      }
    })
    .catch((error) => {
      console.error("Error uploading file:", error);
      return false;
    });
}

// 자료, 보고서에 사용 (private 폴더)
export function uploadImageToS3(presignedUrl, file) {
  const formData = new FormData();
  // formData.append("acl", presignedUrl.fields.acl); // acl 없음
  formData.append("key", presignedUrl.fields.key);
  formData.append("x-amz-algorithm", presignedUrl.fields["x-amz-algorithm"]);
  formData.append("x-amz-credential", presignedUrl.fields["x-amz-credential"]);
  formData.append("x-amz-date", presignedUrl.fields["x-amz-date"]);
  formData.append("policy", presignedUrl.fields.policy);
  formData.append("x-amz-signature", presignedUrl.fields["x-amz-signature"]);
  formData.append("file", file);

  fetch(presignedUrl.url, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      if (response.status === 204) {
        toast(`${file.name} 업로드 완료`);
        console.log("File uploaded successfully");
      } else {
        console.error("File upload failed");
      }
    })
    .catch((error) => {
      console.error("Error uploading file:", error);
    });
}

// 컨설팅 등록에 사용 (public 폴더)
export function uploadImageToS3ReturnResult({ name, presignedUrl, file }) {
  if (!presignedUrl) {
    return null;
  }

  const formData = new FormData();
  formData.append("acl", presignedUrl.fields.acl);
  formData.append("key", presignedUrl.fields.key);
  formData.append("x-amz-algorithm", presignedUrl.fields["x-amz-algorithm"]);
  formData.append("x-amz-credential", presignedUrl.fields["x-amz-credential"]);
  formData.append("x-amz-date", presignedUrl.fields["x-amz-date"]);
  formData.append("policy", presignedUrl.fields.policy);
  formData.append("x-amz-signature", presignedUrl.fields["x-amz-signature"]);
  formData.append("file", file);

  return fetch(presignedUrl.url, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      if (response.status === 204) {
        console.log("File uploaded successfully");
        toast(`${name} 업로드 완료`);
        return name;
      } else {
        console.error("File upload failed");
        return name; // 실패 시 null 반환
      }
    })
    .catch((error) => {
      console.error("Error uploading file:", error);
      return name; // 실패 시 null 반환
    });
}
