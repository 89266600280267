export default function Calendar28() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_19755_686)">
        <path opacity="0.2" d="M28 0H0V28H28V0Z" fill="white" />
        <path
          opacity="0.2"
          d="M23.3334 2.3335H4.66675V25.6668H23.3334V2.3335Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M25.6667 23.3332V4.6665L2.33342 4.6665V23.3332H25.6667Z"
          fill="white"
        />
        <path opacity="0.2" d="M24.5 3.5H3.5V24.5H24.5V3.5Z" fill="white" />
        <path
          opacity="0.2"
          d="M14 19.25C16.8995 19.25 19.25 16.8995 19.25 14C19.25 11.1005 16.8995 8.75 14 8.75C11.1005 8.75 8.75 11.1005 8.75 14C8.75 16.8995 11.1005 19.25 14 19.25Z"
          fill="white"
        />
        <path
          d="M22.1667 4.66699H5.83333C4.54467 4.66699 3.5 5.71166 3.5 7.00033V21.0003C3.5 22.289 4.54467 23.3337 5.83333 23.3337H22.1667C23.4553 23.3337 24.5 22.289 24.5 21.0003V7.00033C24.5 5.71166 23.4553 4.66699 22.1667 4.66699Z"
          fill="#B2BEC9"
        />
        <path
          d="M24.5 10.5003H3.5V7.00033C3.5 5.71163 4.54463 4.66699 5.83333 4.66699H22.1667C23.4554 4.66699 24.5 5.71163 24.5 7.00033V10.5003Z"
          fill="#5D6873"
        />
        <path
          d="M8.16667 3.50016V4.66683L8.16515 3.50028L8.16667 3.50016ZM8.16667 2.3335C7.52232 2.3335 7 2.85581 7 3.50016V4.66683C7 5.31118 7.52232 5.8335 8.16667 5.8335C8.81102 5.8335 9.33333 5.31118 9.33333 4.66683V3.50016C9.33333 2.85581 8.81102 2.3335 8.16667 2.3335Z"
          fill="#B2BEC9"
        />
        <path
          d="M19.8334 3.50016V4.66683L19.8319 3.50028L19.8334 3.50016ZM19.8334 2.3335C19.1891 2.3335 18.6667 2.85581 18.6667 3.50016V4.66683C18.6667 5.31118 19.1891 5.8335 19.8334 5.8335C20.4778 5.8335 21.0001 5.31118 21.0001 4.66683V3.50016C21.0001 2.85581 20.4778 2.3335 19.8334 2.3335Z"
          fill="#B2BEC9"
        />
        <path
          d="M10.5001 12.8335H8.16675V15.1668H10.5001V12.8335Z"
          fill="white"
        />
        <path
          d="M15.1666 12.8335H12.8333V15.1668H15.1666V12.8335Z"
          fill="white"
        />
        <path d="M19.8333 12.8335H17.5V15.1668H19.8333V12.8335Z" fill="white" />
        <path d="M10.5001 17.5H8.16675V19.8333H10.5001V17.5Z" fill="white" />
        <path d="M15.1666 17.5H12.8333V19.8333H15.1666V17.5Z" fill="white" />
        <path d="M19.8333 17.5H17.5V19.8333H19.8333V17.5Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_19755_686">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
