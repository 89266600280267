import { useEffect, useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getCoreRowModel } from "@tanstack/react-table";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Pagination from "@components/core/paging/Pagination";
import "@components/table.css";
import Empty from "@components/common/Empty";

export default function WhiteListTable({ data }) {
  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("number", {
        cell: (info) => info.row.index + 1,
        header: "순번",
        maxSize: 5,
      }),
      columnHelper.accessor("ip", {
        cell: ({ getValue }) => {
          return (
            <p style={{ textAlign: "left", marginLeft: 40 }}>
              {getValue() as string}
            </p>
          );
        },
        header: "ip",
        maxSize: 70,
      }),
      columnHelper.accessor("memo", {
        header: "메모",
        maxSize: 25,
      }),
      // columnHelper.accessor("-", {
      //   header: "-",
      //   maxSize: 35,
      // }),
    ];
  }, []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="contentsData">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Empty data={data} title="컨텐츠가 없어요" />
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div``;

const StyledLink = styled(Link)`
  margin-right: auto;
  margin-left: 20px;
  color: ${({ theme }) => theme.colors.blueGray700};
  text-align: left;
  display: flex;
`;
