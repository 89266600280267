import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import SourceInput from "@components/page/admin/consultation/source/SourceInput";
import { Title } from "@components/styles";
import { useEditSource, useGetDetailedSource } from "@hooks/consultation";
import { uploadImageToS3 } from "@utils/file";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

export default function EditSource() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data } = useGetDetailedSource({ contentsId: id });
  const { mutate, isLoading } = useEditSource({ contentsId: id });
  const [sourceInfo, setSourceInfo] = useState<{
    title: string;
    data: { fileName: string; file: any };
    presignedUrl?: null | any;
  }>({
    title: "",
    data: { file: null, fileName: "" },
    presignedUrl: null,
  });

  useEffect(() => {
    if (data) {
      if (!sourceInfo.presignedUrl) {
        setSourceInfo({ title: data.title, data: data.data });
      }
    }
  }, [data]);

  const onSubmit = async () => {
    console.log(sourceInfo.data.file === data.data.file);
    if (sourceInfo.data.file === data.data.file) {
      console.log("파일 수정 없음");
    } else {
      await uploadImageToS3(sourceInfo.presignedUrl, sourceInfo.data.file);
    }
    const { presignedUrl, ...body } = { ...sourceInfo };
    body.data.file = null;
    console.log("🐙 ~ onSubmit ~ body:", body);
    mutate(body, {
      onSuccess: (res) => {
        alert("등록 완료");
        navigate(-1);
      },
      onError: () => {
        alert("등록에 실패했습니다. 다시 시도해주세요.");
      },
    });
  };

  return (
    <Container>
      <Title>데이터 자료 수정</Title>
      <SourceInput
        onSubmit={onSubmit}
        sourceInfo={sourceInfo}
        setSourceInfo={setSourceInfo}
      />
      {isLoading ? <AbsoluteFillLoading /> : null}
    </Container>
  );
}

const Container = styled.div``;
