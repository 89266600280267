import { AuthInstance } from "@api/index";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Notice } from "./notice.interfaces";

// 공지사항 목록 조회
export const useGetNoticeList = () =>
  useQuery<any, AxiosError, { message: string; announcements: Notice[] }>({
    queryKey: ["notice"],
    queryFn: async () => {
      const response = await AuthInstance({
        url: `/announcements`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 60 * 1000 * 5,
  });

// 공지사항 개별 조회
export const useGetNotice = () =>
  useMutation<{ announcement: Notice }, Error, { id: string | number }>(
    async ({ id }) => {
      const response = await AuthInstance({
        url: `/announcements/${id}`,
        method: "GET",
      });

      return response.data;
    }
  );

// 공지사항 등록
export const usePostNotice = () => {
  const queryClient = useQueryClient();
  return useMutation<object[], Error, Notice>({
    mutationFn: async (data) => {
      const response = await AuthInstance({
        url: `/announcements`,
        method: "POST",
        data,
      });

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["notice"],
      });
    },
  });
};

// 공지사항 수정
export const useEditNotice = () => {
  const queryClient = useQueryClient();
  return useMutation<object[], Error, { id: number | string; data: Notice }>({
    mutationFn: async ({ id, data }) => {
      const response = await AuthInstance({
        url: `/announcements/${id}`,
        method: "PATCH",
        data,
      });

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["notice"],
      });
    },
  });
};

// 공지사항 삭제
export const useDeleteNotice = () => {
  const queryClient = useQueryClient();
  return useMutation<{ announcement: Notice }, Error, { id: string | number }>({
    mutationFn: async ({ id }) => {
      const response = await AuthInstance({
        url: `/announcements/${id}`,
        method: "DELETE",
      });

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["notice"],
      });
    },
  });
};

// 인플루언서 공지사항 목록 조회
export const useGetNotificationList = () =>
  useQuery<any, AxiosError, { message: string; announcements: Notice[] }>({
    queryKey: ["notice"],
    queryFn: async () => {
      const response = await AuthInstance({
        url: `/announcements`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 60 * 1000 * 5,
  });
