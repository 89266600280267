import styled from "styled-components";
import GradientButton from "@components/button/GradientButton";
import ScheduleInputList from "./ScheduleInputList";
import ModalContainer from "@components/core/modal/ModalContainer";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useEditSchedule } from "@hooks/imjang";

export const ScheduleEditPopup = ({ row, isVisible, setIsVisible }) => {
  const { id } = useParams();
  const { mutate } = useEditSchedule({ detailId: id, scheduleId: row.id });
  const [userInfo, setUserInfo] = useState({
    danjiName: row.danjiName,
    description: row.description,
    meetingDate: row.meetingDate,
    meetingTime: row.meetingTime,
    meetingPlace: row.meetingPlace,
    agentOfficeName: row.agentOfficeName,
    agentOfficeContact: row.agentOfficeContact,
  });

  const handleVisible = () => {
    setIsVisible(!isVisible);
  };

  const handleEdit = () => {
    mutate(
      {
        ...row,
        danjiName: userInfo.danjiName,
        description: userInfo.description,
        meetingDate: userInfo.meetingDate,
        meetingTime: userInfo.meetingTime,
        meetingPlace: userInfo.meetingPlace,
        agentOfficeName: userInfo.agentOfficeName,
        agentOfficeContact: userInfo.agentOfficeContact,
      },
      {
        onSuccess: () => {
          handleVisible();
        },
        onError: () => {
          alert("스케줄 수정에 실패했습니다.");
        },
      }
    );
  };

  const isDone = useMemo(() => {
    return Object.values(userInfo).every((value) => value);
  }, [userInfo]);

  return (
    <>
      <ModalContainer
        isVisible={true}
        setIsVisible={handleVisible}
        title="스케줄 수정"
        onCloseButton
        bottomButton={
          <GradientButton
            isDone={isDone}
            text="수정 완료"
            onClick={handleEdit}
          />
        }
      >
        <Modal>
          <ScheduleInputList userInfo={userInfo} setUserInfo={setUserInfo} />
        </Modal>
      </ModalContainer>
    </>
  );
};

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;
  padding-bottom: 20px;
`;
