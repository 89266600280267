import styled from "styled-components";

export default function DateInput({
  placeholder,
  value,
  onChange,
  name,
  disabled = false,
}) {
  return (
    <Container>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          const inputValue = e.target.value.replace(/[^0-9]/g, "");
          let formattedValue = inputValue;
          if (inputValue.length > 4) {
            formattedValue = inputValue.slice(0, 4) + "-" + inputValue.slice(4);
          }
          if (inputValue.length > 6) {
            formattedValue =
              formattedValue.slice(0, 7) + "-" + inputValue.slice(6, 8);
          }
          if (inputValue.length <= 4) {
            formattedValue = inputValue;
          }
          if (inputValue.length <= 6 && inputValue.length > 4) {
            formattedValue = inputValue.slice(0, 4) + "-" + inputValue.slice(4);
          }
          onChange({ name, value: formattedValue });
        }}
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        disabled={disabled}
      />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 10px 8px 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  color: ${({ theme }) => theme.colors.blueGray700};
  font-size: 15px;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
  }
`;
