import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import BTypeInputBoxBtn from "@components/core/BTypeInput/BtypeInputBoxBtn";
import { useConsultantInfo } from "@features/useConsultantInfo";
import { useGetInfluencersList } from "@hooks/admin";
import { useEffect, useState } from "react";
import styled from "styled-components";

export default function ReportInfluencer() {
  const { globalConsultantInfo, setGlobalConsultantInfo } = useConsultantInfo();
  const { data } = useGetInfluencersList();
  const [influencerList, setInfluencerList] = useState([
    { label: "기고자 선택", value: undefined },
  ]);

  useEffect(() => {
    if (data) {
      const arr = data.influencers.map((el) => {
        return { label: el.nickname, value: el.nickname };
      });
      setInfluencerList([{ label: "기고자 선택", value: undefined }, ...arr]);
    }
  }, [data]);

  return (
    <Container>
      <BTypeInputBoxBtn
        inputType={InputTypeEnum.OnlyText}
        value={globalConsultantInfo.reportDetails.isShow}
        onChangeValue={(e) => {
          setGlobalConsultantInfo({
            reportDetails: { isShow: e.target.value === "true" },
          });
        }}
        style={{ padding: 0, width: 237 }}
        listData={[
          { label: "유무선택", value: undefined },
          { label: "있음", value: true },
          { label: "없음", value: false },
        ]}
      />
      <div style={{ width: 10 }} />
      <BTypeInputBoxBtn
        inputType={InputTypeEnum.OnlyText}
        value={globalConsultantInfo.reportDetails.influencerName}
        onChangeValue={(e) => {
          setGlobalConsultantInfo({
            reportDetails: {
              isShow: globalConsultantInfo.reportDetails.isShow,
              influencerName: e.target.value,
            },
          });
        }}
        style={{ padding: 0, width: 237 }}
        listData={influencerList}
        disabled={!globalConsultantInfo.reportDetails.isShow}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
`;
