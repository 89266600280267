import styled from "styled-components";
import ApartmentSelectInput from "./ApartmentSelectInput";
import BTypeCash48 from "@components/input/BTypeCash48/BTypeCash48";
import { useGetMaemulList } from "@api/imjang";

export default function ApartInput({ rateInfo, setRateInfo }) {
  const { mutate } = useGetMaemulList();

  const 가격_변경 = (danjiCode, scaleCode, tradeType) => {
    mutate(
      { danjiCode, scaleCode },
      { onSuccess: (res) => 평균_구하기({ data: res, tradeType }) }
    );
  };

  const 평균_구하기 = ({ data, tradeType }) => {
    let 호가_평균 = 0;
    let newArr = [];

    if (!data.maemulList.sellingInfoList.length) {
      setRateInfo((prev) => ({ ...prev, danjiPrice: 0 }));
      return;
    }

    if (tradeType === "sell" || tradeType === "none") {
      newArr = data.maemulList.sellingInfoList
        .filter((item) => item.sellPrice) // sellPrice가 존재하는 객체 필터링
        .map((item) => item.sellPrice);
    } else if (tradeType === "rent") {
      newArr = data.maemulList.sellingInfoList
        .filter((item) => item.deposit && item.businessDiv === "전세") // sellPrice가 존재하는 객체 필터링
        .map((item) => item.deposit);
    }

    const sum = newArr.reduce((acc, curr) => acc + curr, 0); // 배열 요소의 합
    // 10000 단위 아래 숫자는 버림
    호가_평균 = sum ? Math.round(sum / newArr.length / 10000) * 10000 : 0; // 합을 배열 길이로 나눔

    setRateInfo((prev) => ({ ...prev, danjiPrice: 호가_평균 }));
  };

  return (
    <Container>
      <ApartmentSelectInput
        placeholder="아파트 선택"
        value={{
          danjiName: rateInfo.danjiName,
          danjiCode: rateInfo.danjiCode,
          scaleCode: rateInfo.scaleCode,
          tradeType: rateInfo.tradeType,
        }}
        onChangeValue={(value) => {
          setRateInfo((prev) => ({
            ...prev,
            ...value,
          }));
          가격_변경(value.danjiCode, value.scaleCode, value.tradeType);
        }}
      />
      <BTypeCash48
        placeholder="아파트 가격"
        disabled={!rateInfo.danjiName}
        containerStyle={{ height: "fit-content" }}
        value={rateInfo.danjiPrice}
        onChangeValue={(value) =>
          setRateInfo((prev) => ({
            ...prev,
            danjiPrice: value,
          }))
        }
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
