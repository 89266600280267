import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DownArrow from "@assets/arrow/DownArrow";
import moment from "moment";
import Calendar28 from "@assets/icon/Calendar28";

interface Props {
  value: any;
  onChangeValue: (v: any) => void;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  isFutureAvailable?: boolean;
  isPastAvailable?: boolean;
  inputStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

export default function B1TypeDateInput({
  value,
  onChangeValue,
  label,
  disabled,
  inputStyle,
  placeholder,
  isFutureAvailable = true,
  isPastAvailable = true,
  style,
}: Props) {
  const DateFormat = (pick: Date) => {
    const result = moment(pick).format("YYYY-MM-DD");
    return result;
  };

  const datePickerRef = useRef(null);

  const handleClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const customInput = (
    props: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>
  ) => (
    <InputContainer
      $disabled={disabled}
      $value={value}
      style={{ ...inputStyle }}
      onClick={handleClick}
    >
      <CalendarClickArea>
        <Calendar28 />
        <p {...props}>{value || placeholder}</p>
      </CalendarClickArea>

      <div style={{ marginLeft: "auto" }}>
        <DownArrow />
      </div>
    </InputContainer>
  );

  const maxDate = useMemo(() => {
    return isFutureAvailable ? new Date("2900-01-01") : new Date();
  }, []);
  const minDate = useMemo(() => {
    return isPastAvailable ? new Date("1400-01-01") : new Date();
  }, []);

  return (
    <Container style={{ ...style }} $value={!!value} $disabled={disabled}>
      {label ? <label className="label">{label}</label> : null}
      <DatePicker
        maxDate={maxDate}
        minDate={minDate}
        locale={ko}
        onChange={(date) => onChangeValue(DateFormat(date))}
        customInput={React.createElement(React.forwardRef(customInput))}
        ref={datePickerRef}
      />
    </Container>
  );
}

const Container = styled.div<{
  $value: boolean;
  $disabled: boolean;
}>`
  position: relative;
  padding-top: 24px;
  cursor: pointer;

  .label {
    left: 24px;
    top: 2px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  .react-datepicker-wrapper {
    display: flex;
  }
`;

const InputContainer = styled.div<{
  $disabled: boolean;
  $value: any;
}>`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  margin: 8px 0;
  padding: 13px 15px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background-color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.blueGray100 : theme.colors.blueGrayA100};
  border-radius: 10px;

  p {
    margin-left: 10px;
    color: ${({ theme, $value }) =>
      $value ? theme.colors.blueGray800 : theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }
`;

const CalendarClickArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
