import styled from "styled-components";

export default function CalendarIcon() {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_1161_868)">
        <path
          d="M25.3333 5.3335H6.66667C5.19391 5.3335 4 6.5274 4 8.00016V24.0002C4 25.4729 5.19391 26.6668 6.66667 26.6668H25.3333C26.8061 26.6668 28 25.4729 28 24.0002V8.00016C28 6.5274 26.8061 5.3335 25.3333 5.3335Z"
          fill="#B2BEC9"
        />
        <path
          d="M28 12.0002H4V8.00016C4 6.52736 5.19387 5.3335 6.66667 5.3335H25.3333C26.8061 5.3335 28 6.52736 28 8.00016V12.0002Z"
          fill="#5D6873"
        />
        <path
          d="M9.33333 4.00008V5.33341L9.3316 4.00021L9.33333 4.00008ZM9.33333 2.66675C8.59693 2.66675 8 3.26368 8 4.00008V5.33341C8 6.06981 8.59693 6.66675 9.33333 6.66675C10.0697 6.66675 10.6667 6.06981 10.6667 5.33341V4.00008C10.6667 3.26368 10.0697 2.66675 9.33333 2.66675Z"
          fill="#B2BEC9"
        />
        <path
          d="M22.6666 4.00008V5.33341L22.6649 4.00021L22.6666 4.00008ZM22.6666 2.66675C21.9302 2.66675 21.3333 3.26368 21.3333 4.00008V5.33341C21.3333 6.06981 21.9302 6.66675 22.6666 6.66675C23.403 6.66675 23.9999 6.06981 23.9999 5.33341V4.00008C23.9999 3.26368 23.403 2.66675 22.6666 2.66675Z"
          fill="#B2BEC9"
        />
        <path
          d="M11.9999 14.6667H9.33325V17.3334H11.9999V14.6667Z"
          fill="white"
        />
        <path
          d="M17.3334 14.6667H14.6667V17.3334H17.3334V14.6667Z"
          fill="white"
        />
        <path d="M22.6667 14.6667H20V17.3334H22.6667V14.6667Z" fill="white" />
        <path d="M11.9999 20H9.33325V22.6667H11.9999V20Z" fill="white" />
        <path d="M17.3334 20H14.6667V22.6667H17.3334V20Z" fill="white" />
        <path d="M22.6667 20H20V22.6667H22.6667V20Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1161_868">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}

const Icon = styled.svg`
  cursor: pointer;
`;
