import { CheckedCircleStatusEnum } from "@components/core/icon/checked/CheckedCircle.interfaces";
import CheckedCircle24 from "@components/core/icon/checked/CheckedCircle24";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { useGetAuthorityUsers } from "@hooks/admin";
import {
  useAssignMadmin,
  useGetImjangApplyList,
  useImjangApplyList,
} from "@hooks/imjang";
import { useEffect, useState } from "react";
import styled from "styled-components";

export default function MatchButton() {
  const { data: manager } = useGetAuthorityUsers({ code: "B03" });
  const { data: appliedList } = useGetImjangApplyList({
    filter: {
      statusList: ["APPLIED"],
    },
  });
  const { mutate: match } = useAssignMadmin();
  const [isVisible, setIsVisible] = useState(false);
  const [selectedManager, setSelectedManager] = useState<string[]>([]);
  const [selectedApply, setSelectedApply] = useState<string[]>([]);

  const isDone = selectedManager.length > 0 && selectedApply.length > 0;

  const handleSelectManager = (item: string) => {
    if (selectedManager.includes(item)) {
      setSelectedManager(selectedManager.filter((v) => v !== item));
    } else {
      setSelectedManager([...selectedManager, item]);
    }
  };

  const handleSelectApply = (item: string) => {
    if (selectedApply.includes(item)) {
      setSelectedApply(selectedApply.filter((v) => v !== item));
    } else {
      setSelectedApply([...selectedApply, item]);
    }
  };

  const handleMatch = () => {
    if (!isDone) return;
    match(
      {
        madminIdList: selectedManager,
        applyIdList: selectedApply,
      },
      {
        onSuccess: () => {
          setIsVisible(false);
          setSelectedManager([]);
          setSelectedApply([]);
        },
      }
    );
  };

  useEffect(() => {
    if (!isVisible) {
      setSelectedManager([]);
      setSelectedApply([]);
    }
  }, [isVisible]);

  return (
    <>
      <Button onClick={() => setIsVisible(true)}>
        <span>담당자 지정</span>
      </Button>
      <PopUpContainer isVisible={isVisible} setIsVisible={setIsVisible}>
        <Modal>
          <h3>담당자 지정</h3>
          <Content>
            <div className="column">
              <span className="title">담당자</span>
              <div className="list">
                {manager?.map((item, index) => {
                  const isChecked = selectedManager.includes(item._id);
                  return (
                    <StyledRow
                      onClick={() => handleSelectManager(item._id)}
                      key={index}
                    >
                      <CheckedCircle24
                        status={
                          isChecked
                            ? CheckedCircleStatusEnum.Activate
                            : CheckedCircleStatusEnum.Default
                        }
                      />
                      <span>{item.name}</span>
                    </StyledRow>
                  );
                })}
              </div>
            </div>
            <Arrow />
            <div className="column">
              <span className="title">신청 고객</span>
              <div className="list">
                {appliedList?.applyList.map((item, index) => {
                  const isChecked = selectedApply.includes(item.applyId);
                  return (
                    <StyledRow
                      onClick={() => handleSelectApply(item.applyId)}
                      key={index}
                    >
                      <CheckedCircle24
                        status={
                          isChecked
                            ? CheckedCircleStatusEnum.Activate
                            : CheckedCircleStatusEnum.Default
                        }
                      />
                      <span>{item.name}</span>
                    </StyledRow>
                  );
                })}
              </div>
            </div>
          </Content>
          <BottomButton $isDone={isDone} onClick={handleMatch}>
            담당자 지정
          </BottomButton>
        </Modal>
      </PopUpContainer>
    </>
  );
}

const Button = styled.button`
  padding: 4px 20px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  span {
    color: ${({ theme }) => theme.colors.blue1000};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.32px;
    text-align: center;
  }
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px 20px;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.white};

  h3 {
    color: ${({ theme }) => theme.colors.blueGray1000};
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 47px;
  width: 100%;

  .column {
    padding-top: 16px;
    width: 290px;
    height: 354px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.blueGray100};

    .title {
      padding-left: 16px;
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: -0.32px;
    }

    .list {
      margin: 10px 8px 0;
      height: 300px;
      overflow-y: scroll;
    }
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  cursor: pointer;

  span {
    margin-left: 12px;
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;

const BottomButton = styled.button<{ $isDone: boolean }>`
  padding: 12px 0;
  width: 100%;
  border-radius: 10px;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.blue1000 : theme.colors.blueGray100};
  color: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.white : theme.colors.blueGray500};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
`;

const Arrow = () => {
  return (
    <div style={{ margin: 24 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="16"
        viewBox="0 0 30 16"
        fill="none"
      >
        <path
          d="M2 8L28 8"
          stroke="#1A2637"
          strokeWidth="2.5"
          strokeLinecap="round"
        />
        <path
          d="M22 2L28 8L22 14"
          stroke="#1A2637"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
