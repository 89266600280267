import styled from "styled-components";
import AddButton from "../../AddButton";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { useState } from "react";
import ConsultationMethodPopUp from "./ConsultationMethodPopUp";
import { useConsultantInfo } from "@features/useConsultantInfo";
import PencilIcon from "@assets/icon/PencilIcon";
import { Delete28 } from "@components/core/icon/delete";

export default function ConsultationMethod() {
  const { globalConsultantInfo, setGlobalConsultantInfo } = useConsultantInfo();

  const [isVisible, setIsVisible] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState(null);

  const openPopUp = () => {
    setIsVisible(true);
  };

  const handleDelete = (idx) => {
    if (window.confirm("선택한 상담방식을 삭제합니다.")) {
      const methodArr = [...globalConsultantInfo.methods];
      methodArr.splice(idx, 1);
      setGlobalConsultantInfo({ methods: methodArr });
    }
  };

  return (
    <Container>
      {globalConsultantInfo.methods.map((el, idx) => {
        return (
          <div key={idx}>
            <MethodCard>
              <Column>
                <Row>
                  <h5 className="head">상담방식</h5>
                  <p className="body">{el.type}</p>
                  <span />
                  <h5 className="head">상담시간</h5>
                  <p className="body">{el.time}분</p>
                  <span />
                  <h5 className="head">상담금액</h5>
                  <p className="body">{Number(el.price).toLocaleString()}원</p>
                  <span />
                  <h5 className="head">할인금액</h5>
                  <p className="body">
                    {Number(el.discount).toLocaleString()}원
                  </p>
                </Row>
                <div style={{ height: 12 }} />
                <Row>
                  <h5 className="head">상담설명</h5>
                  <p className="body" style={{ width: "80%" }}>
                    {el.detail}
                  </p>
                </Row>
              </Column>
              <Row style={{ marginLeft: "auto" }}>
                <div
                  className="iconBox"
                  onClick={() => {
                    openPopUp();
                    setSelectedIdx(idx);
                  }}
                >
                  <PencilIcon />
                </div>
                <div style={{ width: 20 }} />
                <div className="iconBox" onClick={() => handleDelete(idx)}>
                  <Delete28 />
                </div>
              </Row>
            </MethodCard>
            <div
              className="divider"
              style={{
                height:
                  idx === 1 || globalConsultantInfo.methods.length === 1
                    ? 0
                    : 1,
                margin: idx === 1 && 0,
              }}
            />
          </div>
        );
      })}
      {globalConsultantInfo.methods.length < 2 ? (
        <AddButton
          onClick={() => {
            openPopUp();
            setSelectedIdx(null);
          }}
        />
      ) : null}
      <PopUpContainer isVisible={isVisible} setIsVisible={setIsVisible}>
        <ConsultationMethodPopUp
          setIsVisible={setIsVisible}
          selectedIdx={selectedIdx}
        />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div`
  .divider {
    margin: 20px 0;
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.blueGray100};
  }
`;

const MethodCard = styled.div`
  display: flex;

  .head {
    margin-right: 24px;
    color: ${({ theme }) => theme.colors.blueGray600};
    font-size: 15px;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }

  .body {
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }

  span {
    margin: 0 16px;
    width: 1px;
    height: 22px;
    background: ${({ theme }) => theme.colors.blueGray100};
  }

  .iconBox {
    cursor: pointer;
  }
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
